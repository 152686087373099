import Validation from '@assets/scripts/components/validationChecks';
import Helpers from '@assets/scripts/helpers';
import Field from '@assets/scripts/components/field';
import i18n from '@assets/i18n';
import { flattenActionFields } from '@modules/FlowBuilder/components/actions';
import { store } from '@assets/scripts/components/store-proxy';
import { getStoreGetter } from '@assets/scripts/store/config';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { GET_ACTION_BY_ID } from '@modules/FlowBuilder/endpoints';

// translate function of vue-i18n
const { t } = i18n.global;

/**
 * Validate write block and returns erros array
 * for a given block
 *
 * @param {Object} block
 *  Block to get errors
 *
 * @returns {Array}
 *	array of errors
 */
export const validateWriteBlock = async (block) => {
	let output = [];

	const setBlockError = (description) => {
		output.push(Helpers.createErrorObject(description, block.guid));
	};

	if (!Validation.stringNotEmpty(block.config.guid)) {
		setBlockError(
			t('fb.flowErrors.actionNotChosen', {
				block: t('fb.blockTypes.write'),
			})
		);
	} else {
		const action = await useApiAsync(GET_ACTION_BY_ID, {
			keys: {
				guid: block.config.guid,
			}
		});

		if (!Validation.isNonEmptyObject(action)) {
			setBlockError(
				t('fb.flowErrors.actionNotExist', {
					block: t('fb.blockTypes.write'),
					action: `${block.config.name} - ${block.config.method}`,
				})
			);
		} else {
			// flatten input and output fields of action
			flattenActionFields(action);

			// check if requirds field are mapped in action of a field
			if (
				!Validation.requiredFieldsMappedInAction(
					action,
					block.config.mapping || []
				)
			) {
				setBlockError(
					t('fb.flowErrors.actionMappingMissing', {
						action: `${block.config.name} - ${block.config.method}`,
						block: t('fb.blockTypes.write'),
					})
				);
			}

			if (action.type !== 'ConnectionMethod') {
				// check if atleast one key filed is mapped
				if (!Validation.keyFieldMapped(action, block.config.mapping)) {
					setBlockError(
						t('fb.flowErrors.noMappingByKeyFieldInWrite', {
							block: t('fb.blockTypes.write'),
						})
					);
				}
	
				// check if atleast two fields are mapped
				if (!Validation.twoFieldsMapped(block.config.mapping)) {
					setBlockError(
						t('fb.flowErrors.noMappingByFields', {
							block: t('fb.blockTypes.write'),
						})
					);
				}
			}

			// get all block inputs
			const blockInputs = store.getters[getStoreGetter('BLOCK_INPUT', 'BLOCKS')](
				block.guid
			);

			block.config.mapping.forEach((mappingObject) => {
				const { fromField, toField } = Helpers.getFromToFields(
					blockInputs,
					mappingObject,
					action
				);

				// check if mapped field exist in the flow vars
				if (!Validation.mappedFieldExists(mappingObject, blockInputs)) {
					setBlockError(
						t('fb.flowErrors.actionMappingInputNotExist', {
							mappingObject: Field.getNameAsPath(
								mappingObject.from
							),
							action: `${block.config.name} - ${block.config.method}`,
							block: t('fb.blockTypes.write'),
						})
					);
				}

				// check if block input type match the action input type
				if (!Validation.fieldTypesCanBeMapped(fromField, toField)) {
					setBlockError(
						t('fb.flowErrors.typeNotMatchInAction', {
							mappingObject: Field.getNameAsPath(
								mappingObject.to
							),
							block: t('fb.blockTypes.write'),
							action: `${block.config.name} - ${block.config.method}`,
						})
					);
				}

				// check if the mapping of a required action field is
				// to a flow field that is also required
				if (toField && toField.validation.required && fromField) {
					if (!Validation.bothFieldsRequired(toField, fromField)) {
						setBlockError(
							t('fb.flowErrors.requiredFieldCanNotBeMappedToNonRequiredField', {
								fromField: Field.getNameAsPath(fromField.name),
								toField: Field.getNameAsPath(toField.name),
								block: t('fb.blockTypes.write'),
							})
						);
					}
				}

				// check if block input max length match the action input max length and regex
				if (fromField) {
					if (
						!Validation.fieldMaxLengthsCanBeMapped(fromField, toField)
					) {
						setBlockError(
							t('fb.flowErrors.maxLengthNotMatchInAction', {
								fromField: Field.getNameAsPath(fromField.name),
								fromFieldMaxLength: fromField.validation.max || t('general.none'),
								toField: Field.getNameAsPath(toField.name),
								toFieldMaxLength: toField.validation.max,
								block: t('fb.blockTypes.write'),
							})
						);
					}
				}
			});

			// validate output, with aliases
			if (block.config.output.length > 0) {
				const fieldAliases = Field.setFullAliases(block.config.output);

				fieldAliases.forEach((fieldAlias) => {
					//check if field exists in action output
					if (!Validation.fieldExistsInList(fieldAlias.name, action.output)) {
						setBlockError(
							t(
								'fb.flowErrors.fieldDoesNotExistInAction',
								{
									fieldName: Field.getNameAsPath(
										fieldAlias.name
									),
									block: t('fb.blockTypes.write'),
								}
							)
						);
					// check if alias is not empty
					} else if (!fieldAlias.alias) {
						setBlockError(
							t(
								'fb.flowErrors.aliasNotEntered',
								{
									fieldName: Field.getNameAsPath(
										fieldAlias.name
									),
									block: t('fb.blockTypes.write'),
								}
							)
						);
					} else {
						// count the occurences of the alias
						const count = fieldAliases.reduce((prev, curr) => {
							if (curr.alias.toLowerCase() === fieldAlias.alias.toLowerCase()) return prev + 1;
							else return prev;
						}, 0);

						// throw error if alias occurs more than 1 time
						if (count > 1) {
							setBlockError(
								t(
									'fb.flowErrors.aliasNotUnique',
									{
										alias: fieldAlias.alias,
										block: t('fb.blockTypes.write'),
									}
								)
							);
						} else {
							// check if given name (alias) already exists in flow variables
							const fieldExists = blockInputs.some((inputField) => {
								return inputField.name.toLowerCase() === fieldAlias.alias.toLowerCase();
							});

							if (fieldExists) {
								setBlockError(
									t(
										'fb.flowErrors.duplicatedNameInFlowVars',
										{
											fieldName: Field.getNameAsPath(fieldAlias.alias),
											block: t('fb.blockTypes.write'),
										}
									)
								);
							}
						}
					}
				});
			}
		}
	}
	return output;
};
