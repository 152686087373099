import {
	getStoreAction,
	getStoreMutation,
	getStoreGetter,
} from '@assets/scripts/store/config';
import { log, debug } from '@assets/scripts/components/notifications';
import setLoader from '@assets/scripts/store/loader';
import Helpers from '@assets/scripts/helpers';
import {
	validateActionDocument,
	createNewActionDocument,
	constructActionDocument,
} from '@modules/ActionDocument/components/action-document';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import i18n from '@assets/i18n';

import {
	GET_ACTION_DOCUMENT,
	DELETE_ACTION_DOCUMENT,
	POST_ACTION_DOCUMENT,
} from '@modules/ActionDocument/endpoints';

// translate function of vue-i18n
const { t } = i18n.global;

export const names = {
	TOGGLE_CREATE_NEW: 'toggleCreateNewDocument',
	NEW_ACTION_DOCUMENT: 'newActionDocument',
	UNSET_ACTION_DOCUMENT: 'unsetActionDocument',
	ACTION_DOCUMENT_UPDATED: 'actionDocumentUpdated',
	UPDATE_ACTION_DOCUMENT_LIST: 'updateActionDocumentList',
	VALIDATE_CURRENT_ACTION_DOCUMENT: 'validateCurrentActionDocument',
	SAVE_CURRENT_ACTION_DOCUMENT: 'saveCurrentActionDocument',
	DELETE_ACTION_DOCUMENT: 'deleteActionDocument',
	PUBLISH_CURRENT_ACTION_DOCUMENT: 'publishCurrentActionDocument',
	LOAD_AND_SHOW_ACTION_DOCUMENT: 'loadAndShowActionDocument',
	RESET: 'reset',
};

export default {
	[names.TOGGLE_CREATE_NEW]({ commit }, status = true) {
		commit(getStoreMutation('TOGGLE_CREATE_NEW', 'AD'), !!status, {
			root: true,
		});
	},
	/**
	 * Action triggered when a new Action Document must be created
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {Object} settings
	 *  Settings for the new Action Document
	 *
	 * @returns {void}
	 */
	async [names.NEW_ACTION_DOCUMENT]({ commit }, settings) {
		// show loader
		setLoader('new-action-document');

		// create new Action Document object with given settings
		const newActionDocument = createNewActionDocument(settings);

		// commit mutation in to set new Document
		commit(getStoreMutation('SET_CURRENT_ACTION_DOCUMENT', 'AD'), newActionDocument, {
			root: true,
		});

		// unset create new
		commit(getStoreMutation('TOGGLE_CREATE_NEW', 'AD'), false, {
			root: true,
		});

		// mark new document as modified so it can be saved immediately
		commit(
			getStoreMutation('MARK_MODIFIED', 'AD'),
			{},
			{
				root: true,
			}
		);

		// remove loader
		setLoader('new-action-document', false);

		// show success message
		log(t('ad.newActionDocument.createdSuccess'), 'success');
	},
	[names.UNSET_ACTION_DOCUMENT]({ commit }) {
		// reset current action document
		commit(getStoreMutation('UNSET_ACTION_DOCUMENT', 'AD'), null, { root: true });

		// unset
		commit(getStoreMutation('TOGGLE_CREATE_NEW', 'AD'), false, {
			root: true,
		});
	},
	[names.ACTION_DOCUMENT_UPDATED]({ dispatch }, conn_guid) {
			// dispatch action to reload action document list
			dispatch(
				getStoreAction('UPDATE_ACTION_DOCUMENT_LIST', 'AD'),
				conn_guid || false,
				{ root: true }
			);
	},
	[names.UPDATE_ACTION_DOCUMENT_LIST](store, conn_guid) {
		// do nothing
		// only exists so components can subscribe to it
	},
	/**
	 * Action triggered to validate the current action document
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	[names.VALIDATE_CURRENT_ACTION_DOCUMENT]({ commit, dispatch }) {
		// prepare for validation
		dispatch(getStoreAction('PREPARE_VALIDATION'), null, { root: true });

		// get current action document from store
		const currentActionDocument = this.getters[getStoreGetter('CURRENT_ACTION_DOCUMENT', 'AD')];

		// setTimeout is needed becouse we use async functions and we want the dom to be updated to show the loader first
		// setTimeout without time given we use 1 ms just to be sure that it works everywhere
		// we should fix this with better solution in the future because the async function for a reason are blocking the dom update
		setTimeout(async () => {
			const errors = await validateActionDocument(currentActionDocument);

			// loop errors and add to the errors array
			errors.forEach((error) => {
				commit(
					getStoreMutation('ADD_VALIDATION_ERROR'),
					error,
					{ root: true }
				);
			});

			// finish validation
			dispatch(getStoreAction('FINISH_VALIDATION'), null, { root: true });
		}, 1);
	},
	/**
	 * Action triggered to save the current Action Document
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	async [names.SAVE_CURRENT_ACTION_DOCUMENT]({ dispatch }) {
		// get current action document from store, and prepare it for post
		const actionDocument = constructActionDocument(this.getters[getStoreGetter('CURRENT_ACTION_DOCUMENT', 'AD')]);

		// post action document through api
		const result = await useApiAsync(POST_ACTION_DOCUMENT, {
			keys: {
				connection: actionDocument.conn_guid,
				guid: actionDocument.guid,
			},
			parameters: {
				...actionDocument
			}
		});

		if (result !== false) {
			debug('succesfully saved', actionDocument);
			
			// show success message
			log(t('ad.documentDetails.savedSuccessfully'), 'success');
	
			// dispatch action to take needed steps after saving
			dispatch(
				getStoreAction('ACTION_DOCUMENT_UPDATED', 'AD'),
				actionDocument.conn_guid,
				{ root: true }
			);
	
			// dispatch action to unset action document
			dispatch(getStoreAction('UNSET_ACTION_DOCUMENT', 'AD'), null, { root: true });
		}
	},
	/**
	 * Action triggered to delete n action document with a
	 * given GUID and Connection GUID
	 *
	 * @param {String} guid
	 *  GUID of action document
	 *
	 * @param {String} conn_guid
	 * GUID of Connection
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	async [names.DELETE_ACTION_DOCUMENT]({ dispatch }, { guid, conn_guid }) {
		// do API call
		const result = await useApiAsync(DELETE_ACTION_DOCUMENT, {
			keys: {
				connection: conn_guid,
				guid
			}
		});

		if (result !== false) {
			// show debug message in console
			debug('successful delete', {
				guid,
				conn_guid,
				result,
			});
	
			// dispatch action to take needed steps after deleting
			dispatch(
				getStoreAction('ACTION_DOCUMENT_UPDATED', 'AD'),
				conn_guid,
				{ root: true }
			);
	
			// show success message
			log(t('ad.documentDelete.success'), 'success');

		} else {
			// show debug message in console
			debug('error in delete', {
				guid,
				conn_guid,
			});
		}
	},
	/**
	 * Action triggered to publish the current Action Document
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	async [names.PUBLISH_CURRENT_ACTION_DOCUMENT]({ commit, dispatch }) {
		// mark action document as published
		commit(getStoreMutation('SET_PUBLISHED_STATE', 'AD'), true, {
			root: true,
		});

		// get current action document from store, and prepare it for post
		const actionDocument = constructActionDocument(this.getters[getStoreGetter('CURRENT_ACTION_DOCUMENT', 'AD')]);

		// post action document through api
		const result = await useApiAsync(POST_ACTION_DOCUMENT, {
			keys: {
				connection: actionDocument.conn_guid,
				guid: actionDocument.guid,
			},
			parameters: {
				...actionDocument
			}
		});

		if (result !== false) {
			debug('successfully published', actionDocument);
	
			// show success message
			log(t('ad.documentDetails.publish.success'), 'success');
	
			// dispatch action to take needed steps after publishing
			dispatch(
				getStoreAction('ACTION_DOCUMENT_UPDATED', 'AD'),
				actionDocument.conn_guid,
				{ root: true }
			);
	
			// dispatch action to unset action document
			dispatch(getStoreAction('UNSET_ACTION_DOCUMENT', 'AD'), null, { root: true });
		} else {
			// mark action document as not published if anything
			// went wrong
			commit(getStoreMutation('SET_PUBLISHED_STATE', 'AD'), false, {
				root: true,
			});
		}
	},
	/**
	 * Action triggered to load an existing Action Document
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {String} guid
	 *  GUID of Action Document to load
	 *
	 * @param {String} conn_guid
	 *  Connection GUID of Action Document
	 *
	 * @returns {void}
	 */
	async [names.LOAD_AND_SHOW_ACTION_DOCUMENT](
		{ commit, dispatch },
		{ guid, conn_guid }
	) {
		// load action document from api
		const document = await useApiAsync(GET_ACTION_DOCUMENT, {
			keys: {
				guid,
				connection: conn_guid
			}
		});

		if (!document) {
			log(t('ad.error.loadActionDocument'), 'danger');
			return;
		}

		// commit mutation in root store
		commit(getStoreMutation('SET_CURRENT_ACTION_DOCUMENT', 'AD'), Helpers.cloneObject(document), {
			root: true,
		});
	},
	[names.RESET]({ commit }) {
		// reset state of store
		commit(getStoreMutation('RESET', 'AD'), null, { root: true });
	},
};
