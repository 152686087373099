import cloneObject from './cloneObject';
import createErrorObject from './createErrorObject';
import getFromToFields from './getFromToFields';
import isObject from './isObject';
import debounce from './debounce';
import focusContentEditable from './focusContentEditable';
import { forceInt, forceDecimal, removeWhitespace } from './formatInput';
import getKeyByValue from './getKeyByValue';
import getPropValueByProp from './getPropValueByProp';
import {
	mapObjectArray,
	mapObjectArrayForSelect,
	mapObjectArrayForSelectUsingMeta,
} from './mapping';
import newGuid from './newGuid';
import obj from './object';
import date from './date';
import {
	filterByValue,
	filterByString,
	getSearchableColumns,
} from './filterTable';
import trueish from './trueish';
import validateEmail from './validateEmail';

export default {
	cloneObject,
	createErrorObject,
	getFromToFields,
	isObject,
	debounce,
	focusContentEditable,
	forceInt,
	forceDecimal,
	removeWhitespace,
	getKeyByValue,
	getPropValueByProp,
	mapObjectArray,
	mapObjectArrayForSelect,
	mapObjectArrayForSelectUsingMeta,
	newGuid,
	obj,
	date,
	filterByValue,
	filterByString,
	getSearchableColumns,
	trueish,
	validateEmail,
};

export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_PROD = process.env.NODE_ENV === 'production';
export const NODE_ENV = process.env.NODE_ENV;
