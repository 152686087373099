<template>
	<VField
		:isFloating="isFloating"
		:label="label"
		:class="[{ 'is-active': isActive }, { 'has-hidden-label': labelHidden && isActive }, fieldClass]"
		:isError="showError"
	>
		<VInput
			v-model="value"
			:placeholder="label"
			:disabled="disabled || null"
			type="text"
			@input="inputChanged"
		/>
	</VField>

	<div class="notification-wrapper" v-if="showError">
		<VNotification
			:text="$t('error.enterValue')"
			:isFullwidth="false"
			:hasClose="false"
			type="danger"
		/>
	</div>
</template>

<script>
export default {
	name: 'VInputString',
};
</script>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import Field from '@assets/scripts/components/field';

const props = defineProps({
	fieldClass: {
		type: String,
		default: '',
	},
	valueModel: {
		type: null,
	},
	validModel: {
		type: Boolean,
		default: false,
	},
	trim: {
		type: Boolean,
		default: false,
	},
	isRequired: {
		type: Boolean,
		default: false,
	},
	label: {
		type: String,
	},
	isFloating: {
		type: Boolean,
		default: true,
	},
	trimFunction: {
		type: Function,
		default: Field.trimChildName,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	labelHidden: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits(['update:valueModel', 'update:validModel']);
    
const value = computed({
	get() {
		return typeof props.valueModel === 'string' ? props.valueModel : '';
	},
	set(_value) {
		emit('update:valueModel', _value);
	},
});

const modified = ref(false);

const isActive = computed(() => value.value !== '');
const isValid = computed(() => !props.isRequired || isActive.value);
const showError = computed(() => modified.value && !isValid.value);

const inputChanged = () => {
	modified.value = true;
	if (props.trim) {
		value.value = props.trimFunction(value.value);
	}
};

watch(isValid, (newVal) => {
	emit('update:validModel', newVal);
});

onMounted(() => {
	if (value.value !== props.valueModel) {
		emit('update:valueModel', value.value);
	}
	emit('update:validModel', isValid.value);
});
</script>
