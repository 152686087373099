/**
 * Translations for English
 * See https://vue-i18n.intlify.dev/guide/ on how to use
 */
 export default {
	pageTitle: 'Segment Builder',
	permissions: {
		label: 'Segment builder',
		info: 'Permissions connected to the Segment builder',
		use: {
			label: 'Use Segment builder',
			info: 'Permission to use the Segment Builder',
		},
	},
};
