<template>
	<VButton
		:disabled="isDisabled"
		@clicked.prevent.stop="callbackFn"
	/>
</template>

<script>
export default {
	name: 'SaveButton',
};
</script>

<script setup>
import useKeyboardShortcuts from '@assets/scripts/composables/useKeyboardShortcuts';

const props = defineProps({
	callbackFn: {
		type: Function,
		required: true,
	},
	isDisabled: {
		type: Boolean,
		default: false,
	},
});

// initiate keyboard shortcut for saving
useKeyboardShortcuts({ save: () => {
	if (!props.isDisabled) props.callbackFn();
}});
</script>
