<template>
	<DrawerItem
		:id="id"
		:drawerIndex="drawerIndex"
		:title="$t('functionLists.drawerName')"
		:hasJsonButton="false"
		:hasFullwidthBody="true"
		:closeOnBackgroundClick="true"
		:hasScrollableContent="true"
	>
		<div class="content-header has-padding has-bottom-divider">
			<div class="field is-grouped is-flex-grow-1">
				<VField
					v-if="!config.conn_guid"
					:label="$t('functionLists.connections')"
					:isFloating="true"
					class="has-margin-right-1"
					:class="{ 'is-active': connectionValue }"
				>
					<VSelect
						:options="connectionOptions"
						v-model="connectionValue"
					/>
				</VField>

				<VSearch v-model="search" />
			</div>
		</div>
		<SortableDataTable
			:rowClickedFn="rowClicked"
			defaultSort="field_name"
			tableClasses="scrollable-content"
			:data="filteredData"
			:columns="columns"
			:emptyText="$t('functionLists.table.noResults')"
		/>
	</DrawerItem>
</template>

<script>
import Helpers from '@assets/scripts/helpers';
import { getStoreMutation } from '@assets/scripts/store/config';
import { formatForFunctionListsTable } from '@assets/scripts/components/function-lists';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import {
	GET_FUNCTION_LISTS,
	GET_CONNECTIONS,
	GET_FUNCTION_LISTS_BY_CONNECTION,
} from '@assets/scripts/api/config';
import filterConnections from '@assets/scripts/components/filterConnections';

export default {
	name: 'FunctionListsDrawer',
	props: {
		/**
		 * Index of this drawer
		 */
		drawerIndex: {
			type: Number,
			required: true,
			default: 0,
		},
		/**
		 * Unique key of this drawer
		 */
		id: {
			type: String,
			required: true,
		},
		/**
		 * Variable type the drawer is opened for
		 */
		config: {
			default: {
				field: {},
				conn_guid: false,
				callback: () => {},
				useFieldPicker: false,
			},
		},
	},
	data: function () {
		return {
			lists: [],
			connections: [],
			// default value for Connection filter
			connectionValue: this.$t('general.all'),
			// values for Connection filter, will be extended
			// on mount with all available Connections
			connectionOptions: [
				{
					value: this.$t('general.all'),
					text: this.$t('general.all'),
				},
			],
			search: '',
			columns: [
				{
					label: this.$t('functionLists.table.columns.list_name'),
					field: 'list_name',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('functionLists.table.columns.field_name'),
					field: 'field_name',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('functionLists.table.columns.field_type'),
					field: 'field_type',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t(
						'functionLists.table.columns.field_validation'
					),
					field: 'field_validation',
					sortable: false,
					searchable: false,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('functionLists.table.columns.connection'),
					field: 'connection',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
			],
		};
	},
	mounted: async function () {
		if (this.config.conn_guid) {
			this.lists = await useApiAsync(GET_FUNCTION_LISTS_BY_CONNECTION, {
				keys: {
					connection: this.config.conn_guid,
				},
			});
		} else {
			this.lists = await useApiAsync(GET_FUNCTION_LISTS);
		}

		this.connections = await useApiAsync(GET_CONNECTIONS);

		if (this.connections && this.connections.length > 0) {
			// map Connection objects to use in select field
			this.connectionOptions.push(
				...Helpers.mapObjectArrayForSelect(
					filterConnections(this.connections, 'DocumentBuilder'),
					{
						key: 'conn_guid',
						text: 'name',
					}
				)
			);
		}
	},
	computed: {
		tableFields: function () {
			return formatForFunctionListsTable(
				this.lists,
				this.connections,
				this.config.field
			);
		},
		searchableCols: function () {
			return Helpers.getSearchableColumns(this.columns);
		},
		filteredData: function () {
			// loop over all table rows
			const newData = this.tableFields.filter((row) => {
				// filter on Connection
				return Helpers.filterByValue(
					row,
					'conn_guid',
					this.connectionValue,
					this.$t('general.all')
				);
			});

			// return if no rows match
			if (this.search.length === 0 || newData.length === 0)
				return newData;

			// filter on search string
			return Helpers.filterByString(
				newData,
				this.search,
				this.searchableCols
			);
		},
	},
	methods: {
		closeDrawer: function () {
			this.$store.commit(getStoreMutation('CLOSE_DRAWER'), this.id);
		},
		getRowClass: function (row) {
			return row.disabled ? 'is-disabled' : false;
		},
		rowClicked: function (row) {
			if (row.disabled) return false;

			// if needed, open the drawer to allow user to choose fields
			// from the chosen function list to use
			if (this.config.useFieldPicker) {
				// open drawer with function list fields
				this.$store.commit(getStoreMutation('OPEN_DRAWER'), {
					type: 'functionListFieldPicker',
					data: {
						callback: (...args) => {
							this.config.callback(...args);
							// close this drawer
							this.closeDrawer();
						},
						name: this.config.field.name,
						fields:
							this.config.field.validation.element.ref.fields || [],
						guid: row.guid,
						map: row.field_name,
						editMode: true, // always true when you are in FunctionListsDrawer
					},
				});
			} else {
				// use callback fn with Function List
				// GUID and selected field name as params
				this.config.callback(row.guid, row.field_name);

				// close drawer
				this.closeDrawer();
			}
		},
	},
};
</script>
