import { staticValue } from '@assets/scripts/api/config';
import Field from '@assets/scripts/components/field';
import { isEmpty } from 'lodash';

/***********************/
/******* GENERAL *******/
/***********************/

/**
 * Checks if the given default value for a field
 * of a given type matches that type and is set
 *
 * @param {String} type
 *  Type of the field
 *
 * @param {Any} value
 *  Current configured value
 *
 * @returns {Boolean}
 */
const staticValueSet = (type, value) => {
	return (
		// field of type Boolean and configured value is boolean
		(type === 'Boolean' && booleanNotEmpty(value)) ||
		// number entered as default value
		(['Number', 'Longnumber', 'Decimal'].includes(type) &&
			numberNotEmpty(value)) ||
		// string entered as default value
		(type === 'String' && stringNotEmpty(value))
	);
};

/**
 * Validates the value for a field with the
 * global set validation in the available var types
 *
 * @param {String} type
 *  Type of the field to validate
 *
 * @param {Any} value
 *  Currently configured default value for the field
 *
 * @returns {String/Boolean}
 *  False if validation passed, error message if failed
 */
const validateDefaultValue = (type, value) => {
	let result = false;

	// get validation rules for specific var type
	const fieldValidation = Field.getValidationForType(type);

	if (fieldValidation) {
		if (
			// check if value matches RegEx if it exists
			(fieldValidation.regex &&
				!new RegExp(fieldValidation.regex).test(value.toString())) ||
			// check for minimum and maximum of numbers
			(typeof fieldValidation.min === 'number' &&
				value < fieldValidation.min) ||
			(typeof fieldValidation.max === 'number' &&
				value > fieldValidation.max)
		) {
			result = fieldValidation.error;
		}
	}

	return result;
};

// STRING

/**
 * Check if a string is not empty
 *
 * @param {Any} value
 *  Value to check
 *
 * @returns {Boolean}
 */
const stringNotEmpty = (value) => {
	return typeof value === 'string' && value.trim() !== '';
};

/**
 * Check if a number is not empty
 *
 * @param {Any} value
 *  Value to check
 *
 * @returns {Boolean}
 */
const numberNotEmpty = (value) => {
	return typeof value === 'number';
};

/**
 * Check if a Boolean is not empty
 *
 * @param {Any} value
 *  Value to check
 *
 * @returns {Boolean}
 */
const booleanNotEmpty = (value) => {
	return typeof value === 'boolean';
};

/**
 * Check if a string has minimum 2 characters
 *
 * @param {String} value
 *  String to check
 *
 * @returns {Boolean}
 */
const stringHasAtleastTwoCharacters = (value) => {
	return typeof value === 'string' && value.length >= 2;
};

/**
 * Check if flow name matches the regex /
 *
 * @param {String} value
 *  String to check
 *
 * @returns {Boolean}
 */
const flowNameDoesNotMatchRegex = (value) => {
	return value.match('(^[/])|([^A-Za-z0-9_/])|(\\/\\/)|([/]$)');
};

/**
 * Check if a string is without spacing
 *
 * @param {String} value
 *  String to check
 *
 * @returns {Boolean}
 */
const stringWithoutSpacing = (value) => {
	return !/\s/.test(value);
};

/**
 * Check if two strings match
 *
 * @param {String} value1
 *  First value to check
 *
 * @param {String} value2
 *  Second value to check
 *
 * @returns {Boolean}
 */
const stringsMatch = (value1, value2) => {
	return valuesMatch(value1, value2);
};

/**
 * Check if a value is unique in a list
 *
 * @param {String} value
 *  Value to check
 *
 * @param {Array} list
 *  List of values
 *
 * @returns {Boolean}
 */
const stringIsUniqueInList = (value, list) => {
	return !list.some((item, idx) => {
		return list.indexOf(item) !== idx && value.toLowerCase() === item.toLowerCase();
	});
};

/**
 * Check if referenceList exist in a list
 *
 * @param {String} fieldRegex
 *  fieldRegex to check
 *
 * @param {Array/Object} list
 *  List of values
 *
 * @returns {Boolean}
 */
const checkIfReferenceListExist = (list, fieldRegex) => {
	return list.includes(fieldRegex);
};

/**
 * Check if foreignReferenceList exist in a list
 *
 * @param {String} fieldRegex
 *  fieldRegex to check
 *
 * @param {Array/Object} list
 *  List of values
 *
 * @returns {Boolean}
 */
const checkIfForeignReferenceListExist = (list, fieldRegex) => {
	let result = false;
	list.forEach((obj) => {
		if(obj.value === fieldRegex) {
			result = true;
		};
	});
	return result;
};

/**
 * Check if a value exists in a list
 *
 * @param {String} value
 *  Value to check
 *
 * @param {Array} list
 *  List of values
 *
 * @returns {Boolean}
 */
const stringExistsInList = (value, list) => {
	return list.includes(value);
};

// OBJECT & ARRAY

/**
 * Check for non empty object
 *
 * @param {Any} input
 *  Value to check
 *
 * @returns {Boolean}
 */
const isNonEmptyObject = (input = false) => {
	return (typeof input === 'object' && !isEmpty(input));
};

/**
 * Check for non empty array
 *
 * @param {Any} input
 *  Value to check
 *
 * @returns {Boolean}
 */
const isNonEmptyArray = (array) => {
	return Array.isArray(array) && array.length > 0;
};

/**
 * Counts the objects inside a list that have a given property
 * that matches the given value
 *
 * @param {Array} list
 *  List of objects to check
 *
 * @param {String} prop
 *  Name of the property to find
 *
 * @param {Any} val
 *  Value of the property to count
 *
 * @returns {Integer}
 */
const countObjectsWithProperty = (list, prop, val = true) => {
	let matches = [];

	// check if list is non-emtpy
	if (isNonEmptyArray(list)) {
		// start filtering
		matches = list.filter((el) => {
			// check if element is object with prop that matches type
			// of given value to check
			if (!isNonEmptyObject(el) || typeof el[prop] !== typeof val) {
				return false;
			}

			// strings are matched trimmed and lowercased
			if (typeof val === 'string') {
				return el[prop].trim().toLowerCase() === val.trim().toLowerCase();
			} else {
				return el[prop] === val;				
			}
		});
	}

	return matches.length;
};

/**
 * Checks if the given value of a given prop is unique within
 * a list of object
 *
 * @param {Array} list
 *  List of objects to check
 *
 * @param {String} prop
 *  Name of the property to check
 *
 * @param {Any} val
 *  Value of the property to count
 *
 * @returns {Boolean}
 */
const propIsUnique = (list, prop, val = true) => {
	return countObjectsWithProperty(list, prop, val) <= 1;
};

// FIELD

/**
 * Check if a field has a type
 *
 * @param {Object} field
 *  Field to check
 *
 * @returns {Boolean}
 */
const fieldHasType = (field) => {
	return stringNotEmpty(field.type);
};

/**
 * Check if a field type is known
 *
 * @param {Object} field
 *  Field to check
 *
 * @returns {Boolean}
 */
const fieldTypeExists = (field) => {
	return Field.getVarType(field.type);
};

/**
 * Checks if a given field is configured to use a static
 * default value
 *
 * @param {Object} field
 *  Field to check
 *
 * @returns {Boolean}
 */
const fieldUsesStaticDefault = (field) => {
	return valuesMatch(field.default.type, staticValue);
};

/**
 * Check if static default has value
 *
 * @param {Object} field
 *  Field to check
 *
 * @returns {Boolean}
 */
const fieldHasStaticDefault = (field) => {
	return (
		// field does not use static default
		!fieldUsesStaticDefault(field) ||
		// static value is set
		staticValueSet(field.type, field.default.value)
	);
};

/**
 * Check if validation regex match between two fields
 *
 * @param {Object} validation1
 *  Validation object 1
 *
 * @param {object} validation2
 * Validation object 1
 *
 * @returns {Boolean}
 */
const fieldValidationsRegexMatch = (validation1, validation2) => {
	return valuesMatch(validation1.regex, validation2.regex);
};

/**
 * Check if both given fields are marked as required
 *
 * @param {Object} field1
 * 	Object of first field to check
 *
 * @param {Object} field2
 * 	Object of second field to check
 *
 * @returns {Boolean}
 */
const bothFieldsRequired = (field1, field2) => {
	let result = false;

	if (
		field1 && field1.validation.required &&
		field2 && field2.validation.required
	)
		result = true;

	return result;
};

/**
 * Checks if a field with a given name exists in
 * given list of fields
 *
 * @param {String} name
 *  name of field to check
 * 
 * @param {Array} list
 *  List of available fields
 *
 * @returns {Boolean}
 */
const fieldExistsInList = (name, list) => {
	return list.some((field) => stringsMatch(field.name, name));
};

/**
 *	Checks if max length validation is set for a given field
 * 
 * @param {Object} field
 *  Field to check
 *
 * @returns {Boolean}
 */
const fieldMaxLengthSet = (field) => {
	return (
		field &&
		field.validation &&
		field.validation.max &&
		typeof field.validation.max === 'number' &&
		field.validation.max > 0
	);
};

/***********************/
/**** FLOW BUILDER *****/
/***********************/

// ADD BLOCK

/**
 * Check if system default in ADD block has value
 *
 * @param {Object} field
 *  Field to check
 *
 * @returns {Boolean}
 */
const fieldHasSystemDefaultInAddBlock = (field) => {
	return (!valuesMatch(field.add_type, 'System') || stringNotEmpty(field.value));
};

/**
 * Checks if a given field is configured to use a static
 * default value
 *
 * @param {Object} field
 *  Field to check
 *
 * @returns {Boolean}
 */
const fieldUsesStaticDefaultInAddBlock = (field) => {
	return valuesMatch(field.add_type, 'Static');
};

/**
 * Check if static default in ADD block has value
 *
 * @param {Object} field
 *  Field to check
 *
 * @returns {Boolean}
 */
const fieldHasStaticDefaultInAddBlock = (field) => {
	return (
		// field does not use static default
		!fieldUsesStaticDefaultInAddBlock(field.add_type) ||
		// static value is set
		staticValueSet(field.type, field.value)
	);
};

/**
 * Check if default function has been chosen
 *
 * @param {Object} field
 *  Field to check
 *
 * @returns {boolean}
 */
const fieldHasFunctionDefault = (field) => {
	return (!valuesMatch(field.add_type, 'Function') || field.function_guid);
};

// READ BLOCK

/**
 * Check if sort object has either direction ASC or DESC
 *
 * @param {Object} 	sortObject
 *  sort object of field
 *
 * @returns {Boolean}
 */
const isAscOrDesc = (sortObject) => {
	return ['ASC', 'DESC'].includes(sortObject.direction);
};

// RESULT BLOCK

/**
 * Check if given field used as output exists in list of fields
 *
 * @param {Object} output
 *  Output field
 *
 * @param {Array} list
 *  List of fields
 *
 * @returns {Boolean}
 */
const outputExists = (output, list) => {
	return fieldExistsInList(output.name, list);
};

// FILTERING & CONDITIONS

/**
 * Check if an condition field has a right operator
 *
 * @param {Object} condition
 *  condition object
 *
 * @param {Array} operators
 *  array of operators
 *
 * @returns {Boolean}
 */
const conditionHasCorrectOperator = (condition, operators) => {
	return operators.some((operator) => {
		return (
			operator.value === condition.operator &&
			operator.types.includes(Field.getVarType(condition.type))
		);
	});
};

/**
 * Check if an condition field has a right amount of values for operator
 *
 * @param {Object} condition
 *  condition object
 *
 * @param {Array} operators
 *  array of operators
 *
 * @returns {Boolean}
 */
const conditionHasCorrectAmountOfValues = (
	condition,
	operators
) => {
	return operators.some((operator) => {
		return (
			operator.value === condition.operator &&
			(
				operator.inputCount === condition.values.length ||
				(
					operator.inputCount === -1 &&
					typeof operator.minInput === 'number' &&
					operator.minInput <= condition.values.length
				)
			)
		);
	});
};

/**
 * Check if condition has an operator
 *
 * @param {Object} condition
 *  condition for input
 *
 * @returns {Boolean}
 */
const conditionHasOperator = (condition) => {
	return stringNotEmpty(condition.operator);
};

/**
 * Check if value 2 is greater than value 1,
 * used for 'between' operator
 *
 * @param {Object} condition
 *  condition for input
 *
 * @returns {Boolean}
 */
const conditionValue2IsGreaterThanValue1 = (
	condition
) => {
	let result = true;
	if (condition.value.length === 2) {
		return condition.value[0] < condition.value[1];
	}
	return result;
};

/**
 * Check if given field exists in list
 *
 * @param {Object} condition
 *  Condition object
 *
 * @param {Array} list
 *  List of fields
 *
 * @returns {Boolean}
 */
const fieldInConditionExists = (condition, list) => {
	return fieldExistsInList(condition.name, list);
};

/**
 * Check if field used as value in condition exists in available fields
 *
 * @param {Object} conditionValue
 *  Condition value name
 *
 * @param {Array} list
 *  List of fields
 *
 * @returns {Boolean}
 */
const valueFieldInConditionExists = (
	conditionValue,
	list
) => {
	return list.some((field) => stringsMatch(field.name, conditionValue));
};

// MAPPING

/**
 * check if at least one key field is mapped
 *
 * @param {Object} objectDefinition
 *  Object definition for the document 
 *
 * @param {Array} mappedFields
 *  List of mappings
 *
 * @returns {Boolean}
 */
const keyFieldMapped = (objectDefinition, mappedFields) => {
	return mappedFields.some((field) => {
		return objectDefinition.input.find(
			// input is a key field and mapped or input has default value for example guid or date
			// if input has default value it will get a value form BE so it does't have to be mapped
			(input) => input.key && ((valuesMatch(field.to, input.name) && stringNotEmpty(field.from)) || input.has_default)
		);
	});
};

/**
 * Check if at least two fields are mapped
 *
 * @param {Array} mappedFields
 *  List of mappings
 *
 * @returns {Boolean}
 */
const twoFieldsMapped = (mappedFields) => {
	return (
		mappedFields.filter((field) => {
			return stringNotEmpty(field.from);
		}).length >= 2
	);
};

/**
 * Check if all required fields are mapped in default function of a field
 *
 * @param {Object} fieldFunction
 *  Function definition
 *
 * @param {Array} mappedFields
 *  List of mappings
 *
 * @returns {Boolean}
 */
const requiredFieldsMappedInFunction = (
	fieldFunction,
	mappedFields
) => {
	return checkIfRequiredFieldsMapped(fieldFunction.input, mappedFields);
};

/**
 * Check if all required fields are mapped in action of a field
 *
 * @param {Object} action
 *  Action definition
 *
 * @param {Array} mappedFields
 *  List of mappings
 *
 * @returns {Boolean}
 */
const requiredFieldsMappedInAction = (action, mappedFields) => {
	return checkIfRequiredFieldsMapped(action.input, mappedFields);
};

/**
 * Check if all required fields are mapped in external connector
 *
 * @param {Object} externalConnector
 *  ExternalConnector definition
 *
 * @param {Array} mappedFields
 *  List of mappings
 *
 * @returns {Boolean}
 */
const requiredFieldsMappedInExternalConnector = (
	externalConnector,
	mappedFields
) => {
	return checkIfRequiredFieldsMapped(externalConnector.input, mappedFields);
};

/**
 * Check if given mapped field exists in list
 *
 * @param {Object} mappingObject
 *  The mapping object with a 'from' and 'to'
 *
 * @param {Array} list
 *  List of fields
 *
 * @returns {Boolean}
 */
const mappedFieldExists = (mappingObject, list) => {
	return (
		mappingObject.from === '' ||
		list.some((input) => stringsMatch(input.name, mappingObject.from))
	);
};

/**
 * Check if field type of mapped fields match
 *
 * @param {Object} field1
 *  Field object
 *
 * @param {Object} field2
 *  Field object
 *
 * @returns {Boolean}
 */
const mappedFieldTypesMatch = (field1, field2) => {
	return !field1 || !field2 || stringsMatch(field1.type, field2.type);
};

/**
 * Checks whether two given fields can be mapped to each other
 * N.B.: Order of the first 2 parameters matter. For instance, when
 * strict is off fields of type Number can be mapped to LongNumber,
 * but NOT the other way around
 * 
 * @param {Object} from 
 *  Object of field to map from
 *
 * @param {Object} to
 *  Object of field to map to
 *
 * @param {Boolean} strict
 *  Indicates wheter to use strict mapping, i.e. only
 *  allow mapping between same var types
 *
 * @returns {Boolean}
 */
const fieldTypesCanBeMapped = (from, to, strict = false) => {
	return !from?.type || !to?.type || Field.fieldCanBeMappedToType(from, to.type, strict);
}

/**
 * Checks whether two given fields can be mapped to each other, based on
 * their (optional) max length
 * N.B.: Order of the first 2 parameters matter. For instance, when
 * strict is off a String with max length 20 can be mapped to String with max
 * length 50, but NOT the other way around
 * 
 * @param {Object} from 
 *  Object of field to map from
 *
 * @param {Object} to
 *  Object of field to map to
 *
 * @param {Boolean} strict
 *  Indicates wheter to use strict mapping, i.e. only
 *  allow mapping with identical max length
 *
 * @returns {Boolean}
 */
const fieldMaxLengthsCanBeMapped = (from, to, strict = false) => {
	// if max length of To field is not set, result is true
	if (typeof to?.validation?.max !== 'number') return true;
	
	// if max length of From is not a number, result is false
	if (typeof from?.validation?.max !== 'number') return false;

	// check for exact match
	if (strict) return from.validation.max === to.validation.max;
	// check for match or less
	else return from.validation.max <= to.validation.max;
}

/***********************/
/******* HELPERS *******/
/***********************/

/**
 * Check if two variables match in both type
 * and value
 *
 * @param {Any} value1
 *  First value to check
 *
 * @param {Any} value2
 *  Second value to check
 *
 * @returns {Boolean}
 */
const valuesMatch = (value1, value2) => {
	return value1 === value2;
};

/**
 * check if requirds field are mapped
 *
 * @param {Array} input
 * inputs to check with
 *
 * @param {Array} fields
 *  mappedfields in block
 *
 * @returns {Boolean}
 */
const checkIfRequiredFieldsMapped = (input, fields) => {
	let result = true;
	input.forEach((input) => {
		// input is required and mapped and input has no default value for example guid or date
		// if input has default value it will get a value form BE so it does't have to be mapped
		if (input.validation.required && result && !input.has_default) {
			result =
				fields.filter(
					(field) => valuesMatch(input.name, field.to) && stringNotEmpty(field.from)
				).length > 0;
		}
	});

	return result;
};

export default {
	validateDefaultValue,

	// String
	stringNotEmpty,
	stringWithoutSpacing,
	stringHasAtleastTwoCharacters,
	stringsMatch,
	stringIsUniqueInList,
	stringExistsInList,
	flowNameDoesNotMatchRegex,

	// Object & array
	isNonEmptyObject,
	isNonEmptyArray,
	countObjectsWithProperty,
	propIsUnique,

	// Field
	fieldHasType,
	fieldTypeExists,
	fieldUsesStaticDefault,
	fieldHasStaticDefault,
	fieldValidationsRegexMatch,
	bothFieldsRequired,
	fieldExistsInList,
	fieldMaxLengthSet,

	// FLOWBUILDER

	// Add block
	fieldHasSystemDefaultInAddBlock,
	fieldUsesStaticDefaultInAddBlock,
	fieldHasStaticDefaultInAddBlock,
	fieldHasFunctionDefault,

	// Read block
	isAscOrDesc,

	// Result block
	outputExists,

	// Filtering & conditions
	conditionHasCorrectOperator,
	conditionHasCorrectAmountOfValues,
	conditionHasOperator,
	conditionValue2IsGreaterThanValue1,
	fieldInConditionExists,
	valueFieldInConditionExists,

	// Mapping
	keyFieldMapped,
	twoFieldsMapped,
	requiredFieldsMappedInFunction,
	requiredFieldsMappedInAction,
	requiredFieldsMappedInExternalConnector,
	mappedFieldExists,
	mappedFieldTypesMatch,
	fieldTypesCanBeMapped,
	fieldMaxLengthsCanBeMapped,

	// Metadata Builder
	checkIfReferenceListExist,
	checkIfForeignReferenceListExist,
};
