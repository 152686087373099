import { debug } from '@assets/scripts/components/notifications';

/**
 * Composable for getting and setting cached API responses
 */
let cache = {};

const getCacheKey = (endpoint, parameters = {}) => {
	let { cacheKey } = endpoint;

	// check if parameters are given
	if (Object.keys(parameters).length > 0) {
		// loop over parameters
		for (const param in parameters) {
			// replace placeholders in cacheKey with param value
			cacheKey = cacheKey.replaceAll(`{${param}}`, parameters[param]);
		}
	}

	return cacheKey;
};

export const setCache = (data, endpoint, parameters = {}) => {
	if (data === null || !endpoint || !endpoint?.cacheKey) return;

	const cacheKey = getCacheKey(endpoint, parameters);

	// set data to cache object
	cache[cacheKey] = data;

	debug('-- CACHE -- stored', {
		key: cacheKey,
		data,
		cache,
	});
};

export const getCache = (endpoint, parameters = {}) => {
	if (!endpoint || !endpoint?.cacheKey) return null;

	const cacheKey = getCacheKey(endpoint, parameters);

	const data = Object.hasOwn(cache, cacheKey) ? cache[cacheKey] : null;

	if (data === null) {
		debug('-- CACHE -- miss', { key: cacheKey, cache });
	} else {
		debug('-- CACHE -- hit', { key: cacheKey, data, cache });
	}

	return data;
};

export const clearCache = () => {
	cache = {};

	debug('-- CACHE -- cleared');
};