/**
 * Translations for English
 * See https://vue-i18n.intlify.dev/guide/ on how to use
 */
 export default {
	pageTitle: 'Datacleansing',
	permissions: {
		label: 'Datacleansing',
		info: 'Permissions connected to the Datacleansing module',
		use: {
			label: 'Use Datacleansing',
			info: 'Permission to use the Datacleansing module',
		},
	},
};
