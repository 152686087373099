import Helpers from '@assets/scripts/helpers';
import {
	getVariablesFromStartInput,
	getVariablesFromAppendInput,
	getVariablesFromAction,
	mergeFlowVariables,
	getVariablesFromExternalConnector,
} from '@modules/FlowBuilder/components/flow-variables';
import { blockMeta } from '@modules/FlowBuilder/endpoints';
import Field from '@assets/scripts/components/field';

import { validateStartBlock } from '@modules/FlowBuilder/components/validation/validateStartBlock';
import { validateAddBlock } from '@modules/FlowBuilder/components/validation/validateAddBlock';
import { validateReadBlock } from '@modules/FlowBuilder/components/validation/validateReadBlock';
import { validateExternalConnectorBlock } from '@modules/FlowBuilder/components/validation/validateExternalConnectorBlock';
import { validateWriteBlock } from '@modules/FlowBuilder/components/validation/validateWriteBlock';
import { validateCheckBlock } from '@modules/FlowBuilder/components/validation/validateCheckBlock';
import { validateErrorBlock } from '@modules/FlowBuilder/components/validation/validateErrorBlock';
import { validateConfigBlock } from '@modules/FlowBuilder/components/validation/validateConfigBlock';
import { validateResultBlock } from '@modules/FlowBuilder/components/validation/validateResultBlock';

// available block types
const blockTypes = {
	FlowItemAppend: 'ADD',
	FlowItemCheck: 'CHECK',
	FlowItemClose: 'CLOSE',
	FlowItemExternal: 'EXTERNAL',
	FlowItemActionRead: 'READ',
	FlowItemErrorResult: 'ERROR',
	FlowItemResult: 'RESULT',
	FlowItemStartApi: 'START',
	FlowItemActionWrite: 'WRITE',
	FlowItemConfig: 'CONFIG',
};

/**
 * Returns a newly created block
 *
 * @param {String} type
 *  Type of Block to create
 *
 * @param {Array} inPort
 *  Array of block GUIDs to add to incoming
 *  port of new block
 *
 * @param {Array} outPort
 *  Array of block GUIDs to add to outgoing
 *  port of new block
 *
 * @param {Array} falsePort
 *  Array of block GUIDs to add to false outgoing
 *  port of new block
 *
 * @returns {Object}
 *  New block (normalized)
 */
export const createNewBlock = (
	type,
	inPort = [],
	outPort = [],
	falsePort = []
) => {
	return Helpers.obj.create(blockMeta, {
		guid: Helpers.newGuid(),
		type: getFullBlockType(type),
		in: inPort || [],
		out: outPort || [],
		false: falsePort || [],
	});
};

/**
 * Adds default field values to given block
 * used for positioning block on canvas
 *
 * @param {Object} block
 *  Block to add fields to (normalized)
 *
 * @returns {void}
 */
export const resetBlockPositionFields = (block) => {
	Helpers.obj.setProp('pos|x', block, 0, true);
	Helpers.obj.setProp('pos|y', block, 0, true);
	Helpers.obj.setProp('cols|col_true', block, 0, true);
	Helpers.obj.setProp('cols|col_false', block, 0, true);
};

/**
 * Returns the Globally Unique Identifier (GUID)
 * for a given block
 *
 * @param {Object} block
 *  Block to get GUID for (normalized)
 *
 * @returns {String/Boolean}
 *  Found GUID or false
 */
export const getBlockGuid = (block) => {
	return block.guid || '';
};

/**
 * Finds and returns the requested coordinate
 * for a given block
 *
 * @param {Object} block
 *  Block to get coordinate for (normalized)
 *
 * @param {String} pos
 *  Requested coordinate, either 'x' or 'y'
 *
 * @returns {Int}
 *  Requested coordinate or 0 if not found
 */
export const getBlockPosition = (block, pos = 'x') => {
	return Helpers.obj.getProp('pos|' + pos.toLowerCase(), block, 0);
};

/**
 * Checks if a given block is of a given type
 *
 * @param {Object} block
 *  Block to check type for (normalized)
 *
 * @param {String} type
 *  Type to check for
 *
 * @returns {Boolean}
 */
export const blockIsOfType = (block, type) => {
	return getBlockType(block) === type;
};

/**
 * Updates one of the coordinates for a given block
 *
 * @param {Object} block
 *  Block to update coordinate for (normalized)
 *
 * @param {String} pos
 *  Coordinate to update, either 'x' or 'y'
 *
 * @param {Int} value
 *  Value to update the coordinate to
 *
 * @returns {void}
 */
export const setBlockPosition = (block, pos = 'x', value = 0) => {
	Helpers.obj.setProp('pos|' + pos.toLowerCase(), block, value, true);
};

/**
 * Finds and returns the requested child column count
 * on a specific side for a given block
 *
 * @param {Object} block
 *  Block to get child columns for (normalized)
 *
 * @param {String} side
 *  Requested side, either 'COL_TRUE' or 'COL_FALSE'
 *
 * @returns {Int}
 *  Requested child column count or 0 if not found
 */
export const getBlockChildCol = (block, side = 'COL_TRUE') => {
	return Helpers.obj.getProp('cols|' + side.toLowerCase(), block, 0);
};

/**
 * Updates the count of child columns on a given
 * side of a given block
 *
 * @param {Object} block
 *  Block to update (normalized)
 *
 * @param {String} side
 *  Side to update count for, either 'COL_TRUE' or 'COL_FALSE'
 *
 * @param {Int} count
 *  Value to update
 */
export const setBlockChildCol = (block, side = 'COL_TRUE', count = 0) => {
	Helpers.obj.setProp('cols|' + side.toLowerCase(), block, count, true);
};

/**
 * Returns GUID's of all blocks that connect
 * to given block through given port
 *
 * @param {Object} block
 *  Block Object to get connections for (normalized)
 *
 * @param {String} port
 *  Port to get connections for, one of:
 *  IN, OUT or FALSE
 *
 * @returns {Array}
 *  Array of GUID's of connected
 */
export const getBlockConnections = (block, port = 'IN') => {
	return Helpers.obj.getProp(port.toLowerCase(), block, []);
};

/**
 * Sets GUID's of all blocks that connect
 * to given block through given port
 *
 * @param {Object} block
 *  Block Object to set connections for (normalized)
 *
 * @param {String} port
 *  Port to set connections for, one of:
 *  IN, OUT or FALSE
 *
 * @param {Array} guids
 *  List of GUID's to set for port
 *
 * @returns {void}
 */
const setBlockConnections = (block, port = 'IN', guids = []) => {
	Helpers.obj.setProp(port.toLowerCase(), block, guids, true);
};

/**
 * Returns GUID's of all child blocks of
 * given block
 *
 * @param {Object} block
 *  Block Object to get children for (normalized)
 *
 * @returns {Array}
 *  Array of GUID's of children
 */
export const getBlockChildren = (block) => {
	return []
		.concat(getBlockConnections(block, 'OUT'))
		.concat(getBlockConnections(block, 'FALSE'));
};

/**
 * Updates a port of a given block by adding a GUID of
 * another block to it, or by replacing an existing GUID
 * with the GUID of another block
 *
 * @param {Object} block
 *  Block to update connection for (normalized)
 *
 * @param {String/Boolean} newGuid
 *  GUID to add to given port, or false if an
 *  existing GUID should just be deleted
 *
 * @param {String} port
 *  Port to update, either 'IN', 'OUT' or 'FALSE'
 *
 * @param {String/Boolean} replace
 *  GUID to replace in connection or false if
 *  new GUID should be added without replacing
 *  an existing value
 *
 * @returns {void}
 */
export const updateBlockConnection = (block, newGuid, port, replace) => {
	if (!block) return;

	// get current GUIDs from port
	const guids = Helpers.cloneObject(getBlockConnections(block, port));

	if (!replace && newGuid) {
		// add new GUID to list
		guids.push(newGuid);
	} else if (replace && !newGuid) {
		// remove existing GUID from list (if it is there)
		if (guids.indexOf(replace) !== -1) {
			guids.splice(guids.indexOf(replace), 1);
		}
	} else if (replace && newGuid) {
		// replace existing GUID with new GUID
		if (guids.indexOf(replace) !== -1) {
			guids[guids.indexOf(replace)] = newGuid;
		}
	}

	// set GUIDs in port
	setBlockConnections(block, port, guids);
};

/**
 * Checks if a given Block splits the flow
 * in multiple streams
 *
 * @param {Object} block
 *  Block Object to check (normalized)
 *
 * @returns {Boolean}
 *  True if given Block splits the flow,
 *  false otherwise
 */
export const isSplittingBlock = (block) => {
	return blockIsOfType(block, 'CHECK');
};

/**
 * Checks whether a given block is a 'Central Axis'
 * Block, i.e. has a X coordinate of 0
 *
 * @param {Object} block
 *  Block to check (normalized)
 *
 * @returns {Boolean}
 */
export const isCentralAxisBlock = (block) => {
	return block && ['START', 'RESULT'].indexOf(getBlockType(block)) !== -1;
};

/**
 * Checks if a given block is a result block
 *
 * @param {Object} block
 *  Block to check (normalized)
 *
 * @returns {Boolean}
 */
export const isResultBlock = (block) => {
	return blockIsOfType(block, 'RESULT');
};

/**
 * Checks if a given block can be deleted
 *
 * @param {Object} block
 *  Block to be deleted (normalized)
 *
 * @returns {Boolean}
 */
export const blockCanBeDeleted = (block) => {
	return !(
		!getBlockType(block) ||
		['START', 'RESULT'].indexOf(getBlockType(block)) !== -1
	);
};

/**
 * Checks if a given block can be deleted by the user
 *
 * @param {Object} block
 *  Block to be deleted (normalized)
 *
 * @returns {Boolean}
 */
export const blockCanBeDeletedByUser = (block) => {
	return !(
		!getBlockType(block) ||
		['START', 'RESULT', 'CLOSE', 'ERROR'].indexOf(getBlockType(block)) !==
			-1
	);
};

/**
 * Find the (simple) type of a given block
 *
 * @param {Object} block
 *  Block to find type for (normalized)
 *
 * @returns {String/Boolean}
 *  Found type or false
 */
export const getBlockType = (block) => {
	const type = block.type;
	return blockTypes[type] || false;
};

/**
 * Find full block type based on given simple type
 *
 * @param {String} type
 *  Simple type to find full type for
 *
 * @returns {String/Boolean}
 *  Found type or false
 */
const getFullBlockType = (type) => {
	return Helpers.getKeyByValue(blockTypes, type);
};

/**
 * Sets output for given block
 *
 * @param {Object} block
 * 	Block to set output for (normalized)
 *
 * @param {Array} output
 * 	New output of block
 *
 * @returns {void}
 */
export const setBlockOutput = (block, output = []) => {
	Helpers.obj.setProp('output', block, output, true);
};

/**
 * Gets Config of given block
 *
 * @param {Object} Block
 *  Block Object to get Config for (normalized)
 *
 * @returns {Object}
 *  Config object
 */
export const getBlockConfig = (block) => {
	return Helpers.obj.getProp('config', block, {});
};

/**
 * Gets Config property value of given block
 *
 * @param {Object} Block
 *  Block Object to get Config prop for (normalized)
 *
 * @param {String} prop
 *  Name of the requested property. Parents and children are
 *  separated by '|'. So 'parent|child' checks for the existence
 *  of object[parent][child]
 *
 * @param {Any} defaultReturn
 *  Return value to use if property is not found
 *
 * @returns {Any}
 *  String (property value) if property found, otherwise
 *  the given value of defaultReturn
 */
export const getBlockConfigProp = (block, prop, defaultReturn = false) => {
	return Helpers.obj.getProp(
		'config|' + prop.toLowerCase(),
		block,
		defaultReturn
	);
};

/**
 * Function to get the current output of
 * a given Block
 *
 * @param {Object} block
 * 	Block to get output for (normalized)
 *
 * @returns {Array}
 *  Output of given block
 */
export const getBlockOutput = (block) => {
	return Helpers.obj.getProp('output', block, []);
};

/**
 * Calculates and returns Flow JSON output
 * for a given block
 *
 * @param {Object} block
 *  Block to calculate output for (normalized)
 *
 * @param {Array} input
 *  Input for given Block
 *
 * @returns {Array}
 *	Calculated output
 */
export const calculateBlockOutput = async (block, input = []) => {
	// get block type
	const type = getBlockType(block);

	if (['CLOSE', 'ERROR'].indexOf(type) !== -1) {
		// Close and Error block have no output
		// return empty array
		return [];
	} else if (type === 'START') {
		// for Start block the output matches the configured expected input
		return await getVariablesFromStartInput(block.config.input);
	} else if (type === 'ADD') {
		// add Append config to output
		return mergeFlowVariables(await getVariablesFromAppendInput(block.config.add), input);
	} else if (type === 'READ' || type === 'WRITE') {
		// add result of Read or Write Action to flow vars
		return mergeFlowVariables(await getVariablesFromAction(block.config), input);
	} else if (type === 'EXTERNAL') {
		// add result of External Connector to flow vars
		return mergeFlowVariables(await getVariablesFromExternalConnector(block.config), input);
	}

	// for Check, Result and Config block the output simply matches the input
	// so no further action needed for those block types

	return input;
};

/**
 * Validate and returns erros array
 * for a given block
 *
 * @param {Object} block
 *  Block to get errors
 *
 * @returns {Array}
 *	array of errors
 */
export const validateBlock = async (block) => {
	let output = [];

	const blockType = getBlockType(block);
	//**********************//
	// validate start block //
	//**********************//
	if (blockType === 'START') {
		output = await validateStartBlock(block);
	}
	//********************//
	// validate add block //
	//********************//
	else if (blockType === 'ADD') {
		output = await validateAddBlock(block);
	}
	//********************//
	// validate read block //
	//********************//
	else if (blockType === 'READ') {
		output = await validateReadBlock(block);
	}
	//********************//
	// validate external connector block //
	//********************//
	else if (blockType === 'EXTERNAL') {
		output = await validateExternalConnectorBlock(block);
	}

	//********************//
	// validate write block //
	//********************//
	else if (blockType === 'WRITE') {
		output = await validateWriteBlock(block);
	}

	//********************//
	// validate check block //
	//********************//
	else if (blockType === 'CHECK') {
		output = await validateCheckBlock(block);
	}

	//********************//
	// validate error block //
	//********************//
	else if (blockType === 'ERROR') {
		output = await validateErrorBlock(block);
	}

	//********************//
	// validate config block //
	//********************//
	else if (blockType === 'CONFIG') {
		output = await validateConfigBlock(block);
	}

	//********************//
	// validate result block //
	//********************//
	else if (blockType === 'RESULT') {
		output = await validateResultBlock(block);
	}

	return output;
};

/**
 * Creates and returns fully constructed versions of
 * given blocks, to be used in communication with
 * the Nominow API
 *
 * @param {Array} blocks
 * Array of normalized blocks
 *
 * @returns {Array}
 *  Array of constructed versions of given blocks
 */
export const constructBlocks = (blocks = []) => {
	const result = [];

	// loop over blocks
	blocks.forEach((block) => {
		const flowBlock = Helpers.cloneObject(block);

		// determine block type
		const type = getBlockType(flowBlock);

		if (type) {

			// for Start blocks the configured fields must be changed from
			// dot notated parent-child relations, to a multi-dimensional array
			if (type === 'START') {				
				flowBlock.config.input = Field.nestChildFields(flowBlock.config.input);
			}
			// for Result blocks the configured output fields must be changed from
			// dot notated parent-child relations, to a multi-dimensional array
			else if (type === 'RESULT') {
				flowBlock.config.output = Field.nestOutputFields(flowBlock.config.output);
			}
			// for Read & Write blocks the configured input mapping fields must be changed from
			// dot notated parent-child relations, to a multi-dimensional array
			// AND the configured output fields must be changed from
			// dot notated parent-child relations, to a multi-dimensional array
			else if (type === 'READ' || type === 'WRITE') {
				flowBlock.config.mapping = Field.nestMappingFields(flowBlock.config.mapping);
				flowBlock.config.output = Field.nestOutputFields(flowBlock.config.output);
			}
			// for External Connector block the configured input mapping fields must be changed from
			// dot notated parent-child relations, to a multi-dimensional array
			else if (type === 'EXTERNAL') {
				flowBlock.config.mapping = Field.nestMappingFields(flowBlock.config.mapping);
			}

			result.push(
				// construct block using type as selector to drop
				// all properties that are not relevant for this type
				Helpers.obj.construct(flowBlock, blockMeta, type.toLowerCase())
			);
		}
	});

	return result;
};

/**
 * Flattens block fields where needed. Changes multi-dimensional
 * array to linear array with parent/child relations noted with
 * dot-notation, like: Parent.Child.GrandChild
 *
 * @param {Array} blocks
 *  Array of normalized blocks
 *
 * @returns {Array}
 *  Array of normalized blocks, flattened where applicable
 */
export const flattenBlocks = (blocks = []) => {
	// loop over blocks
	blocks.forEach((block) => {

		// determine block type
		const type = getBlockType(block);

		if (type === 'START') {
			// get input fields of start block
			const input = Helpers.obj.getProp('config|input', block, []);

			// flatten fields from multi-dimensional structure
			// to linear array
			Field.flattenFields(input);
		} else if (type === 'RESULT') {
			// get output fields of result block
			const output = Helpers.obj.getProp('config|output', block, []);
			
			// flatten fields from multi-dimensional structure
			// to linear array
			Field.flattenFields(output);
		} else if (type === 'READ' || type === 'WRITE') {
			// get input mapping fields of read or write block
			const mapping = Helpers.obj.getProp('config|mapping', block, []);
			
			// flatten fields from multi-dimensional structure
			// to linear array
			Field.flattenMapping(mapping);

			// get output fields of Read or Write block
			const output = Helpers.obj.getProp('config|output', block, []);
			
			// flatten fields from multi-dimensional structure
			// to linear array
			Field.flattenFields(output);
		}
	});

	return blocks;
};