const realms = {
	USER: 'UIServices/Users',
	CLIENTADMIN: 'UIServices/ClientAdmin',
	FLOWBASE: 'UIServices/FlowBase'
};

export const staticValue = 'staticvalue';

// used by multiple modules
export const simpleConnectionMeta = {
	conn_guid: {
		name: 'FlowConnectionGuid',
		type: 'string',
		default: '',
	},
	name: {
		name: 'Name',
		type: 'string',
		default: '',
	},
	modules: {
		name: 'AvailableModules',
		type: 'array',
		default: [],
	},
};

// naming used in definition of GetListByName endpoint
export const listMeta = {
	items: {
		name: 'Items',
		type: 'array',
		default: [],
		children: {
			key: {
				name: 'key',
				default: '',
			},
			type: {
				name: 'type',
				default: '',
			},
			value: {
				name: 'value',
				default: '',
			},
		},
	},
};

export const functionListRefFieldsMeta = {
	guid: {
		name: 'DocumentGuid',
		default: '',
		omitIfEmpty: true,
	},
	conn_guid: {
		name: 'ConnectionGuid',
		default: '',
		omitIfEmpty: true,
	},
	map: {
		name: 'MappingField',
		default: '',
		omitIfEmpty: true,
	},
	fields: {
		name: 'ReturnFields',
		type: 'array',
		default: [],
		omitIfEmpty: true,
		children: {
			name: {
				name: 'FieldName',
				default: '',
			},
			alias: {
				name: 'AliasName',
				default: '',
			},
		},
	},
};

// used by multiple modules
export const functionListRefMeta = {
	element: {
		name: 'Document',
		type: 'object',
		default: {},
		omitIfEmpty: true,
		children: {
			ref: {
				name: 'Reference',
				type: 'object',
				default: {},
				omitIfEmpty: true,
				children: functionListRefFieldsMeta,
			},
		},
	},
};

// used by multiple modules
export const validationMeta = {
	name: 'Validation',
	type: 'object',
	default: {},
	children: {
		required: {
			name: 'IsRequired',
			type: 'boolean',
			default: false,
		},
		max: {
			name: 'MaxLength',
			type: 'integer',
			default: null,
			omitIfEmpty: true,
		},
		regex: {
			name: 'Expression',
			type: 'string',
			default: null,
			omitIfEmpty: true,
		},
		...functionListRefMeta,
	},
};

// used by multiple modules
export const defaultValueMeta = {
	name: 'Default',
	type: 'object',
	default: {},
	omitIfEmpty: true,
	children: {
		type: {
			name: 'Type',
			type: 'string',
			default: false,
			omitIfEmpty: true,
		},
		value: {
			name: 'Value',
			type: 'string',
			default: null,
		},
	},
};

/* Fields */

// simple field
export const simpleFieldMeta = {
	name: {
		name: 'Name',
		type: 'string',
		default: '',
	},
	type: {
		name: 'ValueType',
		type: 'string',
		default: '',
	},
	value: {
		name: 'Value',
		type: 'string',
		default: '',
	},
	validation: validationMeta,
};

const tokenMeta = {
	token: {
		name: 'Token',
		type: 'string',
		default: '',
	},
	refresh: {
		name: 'RefreshToken',
		type: 'string',
		default: '',
	},
}

/* Nominow API data */

// naming used in definition of Function List
const functionListMeta = {
	// N.B.: This object has many more properties
	// but only these are actually used in FE
	guid: {
		name: 'DocumentGuid',
		type: 'string',
		default: '',
	},
	name: {
		name: 'Name',
		type: 'string',
		default: '',
	},
	description: {
		name: 'Description',
		type: 'string',
		default: '',
	},
	type: {
		name: 'DocumentType',
		type: 'string',
		default: '',
	},
	conn_guid: {
		name: 'FlowConnectionGuid',
		type: 'string',
		default: '',
	},
	fields: {
		name: 'Elements',
		type: 'array',
		default: [],
		children: {
			name: {
				name: 'Name',
				type: 'string',
				default: '',
			},
			type: {
				name: 'Type',
				type: 'string',
				default: '',
			},
			is_key: {
				name: 'IsKey',
				type: 'boolean',
				default: false,
			},
			is_map: {
				name: 'IsMapping',
				type: 'boolean',
				default: false,
			},
			validation: validationMeta,
		},
	},
};


const iFrameInfoMeta = {
	url: {
		name: 'URL',
		type: 'string',
		default: '',
	},
	name: {
		name: 'Name',
		type: 'string',
		default: '',
	},
};

/**
 * Users & Roles
 */

//////////////////////////
//          USER        //
//////////////////////////
export const LOGIN = {
	path: 'Authenticate',
	method: 'post',
	useAuth: false,
	realm: realms.USER,
	parameters: {
		email: 'Email',
		pass: 'Password',
	},
	meta: tokenMeta,
};

export const LOGIN_TWO_F_A = {
	path: 'Authenticate2FA',
	method: 'post',
	useAuth: true,
	realm: realms.USER,
	parameters: {
		twoFA: 'TwoFA',
	},
	meta: tokenMeta,
};

export const REFRESH = {
	path: 'RefreshToken',
	useAuth: false,
	realm: realms.USER,
	parameters: {
		refresh: 'refreshtoken',
	},
	meta: tokenMeta,
};

export const ASK_FOR_NEW_PASSWORD = {
	path: 'RequestNewPassword',
	method: 'post',
	useAuth: false,
	realm: realms.USER,
	parameters: {
		email: 'Email',
	},
};

export const RESET_PASSWORD = {
	path: 'ResetPassword',
	method: 'post',
	useAuth: false,
	realm: realms.USER,
	parameters: {
		pass: 'Password',
		key: 'Key',
	},
};

export const CHANGE_PASSWORD = {
	path: 'ChangePassword',
	method: 'post',
	useAuth: true,
	realm: realms.USER,
	parameters: {
		currentPassword: 'CurrentPassword',
		newPassword: 'NewPassword',
	},
	meta: tokenMeta,
};

export const CREATE_SHARED_SECRET = {
	path: 'CreateSharedKey',
	method: 'get',
	useAuth: true,
	realm: realms.USER,
};

export const CHECK_SECRET_KEY = {
	path: 'CheckSecretKey',
	method: 'post',
	useAuth: false,
	realm: realms.USER,
	parameters: {
		key: 'Key',
	},
};

///////////////////
//   FLOW BASE   //
///////////////////

// Connections
export const GET_CONNECTIONS = {
	path: 'GetConnections',
	method: 'get',
	useAuth: true,
	realm: realms.FLOWBASE,
	meta: simpleConnectionMeta,
	cacheKey: 'FlowBase|GET_CONNECTIONS',
};

// Lists
export const GET_LIST_BY_NAME = {
	path: 'GetListByName',
	realm: realms.FLOWBASE,
	parameters: {
		name: 'ListName'
	},
	meta: listMeta,
	cacheKey: 'FlowBase|GET_LIST_BY_NAME|{name}',
};

// Function Lists
export const GET_FUNCTION_LISTS = {
	path: 'GetFunctionLists',
	realm: realms.FLOWBASE,
	meta: functionListMeta,
};

// Function Lists by connection
export const GET_FUNCTION_LISTS_BY_CONNECTION = {
	path: 'GetFunctionLists/{connection}',
	realm: realms.FLOWBASE,
	meta: functionListMeta,
};

export const GET_FUNCTION_LIST_BY_ID = {
	path: 'GetFunctionListMetaByGuid/{guid}',
	realm: realms.FLOWBASE,
	meta: functionListMeta,
	cacheKey: 'FlowBase|GET_FUNCTION_LIST_BY_ID|{guid}',
};

/////////////////////////
//         MISC        //
/////////////////////////

// Get available modules for current domain
export const GET_AVAILABLE_MODULES = {
	path: 'GetClientModules',
	realm: realms.CLIENTADMIN,
};

export const GET_I_FRAME_MODULES_INFO = {
	path: 'GetIFrameInfo',
	meta: iFrameInfoMeta,
	realm: realms.CLIENTADMIN,
	cacheKey: 'FlowBase|GET_I_FRAME_MODULES_INFO',
};
