const DECIMAL_SEPARATOR = '.';

/**
 * Parses any variable into an integer. Used for updating
 * form input values.
 *
 * @param {Any} value
 *  Can be any type
 *
 * @returns {Integer}
 *  Return integer or empty string, since that
 *  is also valid input for form input fields
 */
export const forceInt = (value) => {
	// only allow numbers and minus sign
	const result = parseInt(value);
	return Number.isNaN(result) ? '' : result;
};

/**
 * Parses any variable into a decimal. Used for updating
 * form input values.
 * NOTE: Does not necessarily return a correct decimal value,
 * because it allows trailing commas. This is the case because while
 * user is entering input we do not want to delete the comma he
 * adds before he gets teh chance to enter decimals
 *
 * @param {Any} value
 *  Can be any type
 *
 * @returns {String}
 */
export const forceDecimal = (value) => {
	let result = value;

	// only allow numbers, minus sign, comma and dot
	result = result.replace(new RegExp('[^0-9-,\\.]', 'gi'), '');

	// remove all non-leading minuses
	result = result.replace(new RegExp('(?<!^)-', 'gi'), '');

	// replace dots and commas with used separator
	result = result.replace(',', DECIMAL_SEPARATOR);
	result = result.replace('.', DECIMAL_SEPARATOR);

	// remove all but first separator
	let i = 0;
	result = result.replace(
		new RegExp(`\\${DECIMAL_SEPARATOR}`, 'gi'),
		(match) => {
			i++;
			return i === 1 ? match : '';
		}
	);

	// add 0 before leading separator
	if (result.startsWith(DECIMAL_SEPARATOR)) {
		result = '0' + result;
	} else if (result.startsWith(`-${DECIMAL_SEPARATOR}`)) {
		// add 0 between leading minus and separator
		result = result.replace('-', '-0');
	}

	return result;
};

export const removeWhitespace = (value) => {
	return value.replaceAll(' ', '');
};