/**
 * Translations for English
 * See https://vue-i18n.intlify.dev/guide/ on how to use
 */
 export default {
	pageTitle: 'Dispatcher',
	permissions: {
		label: 'Dispatcher',
		info: 'Permissions connected to the Dispatcher module',
		use: {
			label: 'Use Dispatcher',
			info: 'Permission to use the Dispatcher module',
		},
	},
};
