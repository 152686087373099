<template>
	<DrawerItem
		:id="id"
		:drawerIndex="drawerIndex"
		:title="drawerName || null"
		:hasJsonButton="false"
		:hasFullwidthBody="true"
		:closeOnBackgroundClick="true"
	>
		<div class="spaced-content" v-if="flow">
			<VTitle
				:size="2"
				:text="$t('general.summary')"
				class="has-margin-bottom-1"
			/>
			<FlowSummary :flow="flow" />
		</div>

		<FlowHistory v-if="flow" :flow="flow" />

		<template #footer></template>
	</DrawerItem>
</template>

<script>
import { getStoreMutation } from '@assets/scripts/store/config';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import FlowSummary from '@modules/FlowBuilder/materials/components/FlowSummary.vue';
import FlowHistory from '@modules/FlowBuilder/materials/components/FlowHistory';
import { GET_RESTFLOW_BY_ID } from '@modules/FlowBuilder/endpoints';

export default {
	name: 'FlowDetailsDrawer',
	components: {
		FlowSummary,
		FlowHistory,
	},
	props: {
		/**
		 * Index of this drawer
		 */
		drawerIndex: {
			type: Number,
			required: true,
			default: 0,
		},
		config: {
			required: true,
		},
		/**
		 * Unique key of this drawer
		 */
		id: {
			type: String,
			required: true,
		},
	},
	data: function () {
		return {
			flow: false,
			drawerName: false,
			unsubscribeAction: false
		};
	},
	mounted: function () {
		// subscribe to state updates of Store
		this.unsubscribeAction = this.$store.subscribe((mutation) => {
			// only act when current flow is updated
			if (
				(
					mutation.type !== getStoreMutation('FLOW_DELETED', 'FLOW') &&
					mutation.type !== getStoreMutation('FLOW_STATUS_UPDATED', 'FLOW')
				) || mutation.payload !== this.config
			)
				return;

			// get flow from store after mutations
			this.setFlow();
		});

		// get flow from store on load
		this.setFlow();
	},
	computed: {
		// get the amount of versions the current flow has
		versionCount: function () {
			let result = 0;

			if (this.flow && this.flow.versions) {
				result = this.flow.versions.length;
			}

			return result;
		},
	},
	watch: {
		versionCount(newVal, oldVal) {
			if (oldVal > 0 && newVal === 0) {
				// close drawer when no versions exist anymore
				this.$store.commit(getStoreMutation('CLOSE_DRAWER'), this.id);
			}
		},
	},
	methods: {
		/**
		 * Sets the current flow and flow name as local variable
		 */
		setFlow: async function () {
			this.flow = await useApiAsync(GET_RESTFLOW_BY_ID, {
				keys: {
					guid: this.config
				}
			});

			if (this.flow) {
				this.drawerName = this.flow.name;
			}
		},
	},
	unmounted: function () {
		// unsubscribe from actions
		if (this.unsubscribeAction) this.unsubscribeAction();
	},
};
</script>
