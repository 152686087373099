import ConnectionBuilder from './ConnectionBuilder/i18n';
import DocumentBuilder from './DocumentBuilder/i18n';
import DocumentEditor from './DocumentEditor/i18n';
import FlowBuilder from './FlowBuilder/i18n';
import MetaDocumentBuilder from './MetaDocumentBuilder/i18n';
import MethodBuilder from './MethodBuilder/i18n';
import Reporting from './Reporting/i18n';
import RestApiBuilder from './RestApiBuilder/i18n';
import ScriptBuilder from './ScriptBuilder/i18n';
import SegmentBuilder from './SegmentBuilder/i18n';
import UserManagement from './UserManagement/i18n';
import ComposedDocument from './ComposedDocument/i18n';
import ActionDocument from './ActionDocument/i18n';
import Dashboard from './Dashboard/i18n';
import Customers from './Customers/i18n';
import Queue from './Queue/i18n';
import Dispatcher from './Dispatcher/i18n';
import Datacleansing from './Datacleansing/i18n';
import FunctionBlocks from './FunctionBlocks/i18n';
import InsightTags from './InsightTags/i18n';
import InsightKPI from './InsightKPI/i18n';
import GenerativeAI from './GenerativeAI/i18n';
import SettingsDashboard from './SettingsDashboard/i18n';
import SettingsReports from './SettingsReports/i18n';
import SettingsDatacleansing from './SettingsDatacleansing/i18n';

export default {
    cb: ConnectionBuilder,
    db: DocumentBuilder,
    de: DocumentEditor,
    fb: FlowBuilder,
    mb: MetaDocumentBuilder,
    method: MethodBuilder,
    report: Reporting,
    ra: RestApiBuilder,
    sb: ScriptBuilder,
    smb: SegmentBuilder,
    user: UserManagement,
    cd: ComposedDocument,
    ad: ActionDocument,
    dashboard: Dashboard,
    customers: Customers,
    queue: Queue,
    dispatcher: Dispatcher,
    dc: Datacleansing,
    functionBlocks: FunctionBlocks,
    insightTags: InsightTags,
    insightKPI: InsightKPI,
    generativeAI: GenerativeAI,
    sd: SettingsDashboard,
    sr: SettingsReports,
    sdc: SettingsDatacleansing,
};