/**
 * Translations for English
 * See https://vue-i18n.intlify.dev/guide/ on how to use
 */
export default {
    pageTitle: 'Action Document',
	actionDocumentList: {
		title: 'Action Document Builder',
	},
	newActionDocument: {
		title: 'Create new action document',
		whatName: 'Give your action document a name',
		giveDescription: 'Add a description for the new action document',
		nameInputLabel: 'Action document name',
		whatConnection: 'Which connection will the action document be part of?',
		selectConnection: 'Select connection',
		submitButton: 'Next',
		createdSuccess: 'Succesfully created new Action Document',
	},
	error: {
		loadActionDocument: 'Document was not found',
	},
	docList: {
		addActionDocument: 'Add action document',
		table: {
			noResults: 'No action documents found',
			columns: {
				doc_name: 'Name',
				is_read: 'Is read',
				root_child: 'Root document',
				last_time_edited: 'Last time edited',
				status: 'Status',
			},
		},
		delete: {
			confirm: {
				title: 'Delete this action document?',
				body: 'Are you sure you want to delete this action document?',
				confirmButtonText: 'Delete',
			},
		},
	},
	documentDelete: {
		error: 'Something went wrong while deleting Action document. Please try again.',
		success: 'Action document was succesfully deleted',
	},
	validation: {
		nameEmpty: 'Action document name can not be empty',
		nameIsShort: 'Action document name should be at least 2 characters',
		nameNotUnique: 'Action document name already exists within connection',
		rootActionNotChosen: 'Root action is not set',
		methodIsReadMismatch: 'Property <strong>Is read</strong> of method <strong>{method}</strong> does not match the action document setting',
		actionRelationNotSet: 'Relation between <strong>{action}</strong> and its parent is not set',
		actionRelationAliasNotSet: 'Alias for <strong>{action}</strong> is not set',
		actionRelationParentNotSet: '<i>Parent field</i> for <strong>{action}</strong> is not set',
		actionRelationChildNotSet: '<i>Child field</i> for <strong>{action}</strong> is not set',
		actionRelationAliasNotUnique: '<i>Alias</i> for <strong>{action}</strong> is not unique',
		configuredChildFieldDoesNotExist: 'Configured <i>child field</i> <strong>{field}</strong> does not exist in <strong>{action}</strong>',
		configuredParentFieldDoesNotExist: 'Configured <i>parent field</i> <strong>{field}</strong> for <strong>{action}</strong> does not exist in <strong>{parent}</strong>',
		documentRelationTypeMismatch: 'Reference from document <strong>{action}</strong> to document <strong>{parent}</strong> does not exist or uses other fields',
		relationFieldTypesDoNotMatch: 'Types of <i>parent field</i> <strong>{parentField}</strong> and <i>child field</i> <strong>{childField}</strong> do not match in <strong>{action}</strong>',
		relationFieldMaxLengthsDoNotMatch: 'Max length of <i>parent field</i> <strong>{parentField}</strong> and <i>child field</i> <strong>{childField}</strong> do not match in <strong>{action}</strong>',
		requiredFieldDoesNotExist: 'Configured required field <strong>{field}</strong> does not exist in <strong>{action}</strong>',
		ruleDocumentNotAvailable: 'Configured document <strong>{document}</strong> in <i>Match rule</i> is not available in Action Document',
		ruleInputNotSet: 'No <i>input fields</i> configured in <i>Match rule</i> for document <strong>{document}</strong>',
		ruleOutputNotSet: 'No <i>output fields</i> configured in <i>Match rule</i> for document <strong>{document}</strong>',

		ruleInputFieldDoesNotExist: 'Configured <i>input field</i> <strong>{field}</strong> in <i>Match rule</i> for document <strong>{document}</strong> does not exist in <strong>{document}</strong>',
		ruleOutputFieldDoesNotExist: 'Configured <i>output field</i> <strong>{field}</strong> in <i>Match rule</i> for document <strong>{document}</strong> does not exist in <strong>{document}</strong>',
	},
	documentDetails: {
		previewTitle: 'Action document JSON',
		previewButton: 'Preview JSON',
		backToOverview: 'Back to overview',
		documentName: 'Action document name',
		documentSettings: 'Action document settings',
		description: 'Description',
		close: {
			confirm: {
				title: 'Go back to action document overview?',
				body: 'Are you sure you want to close? Your changes are not saved and will be lost if you close this overlay.',
				confirmButtonText: 'Ok',
			},
		},
		actions: 'Actions',
		addRootAction: 'Add root action',
		documentTable: {
			columns: {
				action: 'Action',
				type: 'Type',
				update: 'Is update',
				mandatory: 'Is mandatory',
				alias: 'Alias',
			},
			chooseDocument: 'Choose document',
			chooseMethod: 'Choose method',
			childName: 'Child name',
			childNameLabel: 'Child name',
			addChildAction: 'Add child action',
			noResults: 'No actions added yet',
		},
		savedSuccessfully: 'Action document was successfully saved',
		publish: {
			success: 'Action document was successfully published',
			confirm: {
				title: 'Publish the action document?',
				body: 'Are you sure you want to publish the action document? This action can not be undone.',
				confirmButtonText: 'Publish',
			},
		},
		settings: {
			is_read: 'Is read',
			is_read_info: 'Is this action document only retrieving data? When true then action in rest flow is a read action.',
		},
		delete: {
			confirm: {
				title: 'Delete this action?',
				body: 'Are you sure you want to delete this action?',
				confirmButtonText: 'Delete',
			},
		},
		deleteChildren: {
			confirm: {
				title: 'Delete children?',
				body: 'Do you want to delete all children of this updated action?',
				confirmButtonText: 'Delete',
				cancelButtonText: 'No, keep them',
			},
		},
		dropAllActions: {
			confirm: {
				title: 'Remove all actions?',
				body: 'You have updated the \'Is read\' property of the document. Do you want to delete all actions?',
				confirmButtonText: 'Delete',
				cancelButtonText: 'No, keep them',
			},
		},
		addMatchRule: 'Add match rule',
		matchRule: 'Match rule',
		matchRules: 'Match rules',
		matchRulesTable: {
			columns: {
				action: 'Action',
				stopIfMatch: 'Stop if match',
				stopIfNoMatch: 'Stop if no match',
				inputFields: 'Input field(s)',
			},
			noResults: 'No match rules configured',
			delete: {
				confirm: {
					title: 'Delete this match rule?',
					body: 'Are you sure you want to delete this match rule?',
					confirmButtonText: 'Delete',
				},
			},
		}
	},
	actionDetails: {
		configureAction: 'Configure action',
		actionDetails: 'Action details',
		selectAction: 'Select action',
		saveButton: 'Save',
		close: {
			confirm: {
				title: 'Go back to document?',
				body: 'Are you sure you want to close? Your changes are not saved and will be lost if you go back.',
				confirmButtonText: 'Ok',
			},
		},
		actionSettings: 'Action settings',
		settings: {
			is_mandatory: 'Is mandatory',
			is_mandatory_info: 'Record will be inserted or updated. Whether an update is allowed, is configured in the flow builder.',
			is_updatable: 'Is updatable',
			is_updatable_info: 'Indication if a record must have a full update when the match is positive. Only applies for write action documents and only for the action type document.',
		},
		actionAlias: 'Action alias',
		actionRelation: 'Relation',
		requiredFields: 'Required fields',
		requiredFieldsPickerTable: {
			noResults: 'No fields found',
			columns: {
				field_name: 'Field name',
				field_type: 'Type',
			}
		}
	},
	matchRuleDetails: {
		document: 'Document',
		matchRuleSettings: 'Configuration',
		saveButton: 'Save',
		settings: {
			stop_if_match: 'Stop if match',
			stop_if_match_info: 'Indicator if the action must issue a 400 bad request if a match has been made',
			stop_if_no_match: 'Stop if no match',
			stop_if_no_match_info: 'Indicator if the action must issue a 400 bad request if a match is not been made',
		},
		close: {
			confirm: {
				title: 'Go back to document?',
				body: 'Are you sure you want to close? Your changes are not saved and will be lost if you go back.',
				confirmButtonText: 'Ok',
			},
		},
		fieldsPickerTable: {
			noResults: 'No fields found',
			columns: {
				field_name: 'Field name',
				field_type: 'Type',
			}
		},
		inputFields: 'Input fields',
		outputFields: 'Output fields',
	},
	permissions: {
		label: 'Action document',
		info: 'Permissions connected to the Action Document builder',
		readActionDocument: {
			label: 'Read action document',
			info: 'Permission to view all action documents',
		},
		editActionDocument: {
			label: 'Edit action document',
			info: 'Permission to create, update and publish action documents',
		},
		deleteActionDocumentDraft: {
			label: 'Delete action document draft',
			info: 'Permission to delete any action document draft',
		},
	}
};
