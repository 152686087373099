import i18n from '@assets/i18n';
const TheMethodBuilder = () => import('./materials/TheMethodBuilder.vue');

// translate function of vue-i18n
const { t } = i18n.global;

export default [
    {
		path: '/methodbuilder',
		component: TheMethodBuilder,
		name: 'methodBuilder',
		meta: {
			title: t('method.pageTitle'),
			permission: 'Read',
			showInSideNav: true,
			showInHome: true,
			icon: 'gear',
			parent: 'builder',
			module: 'MethodBuilder',
			order: 20,
		},
    },
];