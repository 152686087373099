<template>
	<VOverlay
		:hasBackground="false"
		:hasJsonButton="false"
		:hasCloseButton="true"
		:closeOnBackgroundClick="true"
		:isVCentered="true"
		:size="2"
		:title="$t('logout.youWillBeLoggedOutSoon')"
		:modelValue="true"
	>
		<p v-html="$t('logout.inactivityWarning', { seconds: props.secondsLeft })"></p>

		<template #footer>
			<VButton
				text="Continue"
				variant="is-primary"
				@clicked.prevent.stop="continueFunction"
			/>
		</template>
	</VOverlay>
</template>

<script>
export default {
	name: 'TheInactivityWarning',
};
</script>

<script setup>
const props = defineProps({
	secondsLeft: {
		type: Number,
		required: true,
	},
});

const continueFunction = () => {
	// do nothing, since interaction will reset the idle timer automatically
};
</script>
