export default {
	ADD_LOADER: 'addLoader',
	STOP_LOADER: 'stopLoader',
	RESET_LOADER: 'resetLoader',
	SHOW_NOTIFICATION: 'addNotification',
	RESET: 'reset',
	OPEN_DRAWER: 'openDrawer',
	CLOSE_DRAWER: 'closeDrawer',
	OPEN_CONFIRMATION: 'openConfirmation',
	CLOSE_CONFIRMATION: 'closeConfirmation',
	SET_VALIDATION_MODE: 'setFlowValidation',
	ADD_VALIDATION_ERROR: 'addValidationError',
	CLEAR_VALIDATION_ERRORS: 'clearValidationErrors',
	TOGGLE_ERRORS_LIST_COLLAPSED: 'toggleErrorsListCollapsed',
	SET_IS_VALID: 'setIsValid',
	SET_VALIDATION_IN_PROGRESS: 'setValidationInProgress',
};
