<template>
	<DrawerItem
		:id="id"
		:drawerIndex="drawerIndex"
		:title="$t('user.userDetails.title')"
		:hasJsonButton="false"
		:hasFullwidthBody="false"
		:closeOnBackgroundClick="true"
		:onClose="closeDrawer"
	>
		<div class="has-margin-bottom-5">
			<div
				class="has-bottom-divider has-padding-bottom-2 has-margin-bottom-2"
			>
				<VTitle :size="2" :text="$t('user.userDetails.roles')" />
			</div>

			<template v-for="(role, id) in roles" :key="id">
				<VField class="is-fullwidth">
					<VOption
						type="checkbox"
						:label="role.name"
						v-model:modelValue="userRolesModel[role.guid]"
						wrapperClasses="has-margin-0 has-margin-right-05"
						:disabled="!editMode"
						@update:modelValue="modelChanged"
					/>
				</VField>
			</template>
		</div>
		<div class="has-margin-bottom-5">
			<div
				class="has-bottom-divider has-padding-bottom-2 has-margin-bottom-2"
			>
				<VTitle
					:size="2"
					:text="$t('user.userDetails.userInformation')"
				/>
			</div>
			<VField
				:label="$t('user.userDetails.gender')"
				:isFloating="true"
				:class="{ 'is-active': user.gender_code }"
				class="is-wide"
			>
				<VSelect
					:options="genderOptions"
					v-model="user.gender_code"
					@change="markModified"
					:disabled="!editMode"
				/>
			</VField>
			<VField
				:isFloating="true"
				:label="$t('user.userDetails.firstName')"
				:class="{ 'is-active': user.first_name }"
				class="is-wide"
			>
				<VInput
					:placeholder="$t('user.userDetails.firstName')"
					:disabled="!editMode"
					type="text"
					@input="markModified"
					v-model:modelValue="user.first_name"
				/>
			</VField>
			<VField
				:isFloating="true"
				:label="$t('user.userDetails.lastName')"
				:class="{ 'is-active': user.last_name }"
				class="is-wide"
			>
				<VInput
					:placeholder="$t('user.userDetails.lastName')"
					:disabled="!editMode"
					type="text"
					@input="markModified"
					v-model:modelValue="user.last_name"
				/>
			</VField>
			<VField
				:isFloating="true"
				:label="$t('user.userDetails.email')"
				:class="{ 'is-active': user.email }"
				class="is-wide"
			>
				<VInput
					:placeholder="$t('user.userDetails.email')"
					:disabled="!editMode"
					type="email"
					@input="markModified"
					v-model:modelValue="user.email"
				/>
			</VField>
			<VNotification
				:text="$t('user.userDetails.emailError')"
				:isFullwidth="false"
				:hasClose="false"
				type="danger"
				v-if="!emailValid && modified"
			/>
		</div>
		<div class="has-margin-bottom-5">
			<div
				class="has-bottom-divider has-padding-bottom-2 has-margin-bottom-2"
			>
				<VTitle :size="2" :text="$t('user.userDetails.userStatus')" />
			</div>
			<VField class="is-fullwidth">
				<VOption
					type="checkbox"
					:label="$t('user.userDetails.isActive')"
					wrapperClasses="has-margin-0 has-margin-right-05"
					:disabled="!editMode"
					@change="markModified"
					v-model:modelValue="user.is_active"
				/>
			</VField>
			<VField class="is-fullwidth" v-if="canSeeAdminStatus">
				<VOption
					type="checkbox"
					:label="$t('user.userDetails.isAdmin')"
					wrapperClasses="has-margin-0 has-margin-right-05"
					:disabled="true"
					:modelValue="user.is_admin"
				/>
			</VField>
			<div class="field field-parent is-floating is-active">
				<label class="label-floating">
					{{ $t('user.userDetails.lastLogin') }}
				</label>
				<p>
					{{
						getDateForDisplay(user.last_login) ||
						$t('general.notApplicable')
					}}
				</p>
			</div>
			<div class="field field-parent is-floating is-active">
				<label class="label-floating">
					{{ $t('user.userDetails.logins') }}
				</label>
				<p>{{ user.logins }}</p>
			</div>
			<div class="field field-parent is-floating is-active">
				<label class="label-floating">
					{{ $t('user.userDetails.resetMFA.MFAstatus') }}
				</label>
				<p>{{ mfaStatus }}</p>
			</div>
			<VButton
				:text="$t('user.userDetails.resetMFA.button')"
				variant="is-secondary"
				@clicked.prevent.stop="resetMFA"
				:disabled="!editMode || !user.two_factor"
			/>
		</div>
		<template #footer>
			<SaveButton
				:isDisabled="!modified || !emailValid"
				class="button-modal-footer"
				:text="$t('user.userDetails.saveButton')"
				icon="chevron-right"
				:iconOnRight="true"
				:callbackFn="saveUser"
			/>
		</template>
	</DrawerItem>
</template>

<script>
import { mapGetters, useStore } from 'vuex';
import { computed } from 'vue';
import {
	getStoreAction,
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import Helpers from '@assets/scripts/helpers';
import { GET_USERS, GET_ROLES } from '@modules/UserManagement/endpoints';
import usePermission from '@assets/scripts/composables/usePermission';
import usePreventDataLoss from '@assets/scripts/composables/usePreventDataLoss';

export default {
	name: 'UserDetailsDrawer',
	props: {
		/**
		 * Index of this drawer
		 */
		drawerIndex: {
			type: Number,
			required: true,
			default: 0,
		},
		/**
		 * Unique key of this drawer
		 */
		id: {
			type: String,
			required: true,
		},
	},
	data: function () {
		return {
			roles: [],
			editMode: usePermission('Upsert', 'UserManagement'),
			userRolesModel: {},
			genderOptions: [
				{ value: 'M', text: this.$t('user.userDetails.male') },
				{ value: 'F', text: this.$t('user.userDetails.female') },
				{ value: 'U', text: this.$t('user.userDetails.unknown') },
			],
			userEmails: [],
		};
	},
	setup: function () {
		const store = useStore();

		/**
		 * Get indicator whether current User has been modified
		 */
		const modified = computed(() => {
			return store.getters[getStoreGetter('USER_MODIFIED', 'USER')];
		});

		usePreventDataLoss(modified);

		return { modified };
	},
	mounted: async function () {
		const users = await useApiAsync(GET_USERS);

		if (users) {
			// get list of existing user names, excluding
			// current user
			users.forEach((user) => {
				if (user.guid !== this.user.guid) {
					this.userEmails.push(user.email.toLowerCase());
				}
			});
		}

		// get all available roles
		this.roles = await useApiAsync(GET_ROLES);

		// loop over all roles
		this.roles.forEach((role) => {
			// create permissions model for current user roles
			this.userRolesModel[role.guid] =
				this.user.roles.indexOf(role.guid) > -1;
		});
	},
	computed: {
		...mapGetters({
			/**
			 * Get User that is currently being edited
			 */
			user: getStoreGetter('CURRENT_USER', 'USER'),
		}),
		emailValid: function () {
			return (
				Helpers.validateEmail(this.user.email) &&
				this.user.email &&
				this.userEmails.indexOf(this.user.email.toLowerCase()) < 0
			);
		},
		canSeeAdminStatus: function () {
			return usePermission('See Admin Status', 'UserManagement');
		},
		mfaStatus: function () {
			return this.user.two_factor ? this.$t('user.userDetails.resetMFA.MFAEnabled') : this.$t('user.userDetails.resetMFA.MFADisabled')
		}
	},
	methods: {
		modelChanged: function () {
			// loop over user roles model
			for (const userRole in this.userRolesModel) {
				if (
					this.userRolesModel[userRole] &&
					this.user.roles.indexOf(userRole) < 0
				) {
					// add user role to roles
					this.user.roles.push(userRole);
				} else if (
					!this.userRolesModel[userRole] &&
					this.user.roles.indexOf(userRole) > -1
				) {
					// remove user role from roles
					const index = this.user.roles.indexOf(userRole);
					this.user.roles.splice(index, 1);
				}
			}
			this.markModified();
		},
		getDateForDisplay: function (date) {
			return Helpers.date.localeStringWithMinutes(date);
		},
		markModified: function () {
			if (this.modified) return;

			this.$store.commit(getStoreMutation('MARK_USER_MODIFIED', 'USER'));
		},
		saveUser: function () {
			this.$store.dispatch(getStoreAction('SAVE_CURRENT_USER', 'USER'), {
				drawerId: this.id, // id of drawer to close after save
			});
		},
		resetMFA: function () {
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t('user.userDetails.resetMFA.confirm.title'),
				body: this.$t('user.userDetails.resetMFA.confirm.body'),
				confirmButtonText: this.$t(
					'user.userDetails.resetMFA.confirm.confirmButtonText'
				),
				confirmFn: () => {
					// reset mfa after confirmation
					this.$store.dispatch(getStoreAction('RESET_MFA', 'USER'), {
						user_guid: this.user.guid,
						user: this.user
					});
				},
			});
		},
		closeDrawer: function () {
			const closeDrawer = () => {
				// unset current user
				this.$store.commit(getStoreMutation('UNSET_USER', 'USER'));

				// close drawer
				this.$store.commit(getStoreMutation('CLOSE_DRAWER'), this.id);
			};

			if (!this.modified) {
				// close immediately if config has not been modified
				closeDrawer();
			} else {
				// ask confirmation before closing if a change has been made
				// to the field config
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t('user.userDetails.close.confirm.title'),
					body: this.$t('user.userDetails.close.confirm.body'),
					confirmButtonText: this.$t(
						'user.userDetails.close.confirm.confirmButtonText'
					),
					confirmFn: () => {
						// close after confirmation
						closeDrawer();
					},
				});
			}
		},
	},
};
</script>
