const apiBase = 'UIServices/ComposedDocument';

export const composedDocumentMeta = {
	name: {
		name: 'ComposedDocumentName',
		type: 'string',
		default: false,
	},
	description: {
		name: 'Description',
		type: 'string',
		default: '',
		omitIfEmpty: true,
	},
	is_published: {
		name: 'IsActive',
		type: 'boolean',
		default: false,
	},
	is_read: {
		name: 'IsReadAction',
		type: 'boolean',
		default: false,
	},
	guid: {
		name: 'ComposedDocumentGuid',
		type: 'string',
		default: '',
	},
	conn_guid: {
		name: 'FlowConnectionGuid',
		type: 'string',
		default: '',
	},
	created: {
		name: 'CreatedDateTime',
		default: false,
		omitIfEmpty: true,
	},
	modified: {
		name: 'LastModifiedDateTime',
		default: false,
		omitIfEmpty: true,
	},
	document: {
		name: 'Document',
		type: 'object',
		default: {},
		omitIfEmpty: true,
		children: {
			name: {
				name: 'DocumentName',
				type: 'string',
				default: '',
			},
			guid: {
				name: 'DocumentGuid',
				type: 'string',
				default: '',
			},
			method: {
				name: 'DocumentMethod',
				type: 'string',
				default: '',
			},
			childs: {
				name: 'Childs',
				type: 'array',
				recursive: true,
				omitIfEmpty: true,
				default: [],
				children: {
					child_name: {
						name: 'ChildName',
						type: 'string',
						default: '',
					},
					name: {
						name: 'DocumentName',
						type: 'string',
						default: '',
					},
					guid: {
						name: 'DocumentGuid',
						type: 'string',
						default: '',
					},
					method: {
						name: 'DocumentMethod',
						type: 'string',
						default: '',
					},
					key_field: {
						name: 'KeyField',
						type: 'string',
						default: '',
					},
					child_field: {
						name: 'ChildField',
						type: 'string',
						default: '',
					},
				},
			},
		},
	},
};

export const actionDocumentMeta = {
	name: {
		name: 'DocumentName',
		default: '',
	},
	guid: {
		name: 'DocumentGuid',
		default: '',
	},
	key_field: {
		name: 'KeyField',
		default: '',
	},
	child_field: {
		name: 'ChildField',
		default: '',
	},
	methods: {
		name: 'ActionTypes',
		type: 'array',
		default: [],
	},
};

// Composed Documents
export const GET_COMPOSED_DOCUMENTS = {
    path: 'GetComposedDocuments/{connection}',
    realm: apiBase,
    meta: composedDocumentMeta,
}

// Composed Document
export const GET_COMPOSED_DOCUMENT = {
    path: 'GetComposedDocument/{connection}/{guid}',
    realm: apiBase,
    meta: composedDocumentMeta,
}

// Action Documents
export const GET_ACTION_DOCUMENTS = {
    path: 'GetActionDocuments/{connection}',
    realm: apiBase,
    parameters: {
        read: 'IsReadAction',
    },
    meta: actionDocumentMeta,
}
// Referencing action Documents
export const GET_REFERENCING_DOCUMENTS = {
    path: 'GetReferencingDocuments/{connection}/{guid}',
    realm: apiBase,
    parameters: {
        read: 'IsReadAction',
    },
    meta: actionDocumentMeta,
}
// Action Document
export const GET_ACTION_DOCUMENT = {
    path: 'GetActionDocument/{connection}/{guid}',
    realm: apiBase,
    parameters: {
        read: 'IsReadAction',
    },
    meta: actionDocumentMeta,
}

// Save or publish Composed Document
export const POST_COMPOSED_DOCUMENT = {
    path: 'PostComposedDocument/{connection}/{guid}',
    method: 'post',
    realm: apiBase,
    meta: composedDocumentMeta,
}

// Delete Composed Document
export const DELETE_COMPOSED_DOCUMENT = {
    path: 'DeleteComposedDocument/{connection}/{guid}',
    method: 'delete',
    realm: apiBase,
}