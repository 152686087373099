<template>
	<DrawerItem
		:id="id"
		:drawerIndex="drawerIndex"
		:title="drawerName"
		:hasJsonButton="false"
		:hasFullwidthBody="true"
		:closeOnBackgroundClick="true"
		:hasScrollableContent="true"
		:onClose="closeDrawer"
	>
		<template v-if="!error">
			<div class="content-header has-padding has-bottom-divider">
				<div class="field is-grouped is-flex-grow-1">
					<VSearch v-model="search" />
				</div>
			</div>

			<SortableDataTable
				:hoverable="false"
				default-sort="field_name"
				default-sort-direction="ASC"
				:data="filteredData"
				:columns="columns"
				:emptyText="$t('fieldPickerTable.table.noResults')"
				tableClasses="scrollable-content"
				:rowClass="getRowClass"
				:isRowCheckable="setRowCheckable"
				:checkable="editMode"
				:headerCheckable="editMode"
				v-model:checkedRows="checkedRows"
			>
				<template
					v-for="(row, key) in filteredData"
					:key="key"
					#[`alias-${row.key}`]
				>
					<template v-if="editMode">
						<VField
							icon="edit"
							:iconOnRight="true"
							:isError="row.duplicate || false"
						>
							<VInput
								v-model="aliases[row.key].alias"
								:disabled="row.disabled"
								:iconOnRight="true"
								@blur="removeWhitespace"
								@keyup="removeWhitespace"
							/>
						</VField>

						<VNotification
							v-if="errors[row.field_name]"
							type="danger"
							:text="errors[row.field_name]"
						/>
					</template>
					<template v-else>
						{{ aliases[row.key].alias || $t('general.dash') }}
					</template>
				</template>

				<template
					v-for="(row, key) in filteredData"
					:key="key"
					#[`field_name-${key}`]
				>
					<div @click.stop="rowClicked(row)">
						{{ row.field_name }}
					</div>
				</template>
			</SortableDataTable>
		</template>
		<template v-else>
			<VNotification
				class="has-margin-horizontal-5"
				type="danger"
				:text="$t('functionLists.fieldPicker.functionNotFound')"
			/>
		</template>

		<template v-if="editMode && !error" #footer>
			{{
				$t('functionLists.fieldPicker.fieldsSelected', {
					count: checkedRows.length,
				})
			}}
			<SaveButton
				:isDisabled="!valid || !modified"
				class="button-modal-footer"
				:text="$t('functionLists.fieldPicker.addButton')"
				icon="chevron-right"
				:iconOnRight="true"
				:callbackFn="addSelectedFields"
			/>
		</template>
	</DrawerItem>
</template>

<script>
export default {
	name: 'FunctionListFieldPickerDrawer',
};
</script>

<script setup>
import Helpers from '@assets/scripts/helpers';
import Field from '@assets/scripts/components/field';
import { getStoreMutation } from '@assets/scripts/store/config';
import { ref, unref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useApi } from '@assets/scripts/composables/useApi';
import { GET_FUNCTION_LIST_BY_ID } from '@assets/scripts/api/config';

const store = useStore();
const { t } = useI18n();

const { config, id: drawerId } = defineProps({
	/**
	 * Index of this drawer
	 */
	drawerIndex: {
		type: Number,
		required: true,
		default: 0,
	},
	/**
	 * Unique key of this drawer
	 */
	id: {
		type: String,
		required: true,
	},
	/**
	 * Variable type the drawer is opened for
	 */
	config: {
		type: Object,
		default: () => {
			return {
				name: '',
				fields: [],
				guid: false,
				map: '',
				callback: () => {},
				editMode: false,
			};
		},
	},
});

const { guid, map, fields, callback, name, editMode } = config;
const fieldName = Field.getChildName(name);

const checkedRows = ref([]);
const search = ref('');
const aliases = ref([]);
let readyToBeModified = false;
const modified = ref(false);
const errors = ref({});

// get selected function list
const { data: list, error } = useApi(GET_FUNCTION_LIST_BY_ID, {
	keys: {
		guid,
	},
});

const removeWhitespace = (e) => {
	e.target.value = Helpers.removeWhitespace(e.target.value);
};

// create columns for table
const columns = [
	{
		label: t('fieldPickerTable.table.columns.field_name'),
		field: 'field_name',
		sortable: true,
		searchable: true,
		default: t('general.dash'),
	},
	{
		label: t('field.alias'),
		field: 'alias',
		sortable: false,
		searchable: !editMode,
		default: t('general.dash'),
	},
	{
		label: t('fieldPickerTable.table.columns.field_type'),
		field: 'field_type',
		sortable: true,
		searchable: true,
		default: t('general.dash'),
	},
	{
		label: '',
		field: 'disabled',
		sortable: false,
		default: '',
		visible: editMode, // only show tooltip in edit mode
		width: '120', // to keep the popup within the screen
		component: 'VTooltip',
		cellClass: 'is-button-tool',
		args: {
			text: t('functionLists.fieldPicker.table.disabledExplanation'),
		},
	},
];

const rowClicked = (row) => {
	if (row.disabled || !editMode) return false;

	// find index of row in currently checked rows
	const i = getRowIndex(row, unref(checkedRows));

	// N.B.: make SHALLOW copy here
	// two-way binding for checked rows only works that
	// way with Buefy Table
	const newCheckedRows = Helpers.cloneObject(unref(checkedRows), false);

	// check row
	if (i === -1) selectRow(row, newCheckedRows);
	// uncheck row
	else unselectRow(row, newCheckedRows);
};

/**
 * Called for a row that is now checked while it
 * was not checked before
 *
 * @param {Object} row
 *  Newly checked row
 *
 * @param {Array} rows
 *  Array of currently checked rows
 */
const selectRow = (row, rows) => {
	const check = (row) => {
		if (row.disabled) return;
		const i = getRowIndex(row, rows);
		if (i < 0) rows.push(row);
	};

	// check self
	check(row);

	// set checkedRows prop
	checkedRows.value = rows;
};

/**
 * Called for a row that is now unchecked while it
 * was checked before
 *
 * @param {Object} row
 *  Newly unchecked row
 *
 * @param {Array} rows
 *  Array of currently checked rows
 */
const unselectRow = (row, rows) => {
	const uncheck = (i) => {
		if (i > -1) rows.splice(i, 1);
	};

	// uncheck self
	uncheck(getRowIndex(row, rows));

	// set checkedRows prop
	checkedRows.value = rows;
};

const getRowClass = (row) => {
	return row.disabled ? 'is-disabled' : false;
};

const setRowCheckable = (row) => {
	return !row.disabled;
};

const getRowIndex = (row, rows = []) => {
	return rows.findIndex(({ key }) => row.key === key);
};

const markAsModified = () => {
	if (readyToBeModified) modified.value = true;
};

const addSelectedFields = () => {
	if (!valid.value) return;

	// mark as not modified
	modified.value = false;

	// execute given callback fn
	if (callback) callback(guid, map, activeAliases.value);

	closeDrawer();
};

const closeDrawer = () => {
	const closeDrawer = () => {
		store.commit(getStoreMutation('CLOSE_DRAWER'), drawerId);
	};

	if (!modified.value) {
		// close immediately if config has not been modified
		closeDrawer();
	} else {
		// ask confirmation before closing if a change has been made
		// to the field config
		store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
			title: t('functionLists.fieldPicker.confirmClose.title'),
			body: t('functionLists.fieldPicker.confirmClose.body'),
			confirmButtonText: t(
				'functionLists.fieldPicker.confirmClose.confirmButtonText'
			),
			confirmFn: () => {
				// close after confirmation
				closeDrawer();
			},
		});
	}
};

const drawerName = computed(() => {
	let result = t('general.loading');

	if (error.value) result = t('general.notFound');
	else if (list.value) result = list.value.name;

	return result;
});

const activeAliases = computed(() => {
	const activeFieldNames = [];

	checkedRows.value.forEach((row) => {
		if (!row.disabled) activeFieldNames.push(row.field_name);
	});

	return aliases.value.filter((alias) =>
		activeFieldNames.includes(alias.name)
	);
});

const tableFields = computed(() => {
	const result = [];

	if (list.value && unref(list).fields) {
		unref(list).fields.forEach((field, key) => {
			result.push({
				key, // key, useful for handling clicks
				// name of the field
				field_name: field.name,
				// type of the field
				field_type: field.type,
				// rules for disabling row
				//
				// in EDIT mode:
				// field of list that is used as mapping field gets disabled if the field
				// name matches the name of the field that uses the reference list
				//
				// in VIEW mode:
				// fields that are not selected are disabled
				disabled: editMode
					? field.name.toLowerCase() === fieldName.toLowerCase() &&
					  field.name.toLowerCase() === map.toLowerCase()
					: !fields.find(
							(f) =>
								f.name.toLowerCase() ===
								field.name.toLowerCase()
					  ),
			});
		});
	}

	return result;
});

const searchableCols = computed(() => {
	return Helpers.getSearchableColumns(columns);
});

const filteredData = computed(() => {
	// loop over all documents
	const newData = unref(tableFields);

	// return if no rows match
	if (unref(search).length === 0 || newData.length === 0) return newData;

	// filter on search string
	return Helpers.filterByString(
		newData,
		unref(search),
		unref(searchableCols)
	);
});

const valid = computed(() => {
	return (
		activeAliases.value.length > 0 && Object.keys(errors.value).length < 1
	);
});

watch(
	activeAliases,
	(newAliases) => {
		// reset errors object first
		errors.value = {};

		// loop over new set aliases
		newAliases.forEach((field) => {
			if (!field.alias) {
				// set error if alias is empty
				errors.value[field.name] = t(
					'fieldPickerTable.aliasCanNotBeEmpty'
				);
			} else if (
				// check if alias occurs more than once (which is the current alias)
				newAliases.filter(
					(f) => f.alias.toLowerCase() === field.alias.toLowerCase()
				).length > 1
			) {
				// show error about duplicate aliases
				errors.value[field.name] = t(
					'fieldPickerTable.aliasMustBeUnique'
				);
			}
		});

		// N.B. info will NOT be marked as modified the first
		// time 'activeAliases' is changed, because the boolean
		// readyToBeModified will still be false
		markAsModified();
		readyToBeModified = true;
	},
	{
		deep: true,
	}
);

const unwatchList = watch(list, (newList) => {
	newList.fields.forEach((field) => {
		// get already configured alias for the field, if set
		const existingAlias = fields.find((f) => f.name === field.name);

		// create object with name/alias pair for all fields
		// of the list
		aliases.value.push({
			name: field.name,
			alias: Helpers.removeWhitespace(existingAlias?.alias ?? field.name),
		});
	});

	unwatchList();
});

// prefill checked rows as soon as the tablefields
// are created
const unWatchTableFields = watch(tableFields, (newVal) => {
	const activeRows = [];

	// loop over table rows
	newVal.forEach((row) => {
		if (row.disabled) return;
		// check if field in row exists in given
		// fields array of aliases
		if (fields.find((f) => f.name === row.field_name)) {
			activeRows.push(row);
		}
	});

	// set value of activeRows to checkedRows, the
	// model value used by the table
	checkedRows.value = activeRows;

	unWatchTableFields();
});
</script>
