import cbStore, { storeNames as cbStoreNames } from './ConnectionBuilder/store';
import dbStore, { storeNames as dbStoreNames } from './DocumentBuilder/store';
import deStore, { storeNames as deStoreNames } from './DocumentEditor/store';
import fbStore, { storeNames as fbStoreNames } from './FlowBuilder/store';
import flowStore, { storeNames as flowStoreNames } from './FlowBuilder/store/flow';
import blockStore, { storeNames as blockStoreNames } from './FlowBuilder/store/blocks';
import mbStore, { storeNames as mbStoreNames } from './MetaDocumentBuilder/store';
import methodStore, { storeNames as methodStoreNames } from './MethodBuilder/store';
import raStore, { storeNames as raStoreNames } from './RestApiBuilder/store';
import sbStore, { storeNames as sbStoreNames } from './ScriptBuilder/store';
import userStore, { storeNames as userStoreNames } from './UserManagement/store';
import cdStore, { storeNames as cdStoreNames } from './ComposedDocument/store';
import adStore, { storeNames as adStoreNames } from './ActionDocument/store';

export default {
    'ConnectionBuilder': {
        store: cbStore,
        storeId: 'CB',
        storeNames: cbStoreNames
    },
    'DocumentBuilder': {
        store: dbStore,
        storeId: 'DB',
        storeNames: dbStoreNames
    },
    'DocumentEditor': {
        store: deStore,
        storeId: 'DE',
        storeNames: deStoreNames
    },
    'FlowBuilder': {
        store: fbStore,
        storeId: 'FB',
        storeNames: fbStoreNames
    },
    'Flow': {
        store: flowStore,
        storeId: 'FLOW',
        storeNames: flowStoreNames
    },
    'Block': {
        store: blockStore,
        storeId: 'BLOCKS',
        storeNames: blockStoreNames
    },
    'MetaDocumentBuilder': {
        store: mbStore,
        storeId: 'MB',
        storeNames: mbStoreNames
    },
    'MethodBuilder': {
        store: methodStore,
        storeId: 'METHOD',
        storeNames: methodStoreNames
    },
    'RestApiBuilder': {
        store: raStore,
        storeId: 'RA',
        storeNames: raStoreNames
    },
    'ScriptBuilder': {
        store: sbStore,
        storeId: 'SB',
        storeNames: sbStoreNames
    },
    'UserManagement': {
        store: userStore,
        storeId: 'USER',
        storeNames: userStoreNames
    },
    'ComposedDocument': {
        store: cdStore,
        storeId: 'CD',
        storeNames: cdStoreNames
    },
    'ActionDocument': {
        store: adStore,
        storeId: 'AD',
        storeNames: adStoreNames
    },
};