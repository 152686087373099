import { IS_DEV } from '@assets/scripts/helpers';
import { isEmpty } from 'lodash';
import Helpers from '@assets/scripts/helpers';
import { prepareMethodForPost } from '@modules/MethodBuilder/components/method';
import { methodMeta } from '@modules/MethodBuilder/endpoints';
import usePermission from '@assets/scripts/composables/usePermission';

export const names = {
	MODIFIED: 'modified',
	CAN_BE_SAVED: 'canBeSaved',
	CAN_BE_PUBLISHED: 'canBePublished',
	METHOD_ACTIVE: 'methodActive',
	CURRENT_METHOD: 'currentMethod',
	CURRENT_CONNECTION: 'currentConnection',
	SHOW_CREATE_NEW: 'showCreateNew',
	FULL_METHOD: 'fullMethod',
};

export default {
	[names.MODIFIED]: ({ modified }) => {
		return modified;
	},
	[names.CAN_BE_SAVED]: ({ modified, currentMethod }) => {
		// can be saved only if it has never
		// been published yet, and it has been modified
		return (
			usePermission('Upsert', 'MethodBuilder') &&
			modified &&
			!currentMethod.is_published
		);
	},
	[names.CAN_BE_PUBLISHED]: ({ modified, currentMethod }) => {
		// if a method has not been published yet, or it has been
		// modified, it can be published, IF current user has correct permissions
		return (
			(!currentMethod.is_published || modified) &&
			usePermission('Upsert', 'MethodBuilder')
		);
	},
	[names.METHOD_ACTIVE]: ({ currentMethod }) => {
		return Object.keys(currentMethod).length > 0;
	},
	[names.CURRENT_METHOD]: ({ currentMethod }) => {
		return currentMethod;
	},
	[names.CURRENT_CONNECTION]: ({ currentConnection }) => {
		return currentConnection;
	},
	[names.SHOW_CREATE_NEW]: ({ showCreateNew }) => {
		return showCreateNew;
	},
	[names.FULL_METHOD]: ({ currentMethod }) => {
		return !isEmpty(currentMethod) && IS_DEV
			? Helpers.obj.construct(
					prepareMethodForPost(currentMethod),
					methodMeta
			  )
			: {};
	},
	debug: (state) => {
		// output whole state for debugging since Vue Devtools plugin
		// not (always) shows the updated state of the store, but it
		// does show the correct value of all getters
		return IS_DEV ? state : {};
	},
};
