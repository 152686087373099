import i18n from '@assets/i18n';
const TheActionDocument = () => import('./materials/TheActionDocument.vue');

// translate function of vue-i18n
const { t } = i18n.global;

export default [
    {
        path: '/actiondocument',
        component: TheActionDocument,
        name: 'actionDocument',
        meta: {
            title: t('ad.pageTitle'),
            permission: 'Read',
            showInSideNav: true,
            showInHome: true,
            parent: 'builder',
            icon: 'gear',
            module: 'ActionDocument',
            order: 15,
        },
    },
]