<template>
	<div class="datetime-dynamic-group">
		<VInputSelect
			:options="options"
			:label="$t('general.value')"
			v-model:valueModel="value.field"
			@update:valueModel="() => inputChanged()"
		/>

		<VInputInteger
			:label="$t('general.plusMinus')"
			v-model:valueModel="value.offset"
			@update:valueModel="() => inputChanged()"
		/>

		<VInputSelect
			:options="unitOptions"
			:label="$t('general.unit')"
			v-model:valueModel="value.unit"
			@update:valueModel="() => inputChanged()"
		/>
	</div>
</template>

<script>
export default {
	name: 'DateTimeDynamic',
	props: {
		valueModel: {
			type: null,
		},
		validModel: {
			type: Boolean,
			default: false,
		},
		isRequired: {
			type: Boolean,
			default: false,
		},
		options: {
			type: Array,
			default: [],
		},
		label: {
			type: String,
		},
	},
	data: function () {
		return {
			value: typeof this.valueModel === 'object' ? this.valueModel : { field: null, offset: null, unit: null },
			modified: false,
			unitOptions: [
				{
					text: this.$t('datepicker.selectOptions.second'),
					value: 'second',
				},
				{
					text: this.$t('datepicker.selectOptions.minute'),
					value: 'minute',
				},
				{
					text: this.$t('datepicker.selectOptions.hour'),
					value: 'hour',
				},
				{
					text: this.$t('datepicker.selectOptions.day'),
					value: 'day',
				},
				{
					text: this.$t('datepicker.selectOptions.month'),
					value: 'month',
				},
				{
					text: this.$t('datepicker.selectOptions.year'),
					value: 'year',
				},
			],
		};
	},
	mounted: function () {
		if (this.value !== this.valueModel)
			this.$emit('update:valueModel', this.value);
		this.$emit('update:validModel', this.isValid);
	},
	watch: {
		isValid() {
			this.$emit('update:validModel', this.isValid);
		},
	},
	computed: {
		isActive: function () {
			return this.value.field !== '';
		},
		isValid: function () {
			return !this.isRequired || this.isActive;
		},
	},
	methods: {
		inputChanged: function () {
			this.modified = true;
			this.$emit('update:valueModel', this.value);
		},
	},
};
</script>
