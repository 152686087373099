<template>
	<div class="tabs-wrapper">
		<nav class="tabs has-bottom-divider" :class="tabClasses">
			<VField
				v-if="useSelect"
				class="has-margin-right-1"
				:class="{ 'is-active': true }"
			>
				<VSelect :options="tabOptions" v-model="selectedIndex" />
			</VField>
			<ul v-else>
				<li
					v-bind:key="`tab-${index}`"
					:class="{ 'is-active': index == selectedIndex }"
					v-for="(tab, index) in tabs"
				>
					<a @click="selectTab(index)">{{ getTitle(tab) }}</a>
				</li>
			</ul>
		</nav>
		<div class="tab-content">
			<slot ref="tabs" />
		</div>
	</div>
</template>

<script>
import { computed } from 'vue';
import { reactive, toRefs } from '@vue/reactivity';
import { onBeforeMount, onMounted, provide } from '@vue/runtime-core';

export default {
	name: 'VTabs',
	props: {
		/**
		 * Use a select dropdown instead of Tabs?
		 * Useful when tabs do not fit in the available space
		 */
		useSelect: {
			type: Boolean,
			default: false,
		},
		tabClasses: {
			type: String,
			default: '',
		},
	},
	setup(props, { slots }) {
		const state = reactive({
			selectedIndex: 0,
			tabs: [],
			count: 0,
		});

		provide('TabsProvider', state);

		const selectTab = (i) => {
			state.selectedIndex = i;
		};

		const getTitle = (tab) => {
			const title = tab.props?.title;

			if (title) {
				return title;
			}

			return '';
		};

		const tabOptions = computed(() => {
			const result = [];

			if (state.tabs.length > 0) {
				state.tabs.forEach((tab, i) => {
					result.push({
						value: i,
						text: getTitle(tab)
					});
				});
			}

			return result;
		});

		onBeforeMount(() => {
			if (slots.default) {
				const addTab = (tab) => {
					state.tabs.push(tab);
				};

				slots.default().forEach((tab) => {
					if (typeof tab.type === 'symbol') {
						if (tab.children) {
							tab.children.forEach((childTab) => {
								addTab(childTab);
							});
						}
					} else {
						addTab(tab);
					}
				});
			}
		});

		onMounted(() => {
			selectTab(0);
		});

		return { ...toRefs(state), selectTab, getTitle, tabOptions };
	},
};
</script>
