<template>
	<DrawerItem
		:id="id"
		:drawerIndex="drawerIndex"
		:title="$t('fb.resultFields.drawerName')"
		:hasJsonButton="false"
		:hasFullwidthBody="true"
		:closeOnBackgroundClick="true"
		:hasScrollableContent="true"
		:onClose="closeDrawer"
	>
		<div
			class="content-header has-padding has-bottom-divider is-align-right"
		>
			<VSearch v-model="search" />
		</div>

		<BTable
			narrowed
			sticky-header
			scrollable
			hoverable
			@click="rowClicked"
			default-sort="field_name"
			default-sort-direction="ASC"
			:data="filteredData"
			:rowClass="getRowClass"
			:isRowCheckable="setRowCheckable"
			:checkable="true"
			:headerCheckable="false"
			v-model:checkedRows="checkedRows"
			@check="handleCheckedRows"
			@update:checkedRows="checkedRowsUpdated"
			bTableClasses="scrollable-content"
		>
			<BTableColumn
				v-for="(column, index) in columns"
				v-bind:key="index"
				:width="column.width || null"
				:label="column.label"
				:field="column.field"
				:sortable="column.sortable"
				:cellClass="column.cellClass"
				:customSort="column.customSort || null"
			>
				<template v-slot="props">
					<div
						:class="{
							'is-indent':
								column.leveled && props.row.level > 0,
						}"
					>
						<template
							v-if="column.leveled && props.row.level > 0"
						>
							<span
								v-for="item in props.row.level + 1"
								:key="item"
								class="spacer"
							></span>
							<VIcon name="subdir" />
						</template>
						<template v-if="column.component">
							<Component
								:is="column.component"
								v-bind="column.args"
								@click.prevent.stop="
									column.click(props.row)
								"
								v-if="props.row[column.field]"
							/>
							<template v-else>&nbsp;</template>
						</template>
						<template v-else>
							{{ props.row[column.field] || column.default }}
						</template>
					</div>
				</template>
			</BTableColumn>

			<template #empty>
				<div class="has-text-centered">
					{{ $t('fb.resultFields.table.noResults') }}
				</div>
			</template>
		</BTable>

		<template #footer>
			{{ $t('fb.resultFields.fieldsSelected', { count: selectedCount }) }}
			<SaveButton
				:isDisabled="selectedCount < 1"
				class="button-modal-footer"
				:text="$t('fb.resultFields.addButton')"
				icon="chevron-right"
				:iconOnRight="true"
				:callbackFn="addSelectedFields"
			/>
		</template>
	</DrawerItem>
</template>

<script>
import Helpers from '@assets/scripts/helpers';
import { mapGetters } from 'vuex';
import {
	getStoreAction,
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import Field from '@assets/scripts/components/field';
import { formatForResultFieldsTable } from '@assets/scripts/components/field';

export default {
	name: 'ResultFieldsDrawer',
	props: {
		/**
		 * Index of this drawer
		 */
		drawerIndex: {
			type: Number,
			required: true,
			default: 0,
		},
		/**
		 * Unique key of this drawer
		 */
		id: {
			type: String,
			required: true,
		},
	},
	data: function () {
		return {
			search: '',
			checkedRows: [],
			localCheckedRows: [],
			calculating: false,
			fields: [],
			columns: [
				{
					label: this.$t('fb.resultFields.table.columns.field_name'),
					field: 'field_name',
					sortable: true,
					searchable: true,
					leveled: true,
					default: this.$t('general.dash'),
					customSort: this.sortByFieldName,
				},
				{
					label: this.$t('fb.resultFields.table.columns.field_type'),
					field: 'field_type',
					sortable: false,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: '',
					field: 'disabled',
					sortable: false,
					default: '',
					width: '120', // to keep the popup within the screen
					component: 'VTooltip',
					cellClass: 'is-button-tool',
					args: {
						text: this.$t('fb.resultFields.table.disabledExplanation'),
					},
				},
			],
		};
	},
	mounted: function () {
		// copy data from block to component data
		this.fields = this.block.output;
	},
	computed: {
		...mapGetters({
			/**
			 * Get result block fields of block that is currently viewed/edited
			 */
			resultFields: getStoreGetter('RESULT_BLOCK_FIELDS', 'BLOCKS'),
			/**
			 * Get block that is currently viewed/edited
			 */
			block: getStoreGetter('CURRENT_BLOCK', 'BLOCKS'),
		}),
		tableFields: function () {
			return formatForResultFieldsTable(this.fields, this.resultFields);
		},
		searchableCols: function () {
			return Helpers.getSearchableColumns(this.columns);
		},
		filteredData: function () {
			let matches = this.tableFields;

			// return if no rows match
			if (this.search.length === 0) return matches;

			// filter on search string
			matches = Helpers.filterByString(
				matches,
				this.search,
				this.searchableCols
			);

			// also add ancestors of matches to matches
			if (matches.length < this.tableFields.length) {
				Field.addAncestorsOfMatches(
					matches,
					this.tableFields,
					'sort_name'
				);
			}

			return matches;
		},
		selectedCount: function () {
			// only count non-parent fields
			if (this.localCheckedRows.length < 1) return 0;

			let fields = [];

			this.localCheckedRows.forEach((row) => {
				fields.push(this.fields[row.key]);
			});

			fields = Field.filterOutParentFields(fields);

			return fields.length;
		},
	},
	methods: {
		setCalculating: function (isActive = true) {
			this.calculating = isActive;
		},
		addSelectedFields: function () {
			if (this.checkedRows.length < 1) return false;

			let fields = [];

			this.checkedRows.forEach((row) => {
				fields.push(this.fields[row.key]);
			});

			if (fields.length > 0) {
				// dispatch action to actually add the fields
				this.$store.dispatch(
					getStoreAction('ADD_RESULT_OUTPUT_FIELDS', 'BLOCKS'),
					fields
				);

				this.closeDrawer(true);
			}
		},
		closeDrawer: function (force = false) {
			const closeDrawer = () => {
				this.$store.commit(getStoreMutation('CLOSE_DRAWER'), this.id);
			};

			if (force || this.selectedCount < 1) {
				// close immediately if config has not been modified
				closeDrawer();
			} else {
				// ask confirmation before closing if a change has been made
				// to the field config
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t(
						'fb.blockDetails.configDetails.confirmClose.title'
					),
					body: this.$t(
						'fb.blockDetails.configDetails.confirmClose.body'
					),
					confirmButtonText: this.$t(
						'fb.blockDetails.configDetails.confirmClose.confirmButtonText'
					),
					confirmFn: () => {
						// close after confirmation
						closeDrawer();
					},
				});
			}
		},
		sortByFieldName(a, b, isAsc) {
			return Field.orderParentChildList(a.sort_name, b.sort_name, isAsc);
		},
		getRowClass: function (row) {
			return row.disabled ? 'is-disabled' : false;
		},
		setRowCheckable: function (row) {
			return !row.disabled && !this.calculating;
		},
		rowClicked: function (row) {
			if (row.disabled || this.calculating) return false;

			this.setCalculating();

			// find index of row in currently checked rows
			const i = this.getRowIndex(row, this.localCheckedRows);

			// N.B.: make SHALLOW copy here
			// two-way binding for checked rows only works that
			// way with Buefy Table
			const newCheckedRows = Helpers.cloneObject(this.checkedRows, false);

			// check row
			if (i === -1) this.selectRow(row, newCheckedRows);
			// uncheck row
			else this.unselectRow(row, newCheckedRows);

			this.localCheckedRows = Helpers.cloneObject(
				this.checkedRows,
				false
			);

			this.setCalculating(false);
		},
		/**
		 * Called by BTable whenever a row checkbox gets (un)checked
		 *
		 * @param {Array} rows
		 *  Array of rows that are checked
		 *
		 * @param {Object} row
		 *  Newly (un)checked row by user
		 */
		handleCheckedRows(rows, row) {
			this.rowClicked(row);
		},
		checkedRowsUpdated: function () {
			this.checkedRows = Helpers.cloneObject(
				this.localCheckedRows,
				false
			);
		},
		/**
		 * Called for a row that is now checked while it
		 * was not checked before
		 *
		 * @param {Object} row
		 *  Newly checked row
		 *
		 * @param {Array} checkedRows
		 *  Array of currently checked rows
		 */
		selectRow: function (row, checkedRows) {
			const check = (row) => {
				if (row.disabled) return;
				const i = this.getRowIndex(row, checkedRows);
				if (i < 0) checkedRows.push(row);
			};

			// check self
			check(row);

			// check all ancestors
			this.getRowAncestors(row, this.tableFields).forEach((field) => {
				check(field);
			});

			// set checkedRows prop
			this.checkedRows = checkedRows;
		},
		/**
		 * Called for a row that is now unchecked while it
		 * was checked before
		 *
		 * @param {Object} row
		 *  Newly unchecked row
		 *
		 * @param {Array} checkedRows
		 *  Array of currently checked rows
		 */
		unselectRow: function (row, checkedRows) {
			const uncheck = (i) => {
				if (i > -1) checkedRows.splice(i, 1);
			};

			// uncheck children
			this.getRowChildren(row, checkedRows).forEach((field) => {
				uncheck(this.getRowIndex(field, checkedRows));
			});

			// uncheck self
			uncheck(this.getRowIndex(row, checkedRows));

			// set checkedRows prop
			this.checkedRows = checkedRows;
		},
		getRowIndex: function (row, checkedRows = []) {
			let result = -1;

			checkedRows.some((checkedRow, i) => {
				if (checkedRow.key === row.key) {
					result = i;
					return true;
				}
			});

			return result;
		},
		getRowChildren: function (field, fields = []) {
			return fields.filter((row) => {
				const rowName = row.sort_name.toLowerCase();
				return rowName.startsWith(
					Field.getNameWithSplitter(field.sort_name).toLowerCase()
				);
			});
		},
		getRowAncestors: function (field, fields = []) {
			const fieldName = field.sort_name.toLowerCase();

			return fields.filter((row) => {
				const rowName = row.sort_name.toLowerCase();
				return fieldName.startsWith(Field.getNameWithSplitter(rowName));
			});
		},
	},
};
</script>
