<template>
	<DrawerItem
		:id="id"
		:drawerIndex="drawerIndex"
		:title="drawerName"
		:hasJsonButton="false"
		:hasFullwidthBody="false"
		:closeOnBackgroundClick="true"
		:onClose="closeDrawer"
	>

		<VField
			:isFloating="true"
			:label="$t('user.roleDetails.roleName')"
			:class="{ 'is-active': role.name }"
			:isError="!nameValid && modified"
		>
			<VInput
				:placeholder="$t('user.roleDetails.roleName')"
				:disabled="!editMode"
				type="text"
				@input="markModified"
				v-model:modelValue="role.name"
			/>
		</VField>

		<VNotification
			:text="$t('user.roleDetails.nameError')"
			:isFullwidth="false"
			:hasClose="false"
			type="danger"
			v-if="!nameValid && modified"
		/>

		<template v-for="(section, key) in abilities" :key="key">
			<div class="has-margin-bottom-5">
				<div class="has-bottom-divider has-padding-bottom-2 has-margin-bottom-2">
					<VTitle
						:size="2"
						:text="section.label"
					/>
					<VTitle
						v-if="section.info"
						:size="4"
						:text="section.info"
						class="subtitle"
					/>
				</div>

				<template v-for="(ability, id) in section.permissions" :key="id">
					<VField
						class="is-fullwidth"
						:help="ability.info || null"
						v-if="ability.availableInRole"
					>
						<VOption
							v-model:modelValue="role.permissions[section.module][id]"
							type="checkbox"
							:label="ability.label || key"
							wrapperClasses="has-margin-0 has-margin-right-05"
							@update:modelValue="modelChanged"
							:disabled="!editMode"
						/>
					</VField>
				</template>
			</div>
		</template>

		<template #footer>
			<SaveButton
				:isDisabled="!modified || !nameValid"
				class="button-modal-footer"
				:text="$t('user.roleDetails.saveButton')"
				icon="chevron-right"
				:iconOnRight="true"
				:callbackFn="saveRole"
			/>
		</template>
	</DrawerItem>
</template>

<script>
import { useStore } from 'vuex';
import { reactive, toRefs } from '@vue/reactivity';
import { ref, unref, watch, computed } from 'vue';
import {
	getStoreAction,
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import allAbilities from '@assets/scripts/auth/abilities';
import modules from '@assets/scripts/composables/useModules';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { GET_ROLES } from '@modules/UserManagement/endpoints';
import usePermission from '@assets/scripts/composables/usePermission';
import usePreventDataLoss from '@assets/scripts/composables/usePreventDataLoss';

export default {
	name: 'RoleDetailsDrawer',
	props: {
		/**
		 * Index of this drawer
		 */
		drawerIndex: {
			type: Number,
			required: true,
			default: 0,
		},
		/**
		 * Unique key of this drawer
		 */
		id: {
			type: String,
			required: true,
		},
	},
	data: function () {
		return {
			roleNames: [],
			editMode: usePermission('Upsert', 'UserManagement'),
		};
	},
	setup: function () {
		const store = useStore();

		const state = reactive({
			/**
			 * Get Role that is currently being edited
			 */
			role: store.getters[getStoreGetter('CURRENT_ROLE', 'USER')],
		});
		
		const abilities = ref({});

		const createPermissions = () => {
			abilities.value = {};

		// loop over all ability sections
			for (const key in allAbilities) {
				const section = allAbilities[key];
				const { module } = section;
	
				// check if module that abilities belong to is
				// available, leave them out if not
				if (unref(modules).includes(module)) {
					abilities.value[key] = section;
	
					// add module object if not exist in current role
					if(!state.role.permissions[module]) {
						state.role.permissions[module] = {};
					}
		
					// loop over all abililities within section and set the value of each permissons from the role if exist
					for (const ability in section.permissions) {
						// check if ability has availableInRole 
						if(section.permissions[ability].availableInRole) {
							state.role.permissions[module][ability] = 
							(state.role.permissions[module] &&
								state.role.permissions[module][ability]) || false;
						}
						else {
							state.role.permissions[module][ability] = false
						}
					}
				}
	
			};
		};

		// watch for changes in available modules
		watch(modules, () => {
			// update role permissions
			createPermissions();
		});
		
		// set all permissions in the role with correct value
		createPermissions();

		/**
		 * Get indicator whether current Role has been modified
		 */
		const modified = computed(() => {
			return store.getters[getStoreGetter('ROLE_MODIFIED', 'USER')];
		});

		usePreventDataLoss(modified);

		return {
			...toRefs(state),
			modified,
			abilities,
		};
	},
	mounted: async function () {
		const roles = await useApiAsync(GET_ROLES);

		if (roles) {
			// get list of existing role names, excluding
			// current role
			roles.forEach((role) => {
				if (role.guid !== this.role.guid) {
					this.roleNames.push(role.name.toLowerCase());
				}
			});
		}
	},
	computed: {
		drawerName: function () {
			return this.role.name || '...';
		},
		nameValid: function () {
			return (
				this.role.name &&
				this.roleNames.indexOf(this.role.name.toLowerCase()) < 0
			);
		},
	},
	methods: {
		modelChanged: function () {
			this.markModified();
		},
		markModified: function () {
			if (this.modified) return;

			this.$store.commit(
				getStoreMutation('MARK_ROLE_MODIFIED', 'USER')
			);
		},
		saveRole: function () {
			this.$store.dispatch(getStoreAction('SAVE_CURRENT_ROLE', 'USER'), {
				drawerId: this.id, // id of drawer to close after save
			});
		},
		closeDrawer: function () {
			const closeDrawer = () => {
				// unset current role
				this.$store.commit(getStoreMutation('UNSET_ROLE', 'USER'));

				// close drawer
				this.$store.commit(getStoreMutation('CLOSE_DRAWER'), this.id);
			};

			if (!this.modified) {
				// close immediately if config has not been modified
				closeDrawer();
			} else {
				// ask confirmation before closing if a change has been made
				// to the field config
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t(
						'user.roleDetails.close.confirm.title'
					),
					body: this.$t(
						'user.roleDetails.close.confirm.body'
					),
					confirmButtonText: this.$t(
						'user.roleDetails.close.confirm.confirmButtonText'
					),
					confirmFn: () => {
						// close after confirmation
						closeDrawer();
					},
				});
			}
		},
	},
};
</script>
