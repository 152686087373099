/**
 * Translations for English
 * See https://vue-i18n.intlify.dev/guide/ on how to use
 */
 export default {
	pageTitle: 'Customers',
	permissions: {
		label: 'Customers',
		info: 'Permissions connected to the Customers module',
		use: {
			label: 'Use Customers',
			info: 'Permission to use the Customers module',
		},
	},
};
