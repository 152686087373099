import {
    defineAbility,
    AbilityBuilder,
    Ability,
} from '@casl/ability';
import { watch, unref } from 'vue';

import useJWT from '@assets/scripts/composables/useJWT';

const { permissions, is_admin, isLoggedIn } = useJWT();

// create empty ability object
// actual abilities will be added later, based
// on current user
const ability = defineAbility(() => { });

/**
 * Used to set abilities (permissions) based on
 * the user
 *
 * @returns {void}
 */
const setAbility = () => {
    const { can, rules } = new AbilityBuilder(Ability);
    if (unref(is_admin)) {
        // administrator can do everything
        can('manage', 'all');
    } else if (unref(permissions)) {
        // loop over permissions of user
        for (const module in unref(permissions)) {
            for (const permission in unref(permissions)[module]) {
                // grant permission in context
                can(permission, module);
            }
        }
    }

    // set new abilities, these overwrite all
    // currently set abilities
    ability.update(rules);
};

// watch for changes in login status
watch(isLoggedIn, () => {
    // set new abilities
    setAbility();
}, {
    immediate: true,
});

export const userHasAccess = (route) => {
    return (
        !route.meta.permission ||
        ability.can(route.meta.permission, route.meta.module)
    );
};

export default ability;
