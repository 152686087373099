import i18n from '@assets/i18n';
const TheSettingsDashboardPage = () => import('@modules/SettingsDashboard/TheSettingsDashboardPage.vue');

// translate function of vue-i18n
const { t } = i18n.global;

export default [
	{
		path: '/settingsdashboard',
		component: TheSettingsDashboardPage,
		name: 'settingsdashboard',
		meta: {
			title: t('sd.pageTitle'),
			permission: 'Read',
			showInHome: true,
			showInSideNav: true,
			parent: 'clientModules',
			icon: 'gear',
			module: 'Settings Dashboard',
			order: 100,
		},
	},
];