import i18n from '@assets/i18n';
const TheDatacleansingPage = () => import('@modules/Datacleansing/TheDatacleansingPage.vue');

// translate function of vue-i18n
const { t } = i18n.global;

export default [
	{
		path: '/datacleansing',
		component: TheDatacleansingPage,
		name: 'datacleansing',
		meta: {
			title: t('dc.pageTitle'),
			permission: 'Read',
			showInHome: true,
			showInSideNav: true,
			parent: 'clientModules',
			icon: 'gear',
			module: 'Datacleansing',
			order: 100,
		},
	},
];