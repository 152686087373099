/**
 * Check a specific object property against
 * a given filter value
 *
 * @param {Object} row
 *  Object to check against filter
 *
 * @param {String} prop
 *  Property name of object to check
 *
 * @param {String} filter
 *  Value to filter on
 *
 * @param {String} all
 * 	Translated string for 'All'. Meaning that if
 *  filter parameter is equal to this value, all rows
 *  automatically match it
 *
 * @param {Boolean} trueIfMissing
 *  Whether the response should be positive if
 *  the requested property is missing from
 *  the given row
 *
 * @returns {Boolean}
 *  True if row matches filter, false if not
 */
export const filterByValue = (
	row,
	prop,
	filter,
	all = '',
	trueIfMissing = false
) => {
	if (!row || typeof row[prop] === 'undefined') return trueIfMissing;
	return !filter || filter === '' || filter === all || row[prop] === filter;
};

/**
 * Used for filtering Buefy table data
 *
 * @param {Array} rows
 * 	Array of Objects to search through
 *
 * @param {String} query
 *  The string to search on
 *
 * @param {Array} columnsToSearch
 * 	Property names to search
 *
 * @returns {Array}
 *  Array of rows that match the query
 */
export const filterByString = (rows, query, columnsToSearch) => {
	// return all rows if search query is empty
	if (typeof query !== 'string' || query === '') return rows;

	// filter rows
	return rows.filter((row) => {
		// check if at least 1 property value matches
		// the search query
		return Object.keys(row).some((column) => {
			// check if property should be search, and
			// check if property value contains search query
			return (
				columnsToSearch.indexOf(column) !== -1 &&
				['string', 'number', 'boolean'].includes(typeof row[column]) &&
				row[column]
					.toString()
					.toLowerCase()
					.indexOf(query.toLowerCase()) !== -1
			);
		});
	});
};

/**
 * Find column names from column definitions
 * that are searchable
 *
 * @param {Array} tableColumns
 *  Array of column definitions
 *
 * @returns {Array}
 *  Array of column names that are searchable
 */
export const getSearchableColumns = (tableColumns = []) => {
	const result = [];

	tableColumns.forEach((column) => {
		if (column.searchable) result.push(column.field);
	});

	return result;
};
