import { IS_DEV } from '@assets/scripts/helpers';
import Helpers from '@assets/scripts/helpers';
import { isEmpty } from 'lodash';
import usePermission from '@assets/scripts/composables/usePermission';
import { actionDocumentMeta } from '@modules/ActionDocument/endpoints';

export const names = {
	MODIFIED: 'modified',
	CAN_BE_EDITED: 'canBeEdited',
	CAN_BE_SAVED: 'canBeSaved',
	CAN_BE_PUBLISHED: 'canBePublished',
	ACTION_DOCUMENT_ACTIVE: 'actionDocumentActive',
	CURRENT_ACTION_DOCUMENT: 'currentActionDocument',
	CURRENT_CONNECTION: 'currentConnection',
	SHOW_CREATE_NEW: 'showCreateNew',
	FULL_ACTION_DOCUMENT: 'fullActionDocument',
};

export default {
	[names.MODIFIED]: ({ modified }) => {
		return modified;
	},
	[names.CAN_BE_EDITED]: ({ canBeModified }) => {
		// can be edited if it has never been published yet
		return canBeModified;
	},
	[names.CAN_BE_SAVED]: ({ canBeModified, modified }) => {
		// can be saved only if it has never
		// been published yet, and it has been modified
		return canBeModified && modified;
	},
	[names.CAN_BE_PUBLISHED]: ({ canBeModified, modified }) => {
		// if an action document has not been published yet, or it has been
		// modified, it can be published, IF current user has correct permissions
		return (
			canBeModified &&
			usePermission('Upsert', 'ActionDocument')
		);
	},
	[names.ACTION_DOCUMENT_ACTIVE]: ({ currentActionDocument }) => {
		return Object.keys(currentActionDocument).length > 0;
	},
	[names.CURRENT_ACTION_DOCUMENT]: ({ currentActionDocument }) => {
		return currentActionDocument;
	},
	[names.CURRENT_CONNECTION]: ({ currentConnection }) => {
		return currentConnection;
	},
	[names.SHOW_CREATE_NEW]: ({ showCreateNew }) => {
		return showCreateNew;
	},
	[names.FULL_ACTION_DOCUMENT]: ({ currentActionDocument }) => {
		return !isEmpty(currentActionDocument) && IS_DEV ? Helpers.obj.construct(currentActionDocument, actionDocumentMeta) : {};
	},
	debug: (state) => {
		// output whole state for debugging since Vue Devtools plugin
		// not (always) shows the updated state of the store, but it
		// does show the correct value of all getters
		return IS_DEV ? state : {};
	},
};
