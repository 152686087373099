import i18n from '@assets/i18n';
const TheGenerativeAIPage = () => import('@modules/GenerativeAI/TheGenerativeAIPage.vue');

// translate function of vue-i18n
const { t } = i18n.global;

export default [
	{
		path: '/generativeAI',
		component: TheGenerativeAIPage,
		name: 'generativeAI',
		meta: {
			title: t('generativeAI.pageTitle'),
			permission: 'Read',
			showInHome: true,
			showInSideNav: true,
			parent: 'clientModules',
			icon: 'gear',
			module: 'Generative AI',
			order: 100,
		},
	},
];