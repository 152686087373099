import i18n from '@assets/i18n';
const TheSegmentBuilder = () => import('@modules/SegmentBuilder/TheSegmentBuilder.vue');

// translate function of vue-i18n
const { t } = i18n.global;

export default [
	{
		path: '/segmentbuilder',
		component: TheSegmentBuilder,
		name: 'segmentbuilder',
		meta: {
			title: t('smb.pageTitle'),
			permission: 'Read',
			showInHome: true,
			showInSideNav: true,
			parent: 'clientModules',
			icon: 'gear',
			module: 'Segment builder',
			order: 110,
		},
	},
];