import i18n from '@assets/i18n';

// translate function of vue-i18n
const { t } = i18n.global;

export default {
    label: t('report.permissions.label'),
    info: t('report.permissions.info'),
    module: 'Reporting',
    permissions: {
        'Read': {
            label: t('report.permissions.use.label'),
            info: t('report.permissions.use.info'),
            availableInRole: true // if true will be visible in role details as a checkbox
        },
    }
};
