<template>
	<iframe class="black-panther" :src="url"></iframe>
</template>

<script>
export default {
	name: 'TheBlackPanther',
};
</script>

<script setup>
import { GET_I_FRAME_MODULES_INFO } from '@assets/scripts/api/config';
import { useApi } from '@assets/scripts/composables/useApi';
import { watch, ref, unref } from 'vue';
import { isArray } from 'lodash';
import useJWT from '@assets/scripts/composables/useJWT.js';

const { name } = defineProps({
	name: {
		type: String,
		required: true
	},
});

const { data } = useApi(GET_I_FRAME_MODULES_INFO);

const { jsonWebToken } = useJWT();

const url = ref('');

watch(data, (newData) => {
	if(isArray(newData)) {
		const blackPantherModule = newData.find((blackPantherModule) => blackPantherModule.name === name);
		const iframeUrl = new URL(blackPantherModule.url);
		iframeUrl.searchParams.set('token', unref(jsonWebToken));
		url.value = iframeUrl.toString();
	}
});
</script>