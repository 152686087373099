import { IS_DEV } from '@assets/scripts/helpers';

export const names = {
	MODIFIED: 'modified',
	CURRENT_RECORD: 'currentRecord',
	CURRENT_DOCUMENT: 'currentDocument',
	DOCUMENT_ACTIVE: 'documentActive',
};

export default {
	[names.MODIFIED]: ({ modified }) => {
		return modified;
	},
	[names.CURRENT_RECORD]: ({ currentRecord }) => {
		return currentRecord;
	},
	[names.DOCUMENT_ACTIVE]: ({ currentDocument }) => {
		return Object.keys(currentDocument).length > 0;
	},
	[names.CURRENT_DOCUMENT]: ({ currentDocument }) => {
		return currentDocument;
	},
	debug: (state) => {
		// output whole state for debugging since Vue Devtools plugin
		// not (always) shows the updated state of the store, but it
		// does show the correct value of all getters
		return IS_DEV ? state : {};
	},
};
