<template>
	<VField
		:isFloating="isFloating"
		:label="label"
		:class="{ 'is-active': isActive }"
		:isError="showError"
	>
		<VSelect
			:options="options"
			v-model="value"
			@change="inputChanged"
			:disabled="disabled"
		/>
	</VField>

	<div class="notification-wrapper" v-if="showError">
		<VNotification
			:text="$t('error.chooseValue')"
			:isFullwidth="false"
			:hasClose="false"
			type="danger"
		/>
	</div>
</template>

<script>
export default {
	name: 'VInputSelect',
};
</script>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';

const props = defineProps({
	valueModel: {
		type: null,
	},
	validModel: {
		type: Boolean,
		default: false,
	},
	isRequired: {
		type: Boolean,
		default: false,
	},
	label: {
		type: String,
	},
	isFloating: {
		type: Boolean,
		default: true,
	},
	options: {
		type: Array,
		default: [],
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits(['update:valueModel', 'update:validModel']);

const value = computed({
	get() {
		return typeof props.valueModel === 'string' ? props.valueModel : '';
	},
	set(_value) {
		emit('update:valueModel', _value);
	},
});

const modified = ref(false);

const isActive = computed(() => value.value !== '');
const isValid = computed(() => !props.isRequired || isActive.value);
const showError = computed(() => modified.value && !isValid.value);

const inputChanged = () => {
	modified.value = true;
};

watch(isValid, (newVal) => {
	emit('update:validModel', newVal);
});

onMounted(() => {
	if (value.value !== props.valueModel) {
		emit('update:valueModel', value.value);
	}
	emit('update:validModel', isValid.value);
});
</script>
