/**
 * Translations for English
 * See https://vue-i18n.intlify.dev/guide/ on how to use
 */
 export default {
	pageTitle: 'Generative AI',
	permissions: {
		label: 'Generative AI',
		info: 'Permissions connected to the Generative AI module',
		use: {
			label: 'Use Generative AI',
			info: 'Permission to use the Generative AI module',
		},
	},
};
