import { util } from 'jointjs';
import { ref, unref, watch, computed, onMounted, onUnmounted } from 'vue';

const dataLosers = ref([]);
const enabled = ref(true);

export const dataLossPossible = computed(() => {
	return enabled.value && dataLosers.value.length > 0;
});

export const reset = () => {
	dataLosers.value = [];
};

export const enable = () => {
	enabled.value = true;
};

export default (valToWatch) => {
	const key = util.uuid();

	const start = () => {
		if (unref(dataLosers).indexOf(key) === -1) {
			dataLosers.value.push(key);
		}
	};

	const stop = () => {
		const keyIndex = dataLosers.value.indexOf(key);
		if (keyIndex > -1) dataLosers.value.splice(keyIndex, 1);
	};

	onMounted(() => {
		watch(
			valToWatch,
			(newVal) => {
				if (newVal) start();
				else stop();
			},
			{
				immediate: true,
			}
		);
	});

	onUnmounted(() => {
		stop();
	});

	return stop;
};
