import ConnectionBuilder from './ConnectionBuilder/routes';
import DocumentBuilder from './DocumentBuilder/routes';
import DocumentEditor from './DocumentEditor/routes';
import FlowBuilder from './FlowBuilder/routes';
import MetaDocumentBuilder from './MetaDocumentBuilder/routes';
import MethodBuilder from './MethodBuilder/routes';
import Reporting from './Reporting/routes';
import Dashboard from './Dashboard/routes';
import Customers from './Customers/routes';
import Queue from './Queue/routes';
import RestApiBuilder from './RestApiBuilder/routes';
import ScriptBuilder from './ScriptBuilder/routes';
import SegmentBuilder from './SegmentBuilder/routes';
import UserManagement from './UserManagement/routes';
import ComposedDocument from './ComposedDocument/routes';
import ActionDocument from './ActionDocument/routes';
import Dispatcher from './Dispatcher/routes';
import Datacleansing from './Datacleansing/routes';
import FunctionBlocks from './FunctionBlocks/routes';
import InsightTags from './InsightTags/routes';
import InsightKPI from './InsightKPI/routes';
import GenerativeAI from './GenerativeAI/routes';
import SettingsDashboard from './SettingsDashboard/routes';
import SettingsReports from './SettingsReports/routes';
import SettingsDatacleansing from './SettingsDatacleansing/routes';

export default {
    FlowBuilder,
    DocumentBuilder,
    ComposedDocument,
    ActionDocument,
    MethodBuilder,
    ConnectionBuilder,
    Reporting,
    Dashboard,
    Customers,
    Queue,
    Dispatcher,
    Datacleansing,
    RestApiBuilder,
    UserManagement,
    DocumentEditor,
    MetaDocumentBuilder,
    ScriptBuilder,
    SegmentBuilder,
    FunctionBlocks,
    InsightTags,
    InsightKPI,
    GenerativeAI,
    SettingsDashboard,
    SettingsReports,
    SettingsDatacleansing,
};