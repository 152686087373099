import i18n from '@assets/i18n';
import Helpers from '@assets/scripts/helpers';
import { isEmpty } from 'lodash';
import { actionDocumentMeta } from '@modules/ActionDocument/endpoints';
import usePermission from '@assets/scripts/composables/usePermission';
import { debug } from '@assets/scripts/components/notifications';
import Validation from '@assets/scripts/components/validationChecks';
import { useApiAsync } from '@assets/scripts/composables/useApi';

import {
	GET_ACTION_DOCUMENTS,
	GET_DOCUMENT,
	GET_METHOD,
} from '@modules/ActionDocument/endpoints';

// translate function of vue-i18n
const { t } = i18n.global;

// possible statuses for documents
const statuses = {
	PUBLISHED: 'published',
	DRAFT: 'draft',
};

/**
 * Returns a newly created action document
 *
 * @param {String} name
 *  Name of the Document
 *
 * @param {String} description
 *  Description of the Document
 *
 * @param {String} type
 *  Type of Document
 *
 * @param {String} conn_guid
 *  GUID of Connection that Action Document will be part of
 *
 * @returns {Object}
 *  New Action Document
 */
export const createNewActionDocument = ({ name, description, conn_guid }) => {
	// get current date
	const dateIso = new Date().toISOString();

	// create and return new action document
	return Helpers.obj.create(actionDocumentMeta, {
		name,
		description,
		guid: Helpers.newGuid(),
		conn_guid,
		created: dateIso,
		modified: dateIso,
	});
};

/**
 * Returns a newly created action document action
 *
 * @param {String} parent
 *  Internal GUID of the parent action, or omitted/false for root action
 *
 * @param {Number} level
 *  Level of the action in the document hierarchy, 0 for root, 1 for root child etc.
 *
 * @returns {Object}
 *  New Action Document Action
 */
export const createNewActionDocumentAction = (parent = false, level = 0) => {
	// create and return new action document action
	return Object.assign(
		Helpers.obj.create(actionDocumentMeta.action.children.childs.children, {}),
		{
			parent,
			level,
			internal_guid: Helpers.newGuid(),
		},
	);
};

/**
 * Returns a newly created match rule object
 *
 * @param {Object} document
 *  Action document to create new match rule for
 *
 * @returns {Object}
 *  New Match Rule object
 */
export const createNewMatchRule = (document) => {
	// create and return new match rule object
	return Object.assign(
		Helpers.obj.create(actionDocumentMeta.rules.children, {}),
		{
			order: document.rules.length + 1 || 1,
		},
	);
};

/**
 * Gets the status of a given action document
 *
 * @param {Object} actionDocument
 *  Action document to get status of (normalized)
 *
 * @returns {String}
 *  Status of given action document
 */
const getActionDocStatus = (actionDocument) => {
	// determine status
	let status = statuses.DRAFT;

	// check if document is published
	if (actionDocument.is_published === true) {
		status = statuses.PUBLISHED;
	}

	return status;
};

/**
 * Gets the status of a given action document as human readable text,
 * i.e.: Draft, Published etc.
 * Meant for display purposes, not for checking conditions
 *
 * @param {Object} actionDocument
 *  Action document to get status of (normalized)
 *
 * @returns {String}
 *  Translated human readable status
 */
const getActionDocStatusName = (actionDocument) => {
	let output;

	// determine status
	switch (getActionDocStatus(actionDocument)) {
		case statuses.DRAFT:
			output = t('general.draft');
			break;
		case statuses.PUBLISHED:
			output = t('general.published');
			break;
	}

	return output;
};

/**
 * Check if a given action document can be modified by current user
 *
 * @param {Object} actionDocument
 *  Action document to check
 *
 * @returns {Boolean}
 */
export const userCanModifyActionDoc = (actionDocument) => {
	return (
		getActionDocStatus(actionDocument) === statuses.DRAFT && usePermission('Upsert', 'ActionDocument') // document is a Draft & user can edit docs
	);
};

/**
 * Check if a given action document can be deleted by
 * the current user
 *
 * @param {Object} actionDocument
 *  Action Document to check
 *
 * @returns {Boolean}
 */
const userCanDeleteActionDoc = (actionDocument) => {
	return (
		(
			usePermission('Delete', 'ActionDocument') && 
			getActionDocStatus(actionDocument) === statuses.DRAFT
		) || usePermission('Delete published', 'ActionDocument')
	);
};

/**
 * Flattens a given action document for easier handling
 * in the UI
 * 
 * @param {Object} actionDocument
 *  Action document to flatten
 * 
 * @returns {Object}
 *  Flattened action document
 */
export const flattenActionDocument = (actionDocument) => {
	// return empty object if no document is given
	if (!actionDocument || isEmpty(actionDocument)) return {};

	const result = Helpers.cloneObject(actionDocument);
	result.actions = {};

	const flattenRecursive = (action, level = 0, parent = false) => {
		// create new GUID for internal reference
		const guid = Helpers.newGuid();

		// add guid to action
		action.internal_guid = guid;

		// add level to action
		action.level = level;

		// add parent guid to action, will be false for root action
		action.parent = parent;

		// add action to result
		result.actions[guid] = action;

		// check if action has children
		if (action.childs && action.childs.length > 0) {
			// loop over children
			action.childs.forEach((child) => {
				// recursively flatten children
				flattenRecursive(child, level + 1, guid);
			});

			// remove childs from action
			delete action.childs;
		}
	};

	if (result?.action?.guid) {
		// flatten root action
		flattenRecursive(result.action);

		// remove root action
		delete result.action;
	}

	return result;
};

/**
 * Prepares a given action document for posting to the with the Nominow API
 *
 * @param {Object} actionDocument
 *  Normalized version of action document to construct
 *
 * @returns {Object}
 *  Cloned and updated action document object
 */
export const constructActionDocument = (actionDocument) => {
	// return empty object if no document is given
	if (!actionDocument || isEmpty(actionDocument)) return {};

	// return document if no actions are present
	if (!actionDocument.actions) return actionDocument;

	const result = Helpers.cloneObject(actionDocument);

	// find root action
	const rootAction = Object.values(result.actions).find((action) => action.parent === false);

	if (rootAction) {
		// add root action back to document
		result.action = rootAction;

		// loop over actions
		Object.values(result.actions).forEach((action) => {
			if (!action.parent) return;

			// find parent action
			const parent =  result.actions[action.parent] ?? false;

			// add child to parent
			if (parent) {
				// create childs array if it does not exist
				if (!parent.childs) parent.childs = [];

				// add child to parent
				parent.childs.push(action);
			}

		});
	}
	
	// remove actions from result
	delete result.actions;

	// sort match rules based on 'sort' attribute
	result.rules.sort((a, b) => a.order - b.order);

	// return updated document
	return result;
};

/********************/
/* TABLE FORMATTING */
/********************/

/**
 * Get info about action documents to use in Table in ActionDocumentList component
 *
 * @param {Array} actionDocuments
 *  Array of action document objects
 *
 * @returns {Array}
 *  Array of objects per table row
 */
export const formatForTable = (actionDocuments) => {
	const result = [];

	// loop over documents
	actionDocuments.forEach((document, key) => {
		// get last modified time
		const lastModified = document.modified;

		result.push({
			key, // key, useful for handling clicks
			guid: document.guid,
			// name used for sorting
			doc_name: document.name,
			// is read action document
			is_read: document.is_read ? t('general.yes') : t('general.no'),
			// root child first document
			root_child: document.action.name,
			// time as ISO string, used for sorting
			time: lastModified,
			// localized time for display
			last_time_edited: lastModified
				? Helpers.date.localeStringWithMinutes(lastModified)
				: t('general.dash'),
			status: getActionDocStatusName(document),
			edit: userCanModifyActionDoc(document) ? 'edit' : 'view',
			delete: userCanDeleteActionDoc(document),
		});
	});

	return result;
};

/********************/
/*    VALIDATION    */
/********************/

const createErrorObject = (description) => {
	return Helpers.createErrorObject(description);
};

/**
 * Validates given action document and returns errors array
 *
 * @param {Object} actionDocument
 *  Action Document to validate
 *
 * @returns {Array}
 *	array of errors
 */
export const validateActionDocument = async (actionDocument) => {
	debug('Validating', { actionDocument });

	const output = [];
	const fullActions = {};

	const {
		name = '',
		actions = {},
		is_read = false,
		guid = '',
		conn_guid = '',
		rules = [],
	} = actionDocument;

	const setError = (description) => {
		output.push(createErrorObject(description));
	};

	const getFullAction = async ({guid = false, type = false}) => {
		if (!guid || !type) return false;

		if (fullActions[guid]) return fullActions[guid];

		const endpoint = type === 'Document' ? GET_DOCUMENT : GET_METHOD;
	
		// load full action object for current connection
		const result = await useApiAsync(endpoint, {
			keys: {
				connection: conn_guid,
				guid,
			},
		});

		fullActions[guid] = result;
	
		return result;
	};

	// check for empty action document name
	if (!Validation.stringNotEmpty(name)) {
		setError(
			t('ad.validation.nameEmpty')
		);
	} 
	// check if action document name has at least 2 characters
	else if(!Validation.stringHasAtleastTwoCharacters(name)){
		setError(
			t('ad.validation.nameIsShort')
		);	
	}
	else {
		// get all action documents for connection
		const actionDocuments = await useApiAsync(GET_ACTION_DOCUMENTS, {
			keys: {
				connection: conn_guid
			}
		});

		if (actionDocuments) {
			const list = [];

			// create list of action document names, excluding action document that is
			// being validated
			actionDocuments.forEach((doc) => {
				if (doc.guid !== guid) list.push(doc.name.toLowerCase());
			});

			// check if action document name already exists
			if (Validation.stringExistsInList(name.toLowerCase(), list)) {
				setError(
					t('ad.validation.nameNotUnique')
				);
			}
		}
	}

	// check if root action is choosen
	if (!actions || !Object.values(actions).find((action) => action.level === 0 && Validation.stringNotEmpty(action.guid))) {
		setError(
			t('ad.validation.rootActionNotChosen')
		);
	} else {
		// gather all used methods in the action document
		const usedMethods = Object.values(actions).filter((action) => action.type === 'Method');

		for (const method of usedMethods) {
			// get full method object from API
			const fullMethod = await getFullAction(method);

			// check if methods 'is_read' property matches the action document setting
			if (fullMethod && fullMethod.is_read !== is_read) {
				setError(
					t('ad.validation.methodIsReadMismatch', {
						method: method.name,
					})
				);
			}
		}

		for (const action of Object.values(actions)) {
			// get full action object from API
			const fullAction = await getFullAction(action);
			// type of current action
			const actionType = action.type;

			const actionFields = actionType === 'Document' ? (fullAction?.fields ?? []) : (fullAction?.input ?? []);

			// only target non-root actions
			if (action.level > 0) {
				// check if action has a relation object as property
				if (!Validation.isNonEmptyObject(action?.relation)) {
					setError(
						t('ad.validation.actionRelationNotSet', {
							action: action.name,
						})
					);
				} else {
					let actionField = false; // name of configured child field
					let parentField = false; // name of configured parent field
					let parentAction = false; // parent action object
					let parentType = false; // type of parent action

					let fullActionField = false; // full field object of configured child field
					let fullParent = false; // full parent action object
					let fullParentField = false; // full field object of configured parent field

					// check if Alias is set
					if (!Validation.stringNotEmpty(action.relation?.child_name)) {
						setError(
							t('ad.validation.actionRelationAliasNotSet', {
								action: action.name,
							})
						);
					} else {
						// get actions with same alias and parent
						const sameAliasActions = Object.values(actions).filter((a) => {
							return (
								a?.relation?.child_name.toLowerCase() === action.relation?.child_name.toLowerCase() &&
								a.parent === action.parent
							);
						});

						// check if Alias is unique
						if (sameAliasActions.length > 1) {
							setError(
								t('ad.validation.actionRelationAliasNotUnique', {
									action: action.name,
								})
							);
						}
					}

					// check if Child field is set
					if (!Validation.stringNotEmpty(action.relation?.child_field)) {
						setError(
							t('ad.validation.actionRelationChildNotSet', {
								action: action.name,
							})
						);
					} else {
						// set child field
						actionField = action.relation.child_field;

						if (fullAction) {
							// find configured child field in full action object
							fullActionField = actionFields.find((field) => field.name === actionField);

							if (!fullActionField) {
								setError(
									t('ad.validation.configuredChildFieldDoesNotExist', {
										action: action.name,
										field: actionField,
									})
								);
							}
						}
					}

					// check if Parent field is set
					if (!Validation.stringNotEmpty(action.relation?.parent_field)) {
						setError(
							t('ad.validation.actionRelationParentNotSet', {
								action: action.name,
							})
						);
					} else if (action.parent && actions[action.parent]) {
						// set parent field
						parentField = action.relation.parent_field;
						// set parent action
						parentAction = actions[action.parent];
						// set parent type
						parentType = parentAction.type;
						// load full parent object
						fullParent = await getFullAction(actions[action.parent]);

						if (fullParent) {
							// find configured parent field in full parent action object
							const parentFields = parentType === 'Document' ? fullParent.fields : fullParent.output;
							fullParentField = parentFields.find((field) => field.name === parentField);

							if (!fullParentField) {
								setError(
									t('ad.validation.configuredParentFieldDoesNotExist', {
										action: action.name,
										parent: fullParent.name,
										field: parentField,
									})
								);
							}
						}
					}

					// check for Document-to-Document relation
					if (actionType === 'Document' && parentType === 'Document') {
						if (fullAction && !!actionField && !!parentField) {
							// if full action has no references, or no reference to parent action using same
							// parent and child fields, create a validation error
							if (
								!Array.isArray(fullAction.references) ||
								!fullAction.references.length > 0 ||
								!fullAction.references.find((el) => {
									return (
										el.guid === parentAction.guid &&
										el.child === actionField &&
										el.key === parentField
									);
								})
							) {
								setError(
									t('ad.validation.documentRelationTypeMismatch', {
										action: action.name,
										parent: fullParent.name,
									})
								);
							}
						}
					} else if (!!fullActionField && !!fullParentField) {
						// checks for all non Document-to-Document relations

						// check if referencing field types match using strict comparison mode
						if (!Validation.fieldTypesCanBeMapped(fullActionField, fullParentField, true)) {
							setError(
								t('ad.validation.relationFieldTypesDoNotMatch', {
									action: action.name,
									parentField,
									childField: actionField,
								})
							);
						}
						// check if referencing fields max length match using strict comparison mode
						else if (!Validation.fieldMaxLengthsCanBeMapped(fullParentField, fullActionField, true)) {
							setError(
								t('ad.validation.relationFieldMaxLengthsDoNotMatch', {
									action: action.name,
									parentField,
									childField: actionField,
								})
							);
						}
					}
				}
			}

			// check configured required fields
			if (!!fullAction && Array.isArray(action.required) && action.required.length > 0) {
				// loop over configured required fields
				action.required.forEach((field) => {
					// check if required field exists in action
					if (!Validation.fieldExistsInList(field, actionFields)) {
						setError(
							t('ad.validation.requiredFieldDoesNotExist', {
								action: action.name,
								field,
							})
						);
					}
				});
			}
		}
	}

	// validate match rules
	if (Array.isArray(rules) && rules.length > 0) {
		// loop over rules
		for (const rule of rules) {
			// find used action in action document
			const doc = Object.values(actions).find((action) => action.guid === rule.guid);

			// check if rule uses a document that is available in the action document
			if (!doc) {
				setError(
					t('ad.validation.ruleDocumentNotAvailable', {
						document: rule.name,
					})
				);
			} else {
				const fullDoc = await getFullAction(doc);
				const docFields = fullDoc?.fields ?? [];

				// check if input is configured for this rule
				if (!(Array.isArray(rule.input) && rule.input.length > 0)) {
					setError(
						t('ad.validation.ruleInputNotSet', {
							document: rule.name,
						})
					);
				} else {
					// check if all input fields are available in the selected document

					// loop over configured input fields
					rule.input.forEach((field) => {
						// check if input field exists in document
						if (!Validation.fieldExistsInList(field, docFields)) {
							setError(
								t('ad.validation.ruleInputFieldDoesNotExist', {
									document: rule.name,
									field,
								})
							);
						}
					});
				}

				// check if output is configured for this rule
				if (!(Array.isArray(rule.output) && rule.output.length > 0)) {
					setError(
						t('ad.validation.ruleOutputNotSet', {
							document: rule.name,
						})
					);
				} else {
					// check if all output fields are available in the selected document

					// loop over configured output fields
					rule.output.forEach((field) => {
						// check if output field exists in document
						if (!Validation.fieldExistsInList(field, docFields)) {
							setError(
								t('ad.validation.ruleOutputFieldDoesNotExist', {
									document: rule.name,
									field,
								})
							);
						}
					});
				}
			}
		};
	}

	return output;
};