import Validation from '@assets/scripts/components/validationChecks';
import Helpers from '@assets/scripts/helpers';
import Field from '@assets/scripts/components/field';
import i18n from '@assets/i18n';
import { getMapFieldsForFunctionList } from '@assets/scripts/components/function-lists';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { GET_FUNCTION_LIST_BY_ID } from '@assets/scripts/api/config';

// translate function of vue-i18n
const { t } = i18n.global;

/**
 * Validate start block and returns erros array
 * for a given block
 *
 * @param {Object} block
 *  Block to get errors
 *
 * @returns {Array}
 *	array of errors
 */
export const validateStartBlock = async (block) => {
	const output = [];

	const setBlockError = (description) => {
		output.push(Helpers.createErrorObject(description, block.guid));
	};

	const inputNames = block.config.input.map((input) => {
		return input.name.toLowerCase();
	});
	const checkedInputs = [];

	const outputNames = block.output.map((output) => {
		return output.name.toLowerCase();
	});
	const checkedOutputs = [];

	// for loop used becouse of the await inside
	for (const input of block.config.input) {
		// check if a field has type
		if (!Validation.fieldHasType(input)) {
			setBlockError(
				t('fb.flowErrors.fieldTypeMissing', {
					fieldName: Field.getNameAsPath(input.name),
					block: t('fb.blockTypes.start'),
				})
			);
		}

		// check if field uses a static default value
		if (Validation.fieldUsesStaticDefault(input)) {
			// check if static default of a field has value
			if (!Validation.fieldHasStaticDefault(input)) {
				setBlockError(
					t('fb.flowErrors.defaultValueOfStaticMissing', {
						fieldName: Field.getNameAsPath(input.name),
						block: t('fb.blockTypes.start'),
					})
				);
			} else {
				// field uses default value and it is entered
				// so now check needs to be done if the value is correct

				const error = Validation.validateDefaultValue(
					input.type,
					input.default.value
				);

				if (error) {
					// show error if anything came back from validation
					setBlockError(
						t('fb.flowErrors.defaultValueIncorrect', {
							fieldName: Field.getNameAsPath(input.name),
							block: t('fb.blockTypes.start'),
							error,
						})
					);
				}
			}
		}

		// check if name is duplicated or used more than one time
		if (
			!checkedInputs.includes(input.name.toLowerCase()) &&
			!Validation.stringIsUniqueInList(
				input.name.toLowerCase(),
				inputNames
			)
		) {
			setBlockError(
				t('fb.flowErrors.duplicatedName', {
					fieldName: Field.getNameAsPath(input.name),
					block: t('fb.blockTypes.start'),
				})
			);
		}

		// check if input name exist
		if (!Validation.stringNotEmpty(input.name)) {
			setBlockError(
				t('fb.flowErrors.nameIsEmpty', {
					block: t('fb.blockTypes.start'),
				})
			);
		}

		// check if input name has atleast 2 characters
		if (!Validation.stringHasAtleastTwoCharacters(input.name)) {
			setBlockError(
				t('fb.flowErrors.nameIsShort', {
					fieldName: Field.getNameAsPath(input.name),
					block: t('fb.blockTypes.start'),
				})
			);
		}

		// check if input name without whitspacing
		if (!Validation.stringWithoutSpacing(input.name)) {
			setBlockError(
				t('fb.flowErrors.nameContainsSpace', {
					fieldName: Field.getNameAsPath(input.name),
					block: t('fb.blockTypes.start'),
				})
			);
		}

		// check if type exist or known in the app
		if (!Validation.fieldTypeExists(input)) {
			setBlockError(
				t('fb.flowErrors.typeNotExist', {
					fieldName: Field.getNameAsPath(input.name),
					block: t('fb.blockTypes.start'),
					type: input.type,
				})
			);
		}

		// check if field uses a Reference List for validating
		if (input.validation.element.ref.guid) {
			const {
				guid: funcGuid,
				map: mappingFieldName,
				conn_guid: funcConnGuid,
				fields: usedFields,
			} = input.validation.element.ref;

			// get functionList
			const functionList = await useApiAsync(GET_FUNCTION_LIST_BY_ID, {
				keys: {
					guid: funcGuid,
				},
			});

			// check if function list exists
			if (!Validation.isNonEmptyObject(functionList)) {
				setBlockError(
					t('fb.flowErrors.functionListNotExist', {
						fieldName: Field.getNameAsPath(input.name),
						block: t('fb.blockTypes.start'),
					})
				);
			} else {
				// check if connection guid match the connection guid in de function list
				if (
					!Validation.stringsMatch(
						functionList.conn_guid,
						funcConnGuid
					)
				) {
					setBlockError(
						t('fb.flowErrors.connectionGuidNotMatch', {
							fieldName: Field.getNameAsPath(input.name),
							block: t('fb.blockTypes.start'),
						})
					);
				}

				// check if at least 1 field is used from the chosen function list
				if (!usedFields || usedFields.length < 1) {
					setBlockError(
						t('fb.flowErrors.noFieldsChosenFromFunctionList', {
							fieldName: Field.getNameAsPath(input.name),
							block: t('fb.blockTypes.start'),
							fieldFunction: functionList.name,
						})
					);
				} else {
					// loop over chosen return fields of function list
					usedFields.forEach((field) => {
						// check if alias is set
						if (!field.alias) {
							setBlockError(
								t(
									'fb.flowErrors.noAliasSetForFieldChosenFromFunctionList',
									{
										functionField: Field.getNameAsPath(
											field.name
										),
										fieldName: Field.getNameAsPath(
											input.name
										),
										block: t('fb.blockTypes.start'),
										fieldFunction: functionList.name,
									}
								)
							);
						}
					});
				}

				// get used mapping field by name
				const mappingField = getMapFieldsForFunctionList(
					functionList
				).find((field) => field.name === mappingFieldName);

				// check if mapping field was found
				if (!mappingField) {
					setBlockError(
						t('fb.flowErrors.mappingFieldNotFoundInFunctionList', {
							fieldName: Field.getNameAsPath(input.name),
							block: t('fb.blockTypes.start'),
							mappingField: mappingFieldName,
						})
					);
				} else {
					// check if input and mapping field can be mapped
					if (
						!Validation.fieldTypesCanBeMapped(input, mappingField)
					) {
						setBlockError(
							t('fb.flowErrors.typeNotMatchInFunctionList', {
								fieldName: Field.getNameAsPath(input.name),
								block: t('fb.blockTypes.start'),
								fieldFunction: functionList.name,
							})
						);
					} else if (
						// check if input max length match the mapping fields max length
						!Validation.fieldMaxLengthsCanBeMapped(input, mappingField)
					) {
						setBlockError(
							t('fb.flowErrors.maxLengthNotMatchInFunctionList', {
								fieldName: Field.getNameAsPath(input.name),
								block: t('fb.blockTypes.start'),
								objectDefinition: functionList.name,
								objectDefinitionMaxLength:
									mappingField.validation.max ||
									t('general.dash'),
								fieldMaxLength:
									input.validation.max || t('general.dash'),
							})
						);
					}
				}
			}
		}

		// check if field is of type 'Array'
		if (Field.getVarType(input.type) === 'ARRAY') {
			// get direct children of field
			const children = Field.getChildren(input.name, block.config.input);

			// an array should have exactly 1 child
			if (children.length !== 1) {
				setBlockError(
					t('fb.flowErrors.arrayMustHaveExactlyOneChild', {
						fieldName: Field.getNameAsPath(input.name),
						block: t('fb.blockTypes.start'),
					})
				);
			} else if (children.length > 0) {
				let arrayChildError = false;

				// loop over children of array to check it's type
				children.forEach((child) => {
					if (Field.getVarType(child.type) !== 'OBJECT') {
						// make sure an error is displayed if the child
						// is not of type 'Object'
						arrayChildError = true;
					}
				});

				if (arrayChildError) {
					// show validation error if any of the child fields
					// is not an object
					setBlockError(
						t('fb.flowErrors.arrayCanOnlyHaveObjectAsChild', {
							fieldName: Field.getNameAsPath(input.name),
							block: t('fb.blockTypes.start'),
						})
					);
				}
			}
			// check if field is of type 'Object'
		} else if (Field.getVarType(input.type) === 'OBJECT') {
			// get direct children of field
			const children = Field.getChildren(input.name, block.config.input);

			// an object should have at least 1 child
			if (!children || children.length < 1) {
				setBlockError(
					t('fb.flowErrors.objectMustHaveAtLeastOneChild', {
						fieldName: Field.getNameAsPath(input.name),
						block: t('fb.blockTypes.start'),
					})
				);
			}
		}

		// add input to already checked inputs array
		checkedInputs.push(input.name.toLowerCase());
	}

	block.output.forEach((output) => {
		// check if name of output field is duplicated or used more than one time
		if (
			!checkedOutputs.includes(output.name.toLowerCase()) &&
			!Validation.stringIsUniqueInList(
				output.name.toLowerCase(),
				outputNames
			)
		) {
			setBlockError(
				t('fb.flowErrors.duplicatedOutputName', {
					fieldName: Field.getNameAsPath(output.name),
					block: t('fb.blockTypes.start'),
				})
			);

			// add output to already checked outputs array
			checkedOutputs.push(output.name.toLowerCase());
		}
	});

	return output;
};
