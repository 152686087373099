import Validation from '@assets/scripts/components/validationChecks';

import Helpers from '@assets/scripts/helpers';
import i18n from '@assets/i18n';

import { useApiAsync } from '@assets/scripts/composables/useApi';
import { CHECK_FOR_EXISTING_REST_METHOD_FLOW } from '@modules/FlowBuilder/endpoints';

// translate function of vue-i18n
const { t } = i18n.global;

/**
 * Validate Flow and returns erros array
 * for a given flow
 *
 * @param {Object} flow
 *	current flow to validate
 *
 * @returns {Array}
 *	array of errors
 */
export const validateFlow = async (flow) => {
	const output = [];

	const setFlowError = (description) => {
		output.push(Helpers.createErrorObject(description));
	};
	// check for empty flow name
	if (!Validation.stringNotEmpty(flow.name)) {
		setFlowError(t('fb.flowErrors.flowNameEmpty'));
	}
	// check if flow name has atleast 2 characters
	else if (!Validation.stringHasAtleastTwoCharacters(flow.name)) {
		setFlowError(t('fb.flowErrors.flowNameIsShort'));
	} else {
		// check if flow name without white spacing
		if (!Validation.stringWithoutSpacing(flow.name)) {
			setFlowError(t('fb.flowErrors.flowNameContainsSpace'));
		}
		// check if flow name matches the regex
		if (Validation.flowNameDoesNotMatchRegex(flow.name)) {
			setFlowError(t('fb.flowErrors.flowNameDoesNotMatchRegex'));
		}

		// check if flow already exist with the same name + method + api
		// by making an api call to check
		const { exists: restMethodFlowExist } = await useApiAsync(
			CHECK_FOR_EXISTING_REST_METHOD_FLOW,
			{
				parameters: {
					method: flow.method,
					api_guid: flow.api,
					flow_name: flow.name,
					flow_guid: flow.guid,
				},
			}
		);

		if (restMethodFlowExist) {
			setFlowError(t('fb.flowErrors.restMethodFlowExist'));
		}
	}
	return output;
};
