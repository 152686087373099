import Helpers from '@assets/scripts/helpers';
import {
	validationMeta,
	defaultValueMeta,
	functionListRefMeta,
} from '@assets/scripts/api/config';

// naming used in definition of Action REST Flow
const actionBaseMeta = {
	conn_guid: {
		name: 'FlowConnectionGuid',
		type: 'string',
		default: '',
	},
	guid: {
		name: 'ActionGuid',
		default: '',
		restrictToSelectors: ['read', 'write', 'config'],
	},
	name: {
		name: 'ActionName',
		default: '',
		restrictToSelectors: ['read', 'write'],
	},
	type: {
		name: 'ObjectType',
		default: '',
		restrictToSelectors: ['read', 'write', 'config'],
	},
	method: {
		name: 'ActionType',
		default: '',
		restrictToSelectors: ['read', 'write'],
	},
};

const actionRefMeta = {
	...actionBaseMeta,
	obj_guid: {
		name: 'ObjectGuid',
		default: false,
		omitIfEmpty: true,
		restrictToSelectors: ['read', 'write', 'config'],
	},
};

// naming used in definition of Action REST flow list
const actionListMeta = {
	...actionBaseMeta,
};

// naming used in definition of Document Action
const actionDetailBaseFieldMeta = {
	type: {
		name: 'Type',
		type: 'string',
		default: false,
	},
	name: {
		name: 'Name',
		type: 'string',
		default: false,
	},
	validation: validationMeta,
};

actionDetailBaseFieldMeta.elements = {
	name: 'Elements',
	type: 'array',
	default: [],
	recursive: true,
	omitIfEmpty: true,
	children: {
		...Helpers.cloneObject(actionDetailBaseFieldMeta)
	}
}

const actionDetailInputFieldsMeta = {
	...actionDetailBaseFieldMeta,
	key: {
		name: 'IsKey',
		type: 'boolean',
		default: false,
	},
	has_default: {
		name: 'HasDefault',
		type: 'boolean',
		default: false,
	},
	search: {
		name: 'IsSearch',
		type: 'boolean',
		default: false,
	},
	filter: {
		name: 'IsFilter',
		type: 'boolean',
		default: false,
	},
}

const actionDetailMeta = {
	...actionRefMeta,
	input: {
		name: 'Input',
		type: 'array',
		default: [],
		omitIfEmpty: true,
		children: {
			...actionDetailInputFieldsMeta,
		},
	},
	output: {
		name: 'Output',
		type: 'array',
		default: [],
		omitIfEmpty: true,
		children: {
			...actionDetailBaseFieldMeta
		},
	},
};

// naming used in definition of External Connectors
const externalConnectorMeta = {
	guid: {
		name: 'MethodGuid',
		type: 'string',
		default: '',
	},
	name: {
		name: 'MethodName',
		type: 'string',
		default: '',
	},
	conn_guid: {
		name: 'ConnectionGuid',
		type: 'string',
		default: '',
	},
	conn_name: {
		name: 'ConnectionName',
		type: 'string',
		default: '',
	},
	input: {
		name: 'Input',
		type: 'array',
		default: [],
		children: {
			name: {
				name: 'Name',
				type: 'string',
				default: '',
			},
			type: {
				name: 'Type',
				type: 'string',
				default: '',
			},
			validation: validationMeta,
		},
	},
	output: {
		name: 'Output',
		type: 'array',
		default: [],
		children: {
			name: {
				name: 'Name',
				type: 'string',
				default: '',
			},
			type: {
				name: 'Type',
				type: 'string',
				default: '',
			},
			validation: validationMeta,
		},
	},
};

// naming used in definition of Functions
const functionMeta = {
	guid: {
		name: 'FunctionGuid',
		type: 'string',
		default: '',
	},
	conn_guid: {
		name: 'FlowConnectionGuid',
		type: 'string',
		default: '',
	},
	name: {
		name: 'FunctionName',
		type: 'string',
		default: '',
	},
	description: {
		name: 'Description',
		type: 'string',
		default: '',
	},
	input: {
		name: 'Input',
		type: 'array',
		default: [],
		children: {
			name: {
				name: 'Name',
				type: 'string',
				default: '',
			},
			type: {
				name: 'Type',
				type: 'string',
				default: '',
			},
			validation: validationMeta,
		},
	},
	output: {
		name: 'Output',
		type: 'object',
		default: {},
		children: {
			name: {
				name: 'Name',
				type: 'string',
				default: '',
			},
			type: {
				name: 'Type',
				type: 'string',
				default: '',
			},
		},
	},
};

// naming used in definition of Document
const documentMeta = {
	conn_guid: {
		name: 'FlowConnectionGuid',
		type: 'string',
		default: '',
	},
	name: {
		name: 'DocumentName',
		default: '',
	},
	guid: {
		name: 'DocumentGuid',
		default: false,
	},
	fields: {
		name: 'Fields',
		type: 'array',
		default: [],
		children: {
			type: {
				name: 'Type',
				type: 'string',
				default: false,
			},
			name: {
				name: 'Name',
				type: 'string',
				default: false,
			},
			key: {
				name: 'IsKey',
				type: 'boolean',
				default: false,
			},
			validation: validationMeta,
			default: defaultValueMeta,
		},
	},
};

// naming used in definition of REST API List
const restApiMeta = {
	guid: {
		name: 'FlowRestApiGuid',
		default: '',
	},
	name: {
		name: 'Name',
		default: '',
	},
};

///////////////////////////
// FLOW BLOCK DEFINITION //
///////////////////////////

export const filterMeta = {
	operator: {
		name: 'Operator',
		omitIfEmpty: true,
		default: null,
	},
	rule_type: {
		name: 'Type',
		omitIfEmpty: true,
	},
	type: {
		name: 'ValueType',
		omitIfEmpty: true,
	},
	name: {
		name: 'Name',
		omitIfEmpty: true,
	},
	values: {
		name: 'Values',
		type: 'object',
		default: [],
		children: {
			value: {
				name: 'Value',
				default: null,
				omitIfEmpty: true,
			},
			field: {
				name: 'Comparison',
				default: null,
				omitIfEmpty: true,
			},
			offset: {
				name: 'Offset',
				type: 'integer',
				default: null,
				omitIfEmpty: true,
			},
			unit: {
				name: 'OffsetType',
				default: null,
				omitIfEmpty: true,
			},
		}
	},
};

const blockRuleMeta = {
	rules: {
		name: 'BlockRules',
		type: 'array',
		default: [],
		omitIfEmpty: true,
		children: filterMeta,
	},
};

// naming used in Start Block Input fields
export const startBlockInputFieldMeta = {
	name: {
		name: 'Name',
		type: 'string',
		default: '',
	},
	type: {
		name: 'Type',
		type: 'string',
		default: '',
	},
	validation: {
		...validationMeta,
		children: {
			...validationMeta.children,
			...functionListRefMeta,
		},
	},
	default: defaultValueMeta,
};

const startBlockRecursive = Helpers.cloneObject(startBlockInputFieldMeta);

startBlockInputFieldMeta.elements = {
	name: 'Elements',
	type: 'array',
	default: [],
	recursive: true,
	omitIfEmpty: true,
	children: {
		...startBlockRecursive
	}
}

const fieldMappingElementMeta = {
	field: {
		name: 'Name',
		type: 'string',
		omitIfEmpty: true,
	},
	type: {
		name: 'Type',
		type: 'string',
		omitIfEmpty: true,
	},
};

fieldMappingElementMeta.elements = {
	name: 'Elements',
	type: 'object',
	default: {},
	recursive: true,
	omitIfEmpty: true,
	children: {
		...Helpers.cloneObject(fieldMappingElementMeta)
	}
};

const fieldMappingMeta = {
	from: {
		name: 'Source',
		type: 'object',
		default: {},
		omitIfEmpty: true,
		children: {
			...Helpers.cloneObject(fieldMappingElementMeta)
		}
	},
	to: {
		name: 'Target',
		type: 'object',
		default: {},
		children: {
			...Helpers.cloneObject(fieldMappingElementMeta)
		}
	},
};

// naming used in Add Block Append fields
export const addBlockAppendFieldMeta = {
	name: {
		name: 'Name',
		type: 'string',
		default: '',
	},
	type: {
		name: 'Type',
		type: 'string',
		default: '',
	},
	value: {
		name: 'Value',
		type: 'string',
		default: '',
	},
	add_type: {
		name: 'AppendType',
		type: 'string',
		default: '',
	},
	function_guid: {
		name: 'FunctionGuid',
		type: 'string',
		default: false,
		omitIfEmpty: true,
	},
	conn_guid: {
		name: 'ConnectionGuid',
		type: 'string',
		default: false,
		omitIfEmpty: true,
	},
	// NOTE: this is temp out for version 1.0 we will have functions in 1.1
	// function_name: {
	// 	name: 'FunctionName',
	// 	type: 'string',
	// 	default: false,
	// 	omitIfEmpty: true,
	// },
	// mapping: {
	// 	name: 'InputMapping',
	// 	type: 'array',
	// 	default: [],
	// 	omitIfEmpty: true,
	// 	children: fieldMappingMeta,
	// },
};

const blockOutputMeta = {
	name: {
		name: 'Name',
		type: 'string',
		omitIfEmpty: true,
	},
	alias: {
		name: 'AliasName',
		type: 'string',
		omitIfEmpty: true,
	},
	type: {
		name: 'Type',
		type: 'string',
		omitIfEmpty: true,
	},
};

blockOutputMeta.elements = {
	name: 'Elements',
	type: 'array',
	default: [],
	recursive: true,
	omitIfEmpty: true,
	children: {
		...Helpers.cloneObject(blockOutputMeta)
	}
};

// definition of Block Object
export const blockMeta = {
	type: {
		name: 'Type',
		type: 'string',
		default: '',
	},
	guid: {
		name: 'FlowBlockGuid',
		type: 'string',
		default: '',
	},
	config: {
		name: 'Config',
		type: 'object',
		default: {},
		children: {
			// config for Action
			...actionRefMeta,
			sorting: {
				name: 'SortField',
				type: 'array',
				default: [],
				omitIfEmpty: true,
				restrictToSelectors: ['read'],
				children: {
					field: {
						name: 'Name',
						default: '',
						omitIfEmpty: true,
					},
					order: {
						name: 'SortOrder',
						type: 'integer',
						default: false,
						omitIfEmpty: true,
					},
					direction: {
						name: 'Sorting',
						default: '',
						omitIfEmpty: true,
					},
				},
			},
			settings: {
				name: 'ResultSettings',
				type: 'object',
				default: {},
				restrictToSelectors: ['read'],
				children: {
					first_match: {
						name: 'FirstMatchOnly',
						type: 'boolean',
						default: false,
					},
				},
			},
			filters: {
				name: 'FilterField',
				type: 'array',
				default: [],
				restrictToSelectors: ['read'],
				children: blockRuleMeta,
			},
			// Input of Start Block
			input: {
				name: 'Input',
				type: 'array',
				default: [],
				restrictToSelectors: ['start'],
				children: startBlockInputFieldMeta,
			},
			// Input of Add Block
			add: {
				name: 'Append',
				type: 'array',
				default: [],
				restrictToSelectors: ['add'],
				children: addBlockAppendFieldMeta,
			},
			// Rules of Check Block
			rules: {
				name: 'Rules',
				type: 'array',
				default: [],
				restrictToSelectors: ['check'],
				children: blockRuleMeta,
			},
			// Config of Result Block
			is_array: {
				name: 'IsArray',
				type: 'boolean',
				default: false,
				restrictToSelectors: ['result'],
			},
			ignore_nulls: {
				name: 'IgnoreNulls',
				type: 'boolean',
				default: false,
				restrictToSelectors: ['result'],
			},
			full_output: {
				name: 'FullOutput',
				type: 'boolean',
				default: false,
				restrictToSelectors: ['result'],
			},
			output: {
				name: 'Output',
				type: 'array',
				default: [],
				restrictToSelectors: ['result', 'read', 'write'],
				children: {
					...blockOutputMeta
				},
			},
			// Config of External Connector Block
			method_guid: {
				name: 'MethodGuid',
				default: '',
				restrictToSelectors: ['external'],
			},
			method_name: {
				name: 'MethodName',
				default: '',
				restrictToSelectors: ['external'],
			},
			conn_guid: {
				name: 'ConnectionGuid',
				default: '',
				restrictToSelectors: ['external'],
			},
			conn_name: {
				name: 'ConnectionName',
				default: '',
				restrictToSelectors: ['external'],
			},
			// Additional config of External, Read and Write Blocks
			mapping: {
				name: 'InputMapping',
				type: 'array',
				default: [],
				omitIfEmpty: true,
				restrictToSelectors: ['external', 'read', 'write'],
				children: fieldMappingMeta,
			},
			// Config of Error Block
			error_code: {
				name: 'ErrorCode',
				type: 'string',
				default: '',
				restrictToSelectors: ['error'],
			},
			error_message: {
				name: 'ErrorMessage',
				type: 'string',
				default: '',
				restrictToSelectors: ['error'],
			},
			// Config of Config block
			properties: {
				name: 'Properties',
				type: 'object',
				default: {},
				restrictToSelectors: ['config'],
			},
			input_mapping: {
				name: 'InputMapping',
				type: 'object',
				default: {},
				restrictToSelectors: ['config'],
			},
			object_guid : {
				name: 'ObjectGuid',
				type: 'string',
				default: '',
				restrictToSelectors: ['config'],
			},
			config: {
				name: 'Config',
				type: 'object',
				default: {},
				restrictToSelectors: ['config'],
			},
			function_guid: {
				name: 'FunctionGuid',
				type: 'string',
				default: '',
				restrictToSelectors: ['config'],
			},
			function_name: {
				name: 'FunctionName',
				type: 'string',
				default: '',
				restrictToSelectors: ['config'],
			},
			class_name: {
				name: 'ClassName',
				type: 'string',
				default: '',
				restrictToSelectors: ['config'],
			},
		},
	},
	pos: {
		name: 'Position',
		type: 'object',
		default: {},
		omitOnConstruct: true,
		children: {
			x: {
				name: 'x',
				type: 'number',
				default: 0,
			},
			y: {
				name: 'y',
				type: 'number',
				default: 0,
			},
		},
	},
	cols: {
		name: 'ChildColumns',
		type: 'object',
		default: {},
		omitOnConstruct: true,
		children: {
			col_true: {
				name: 'right',
				type: 'integer',
				default: 0,
			},
			col_false: {
				name: 'left',
				type: 'integer',
				default: 0,
			},
		},
	},
	in: {
		name: 'In',
		type: 'array',
		default: [],
	},
	out: {
		name: 'Out',
		type: 'array',
		default: [],
	},
	false: {
		name: 'OutFalse',
		type: 'array',
		default: [],
	},
	output: {
		name: 'Output',
		type: 'array',
		default: [],
		omitOnConstruct: true,
	},
};

// date fields used in Flow meta
const restFlowMetaSub = {
	published: {
		name: 'FirstPublishDateTime',
		default: false,
		omitIfEmpty: true,
	},
	last_published: {
		name: 'LastPublishDateTime',
		default: false,
		omitIfEmpty: true,
	},
	modified: {
		name: 'LastModifiedDateTime',
		default: false,
		omitIfEmpty: true,
	},
	archived: {
		name: 'ArchivedDateTime',
		default: false,
		omitIfEmpty: true,
	},
	// says something about the flow version
	is_published: {
		name: 'IsPublished',
		type: 'boolean',
		default: false,
	},
	// says something about the flow as a whole
	flow_is_published: {
		name: 'IsFlowMethodPublished',
		type: 'boolean',
		default: false,
		omitOnConstruct: true,
	},
	version: {
		name: 'VersionGuid',
		default: '',
	},
};

// definition of meta data for REST flow
export const restFlowMeta = {
	guid: {
		name: 'RestMethodGuid',
		default: '',
	},
	name: {
		name: 'RestMethodName',
		default: '',
	},
	description: {
		name: 'RestMethodDescription',
		default: '',
	},
	created: {
		name: 'CreatedDateTime',
		default: false,
		omitIfEmpty: true,
	},
	method: {
		name: 'HttpMethod',
		default: '',
	},
	api: {
		name: 'FlowRestApiGuid',
		default: '',
	},
	api_name: {
		name: 'RestApiName',
		default: '',
		omitOnConstruct: true,
	},
	is_script: {
		name: 'IsScripting',
		type: 'boolean',
		default: false,
	},
	is_new: {
		name: 'IsNew',
		type: 'boolean',
		default: false,
		restrictToSelectors: [],
	},
	is_active: {
		name: 'IsActive',
		type: 'boolean',
		default: false,
	},
	...restFlowMetaSub,
	versions: {
		name: 'Versions',
		type: 'array',
		default: [],
		children: {
			...restFlowMetaSub,
			published: {
				name: 'PublishedDateTime',
				default: false,
				omitIfEmpty: true,
			},
		},
	},
	flow: {
		name: 'Flow',
		type: 'object',
		default: {},
		children: {
			blocks: {
				name: 'Items',
				type: 'array',
				default: [],
				restrictToSelectors: ['block'],
				children: {
					...blockMeta,
				},
			},
			start_block: {
				name: 'StartId',
				default: '',
			},
			result_block: {
				name: 'ResultId',
				default: '',
			},
			settings: {
				name: 'Settings',
				type: 'array',
				default: [],
				children: {
					key: {
						name: 'key',
						omitIfEmpty: true,
					},
					value: {
						name: 'value',
						omitIfEmpty: true,
					},
				},
			},
		},
	},
};

const flowExistsMeta = {
	exists: {
		name: 'RestMethodFlowExists',
		type: 'boolean',
		default: true,
	}
};

// File exports all available Endpoints
const apiBase = 'UIServices/FlowBuilder';

// Flows
export const GET_RESTFLOWS = {
	path: 'GetRestMethodFlows',
	realm: apiBase,
	meta: restFlowMeta,
};

export const GET_RESTFLOW_BY_ID = {
	path: 'GetRestMethodFlowById/{guid}',
	realm: apiBase,
	meta: restFlowMeta,
};

export const GET_RESTFLOW_VERSION = {
	path: 'GetRestMethodFlowById/{guid}/{version}',
	realm: apiBase,
	meta: restFlowMeta,
};

export const DELETE_RESTFLOW = {
	path: 'DeleteRestMethodFlow/{guid}/{version}',
	method: 'delete',
	realm: apiBase,
};

export const POST_RESTFLOW = {
	path: 'PostRestMethodFlow',
	method: 'post',
	realm: apiBase,
};

export const SET_RESTFLOW_STATUS = {
	path: 'SetRestMethodFlowStatus/{guid}',
	method: 'post',
	realm: apiBase,
	parameters: {
		active: 'IsActive',
	},
};

export const CHECK_FOR_EXISTING_REST_METHOD_FLOW = {
	path: 'CheckForExistingRestMethodFlow',
	realm: apiBase,
	parameters: {
		method: 'Method',
		api_guid: 'ApiGuid',
		flow_name: 'FlowName',
		flow_guid: 'FlowGuid',
	},
	meta: flowExistsMeta,
};

// Actions
export const GET_ACTIONS = {
	path: 'GetActionsRestFlow/{connection}',
	realm: apiBase,
	parameters: {
		read: 'IsReadAction'
	},
	meta: actionListMeta,
};

export const GET_ACTION_BY_ID = {
	path: 'GetActionsRestFlowById/{guid}',
	realm: apiBase,
	meta: actionDetailMeta,
};

// ExternalConnectors
export const GET_EXTERNAL_CONNECTORS = {
	path: 'GetExternalConnectors',
	realm: apiBase,
	meta: externalConnectorMeta,
};

export const GET_EXTERNAL_CONNECTOR_BY_ID = {
	path: 'GetExternalConnectorById/{guid}',
	realm: apiBase,
	meta: externalConnectorMeta,
};

// Documents
export const GET_DOCUMENTS = {
	path: 'GetDocuments/{connection}',
	realm: apiBase,
	meta: documentMeta,
};

// Functions
export const GET_FUNCTIONS = {
	path: 'GetFunctions',
	realm: apiBase,
	meta: functionMeta,
};

// REST API's
export const GET_REST_APIS = {
	path: 'GetApiList',
	realm: apiBase,
	meta: restApiMeta,
	cacheKey: 'FlowBuilder|GET_REST_APIS',
};