<template>
	<div class="content-header has-padding has-bottom-divider has-margin-top-2">
		<VTitle
			v-if="title"
			:text="title"
			:size="3"
			class="has-margin-bottom-0 has-margin-right-3"
		/>

		<div class="field is-grouped is-flex-grow-1">
			<VSearch v-model="search" />
		</div>
	</div>

	<SortableDataTable
		:hoverable="false"
		default-sort="field_name"
		default-sort-direction="ASC"
		:data="filteredData"
		:columns="columns"
		:emptyText="emptyText"
		:rowClass="getRowClass"
		:isRowCheckable="setRowCheckable"
		:checkable="editMode"
		@check="handleCheckedRow"
		:headerCheckable="editMode"
		v-model:checkedRows="checkedRows"
	>
		<template
			v-for="(row) in filteredData"
			:key="row.key"
			#[`alias-${row.key}`]
		>
			<template v-if="editMode">
				<VField
					icon="edit"
					:iconOnRight="true"
					:isError="row.duplicate || false"
				>
					<VInput
						v-model="aliasPairs[row.key].alias"
						:disabled="row.disabled"
						:iconOnRight="true"
						@blur="removeWhitespace"
						@keyup="removeWhitespace"
					/>
				</VField>

				<VNotification
					v-if="errors[row.full_name]"
					type="danger"
					:text="errors[row.full_name]"
				/>
			</template>
			<template v-else>
				{{ aliasPairs[row.key].alias || $t('general.dash') }}
			</template>
		</template>

		<template
			v-for="(row) in filteredData"
			:key="row.key"
			#[`field_name-${row.key}`]
		>
			<div @click.stop="rowClicked(row)">
				{{ row.field_name }}
			</div>
		</template>
	</SortableDataTable>
</template>

<script>
export default {
	name: 'FieldPickerTable',
};
</script>

<script setup>
/**
 * Table to set aliases and choose which fields to use
 */
import Helpers from '@assets/scripts/helpers';
import Field from '@assets/scripts/components/field';
import { ref, unref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';

// const store = useStore();
const { t } = useI18n();

const props = defineProps({
	editMode: {
		type: Boolean,
		default: false,
	},
	fields: {
		type: Array,
		required: true,
	},
	modelValue: {
		type: Array,
	},
	title: {
		type: String
	}
});

const { editMode } = props;

const emit = defineEmits(['update:modelValue']);

const checkedRows = ref([]);
const search = ref('');
const aliasPairs = ref([]);
const tableFields = ref([]);
const errors = ref({});

const removeWhitespace = (e) => {
	e.target.value = Helpers.removeWhitespace(e.target.value);
};

const sortByFieldName = (a, b, isAsc) => {
	return Field.orderParentChildList(a.full_name, b.full_name, isAsc);
};

// create columns for table
const columns = [
	{
		label: t('fieldPickerTable.table.columns.field_name'),
		field: 'field_name',
		sortable: true,
		customSort: sortByFieldName,
		searchable: true,
		default: t('general.dash'),
		leveled: true,
	},
	{
		label: t('field.alias'),
		field: 'alias',
		sortable: false,
		searchable: !editMode,
		default: t('general.dash'),
	},
	{
		label: t('fieldPickerTable.table.columns.field_type'),
		field: 'field_type',
		sortable: false,
		searchable: true,
		default: t('general.dash'),
	},
];

const rowClicked = (row) => {
	if (row.disabled || !editMode) return false;

	// find index of row in currently checked rows
	const i = getRowIndex(row, unref(checkedRows));

	// N.B.: make SHALLOW copy here
	// two-way binding for checked rows only works that
	// way with Buefy Table
	const newCheckedRows = Helpers.cloneObject(unref(checkedRows), false);

	// check row
	if (i === -1) selectRow(row, newCheckedRows);
	// uncheck row
	else unselectRow(row, newCheckedRows);
};

/**
 * Called for a row that is now checked while it
 * was not checked before
 *
 * @param {Object} row
 *  Newly checked row
 *
 * @param {Array} rows
 *  Array of currently checked rows
 */
const selectRow = (row, rows) => {
	const check = (row) => {
		if (row.disabled) return;
		const i = getRowIndex(row, rows);
		if (i < 0) rows.push(row);
	};

	// check self
	check(row);

	// check all ancestors
	getRowAncestors(row).forEach((field) => {
		check(field);
	});

	// check all children
	getRowChildren(row).forEach((field) => {
		check(field);
	});
	
	// timeout needed to work correctly with BTable
	setTimeout(() => {
		// set checkedRows prop
		checkedRows.value = rows;
	}, 1);
};

/**
 * Called for a row that is now unchecked while it
 * was checked before
 *
 * @param {Object} row
 *  Newly unchecked row
 *
 * @param {Array} rows
 *  Array of currently checked rows
 */
const unselectRow = (row, rows) => {
	const uncheck = (i) => {
		if (i > -1) rows.splice(i, 1);
	};

	// uncheck self
	uncheck(getRowIndex(row, rows));

	// uncheck children
	getRowChildren(row).forEach((field) => {
		uncheck(getRowIndex(field, rows));
	});

	// loop over checked ancestors, ordered DESC by full_name
	getRowAncestors(row, rows).forEach((field) => {
		// check if ancestor has checked children
		// NOTE: row unchecked by user is already deleted
		// from 'rows' at this point
		if (!getRowChildren(field, rows).length > 0) {
			// uncheck ancestor if it has no (other) checked children
			uncheck(getRowIndex(field, rows));
		}
	});

	// timeout needed to work correctly with BTable
	setTimeout(() => {
		// set checkedRows prop
		checkedRows.value = rows;
	}, 1);
};

const getRowClass = (row) => {
	return row.disabled ? 'is-disabled' : false;
};

const setRowCheckable = (row) => {
	return !row.disabled;
};

const getRowIndex = (row, rows = []) => {
	return rows.findIndex(({ key }) => row.key === key);
};

const getRowAncestors = (field, fields = tableFields) => {
	const fieldName = field.full_name.toLowerCase();

	return unref(fields).filter((row) => {
		const rowName = row.full_name.toLowerCase();
		return fieldName.startsWith(Field.getNameWithSplitter(rowName));
	}).sort((a, b) => {
		// sort DESC by full_name
		if (a.full_name > b.full_name) return -1;
		else if (b.full_name > a.full_name) return 1;
		else return 0;
	});
};

const getRowChildren = (field, fields = tableFields) => {
	return unref(fields).filter((row) => {
		const rowName = row.full_name.toLowerCase();
		return rowName.startsWith(
			Field.getNameWithSplitter(field.full_name).toLowerCase()
		);
	});
};

/**
 * Called by BTable whenever a row checkbox gets (un)checked
 *
 * @param {Array} rows
 *  Array of rows that are checked
 *
 * @param {Object} row
 *  Newly (un)checked row by user
 */
const handleCheckedRow = (rows, row) => {
	if (row) rowClicked(row);
};

const emptyText = computed(() => {
	if (!props.fields || props.fields.length < 1) return t('fieldPickerTable.table.outputNotConfigurable');

	return t('fieldPickerTable.table.noResults');
});

const activeAliasPairs = computed(() => {
	const activeFieldNames = [];

	checkedRows.value.forEach((row) => {
		if (!row.disabled) activeFieldNames.push(row.full_name);
	});

	return aliasPairs.value.filter((alias) =>
		activeFieldNames.includes(alias.name)
	);
});

const searchableCols = computed(() => {
	return Helpers.getSearchableColumns(columns);
});

const filteredData = computed(() => {
	// loop over all documents
	let newData = unref(tableFields);

	// return if no rows match
	if (unref(search).length === 0 || newData.length === 0) return newData;

	// filter on search string
	newData = Helpers.filterByString(
		newData,
		unref(search),
		unref(searchableCols)
	);

	// also add ancestors of matches to matches
	if (newData.length < unref(tableFields).length) {
		Field.addAncestorsOfMatches(
			newData,
			unref(tableFields),
			'full_name'
		);
	}

	return newData;
});

watch(() => props.fields, (newFields) => {
	// unset alias pairs and table field if fields prop changes
	aliasPairs.value = [];
	tableFields.value = [];
	const activeRows = [];

	newFields.forEach((field, key) => {
		// get already configured alias for the field, if set
		const existingAlias = props.modelValue.find((f) => f.name === field.name);

		// create object with name/alias pair for all fields
		// of the list
		aliasPairs.value.push({
			name: field.name,
			alias: Helpers.removeWhitespace(existingAlias?.alias ?? Field.getChildName(field.name)),
		});

		// create table row for field
		const row = {
			key, // key, useful for handling clicks
			// name used for sorting
			full_name: field.name,
			// name of the field
			field_name: Field.getChildName(field.name),
			// type of the field
			field_type: field.type,
			level: Field.getFieldLevel(field.name),
			// in VIEW mode fields that are not selected are disabled
			disabled: editMode
				? false
				: !props.modelValue.find(
						(f) =>
							f.name.toLowerCase() ===
							field.name.toLowerCase()
					),
		};

		if (row.disabled) return;
		
		tableFields.value.push(row);

		// check if field in row exists in given
		// fields array of aliases
		if (props.modelValue.find((f) => f.name === row.full_name)) {
			activeRows.push(row);
		}
	});

	// set value of activeRows to checkedRows, the
	// model value used by the table
	checkedRows.value = activeRows;
}, {
	immediate: true,
});

watch(
	activeAliasPairs,
	(newAliases) => {
		emit('update:modelValue', newAliases);

		// reset errors object first
		errors.value = {};

		// loop over new set aliases
		newAliases.forEach((field) => {
			if (!field.alias) {
				// set error if alias is empty
				errors.value[field.name] = t(
					'fieldPickerTable.aliasCanNotBeEmpty'
				);
			} else if (
				// check if alias occurs more than once (which is the current alias) between siblings
				newAliases.filter(
					(f) => Field.fieldsAreSiblings(f.name, field.name) && f.alias.toLowerCase() === field.alias.toLowerCase()
				).length > 1
			) {
				// show error about duplicate aliases
				errors.value[field.name] = t(
					'fieldPickerTable.aliasMustBeUnique'
				);
			}
		});
	},
	{
		deep: true,
	}
);
</script>
