import i18n from '@assets/i18n';
import Helpers from '@assets/scripts/helpers';
import { isEmpty } from 'lodash';
import {
	methodMeta,
	GET_METHODS
} from '@modules/MethodBuilder/endpoints';
import { debug } from '@assets/scripts/components/notifications';
import Field from '@assets/scripts/components/field';
import Validation from '@assets/scripts/components/validationChecks';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import usePermission from '@assets/scripts/composables/usePermission';

import { GET_FUNCTION_LISTS_BY_CONNECTION } from '@assets/scripts/api/config'

// translate function of vue-i18n
const { t } = i18n.global;

// possible statuses for methods
const statuses = {
	PUBLISHED: 'published',
	DRAFT: 'draft',
};

/**
 * Returns a newly created method
 *
 * @param {String} name
 *  Name of the Method
 *
 * @param {String} description
 *  Description of the Method
 *
 * @param {String} conn_guid
 *  GUID of Connection that Method will be part of
 *
 * @returns {Object}
 *  New Method
 */
export const createNewMethod = ({ name, description, conn_guid }) => {
	// create and return new flow
	return Helpers.obj.create(methodMeta, {
		name,
		description,
		guid: Helpers.newGuid(),
		conn_guid,
	});
};

/**
 * Gets the status of a given method
 *
 * @param {Object} method
 *  Method to get status of (normalized)
 *
 * @returns {String}
 *  Status of given method
 */
const getMethodStatus = (method) => {
	// determine status
	let status = statuses.DRAFT;

	// check if method is active
	if (method.is_published === true) {
		status = statuses.PUBLISHED;
	}

	return status;
};

export const trimMethodName = (methodName) => {
	// only allow lower and uppercase letters and numbers and underscroe and space
	const regex2 = new RegExp('[^a-z0-9_ ]', 'gi');
	return methodName.replace(regex2, '');
};

/**
 * Gets the status of a given method as human readable text,
 * i.e.: Draft, Published etc.
 * Meant for display purposes, not for checking conditions
 *
 * @param {Object} method
 *  Method to get status of (normalized)
 *
 * @returns {String}
 *  Translated human readable status
 */
const getMethodStatusName = (method) => {
	let output;

	// determine status
	switch (getMethodStatus(method)) {
		case statuses.DRAFT:
			output = t('general.draft');
			break;
		case statuses.PUBLISHED:
			output = t('general.published');
			break;
	}

	return output;
};

/**
 * Prepares a given method for posting to the with the Nominow API
 *
 * @param {Object} method
 *  Normalized version of method to construct
 *
 * @returns {Object}
 *  Cloned and updated method object
 */
export const prepareMethodForPost = (method) => {
	// return empty object if no method is given
	if (!method || isEmpty(method)) return {};

	if (method.full_output) {
		// make clone of method, because we are going to
		// clear out output property
		method = Helpers.cloneObject(method);

		// clear out output if Full Output options is enabled
		method.output = [];
	}

	// return updated method
	return method;
};

/********************/
/* TABLE FORMATTING */
/********************/

/**
 * Get info about methods to use in Table in MethodList component
 *
 * @param {Array} methods
 *  Array of method objects
 *
 * @returns {Array}
 *  Array of objects per table row
 */
export const formatForTable = (methods) => {
	const result = [];

	// loop over methods
	methods.forEach((method, key) => {
		// get last modified time
		const lastModified = method.modified;

		result.push({
			key, // key, useful for handling clicks
			guid: method.guid,
			// name used for sorting
			name: method.name,
			is_read_method: method.is_read ? t('general.yes') : t('general.no'),
			// time as ISO string, used for sorting
			time: lastModified,
			// localized time for display
			last_time_edited: lastModified
				? Helpers.date.localeStringWithMinutes(lastModified)
				: t('general.dash'),
			status: getMethodStatusName(method),
			edit: usePermission('Upsert', 'MethodBuilder') ? 'edit' : 'view',
			delete: usePermission('Delete', 'MethodBuilder'),
		});
	});

	return result;
};

/**
 * Get info about fields formatted for
 * use in MethodDetails component table
 *
 * @param {Array} fields
 *  Fields to format for output in table
 *
 * @param {String} type
 *  Either 'input' or 'output'
 *
 * @param {Object} method
 *	Method to which the fields belong
 *
 * @returns {Array}
 *  Array of objects per table row
 */
export const formatFieldsForTable = (fields, type = 'input', method) => {
	const result = [];

	// check if field can be edited
	const isEditable = usePermission('Upsert', 'MethodBuilder') && type === 'output';

	// loop over fields
	fields.forEach((field, key) => {
		result.push({
			key, // key, useful for handling clicks
			// name used for sorting
			sort_name: field.name,
			// name of the field
			field_name: Field.getChildName(field.name),
			// type of the field
			field_type: field.type,
			// default value of the field, if any
			default_value: Field.getDefaultValue(field),
			// output types of validation
			validation: Field.getValidation(field),
			// indicator whether field is required
			required_field: Helpers.trueish(field.validation.required)
				? t('general.yes')
				: t('general.no'),
			edit: isEditable ? 'edit' : 'view',
			delete: isEditable,
			type,
		});
	});

	return result;
};

/********************/
/*    VALIDATION    */
/********************/

const createErrorObject = (description) => {
	return Helpers.createErrorObject(description);
};

/**
 * Validates given method field and returns errors array
 *
 * @param {Object} field
 *  Field to validate
 * 
 * @param {Array} fields
 *  List of fields of the method
 *
 * @param {Object} method
 *  Method to which the field belongs
 *
 * @returns {Array}
 *	array of errors
 */
const validateField = async (field, fields, method) => {
	const errors = [];

	const setError = (description) => {
		errors.push(createErrorObject(description));
	};

	// check if field name set
	if (!Validation.stringNotEmpty(field.name)) {
		setError(t('method.validation.field.fieldNameEmpty'));
	}
	// check if field name has atleast 2 characters
	else if (!Validation.stringHasAtleastTwoCharacters(field.name)) {
		setError(
			t('method.validation.field.fieldNameIsShort', {
				name: field.name,
			})
		);
	}
	// check if field name is unique
	else if (!Validation.propIsUnique(fields, 'name', field.name)) {
		setError(
			t('method.validation.field.multipleFieldsWithSameName', {
				name: field.name,
			})
		);
	} else {
		// check if field name without white spacing
		if (!Validation.stringWithoutSpacing(field.name)) {
			setError(
				t('method.validation.field.fieldNameContainsSpace', {
					name: field.name,
				})
			);
		}

		// check if field has type
		if (!Validation.fieldHasType(field)) {
			setError(
				t('method.validation.field.fieldTypeMissing', {
					name: field.name,
				})
			);
		}
		// check if type exist or known in the app
		else if (!Validation.fieldTypeExists(field)) {
			setError(
				t('method.validation.field.fieldTypeUnknown', {
					name: field.name,
					type: field.type,
				})
			);
		}
	}

	return errors;
};

/**
 * Validates given method and returns errors array
 *
 * @param {Object} method
 *  Method to validate
 *
 * @returns {Array}
 *	array of errors
 */
export const validateMethod = async (method) => {
	debug('Validating', { method });

	let output = [];

	const setError = (description) => {
		output.push(createErrorObject(description));
	};

	// check for empty method name
	if (!Validation.stringNotEmpty(method.name)) {
		setError(
			t('method.validation.nameEmpty')
		);
	} 
	// check if method name has atleast 2 characters
	else if(!Validation.stringHasAtleastTwoCharacters(method.name)){
		setError(
			t('method.validation.nameIsShort')
		);	
	}
	else {
		// get all methods for connection
		const connMethods = await useApiAsync(GET_METHODS, {
			keys: {
				connection: method.conn_guid
			}
		});

		if (connMethods) {
			const list = [];

			// create list of method names, excluding method that is
			// being validated
			connMethods.forEach((mthd) => {
				if (mthd.guid !== method.guid) list.push(mthd.name.toLowerCase());
			});

			// check if method name already exists
			if (Validation.stringExistsInList(method.name.toLowerCase(), list)) {
				setError(
					t('method.validation.nameNotUnique')
				);
			}
		}
	}

	// get method input, output and FullOutput indicator
	const {
		input: inputFields,
		output: outputFields,
		full_output
	} = method;

	// check if at least 1 input field is set for non read methods
	if (!Validation.isNonEmptyArray(inputFields) && !method.is_read) {
		setError(
			t('method.validation.noInputSet')
		);	
	} else {
		// loop over input fields, using for loop because of
		// the await inside
		for (const field of inputFields) {
			output = output.concat(await validateField(field, inputFields, method));
		}
	}

	// check if at least 1 output field is set
	if (!Validation.isNonEmptyArray(outputFields)) {

		// check if FullOutput option is enabled, in that case
		// no output fields need to be configured
		if (full_output !== true) {
			setError(
				t('method.validation.noOutputSet')
			);
		}
	} else {
		// loop over output fields, using for loop because of
		// the await inside
		for (const field of outputFields) {
			output = output.concat(await validateField(field, outputFields, method));

			// check if output field of type string has maxlength set
			if(field.type.toLowerCase() === 'string' && !field.validation.max) {
				setError(
					t('method.validation.field.noMaxlengthOutputField', {
						name: field.name,
					})
				);
			}
		}
	}

	// check if stored procedure is set
	// N.B.: no way to check if whatever is entered is correct
	if (!Validation.stringNotEmpty(method.script)) {
		setError(
			t('method.validation.scriptEmpty')
		);	
	}

	return output;
};
