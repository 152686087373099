<template>
	<div
		class="is-flex is-flex-grow-0 is-align-items-flex-start has-margin-bottom-075"
	>
		<ParentSelector
			v-if="canHaveParent"
			:parents="parents"
			v-model:valueModel="parent"
			:disabled="hasChildren || !editMode"
			@update:valueModel="changed"
			class="has-margin-0 has-margin-right-2"
		/>

		<VField
			:isFloating="true"
			:label="$t('fieldConfig.addNewLabel')"
			:class="{ 'is-active': name }"
			:isError="showNameError"
			class="has-margin-0 has-margin-right-2"
		>
			<VInput
				:placeholder="$t('fieldConfig.addNewLabel')"
				:disabled="hasChildren || !editMode"
				type="text"
				@input="nameChanged"
				v-model:modelValue="name"
				@keydown.enter="addFieldWithEnterKeydown"
			/>
		</VField>

		<VField
			:label="$t('fieldConfig.fieldType')"
			:isFloating="true"
			:class="{ 'is-active': type }"
			:isError="showTypeError"
			v-if="showTypeInput"
		>
			<VSelect
				:options="varTypeOptions"
				@change="typeChanged"
				v-model="type"
				:disabled="!editMode"
			/>
		</VField>

		<VButton
			v-if="showSaveButton"
			:text="saveButtonText"
			variant="is-secondary"
			:disabled="!nameValid"
			@clicked.prevent.stop="(ev) => $emit('submit')"
		/>
	</div>
	<div v-if="showError">
		<VNotification
			:text="nameError"
			:isFullwidth="false"
			:hasClose="false"
			type="danger"
			v-if="showNameError"
		/>

		<VNotification
			:text="$t('error.fieldTypeError')"
			:isFullwidth="false"
			:hasClose="false"
			type="danger"
			v-if="showTypeError"
		/>
	</div>
</template>

<script>
import Field from '@assets/scripts/components/field';
import i18n from '@assets/i18n';

// translate function of vue-i18n
const { t } = i18n.global;

export default {
	name: 'FieldConfigMain',
	props: {
		/**
		 * Array of fields
		 */
		fields: {
			type: Array,
			default: () => [],
		},
		parents: {
			type: [Array, Boolean],
			default: () => [],
		},
		/**
		 * Array of fields, used to prevent from
		 * using an existing name for new field
		 */
		existingFields: {
			type: Array,
			default: () => [],
		},
		fieldKey: {
			type: [Boolean, Number],
			default: false,
		},
		showTypeInput: {
			type: Boolean,
			default: true,
		},
		valueModel: {
			type: Object,
			default: () => {
				return {
					name: '',
					type: '',
				};
			},
		},
		nameValid: {
			type: Boolean,
			default: true,
		},
		showSaveButton: {
			type: Boolean,
			default: false,
		},
		saveButtonText: {
			type: String,
			default: t('fieldConfig.addNewButton'),
		},
		editMode: {
			type: Boolean,
			default: false,
		},
		context: {
			type: String,
			required: false,
			default: '',
		},
	},
	data: function () {
		return {
			nameError: '',
			showNameError: false,
			showTypeError: false,
			name: '',
			parent: '',
			type: '',
			nameModified: false,
		};
	},
	computed: {
		canHaveParent: function () {
			return this.parents !== false;
		},
		hasChildren: function () {
			// check if field has children
			return (
				Field.hasChildren(this.fullName, this.fields) &&
				!this.nameModified
			);
		},
		varTypeOptions: function () {
			if (!this.hasChildren) return Field.getVarTypeOptionsByContext(this.context);

			// limit var type options to types that can have children
			return Field.getVarTypeOptionsByContext(this.context).filter(({ value }) => {
				return Field.varCanHaveChildren(value);
			});
		},
		fullName: function () {
			return this.parent
				? Field.getJoinedName([this.parent, this.name])
				: this.name;
		},
		showError: function () {
			return this.editMode && (this.showNameError || this.showTypeError);
		},
	},
	mounted: function () {
		this.getNameFromModel();
		this.getParentFromModel();
		this.getTypeFromModel();
	},
	methods: {
		addFieldWithEnterKeydown: function () {
			if(this.nameValid && this.showSaveButton){
				this.$emit('submit')
			}
		},
		changed: function () {
			this.$emit('update:valueModel', {
				...this.valueModel,
				name: this.fullName,
				type: this.type,
			});

			// validate entered name
			const result = Field.validateName(
				this.fullName,
				this.fields.concat(this.existingFields),
				this.fieldKey
			);

			// show/hide error about entered name
			if (result === true) {
				this.showNameError = false;
				this.$emit('update:nameValid', true);
			} else {
				this.nameError = result;
				this.showNameError = true;
				this.$emit('update:nameValid', false);
			}
		},
		nameChanged: function () {
			// trim entered name
			this.name = Field.trimChildName(this.name);
			this.nameModified = true;
			this.changed();
		},
		typeChanged: function () {
			// show/hide error about type
			this.showTypeError = this.type.length < 1;
			this.changed();
		},
		getNameFromModel: function () {
			this.name = Field.getChildName(this.valueModel.name) || '';
		},
		getParentFromModel: function () {
			this.parent = Field.getFullParentName(this.valueModel.name) || 0;
		},
		getTypeFromModel: function () {
			this.type = this.valueModel.type || '';
		},
	},
	watch: {
		valueModel: function () {
			this.getNameFromModel();
			this.getParentFromModel();
			this.getTypeFromModel();
		},
	},
	emits: ['update:valueModel', 'update:nameValid', 'submit'],
};
</script>
