<template>
	<VInputInteger
		v-model:valueModel="value"
		v-model:validModel="isValid"
		:isRequired="true"
		:label="$t('defaultValue.integer.label')"
		:isDisabled="isDisabled"
		:classes="classes"
		:min="min"
		:max="max"
	/>
</template>

<script>
import Field from '@assets/scripts/components/field';

export default {
	name: 'LongnumberDefaultValue',
	props: {
		valueModel: {
			type: null,
		},
		validModel: {
			type: Boolean,
			default: false,
		},
		isDisabled: {
			type: Boolean,
			default: false,
		},
		classes: {
			type: String,
		},
	},
	data: function () {
		const { min, max } = Field.varTypes['LONGNUMBER'].validation;

		return {
			value: this.valueModel,
			isValid: this.validModel,
			min,
			max,
		};
	},
	watch: {
		value() {
			this.$emit('update:valueModel', this.value);
		},
		isValid() {
			this.$emit('update:validModel', this.isValid);
		},
	},
};
</script>
