import { IS_DEV } from '@assets/scripts/helpers';
import usePermission from '@assets/scripts/composables/usePermission';

export const names = {
	MODIFIED: 'modified',
	SHOW_CREATE_NEW: 'showCreateNew',
	CONNECTION_ACTIVE: 'connectionActive',
	CAN_BE_EDITED: 'canBeEdited',
	CAN_BE_PUBLISHED: 'canBePublished',
	CURRENT_CONNECTION: 'currentConnection',
};

export default {
	[names.MODIFIED]: ({ modified }) => {
		return modified;
	},
	[names.CURRENT_CONNECTION]: ({ currentConnection }) => {
		return currentConnection;
	},
	[names.CAN_BE_EDITED]: ({ canBeModified }) => {
		// can be edited if it has never been published yet
		return canBeModified;
	},
	[names.CAN_BE_PUBLISHED]: ({ currentConnection, modified }) => {
		return (
			(currentConnection.is_new || modified) &&
			usePermission('Upsert', 'ConnectionBuilder')
		);
	},
	[names.SHOW_CREATE_NEW]: ({ showCreateNew }) => {
		return showCreateNew;
	},
	[names.CONNECTION_ACTIVE]: ({ currentConnection }) => {
		return Object.keys(currentConnection).length > 0;
	},
	debug: (state) => {
		// output whole state for debugging since Vue Devtools plugin
		// not (always) shows the updated state of the store, but it
		// does show the correct value of all getters
		return IS_DEV ? state : {};
	},
};
