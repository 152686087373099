/**
 * Translations for English
 * See https://vue-i18n.intlify.dev/guide/ on how to use
 */
export default {
	pageTitle: 'Document Builder',
	documentList: {
		title: 'Document Builder',
	},
	docTypes: {
		action: 'Action',
		list: 'List',
		composed: 'Composed document',
	},
	docList: {
		docTypes: 'Document types',
		addDocument: 'Add document',
		table: {
			noResults: 'No documents found',
			columns: {
				doc_name: 'Name',
				type: 'Type',
				last_time_edited: 'Last time edited',
				status: 'Status',
			},
		},
		delete: {
			confirm: {
				title: 'Delete this document?',
				body: 'Are you sure you want to delete this document?',
				confirmButtonText: 'Delete',
			},
		},
	},
	docDetails: {
		backToOverview: 'Back to overview',
		docName: 'Document name',
		documentSettings: 'Document settings',
		description: 'Description',
		addField: 'Add field',
		close: {
			confirm: {
				title: 'Go back to document overview?',
				body: 'Are you sure you want to close? Your changes are not saved and will be lost if you close this overlay.',
				confirmButtonText: 'Ok',
			},
		},
		table: {
			noResults: 'No fields',
		},
		delete: {
			confirm: {
				title: 'Delete this field?',
				body: 'Are you sure you want to delete this field? This action can not be undone.',
				confirmButtonText: 'Delete',
			},
		},
		references: 'References',
		addReference: 'Add reference',
		referenceTable: {
			columns: {
				document: 'Document',
				foreignKey: 'Foreign key',
				keyField: 'Key field',
			},
			chooseField: 'Choose field',
			noResults: 'No references added',
		},
		deleteRef: {
			confirm: {
				title: 'Delete this reference?',
				body: 'Are you sure you want to delete this reference? This action can not be undone.',
				confirmButtonText: 'Delete',
			},
		},
		savedSuccessfully: 'Document was successfully saved',
		publish: {
			success: 'Document was successfully published',
			confirm: {
				title: 'Publish the document?',
				body: 'Are you sure you want to publish the document? It will overwrite the current published version, and this action can not be undone.',
				confirmButtonText: 'Publish',
			},
		},
	},
	docDelete: {
		error: 'Something went wrong while deleting document. Please try again.',
		success: 'Document was succesfully deleted',
	},
	newDocument: {
		title: 'Create new document',
		whatName: 'Give your document a name',
		giveDescription: 'Add a description for the new document',
		nameInputLabel: 'Document name',
		whatType: 'Of which type is the new document?',
		whatConnection: 'Which connection will the document be part of?',
		selectConnection: 'Select connection',
		submitButton: 'Next',
		createdSuccess: 'Succesfully created new Document',
	},
	fieldDetails: {
		saveButton: 'Save',
		close: {
			confirm: {
				title: 'Go back to document?',
				body: 'Are you sure you want to close? Your changes are not saved and will be lost if you go back.',
				confirmButtonText: 'Ok',
			},
		},
	},
	referenceDrawer: {
		drawerName: 'Choose a reference',
		table: {
			noResults: 'No documents found',
			columns: {
				doc_name: 'Name',
				key: 'Key field',
				type: 'Field type',
			},
		},
	},
	error: {
		loadDocument: 'Document was not found',
		loadUserDocAndCreateNew: 'Could not find <i>{table}</i> document, unable to continue creating your new document',
		canNotDropHere: 'Can not drop row in this location'
	},
	validation: {
		docNameEmpty: 'Document name can not be empty',
		docNameIsShort: 'Document name should be at least 2 characters',
		documentNameContainsSpace: 'Document name contains a space',
		docNameNotUnique: 'Document name already exists within connection',
		noFieldsSet: 'No fields are added to the document yet',
		noKeyFieldsSet: 'At least 1 key field should be set for the document',
		onlySingleFieldCanBeKeyAndId: 'When using both key and ID field, they should be set on a single field',
		fieldMarkedAsBeKeyAndIdShouldBeNumber: 'When a field is both key and ID, it should be of type number or long number',
		field: {
			fieldNameEmpty: 'Field name is empty',
			fieldNameIsShort: 'Field <strong>{name}</strong> name should be at least 2 characters',
			multipleFieldsWithSameName: 'Multiple fields with name <strong>{name}</strong> are set',
			fieldNameContainsSpace: 'Field name <strong>{name}</strong> contains a space',
			fieldTypeMissing: 'Field <strong>{name}</strong> has no type set',
			fieldTypeUnknown: 'Field type <strong>{type}</strong> of field <strong>{name}</strong> is unknown',
			functionListUnknown: 'Used validation tool for field <strong>{name}</strong> is unknown within current connection',
			mappingFieldNotFoundInFunctionList: 'Configured mapping field <strong>{mappingField}</strong> does not exist in function list used by field <strong>{name}</strong>',
			typeNotMatchInFunctionList:
				'Type of mapping input in validation tool for field <strong>{name}</strong> does not match the type of the field',
			maxLengthNotMatchInFunctionList:
				'Max length in <strong>{objectDefinition} ({objectDefinitionMaxLength})</strong> does not match the max length of <strong>{name} ({fieldMaxLength})</strong>',
			defaultValueForStaticMissing: 'No default value is set for field <strong>{name}</strong>',
			maxLengthEmpty: 'No max length is set for field <strong>{name}</strong>',
			keyFieldShouldBeRequired: 'Key field <strong>{name}</strong> should be marked as required',
			defaultValueOfStaticMissing: 'No default value of static default for field <strong>{fieldName}</strong>',
			defaultValueIncorrect: 'Incorrect default value for field <strong>{fieldName}</strong>: {error}',
			keyFieldCannotBeUpdatable: 'Key field <strong>{fieldName}</strong> can not be marked as updatable',
			idFieldCannotBeUpdatable: 'ID field <strong>{fieldName}</strong> can not be marked as updatable',
		},
		referenceNameMissing: 'Name for referenced document is missing',
		referenceGuidMissing: 'GUID for referenced document <strong>{name}</strong> is missing',
		referenceKeyMissing: 'Key field for referenced document <strong>{name}</strong> is missing',
		referenceChildMissing: 'Foreign key field for reference <strong>{name}</strong> is not set',
		foreignKeyUsedMultipleTimes: 'Field <strong>{name}</strong> is used as Foreign key in multiple references',
		refDocMissing: 'Referenced document <strong>{name}</strong> does not exist within current connection',
		referencedDocHasNoKeyfield: 'Referenced document <strong>{name}</strong> has no key field',
		keyFieldIncorrect:
			'Referenced document <strong>{name}</strong> has a key field <strong>{keyField}</strong>, and not the configured <strong>{configured}</strong>',
		childFieldDoesNotExist:
			'Configured foreign key <strong>{foreignKey}</strong> in reference to <strong>{name}</strong> does not exist in document fields',
		referencingFieldTypesDoNotMatch:
			'Types of key field <strong>{keyField}</strong> and foreign key field <strong>{foreign}</strong> for referenced document <strong>{name}</strong> do not match',
		referencingFieldMaxLengthsDoNotMatch:
			'Max length of key field <strong>{keyField}</strong> and foreign key field <strong>{foreign}</strong> for referenced document <strong>{name}</strong> do not match',
		action: {
			multipleFieldsMarkedAsId: 'Multiple fields are marked as identifier',
		},
		list: {
			referencedDocNotList: 'Referenced document <strong>{name}</strong> is not of type <strong>List</strong>',
			singleKeyFieldNeeded: 'Exactly 1 field should be marked as Key',
		}
	},
	permissions: {
		label: 'Document builder',
		info: 'Permissions connected to the Document builder',
		readDocument: {
			label: 'Read document',
			info: 'Permission to view all documents',
		},
		editDocument: {
			label: 'Edit document',
			info: 'Permission to create, update and publish documents',
		},
		deleteDocumentDraft: {
			label: 'Delete document draft',
			info: 'Permission to delete any document draft',
		},
	}
};
