import { util } from 'jointjs';
import { ref, unref, watch, computed, onMounted, onUnmounted } from 'vue';
import { useMagicKeys } from '@vueuse/core';

const contexts = ref([]);

const currentContext = computed(() => {
	return contexts.value[contexts.value.length - 1] || false;
});

const {
	ctrl_s,
	meta_s,
	meta_shift_v,
	ctrl_shift_v,
	meta_shift_p,
	ctrl_shift_p,
	meta_shift_c,
	ctrl_shift_c,
} = useMagicKeys({
	passive: false,
	onEventFired(e) {
		if (
			(e.ctrlKey || e.metaKey) &&
			e.type === 'keydown' &&
			(e.key == 's' ||
				(e.key == 'p' && e.shiftKey) ||
				(e.key == 'v' && e.shiftKey) ||
				(e.key == 'c' && e.shiftKey))
		)
			e.preventDefault();
	},
});

export default ({
	save = false,
	publish = false,
	validate = false,
	create = false,
}) => {
	const context = util.uuid();
	const unwatchers = [];

	onMounted(() => {
		contexts.value.push(context);

		const shortcutWatcher = (keyCombos, callbackFn) => {
			if (typeof callbackFn !== 'function') return;

			unwatchers.push(
				watch(keyCombos, (v) => {
					if (v.some(el => el)) return;
					if(context === unref(currentContext)) {
						callbackFn();
					}
				})
			);
		};

		shortcutWatcher([meta_s, ctrl_s], save);
		shortcutWatcher([meta_shift_p, ctrl_shift_p], publish);
		shortcutWatcher([meta_shift_v, ctrl_shift_v], validate);
		shortcutWatcher([meta_shift_c, ctrl_shift_c], create);
	});

	onUnmounted(() => {
		const index = unref(contexts).indexOf(context);
		if (index > -1) contexts.value.splice(index, 1);

		unwatchers.forEach((unwatcher) => {
			unwatcher();
		});
	});
};
