import i18n from '@assets/i18n';
const TheDocumentBuilder = () => import('./materials/TheDocumentBuilder.vue');

// translate function of vue-i18n
const { t } = i18n.global;

export default [
	{
		path: '/documentbuilder',
		component: TheDocumentBuilder,
		name: 'documentBuilder',
		meta: {
			title: t('db.pageTitle'),
			permission: 'Read',
			showInSideNav: true,
			showInHome: true,
			icon: 'gear',
			parent: 'builder',
			module: 'DocumentBuilder',
			order: 10,
		},
	},
];