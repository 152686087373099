import i18n from '@assets/i18n';

// translate function of vue-i18n
const { t } = i18n.global;

export const FILTER_TYPE_STATIC = 'Static';
export const FILTER_TYPE_DYNAMIC = 'Dynamic';
const FILTER_NULL_CHECKS = ['', null, undefined];

export const operators = [
	{
		name: t('fb.conditions.operators.equals'),
		value: 'equal',
		inputCount: 1,
		types: [
			'DATE',
			'DECIMAL',
			'GUID',
			'LONGNUMBER',
			'NUMBER',
			'STRING',
		],
	},
	{
		name: t('fb.conditions.operators.notEquals'),
		value: 'not_equal',
		inputCount: 1,
		types: [
			'DATE',
			'DECIMAL',
			'GUID',
			'LONGNUMBER',
			'NUMBER',
			'STRING',
		],
	},
	{
		name: t('fb.conditions.operators.greaterThan'),
		value: 'greater_than',
		inputCount: 1,
		types: [
			'DATE',
			'DATETIME',
			'DECIMAL',
			'LONGNUMBER',
			'NUMBER',
		],
	},
	{
		name: t('fb.conditions.operators.greaterEquals'),
		value: 'greater_or_equal',
		inputCount: 1,
		types: [
			'DATE',
			'DATETIME',
			'DECIMAL',
			'LONGNUMBER',
			'NUMBER',
		],
	},
	{
		name: t('fb.conditions.operators.smallerThan'),
		value: 'smaller_than',
		inputCount: 1,
		types: [
			'DATE',
			'DATETIME',
			'DECIMAL',
			'LONGNUMBER',
			'NUMBER',
		],
	},
	{
		name: t('fb.conditions.operators.smallerEquals'),
		value: 'smaller_or_equal',
		inputCount: 1,
		types: [
			'DATE',
			'DATETIME',
			'DECIMAL',
			'LONGNUMBER',
			'NUMBER',
		],
	},
	{
		name: t('fb.conditions.operators.between'),
		value: 'between',
		inputCount: 2,
		types: [
			'DATE',
			'DATETIME',
			'DECIMAL',
			'LONGNUMBER',
			'NUMBER',
		],
	},
	{
		name: t('fb.conditions.operators.contains'),
		value: 'contain',
		inputCount: 1,
		types: ['STRING'],
	},
	{
		name: t('fb.conditions.operators.notContains'),
		value: 'not_contain',
		inputCount: 1,
		types: ['STRING'],
	},
	{
		name: t('fb.conditions.operators.in'),
		value: 'in',
		inputCount: -1,
		minInput: 2,
		types: ['STRING'],
	},
	{
		name: t('fb.conditions.operators.notIn'),
		value: 'not_in',
		inputCount: -1,
		minInput: 2,
		types: ['STRING'],
	},
	{
		name: t('fb.conditions.operators.isSet'),
		value: 'is_set',
		inputCount: 0,
		types: [
			'BOOLEAN',
			'DATE',
			'DATETIME',
			'DECIMAL',
			'GUID',
			'LONGNUMBER',
			'NUMBER',
			'STRING',
		],
	},
	{
		name: t('fb.conditions.operators.notSet'),
		value: 'is_not_set',
		inputCount: 0,
		types: [
			'BOOLEAN',
			'DATE',
			'DATETIME',
			'DECIMAL',
			'GUID',
			'LONGNUMBER',
			'NUMBER',
			'STRING',
		],
	},
	{
		name: t('fb.conditions.operators.isTrue'),
		value: 'true',
		inputCount: 0,
		types: ['BOOLEAN'],
	},
	{
		name: t('fb.conditions.operators.isFalse'),
		value: 'false',
		inputCount: 0,
		types: ['BOOLEAN'],
	},
	{
		name: t('fb.conditions.operators.startsWith'),
		value: 'start_with',
		inputCount: 1,
		types: ['STRING'],
	},
	{
		name: t('fb.conditions.operators.endsWith'),
		value: 'end_with',
		inputCount: 1,
		types: ['STRING'],
	},
	{
		name: t('fb.conditions.operators.maxLength'),
		value: 'max_length',
		inputCount: 1,
		types: ['STRING'],
	},
	{
		name: t('fb.conditions.operators.minLength'),
		value: 'min_length',
		inputCount: 1,
		types: ['STRING'],
	},
];

export let availableTypesForCondition = new Set();

operators.forEach((operator) => {
	availableTypesForCondition = new Set([...availableTypesForCondition, ...operator.types]);
});

export const getOperatorName = (operator) => {
	let result = false;

	operators.some((item) => {
		if (item.value === operator) {
			result = item.name;
			return true;
		}
	});

	return result;
};

export const filterValueIsNullish = (val) => {
	return FILTER_NULL_CHECKS.includes(val);
};

export const outputRuleValues = (values) => {
	const result = [];

	values.forEach((val) => {
		if (!filterValueIsNullish(val?.value)) {
			result.push(val.value);
		} else if (!filterValueIsNullish(val?.field)) {
			const valResult = [];

			if (val.field.startsWith('$')) {
				valResult.push(t(`datepicker.dynamicOptions.${val.field}`));
			} else {
				valResult.push(t('fb.conditions.fieldValue', {
					name: val.field
				}));
			}

			if (!filterValueIsNullish(val?.offset) && !filterValueIsNullish(val?.unit)) {

				if (val.offset < 0) valResult.push(val.offset);
				else valResult.push(`+${val.offset}`);

				valResult.push(t(`datepicker.unitsPluralized.${val.unit}`, Math.abs(val.offset)));
			}

			result.push(valResult.join(' '));
		}
	});

	return result.length ? result.join(', ') : false;
};