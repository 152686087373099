/**
 * Filter connections by module(s)
 * 
 * @param {Array} connections 
 *  Array of normalized connections
 *
 * @param {Array|String} modules
 *  Array of module names or single module name
 * 
 * @returns {Array}
 *  Filtered connections
 */
export default (connections, modules) => {
	if (typeof modules !== 'object') {
		modules = [modules];
	}

	// Filter connections by module
	return connections.filter(connection => {
		let match = false;

		modules.forEach(module => {
			if (connection?.modules.includes(module)) {
				match = true;
			}
		});

		return match;
	});
};
