import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import {
	stores,
	getStoreMutation,
	getStoreGetter,
	getStoreAction,
} from '@assets/scripts/store/config';
import Mutations from '@assets/scripts/store/mutations';
import Actions from '@assets/scripts/store/actions';
import Getters from '@assets/scripts/store/getters';
import setLoader from '@assets/scripts/store/loader';
import {
	LOGIN,
	LOGIN_TWO_F_A,
	ASK_FOR_NEW_PASSWORD,
	RESET_PASSWORD,
	CHANGE_PASSWORD,
} from '@assets/scripts/api/config';
import Helpers from '@assets/scripts/helpers';
import { IS_DEV } from '@assets/scripts/helpers';
import { log, debug } from '@assets/scripts/components/notifications';
import i18n from '@assets/i18n';
import router from '@assets/scripts/router';
import { useApi, useApiAsync } from '@assets/scripts/composables/useApi';
import { watch, unref } from 'vue';
import modules from '@modules/store';
import useJWT from '@assets/scripts/composables/useJWT.js';

const {
	login,
	logout,
	update,
 } = useJWT();

// translate function of vue-i18n
const { t } = i18n.global;

const getDefaultState = () => {
	return Helpers.cloneObject({
		screenSizes: {
			tablet: 768,
			desktop: 1024,
			fullhd: 1440,
		},
		loadingElements: [],
		activeDrawers: [],
		confirmation: {
			active: false,
			title: false,
			body: false,
			icon: false,
			confirmButtonText: t('general.ok'),
			confirmFn: false,
			cancelButtonText: t('general.cancel'),
			cancelFn: false,
		},
		validationMode: false,
		validationInProgress: false,
		isValid: false,
		validationErrors: [],
		errorsListcollapsed: true,
	});
};

const store = {
	state: getDefaultState(),
	mutations: {
		/**
		 * Updates the validation status
		 *
		 * @param {Object} state
		 *  Ref to current store state
		 *
		 * @returns {void}
		 */
		[Mutations.SET_VALIDATION_MODE](state, status) {
			state.validationMode = status;
		},
		/**
		 * Updates the validation in progress status
		 *
		 * @param {Object} state
		 *  Ref to current store state
		 *
		 * @returns {void}
		 */
		[Mutations.SET_VALIDATION_IN_PROGRESS](state, status) {
			state.validationInProgress = status;
		},
		/**
		 * Updates the valid state
		 *
		 * @param {Object} state
		 *  Ref to current store state
		 *
		 * @returns {void}
		 */
		[Mutations.SET_IS_VALID](state, status) {
			state.isValid = status;
		},
		/**
		 * Updates the valiodation error array
		 *
		 * @param {Object} state
		 *  Ref to current store state
		 *
		 * @returns {void}
		 */
		[Mutations.ADD_VALIDATION_ERROR](state, error) {
			state.validationErrors.push(error);
		},
		/**
		 * Clear the valiodation error array
		 *
		 * @param {Object} state
		 *  Ref to current store state
		 *
		 * @returns {void}
		 */
		[Mutations.CLEAR_VALIDATION_ERRORS](state) {
			state.validationErrors = [];
		},
		/**
		 * update the errorList toggle staet
		 *
		 * @param {Object} state
		 *  Ref to current store state
		 *
		 * @returns {void}
		 */
		[Mutations.TOGGLE_ERRORS_LIST_COLLAPSED](state, value) {
			state.errorsListcollapsed = value;
		},
		/**
		 * Adds a given key to the list of active loaders. Store
		 * getter 'IS_LOADING' will return true as long as at least
		 * 1 key is in the list of active loaders.
		 *
		 * @param {Object} state
		 *  Ref to current store state
		 *
		 * @param {String} key
		 *  Identifying key for active loader
		 *
		 * @returns {void}
		 */
		[Mutations.ADD_LOADER](state, key) {
			if (state.loadingElements.indexOf(key) === -1) {
				state.loadingElements.push(key);
			}
		},
		/**
		 * Removes a given key from the list of active loaders. Store
		 * getter 'IS_LOADING' will return true as long as at least
		 * 1 key is in the list of active loaders.
		 *
		 * @param {Object} state
		 *  Ref to current store state
		 *
		 * @param {String} key
		 *  Identifying key for loader
		 *
		 * @returns {void}
		 */
		[Mutations.STOP_LOADER](state, key) {
			const keyIndex = state.loadingElements.indexOf(key);
			if (keyIndex > -1) state.loadingElements.splice(keyIndex, 1);
		},
		[Mutations.RESET_LOADER](state) {
			// remove all loaders
			state.loadingElements = [];
		},
		[Mutations.SHOW_NOTIFICATION]() {
			// do nothing
			// mutation only exists so components can subscribe to it
		},
		[Mutations.RESET](state) {
			// reset state to initial state
			Object.assign(state, getDefaultState());
		},
		[Mutations.OPEN_DRAWER](state, { type, data = false }) {
			debug('Opening drawer', { type, data });

			state.activeDrawers.push({
				type,
				data,
				key: Helpers.newGuid(),
			});
		},
		[Mutations.CLOSE_DRAWER](state, guid) {
			debug('Closing drawer', guid);

			state.activeDrawers = state.activeDrawers.filter(
				(drawer) => drawer.key !== guid
			);
		},
		[Mutations.OPEN_CONFIRMATION](state, settings = {}) {
			state.confirmation = Object.assign(state.confirmation, settings);
			state.confirmation.active = true;
		},
		[Mutations.CLOSE_CONFIRMATION](state) {
			const { confirmation } = getDefaultState();
			state.confirmation = confirmation;
		},
	},
	actions: {
		[Actions.INIT](store) {
			debug('Init Store state', Helpers.cloneObject(store.state));

			// reset loader, to prevent showing a loader retrieved
			// from the persisted store
			store.commit(getStoreMutation('RESET_LOADER'));
		},
		/**
		 * Try to login user with given name and password
		 *
		 * @param {Function} commit
		 *  Ref to store.commit
		 *
		 * @param {String} email
		 *  Email entered by user
		 *
		 * @param {String} password
		 *  Password entered by user
		 *
		 * @returns {void}
		 */
		[Actions.LOGIN]({ commit }, { email, password }) {
			return new Promise((resolve, reject) => {
				let unwatchError, unwatchData;

				// stop watchers for error and data
				const unwatchLogin = () => {
					unwatchError();
					unwatchData();
				};

				const { data, error } = useApi(LOGIN, {
					parameters: {
						email: email,
						pass: password,
					}
				}, true, false);

				// returned error object is reactive,
				// so watch for update
				unwatchError = watch(error, () => {
					// stop watchers
					unwatchLogin();
					// reject promise with message
					reject(t('login.notification'));
				});

				// returned data object is reactive,
				// so watch for update
				unwatchData = watch(data, () => {
					// stop watchers
					unwatchLogin();
					
					// run 'login' function from useJWT
					login(unref(data));
					
					// resolve promise
					resolve();
				});
			});
		},
		/**
		 * Try to login user with given twoFA code
		 *
		 * @param {Function} commit
		 *  Ref to store.commit
		 *
		 * @param {String} twoFA
		 *  two factor authentication code
		 *
		 * @returns {void}
		 */
		[Actions.LOGIN_TWO_F_A]({ commit }, { twoFA }) {
			return new Promise((resolve, reject) => {
				let unwatchError, unwatchData;

				// stop watchers for error and data
				const unwatchLogin = () => {
					unwatchError();
					unwatchData();
				};

				const { data, error } = useApi(LOGIN_TWO_F_A, {
					parameters: {
						twoFA: twoFA,
					}
				}, true, false);

				// returned error object is reactive,
				// so watch for update
				unwatchError = watch(error, () => {
					// stop watchers
					unwatchLogin();
					// reject promise with message
					reject(t('login.twoFANotification'));
				});

				// returned data object is reactive,
				// so watch for update
				unwatchData = watch(data, () => {
					// stop watchers
					unwatchLogin();

					// get user data
					const tokens = unref(data);

					// check if token was received
					if (!tokens) {
						// reject promise with message
						reject(t('login.twoFANotification'));
					} else {
						// run 'login' function from useJWT
						login(tokens);

						// show success message
						log(t('login.success'), 'success');

						// redirect uer to homepage
						router.push({ name: 'home' });
						
						// resolve promise
						resolve();
					}
				});
			});
		},
		/**
		 * Ask for new password
		 *
		 * @param {String} email
		 *  Email entered by user
		 *
		 * @returns {void}
		 */
		async [Actions.ASK_FOR_NEW_PASSWORD](store, { email }) {
			const result = await useApiAsync(ASK_FOR_NEW_PASSWORD, {
				parameters: {
					email: email
				}
			});

			if (result !== false) {
				// show success message
				log(t('requestNewPassword.success'), 'success');
				router.push({ name: 'login' });
			} else {
				// reject promise with message
				log(t('requestNewPassword.notification'), 'danger');
			}
		},
		/**
		 * Reset password
		 *
		 * @param {String} key
		 * request key
		 * 
		 * @param {String} newPassword
		 * new password
		 *
		 * @returns {void}
		 */
		async [Actions.RESET_PASSWORD](store, { newPassword, key }) {
			const result = await useApiAsync(RESET_PASSWORD, {
					parameters: {
						pass: newPassword,
						key,
					}
				},
				true,
				(error) => {
					// redirect to requets password if key is expired 
					if (error.response.status === 498) {
						router.push({ name: 'requestPassword' });
						log(error.response.data.message, 'danger');
					} else if (error.response.status === 400){
						log(error.response.data.message, 'danger');
					}else {
						log(t('resetPassword.notification'), 'danger');
					}
				}
			);

			if (result !== false) {
				// show success message
				log(t('resetPassword.success'), 'success');
				router.push({ name: 'login' });
			} 
		},
		/**
		 * Change password
		 *
		 * @param {String} currentPassword
		 *  current password
		 *
		 * @param {String} newPassword
		 * new password
		 *
		 * @returns {void}
		 */
		async [Actions.CHANGE_PASSWORD](store, { currentPassword, newPassword }) {
			const result = await useApiAsync(CHANGE_PASSWORD, {
				parameters: {
					currentPassword: currentPassword,
					newPassword: newPassword,
				}
			});

			if (result !== false) {
				// execute 'update' function from useJWT
				update(result);
				// show success message
				log(t('changePassword.success'), 'success');
			} else {
				// show error message
				log(t('changePassword.notification'), 'danger');
			}
		},
		[Actions.RESET]({ commit }) {
			// reset state of all modules
			Object.keys(stores).forEach((id) => {
				try {
					commit(getStoreMutation('RESET', id));
				} catch (err) {
					// no need to handle error here
				}
			});
		},
		[Actions.LOGOUT]({ dispatch }) {
			// reset state of all modules
			dispatch(getStoreAction('RESET'));

			// execute 'logout' function from useJWT
			logout();

			// show message
			log(t('logout.success'), 'success');

			router.push({ name: 'login' });
		},
		[Actions.CLOSE_ALL_DRAWERS](store) {
			store.state.activeDrawers.forEach((drawer) => {
				store.commit(getStoreMutation('CLOSE_DRAWER'), drawer.key);
			});
		},
		[Actions.CONFIRM](store) {
			// get confirmation settings
			const confirmation = store.getters[getStoreGetter('CONFIRMATION')];

			if (confirmation && typeof confirmation.confirmFn == 'function') {
				// execute confirm function
				confirmation.confirmFn();
			}

			// commit mutation to disable confirmation
			store.commit(getStoreMutation('CLOSE_CONFIRMATION'));
		},
		[Actions.CANCEL_CONFIRMATION](store) {
			// get confirmation settings
			const confirmation = store.getters[getStoreGetter('CONFIRMATION')];

			if (confirmation && typeof confirmation.cancelFn == 'function') {
				// execute cancel function
				confirmation.cancelFn();
			}

			// commit mutation to disable confirmation
			store.commit(getStoreMutation('CLOSE_CONFIRMATION'));
		},
		[Actions.CLEAR_VALIDATION]({ commit }) {
			// clear errors array
			commit(getStoreMutation('CLEAR_VALIDATION_ERRORS'), null, {
				root: true,
			});
			// set validation mode to false
			commit(getStoreMutation('SET_VALIDATION_MODE'), false, {
				root: true,
			});
			// close error list
			commit(
				getStoreMutation('TOGGLE_ERRORS_LIST_COLLAPSED'),
				true,
				{
					root: true,
				}
			);
		},
		[Actions.PREPARE_VALIDATION]({ commit }) {
			// show loader
			setLoader('validation');

			// clear errors array
			commit(getStoreMutation('CLEAR_VALIDATION_ERRORS'), null, {
				root: true,
			});

			// set validation in progress to true
			commit(getStoreMutation('SET_VALIDATION_IN_PROGRESS'), true, {
				root: true,
			});
			
			// set validation mode to true
			commit(getStoreMutation('SET_VALIDATION_MODE'), true, {
				root: true,
			});
		},
		[Actions.FINISH_VALIDATION]({ commit }) {
			// set validation in progress to false
			commit(getStoreMutation('SET_VALIDATION_IN_PROGRESS'), false, {
				root: true,
			});

			const errors = this.getters[getStoreGetter('VALIDATION_ERRORS')];

			// set isValid to true or false
			commit(getStoreMutation('SET_IS_VALID'), errors.length === 0, {
				root: true,
			});

			// stop loader
			setLoader('validation', false);
		},
	},
	modules: {},
	getters: {
		[Getters.IS_VALID]: ({ isValid }) => {
			return isValid;
		},
		[Getters.VALIDATION_IN_PROGRESS]: ({ validationInProgress }) => {
			return validationInProgress;
		},
		[Getters.VALIDATION_MODE]: ({ validationMode }) => {
			return validationMode;
		},
		[Getters.ERRORS_LIST_COLLAPSED]: ({ errorsListcollapsed }) => {
			return errorsListcollapsed;
		},
		[Getters.VALIDATION_ERRORS]: ({ validationErrors }) => {
			return validationErrors;
		},
		[Getters.SCREEN_SIZES]: ({ screenSizes }) => {
			return screenSizes;
		},
		[Getters.IS_LOADING]: ({ loadingElements }) => {
			return loadingElements.length > 0;
		},
		[Getters.DRAWERS]: ({ activeDrawers }) => {
			return activeDrawers;
		},
		[Getters.DRAWER_COUNT]: ({ activeDrawers }) => {
			return activeDrawers.length;
		},
		[Getters.CONFIRMATION]: ({ confirmation }) => {
			return confirmation;
		},
		[Getters.CONFIRMATION_ACTIVE]: ({ confirmation }) => {
			return confirmation && confirmation.active;
		},
		debug: (state) => {
			// output whole state for debugging since Vue Devtools plugin
			// not (always) shows the updated state of the store, but it
			// does show the correct value of all getters
			return IS_DEV ? state : {};
		},
	},
	plugins: [createPersistedState()],
};

// loop over modules
for (const module in modules) {
	// add module store to existing store
	store.modules[module] = modules[module].store;
}

export default createStore(store);