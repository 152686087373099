import jwt from 'jsonwebtoken';
import { ref, computed, watch } from 'vue';
import { api } from '@assets/scripts/api';
import { REFRESH } from '@assets/scripts/api/config';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { debug } from '@assets/scripts/components/notifications';

const loginLevel = ref(0);
const permissions = ref(false);
const mfaEnabled = ref(false);
const is_admin = ref(false);
const userName = ref('');
const jsonWebToken = ref(false);
const tokenExpiration = ref(false);
const refreshToken = ref(false);
const env = ref(false);

let expirationTimer = false;

watch(tokenExpiration, (newExpiration) => {
    // stop existing timer
    if (expirationTimer) clearTimeout(expirationTimer);

    if (newExpiration) {
        debug('New token expiration', newExpiration.toString());
        // calculate countdown to expiration of token, subtract 2 seconds to be sure
        const countdownMs = newExpiration.getTime() - new Date().getTime() - 2000;

        if (countdownMs > 0) {
            expirationTimer = setTimeout(() => {
                debug('Token expired, starting refresh');
                useRefreshToken();
            }, countdownMs);
        } else {
            debug('Token was already expired, starting refresh immediately');
            useRefreshToken();
        }
    }

}, {
    immediate: true
});

const getMfaStatus= (decodedToken) => {
    if (decodedToken.amr) {
        const amr = JSON.parse(decodedToken.amr);
        if (amr.find(string => string === 'mfa')) {
            return true;
        } else {
            return false;
        }
    }
}

// calculate login status
const isLoggedIn = computed(() => loginLevel.value === 2);

const setJWT = (token) => {
    jsonWebToken.value = token;
    const decodedToken = token ? jwt.decode(token) : false;
    
    // set login level
    loginLevel.value = decodedToken && decodedToken.acr ? Number(decodedToken.acr.split('')[1]) : 0;

    // set user name
    userName.value = decodedToken && decodedToken.sub ? decodedToken.sub : '';

    // set permissions
    permissions.value = decodedToken && decodedToken.entitlements ? JSON.parse(decodedToken.entitlements) : false;

    // set mfaEnabled
    mfaEnabled.value = decodedToken ? getMfaStatus(decodedToken) : false;

    // set is_admin
    is_admin.value = decodedToken && decodedToken.admin && decodedToken.admin === 'true' ? true : false;

    // set expiration time 
    tokenExpiration.value = decodedToken && decodedToken.exp ? new Date(decodedToken.exp * 1000) : false;

    // set environment
    env.value = decodedToken && decodedToken.env ? decodedToken.env : false;

    // save to localstorage
    if(token) {
        localStorage.setItem('token', token);
    } else {
        // remove token from localstorage
        localStorage.removeItem('token');
    }
}

const setRefreshToken = (token = false) => {
    refreshToken.value = token;

    // save to localstorage
    if(token) {
        localStorage.setItem('refreshToken', token);
    } else {
        // remove token from localstorage
        localStorage.removeItem('refreshToken');
    }
}

/**
 * Called by Store when user logs in succesfully
 */
const login = ({ token = false, refresh = false }) => {
    debug('Setting tokens after login', { token, refresh });

    // set token
    setJWT(token);

    // set refresh token
    setRefreshToken(refresh);
};

/**
 * Called:
 *  - by Store when user logs out,
 *  - when token is expired
 *  - when refresh failed
 */
const logout = () => {
    debug('Deleting tokens after logout');

    // set token
    setJWT(false);

    // set refresh token
    setRefreshToken(false);
};

/**
 * Called by Store when user changes password,
 * and after succesfull refresh of tokens
 */
const update = ({ token = false, refresh = false }) => {
    debug('Setting tokens after update', { token, refresh });

    // set token
    setJWT(token);

    // set refresh token
    setRefreshToken(refresh);
};

export default () => {
    const init = () => {
        const token = localStorage.getItem('token');
        if (token) {
            setJWT(token);
        }

        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
            setRefreshToken(refreshToken);
        }
    }

    return {
        loginLevel,
        userName,
        permissions,
        mfaEnabled,
        is_admin,
        env,
        isLoggedIn,
        init,
        login,
        logout,
        update,
        jsonWebToken,
    }
};

const useRefreshToken = async () => {

    const refreshFail = () => {
        // unset tokens
        logout();

        return false;
    };

    if (!refreshToken.value) return refreshFail();

	const result = await useApiAsync(
		REFRESH,
		{
			parameters: {
                refresh: refreshToken.value,
            },
		},
		true,
		() => {}
	);

	debug('Refreshing token', { result });
	
	if (result && result?.token) {
        // set new tokens
        update(result);

		return true;
	} else {
		return refreshFail();
	}
};

api.interceptors.request.use(
    config => {
        debug('Executing request interceptor in useJWT.js', { config });

        const { __endpoint: endpoint } = config ?? {};

        if (endpoint && jsonWebToken.value) {
            const { useAuth = true } = endpoint;

            // add JWT to headers if useAuth is set to true for this endpoint
            if (useAuth) {
                config.headers.Authorization = `Bearer ${jsonWebToken.value}`;
            }
        }


        return config;
    },
);

api.interceptors.response.use(null, async (origError) => {
	debug('Doing AUTH error interceptor', { origError });

	if (origError.response.status === 401) {
        if (origError.config && !origError.config.__isRetryRequest && refreshToken.value) {
            debug('Going to try refresh');
            const refreshed = await useRefreshToken();

            debug('Refreshed', { refreshed } );

            if (refreshed) {
                debug('Trying original call again');
                origError.config.__isRetryRequest = true;

                return new Promise((resolve, reject) => {
                    api(origError.config)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(() => {
                        reject(origError);
                    });
                })
            } else {
                debug('Refresh failed, NOT trying original call again');
            }

        } else {
            debug('NOT going to try refresh');
        }
	}
    
    if ([401, 403].includes(origError.response.status)) {
        // log out user
        logout();
    }

    return Promise.reject(origError);
});
