/**
 * Translations for English
 * See https://vue-i18n.intlify.dev/guide/ on how to use
 */
 export default {
	pageTitle: 'Reporting',
	permissions: {
		label: 'Reporting',
		info: 'Permissions connected to the Reporting module',
		use: {
			label: 'Use Reporting',
			info: 'Permission to use the Reporting module',
		},
	},
};
