import i18n from '@assets/i18n';
const TheInsightKPIPage = () => import('@modules/InsightKPI/TheInsightKPIPage.vue');

// translate function of vue-i18n
const { t } = i18n.global;

export default [
	{
		path: '/insightKPI',
		component: TheInsightKPIPage,
		name: 'insightKPI',
		meta: {
			title: t('insightKPI.pageTitle'),
			permission: 'Read',
			showInHome: true,
			showInSideNav: true,
			parent: 'clientModules',
			icon: 'gear',
			module: 'Insight KPI',
			order: 100,
		},
	},
];