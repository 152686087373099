import i18n from '@assets/i18n';
const TheCustomersPage = () => import('@modules/Customers/TheCustomersPage.vue');

// translate function of vue-i18n
const { t } = i18n.global;

export default [
	{
		path: '/customers',
		component: TheCustomersPage,
		name: 'customers',
		meta: {
			title: t('customers.pageTitle'),
			permission: 'Read',
			showInHome: true,
			showInSideNav: true,
			icon: 'gear',
			parent: 'clientModules',
			module: 'Customers',
			order: 100,
		},
	},
];