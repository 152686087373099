import { IS_DEV } from '@assets/scripts/helpers';
import Helpers from '@assets/scripts/helpers';
import { isEmpty } from 'lodash';
import usePermission from '@assets/scripts/composables/usePermission';
import { prepareComposedDocumentForPost } from '@modules/ComposedDocument/components/composed-document';
import { composedDocumentMeta } from '@modules/ComposedDocument/endpoints';

export const names = {
	MODIFIED: 'modified',
	CAN_BE_EDITED: 'canBeEdited',
	CAN_BE_EXTENDED: 'canBeExtended',
	CAN_BE_SAVED: 'canBeSaved',
	CAN_BE_PUBLISHED: 'canBePublished',
	COMPOSED_DOCUMENT_ACTIVE: 'composedDocumentActive',
	CURRENT_COMPOSED_DOCUMENT: 'currentComposedDocument',
	CURRENT_CONNECTION: 'currentConnection',
	SHOW_CREATE_NEW: 'showCreateNew',
	FULL_COMPOSED_DOCUMENT: 'fullComposedDocument',
};

export default {
	[names.MODIFIED]: ({ modified }) => {
		return modified;
	},
	[names.CAN_BE_EDITED]: ({ canBeModified }) => {
		// can be edited if it has never been published yet
		return canBeModified;
	},
	[names.CAN_BE_EXTENDED]: ({ canBeExtended }) => {
		return canBeExtended;
	},
	[names.CAN_BE_SAVED]: ({ canBeModified, modified }) => {
		// can be saved only if it has never
		// been published yet, and it has been modified
		return canBeModified && modified;
	},
	[names.CAN_BE_PUBLISHED]: ({ canBeModified, canBeExtended, modified }) => {
		// if a composed document has not been published yet, or it has been
		// modified, it can be published, IF current user has correct permissions
		return (
			(canBeModified || (canBeExtended && modified)) &&
			usePermission('Upsert', 'ComposedDocument')
		);
	},
	[names.COMPOSED_DOCUMENT_ACTIVE]: ({ currentComposedDocument }) => {
		return Object.keys(currentComposedDocument).length > 0;
	},
	[names.CURRENT_COMPOSED_DOCUMENT]: ({ currentComposedDocument }) => {
		return currentComposedDocument;
	},
	[names.CURRENT_CONNECTION]: ({ currentConnection }) => {
		return currentConnection;
	},
	[names.SHOW_CREATE_NEW]: ({ showCreateNew }) => {
		return showCreateNew;
	},
	[names.FULL_COMPOSED_DOCUMENT]: ({ currentComposedDocument }) => {
		return !isEmpty(currentComposedDocument) && IS_DEV ? Helpers.obj.construct(prepareComposedDocumentForPost(currentComposedDocument), composedDocumentMeta) : {};
	},
	debug: (state) => {
		// output whole state for debugging since Vue Devtools plugin
		// not (always) shows the updated state of the store, but it
		// does show the correct value of all getters
		return IS_DEV ? state : {};
	},
};
