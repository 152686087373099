import i18n from '@assets/i18n';

// translate function of vue-i18n
const { t } = i18n.global;    

export default {
    label: t('ad.permissions.label'),
    info: t('ad.permissions.info'),
    module: 'ActionDocument',
    permissions: {
        'Read': {
            label: t('ad.permissions.readActionDocument.label'),
            info: t('ad.permissions.readActionDocument.info'),
            availableInRole: true // if true will be visible in role details as a checkbox
        },
        'Upsert': {
            implicit: [
                'Read',
                'Publish',
            ],
            label: t('ad.permissions.editActionDocument.label'),
            info: t('ad.permissions.editActionDocument.info'),
            availableInRole: true // if true will be visible in role details as a checkbox
        },
        'Delete': {
            implicit: [
                'Read',
            ],
            label: t('ad.permissions.deleteActionDocumentDraft.label'),
            info: t('ad.permissions.deleteActionDocumentDraft.info'),
            availableInRole: true // if true will be visible in role details as a checkbox
        },
        'Publish': {
            // this permission is only for the backend to check if user can publish action document 
            // in the frontend we implicit this permission with Upsert permission and we only show one checkbox for both Upsert and publish
            // in this case backend will get a separate publish and upsert permissions
            availableInRole: false // if true will be visible in role details as a checkbox
        },
    }
}