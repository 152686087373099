/**
 * File offers functionality to process flow variables and
 * Block configuration. For instance, it transforms config of
 * Start Block to an array of variables that can be used in the
 * rest of the Flow.
 */
import Helpers from '@assets/scripts/helpers';
import { debug } from '@assets/scripts/components/notifications';
import { getOutputForAction } from '@modules/FlowBuilder/components/actions';
import { getOutputForExternalConnector } from '@modules/FlowBuilder/components/external-connector';
import { getVariablesFromFunctionList } from '@assets/scripts/components/function-lists';
import Field from '@assets/scripts/components/field';

/**
 * Function translates block configuration
 * to Flow Variables
 *
 * @param {Array} input
 *  Array of configurated variables from
 *  various blocks
 *
 * @returns {Array}
 *  Array of Flow Variables
 */
const getVariablesFromInput = async (input) => {
	let variables = [];

	// loop over configuration
	for (const inputVal of input) {
		// get var type
		const type = Field.getVarType(inputVal.type);

		if (!type) {
			// throw error if type was not found
			debug(
				'Could not map value type of input value to known variable type',
				Helpers.cloneObject(inputVal),
				'danger'
			);
		} else if (!Field.nameExists(inputVal.name)) {
			// throw error if name is not given
			debug(
				'Could not find name of input',
				Helpers.cloneObject(inputVal),
				'danger'
			);
		} else {
			// get new flow variable
			const newVariable = Field.createNew({
				...inputVal,
			});

			// add new flow variable to result array
			if (newVariable) variables.push(newVariable);

			// get variables from optional Function List
			const listInfo = await getVariablesFromFunctionList(inputVal);

			if (listInfo) {
				// recursively get flow variables from found
				// List Function variables
				const listVariables = await getVariablesFromInput(listInfo);

				// merge flow variables
				variables = variables.concat(listVariables);
			}
		}
	}

	return variables;
};

/**
 * Function translates Start Block configuration
 * to Flow Variables
 *
 * @param {Array} input
 *  Array of configurated variables from
 *  Start Block
 *
 * @returns {Array}
 *  Array of Flow Variables
 */
export const getVariablesFromStartInput = async (input) => {
	return await getVariablesFromInput(input);
};

/**
 * Function translates Add Block configuration
 * to Flow Variables
 *
 * @param {Array} input
 *  Array of configurated variables from
 *  Add Block
 *
 * @returns {Array}
 *  Array of Flow Variables
 */
export const getVariablesFromAppendInput = async (input) => {
	return await getVariablesFromInput(input);
};

/**
 * Function translates Action (read or write) Block
 * configuration to Flow Variables
 *
 * @param {Object} config
 *  Config object of Action Block
 *
 * @returns {Array}
 *  Array of Flow variables
 */
export const getVariablesFromAction = async (config) => {
	// get configured aliases
	const outputFields = Field.setFullAliases(config?.output || []);

	// get full output of Action
	const actionOutput = await getOutputForAction(config);

	// check if Action has output and at least 1 alias is configured
	if (actionOutput && outputFields && outputFields?.length > 0) {

		const fieldsToUse = [];

		// loop over action output fields
		actionOutput.forEach((f) => {
			// check if alias is configured for this field
			const alias = outputFields.find(
				(a) => a.name.toLowerCase() === f.name.toLowerCase()
			);

			// add field with alias as name to array of fields to use
			if (alias) {
				fieldsToUse.push({
					...f,
					name: alias.alias,
				});
			}
		});

		return await getVariablesFromInput(fieldsToUse);
	}
	else return [];
};

/**
 * Function translates External Connector Block
 * configuration to Flow Variables
 *
 * @param {Object} config
 *  Config object of Action Block
 *
 * @returns {Array}
 *  Array of Flow variables
 */
export const getVariablesFromExternalConnector = async (config) => {
	const output = await getOutputForExternalConnector(config);
	if (output) return await getVariablesFromInput(output);
	else return [];
};

/**
 * Function merges two arrays of Flow Variables
 * to a single array with only unique values
 * Flow Variables from newVars take precedence over
 * duplicate Flow Variables from existingVars
 *
 * @param {Array} newVars
 *  Array of Flow Variable Objects
 *
 * @param {Array} existingVars
 *  Array of Flow Variable Objects
 *
 * @returns {Array}
 *  Array of unique Flow Variable Objects
 */
export const mergeFlowVariables = (newVars, existingVars) => {
	// return complete existingVars if newVars is empty
	if (!newVars || newVars.length < 1) return existingVars;

	// return complete newVars if existingVars is empty
	if (!existingVars || existingVars.length < 1) return newVars;

	// filter out flow vars from existing vars that also exist
	// in new vars
	const result = existingVars.filter((flowVar) => {
		return !newVars.some((el) => {
			// match is made on name and type
			return flowVariablesMatch(el, flowVar);
		});
	});

	// concat new vars to result
	return result.concat(newVars);
};

/**
 * Calculates and returns the intersect of multiple arrays
 * of Flow Variables. Returns an array of Flow Variables that
 * exist in every given array. Match is made on name and type.
 *
 * @param {Array} vars
 *  Array of array of Flow Variable Objects
 *
 * @returns {Array}
 *  Array of unique Flow Variable Objects
 */
export const intersectFlowVariables = (vars = []) => {
	// return empty array if vars is empty
	if (!vars || !vars.length) return [];

	// return first array if only 1 array of flow vars is given
	if (vars.length === 1) return vars[0];
	
	// use first array of input vars as starting results
	let result = vars.shift();

	// loop over remaining arrays of input vars
	vars.forEach((arr) => {
		// filter result array by only keeping input vars
		// that also exist in this array
		result = result.filter((flowVar) => {
			return arr.some((el) => {
				// match is made on name and type
				const result = flowVariablesMatch(el, flowVar);

				if (result) {
					// keep highest maxlength value, if given
					setMaxLengthForMergedFlowVariables(flowVar, el);
				}

				return result;
			});
		});
	});

	return result;
};

const flowVariablesMatch = (var1, var2) => {
	return (
		var1 &&
		var2 &&
		var1.name?.toLowerCase() === var2.name?.toLowerCase() &&
		var1.type?.toLowerCase() === var2.type?.toLowerCase()
	);
}

const setMaxLengthForMergedFlowVariables = (var1, var2) => {
	if (typeof var1.validation.max === 'number' || typeof var2.validation.max === 'number') {
		var1.validation.max = Math.max(var1.validation.max || false, var2.validation.max || false);
	}
};