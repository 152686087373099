<template>
	<div class="is-flex">
		<VField
			:label="$t('fb.conditions.chooseField')"
			:isFloating="true"
			:class="{ 'is-active': filter.name }"
			class="has-margin-right-1"
		>
			<VSelect
				:options="fieldOptions"
				v-model="filter.name"
				@change="fieldUpdated"
			/>
		</VField>

		<VField
			:label="$t('fb.conditions.chooseOperator')"
			:isFloating="true"
			:class="{ 'is-active': filter.operator }"
			v-if="field"
		>
			<VSelect
				:options="operatorOptions"
				v-model="filter.operator"
				@change="filterUpdated"
			/>
		</VField>
	</div>

	<VField
		v-if="showRuleTypeChoice"
		:label="$t('fb.conditions.chooseType')"
		:isGrouped="true"
	>
		<VOption
			v-for="(item, key) in ruleTypeOptions"
			:isButton="true"
			:key="key"
			:value="item.value"
			:label="item.label"
			:disabled="item.disabled"
			v-model="filter.rule_type"
			@update:modelValue="ruleTypeUpdated"
			type="radio"
		/>
	</VField>

	<template v-if="showValueComponent">
		<div
			v-for="(item, key) in filter.values"
			class="is-flex is-align-items-center has-margin-bottom-1"
			:key="`${rerenderCounter}-${key}`"
		>
			<DateTimeDynamic
				v-if="valueComponent === 'DateTimeDynamic'"
				:label="valueLabel(key)"
				v-model:valueModel="filter.values[key]"
				@update:valueModel="() => valueUpdated()"
				:isRequired="false"
				v-bind="valueComponentArgs"
			/>
			<VInputSelect
				v-else-if="valueComponent === 'VInputSelect'"
				:label="valueLabel(key)"
				v-model:valueModel="filter.values[key].field"
				@update:valueModel="() => valueUpdated()"
				:isRequired="false"
				v-bind="valueComponentArgs"
			/>
			<Component
				v-else
				:is="valueComponent"
				:label="valueLabel(key)"
				v-model:valueModel="filter.values[key].value"
				@update:valueModel="() => valueUpdated()"
				:isRequired="false"
				v-bind="valueComponentArgs"
			/>

			<VButton
				v-if="showExtraValueComponent && key < filter.values.length - 1"
				:title="$t('general.delete')"
				:isTool="true"
				icon="delete"
				tabindex="-1"
				@click.prevent.stop="deleteValue(key)"
				class="has-margin-left-1"
			/>
		</div>
	</template>
</template>

<script>
import Helpers from '@assets/scripts/helpers';
import Field from '@assets/scripts/components/field';
import {
	operators,
	availableTypesForCondition,
	FILTER_TYPE_STATIC,
	FILTER_TYPE_DYNAMIC,
} from '@modules/FlowBuilder/components/filtering';
import { filterMeta } from '@modules/FlowBuilder/endpoints';
import { mapGetters } from 'vuex';
import { getStoreGetter } from '@assets/scripts/store/config';

import DateTimeDynamic from '@modules/FlowBuilder/materials/components/DateTimeDynamic.vue';

export default {
	name: 'ConditionEditor',
	components: {
		DateTimeDynamic,
	},
	props: {
		/**
		 * The current config of the filter
		 */
		modelValue: {
			type: Object,
			required: true,
			default: () => Helpers.obj.create(filterMeta, {}),
		},
		/**
		 * The fields that can be filtered on
		 */
		fields: {
			type: Array,
			required: true,
			default: () => [],
		},
		/**
		 * The fields that can be used in comparisons
		 */
		dynamic: {
			type: Array,
			required: false,
			default: () => [],
		},
	},
	data() {
		return {
			filter: Helpers.cloneObject(this.modelValue),
			rerenderCounter: 0,
		};
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate if flow is in Edit mode
			 */
			editMode: getStoreGetter('EDIT_MODE', 'FLOW'),
		}),
		fieldOptions: function () {
			if (!this.fields || Field.filterOutParentFields(this.fields).length < 1) return [];

			const options = [];

			Field.filterOutParentFields(this.fields).forEach((row) => {
				// only show fields of type that have condition operators available
				if (availableTypesForCondition.has(Field.getVarType(row.type))) {
					options.push({
						key: row.name,
						name: Field.getNameAsPath(row.name),
					});
				}
			});

			// map available fields into list for VSelect
			return Helpers.mapObjectArrayForSelect(options, {
				key: 'key',
				text: 'name',
			});
		},
		compareFieldOptions() {
			if (this.filter.type) {
				const result = [];
				const extraOptions = [];
				const filterType = Field.getVarType(this.filter.type);
				const isDateOnly = filterType === 'DATE';
				const isDateTime = filterType === 'DATETIME';

				if (isDateOnly || isDateTime) {
					// Date and DateTime fields can also be compared to 'NOW' a.o., which
					// is interpreted as the time of execution of the flow
					if (isDateTime) {
						extraOptions.push({
							key: '$now',
							name: this.$t('datepicker.dynamicOptions.$now'),
						});
					} else {
						extraOptions.push({
							key: '$today',
							name: this.$t('datepicker.dynamicOptions.$today'),
						});
					}

					['$month', '$year'].forEach((val) => {
						extraOptions.push({
							key: val,
							name: this.$t('datepicker.dynamicOptions.' + val.toLowerCase()),
						});
					});
				}

				this.dynamic.forEach((field) => {
					if (Field.fieldCanBeComparedToType(this.field, field.type)) {
						result.push({
							key: field.name,
							name: Field.getNameAsPath(field.name),
						});
					}
				});

				// map fields into list for VSelect, with extra options at the top of the list
				return Helpers.mapObjectArrayForSelect(extraOptions, {
					key: 'key',
					text: 'name',
				}).concat(Helpers.mapObjectArrayForSelect(result, {
					key: 'key',
					text: 'name',
				}));
			}

			return false;
		},
		dynamicOptionAvailable() {
			return (
				this.compareFieldOptions &&
				this.compareFieldOptions.length > 0 &&
				!['max_length', 'min_length'].includes(this.filter.operator)
			);
		},
		ruleTypeOptions() {
			return [
				{
					label: this.$t('fb.conditions.static'),
					value: FILTER_TYPE_STATIC,
					disabled: false,
				},
				{
					label: this.$t('fb.conditions.dynamic'),
					value: FILTER_TYPE_DYNAMIC,
					disabled: !this.dynamicOptionAvailable,
				},
			];
		},
		// returns currently selected field
		field() {
			return (
				this.fields.find((field) => field.name === this.filter.name) ||
				false
			);
		},
		// returns type of currently selected field
		fieldType() {
			if (!this.field) return false;
			return Field.getVarType(this.field.type);
		},
		operator() {
			return (
				operators.find(
					(operator) => operator.value === this.filter.operator
				) || false
			);
		},
		operatorsAvailable() {
			return operators.filter((operator) =>
				operator.types.includes(this.fieldType)
			);
		},
		operatorOptions() {
			// map available actions into list for VSelect
			return Helpers.mapObjectArrayForSelect(this.operatorsAvailable, {
				key: 'value',
				text: 'name',
			});
		},
		showRuleTypeChoice() {
			return this.operator && this.operator.inputCount !== 0;
		},
		showValueComponent() {
			return !!this.valueComponent && !!this.filter.rule_type;
		},
		valueComponent() {
			if (
				!this.filter.type ||
				!this.showRuleTypeChoice
			)
				return false;

			let result = false;

			if (this.filter.rule_type === FILTER_TYPE_DYNAMIC) {
				if (['DATE', 'DATETIME'].includes(this.fieldType)) {
					// dynamic date(time) fields
					result = 'DateTimeDynamic';
				} else {
					// other dynamic values use VInputSelect
					result = 'VInputSelect';
				}
			} else if (['max_length', 'min_length'].includes(this.filter.operator)) {
				// operators max/min length should always use integer > 0
				result = 'VInputInteger';
			} else {
				switch (this.fieldType) {
					case 'NUMBER':
					case 'LONGNUMBER':
						result = 'VInputInteger';
						break;
					case 'STRING':
					case 'TEXT':
					case 'GUID':
						result = 'VInputString';
						break;
					case 'DECIMAL':
						result = 'VInputDecimal';
						break;
					case 'DATE':
					case 'DATETIME':
						result = 'VInputDate';
						break;
				}
			}

			return result;
		},
		valueComponentArgs() {
			if (
				!this.filter.type ||
				!this.showRuleTypeChoice
			)
				return false;

			// dynamic values use VInputSelect with compareFieldOptions
			// as options
			if (this.filter.rule_type === FILTER_TYPE_DYNAMIC) {
				return { options: this.compareFieldOptions };
			} else if (['max_length', 'min_length'].includes(this.filter.operator)) {
				// operators max/min length should always use integer > 0
				return { min: 1 };
			} else if (this.fieldType === 'DATETIME') {
				return { setTimeInOutput: true };
			} else {
				return false;
			}
		},
		showExtraValueComponent() {
			return this.operator && this.operator.inputCount === -1;
		},
	},
	mounted() {
		this.valueUpdated(true);

		// check if input count for current operator is higher
		// than current configured values
		if (
			this.operator &&
			this.operator.inputCount > this.filter.values.length
		) {
			// add as many empty inputs as needed
			for (
				let i = 0;
				this.operator.inputCount > this.filter.values.length;
				i++
			) {
				this.addNewValueInput();
			}
		}
	},
	methods: {
		filterUpdated() {
			this.$emit('update:modelValue', this.filter);
		},
		fieldUpdated() {
			// set correct field type
			this.filter.type = this.field ? this.field.type : false;

			// unset operator if current operator is not available
			// for newly selected field
			if (
				this.filter.operator &&
				(this.operatorsAvailable.length < 1 ||
					!this.operatorsAvailable.includes(this.operator))
			) {
				this.filter.operator = null;
			}

			this.unsetValueInputs();
			this.filterUpdated();
		},
		ruleTypeUpdated() {
			this.unsetValueInputs();
			this.filterUpdated();
		},
		lastValueEmpty() {
			if (!this.filter.values || this.filter.values?.length < 1) return true;
			
			const lastValue = this.filter.values[this.filter.values.length - 1];

			if (
				(this.filter.rule_type === FILTER_TYPE_STATIC && !lastValue.value) ||
				(this.filter.rule_type === FILTER_TYPE_DYNAMIC && !lastValue.field)
			) {
				return true;
			}

			return false;
		},
		valueUpdated(initial = false) {
			if (
				this.showExtraValueComponent &&
				!this.lastValueEmpty()
			) {
				this.addNewValueInput();
			}

			if (!initial) this.filterUpdated();
		},
		valueLabel(key) {
			if (!this.operator) return false;

			const pluralization =
				this.operator.inputCount === -1 ? 2 : this.operator.inputCount;

			return this.$t('fb.conditions.valueLabel', pluralization, {
				index: key + 1,
			});
		},
		unsetValueInputs() {
			this.rerenderCounter++;
			this.filter.values = [];

			if (this.operator) {
				switch (this.operator.inputCount) {
					case 0:
						this.filter.rule_type = FILTER_TYPE_STATIC;
						break;
					case -1:
						this.addNewValueInput();
						break;
					default:
						for (let i = 1; i <= this.operator.inputCount; i++) {
							this.addNewValueInput();
						}
						break;
				}
			}
		},
		addNewValueInput() {
			this.filter.values.push({});
		},
		deleteValue(key) {
			this.filter.values.splice(key, 1);
			this.rerenderCounter++;
			this.valueUpdated();
		},
	},
	watch: {
		operator() {
			this.unsetValueInputs();
		},
		dynamicOptionAvailable(newVal) {
			if (!newVal) this.filter.rule_type = FILTER_TYPE_STATIC;
		},
	},
};
</script>
