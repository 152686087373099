import i18n from '@assets/i18n';
const TheScriptBuilder = () => import('@modules/ScriptBuilder/materials/TheScriptBuilder.vue');

// translate function of vue-i18n
const { t } = i18n.global;

export default [
	{
		path: '/scriptbuilder',
		component: TheScriptBuilder,
		name: 'scriptbuilder',
		meta: {
			title: t('sb.pageTitle'),
			permission: 'Read',
			showInSideNav: true,
			showInHome: true,
			icon: 'chevron-left',
			parent: 'admin',
			module: 'ScriptBuilder',
			order: 50,
		},
	},
];