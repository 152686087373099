import Validation from '@assets/scripts/components/validationChecks';
import Helpers from '@assets/scripts/helpers';
import Field from '@assets/scripts/components/field';
import i18n from '@assets/i18n';
import { flattenActionFields } from '@modules/FlowBuilder/components/actions';
import { operators, FILTER_TYPE_DYNAMIC } from '@modules/FlowBuilder/components/filtering';
import { store } from '@assets/scripts/components/store-proxy';
import { getStoreGetter } from '@assets/scripts/store/config';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { GET_ACTION_BY_ID } from '@modules/FlowBuilder/endpoints';

// translate function of vue-i18n
const { t } = i18n.global;

/**
 * Validate read block and returns erros array
 * for a given block
 *
 * @param {Object} block
 *  Block to get errors
 *
 * @returns {Array}
 *	array of errors
 */
export const validateReadBlock = async (block) => {
	let output = [];

	const setBlockError = (description) => {
		output.push(Helpers.createErrorObject(description, block.guid));
	};

	// get all block inputs
	const blockInputs = store.getters[getStoreGetter('BLOCK_INPUT', 'BLOCKS')](block.guid);

	if (!Validation.stringNotEmpty(block.config.guid)) {
		setBlockError(
			t('fb.flowErrors.actionNotChosen', {
				block: t('fb.blockTypes.read'),
			})
		);
	} else {
		const action = await useApiAsync(GET_ACTION_BY_ID, {
			keys: {
				guid: block.config.guid,
			}
		});

		if (!Validation.isNonEmptyObject(action)) {
			setBlockError(
				t('fb.flowErrors.actionNotExist', {
					block: t('fb.blockTypes.read'),
					action: `${block.config.name} - ${block.config.method}`,
				})
			);
		} else {
			// flatten input and output fields of action
			flattenActionFields(action);

			// check if atleast one key filed is mapped
			if (!Validation.keyFieldMapped(action, block.config.mapping) && action.type !== 'ConnectionMethod') {
				setBlockError(
					t('fb.flowErrors.noMappingByKeyField', {
						block: t('fb.blockTypes.read'),
					})
				);
			}

			block.config.mapping.forEach((mappingObject) => {
				const { fromField, toField } = Helpers.getFromToFields(
					blockInputs,
					mappingObject,
					action
				);

				// check if mapped field exist in the flow vars
				if (!Validation.mappedFieldExists(mappingObject, blockInputs)) {
					setBlockError(
						t('fb.flowErrors.actionMappingInputNotExist', {
							mappingObject: Field.getNameAsPath(
								mappingObject.from
							),
							action: `${block.config.name} - ${block.config.method}`,
							block: t('fb.blockTypes.read'),
						})
					);
				}

				// check if block input field type match the action input field type
				if (!Validation.fieldTypesCanBeMapped(fromField, toField)) {
					setBlockError(
						t('fb.flowErrors.typeNotMatchInAction', {
							mappingObject: Field.getNameAsPath(
								mappingObject.to
							),
							block: t('fb.blockTypes.read'),
							action: `${block.config.name} - ${block.config.method}`,
						})
					);
				}

				// check if the mapping of a required action field is
				// to a flow field that is also required
				if (toField && toField.validation.required && fromField) {
					if (!Validation.bothFieldsRequired(toField, fromField)) {
						setBlockError(
							t('fb.flowErrors.requiredFieldCanNotBeMappedToNonRequiredField', {
								fromField: Field.getNameAsPath(fromField.name),
								toField: Field.getNameAsPath(toField.name),
								block: t('fb.blockTypes.read'),
							})
						);
					}
				}

				// check if block input max length match the action input max length
				if (fromField) {
					if (
						!Validation.fieldMaxLengthsCanBeMapped(fromField, toField)
					) {
						setBlockError(
							t('fb.flowErrors.maxLengthNotMatchInAction', {
								fromField: Field.getNameAsPath(fromField.name),
								fromFieldMaxLength: fromField.validation.max || t('general.none'),
								toField: Field.getNameAsPath(toField.name),
								toFieldMaxLength: toField.validation.max,
								block: t('fb.blockTypes.read'),
							})
						);
					}
				}
			});
			if (block.config.filters.length > 0) {
				block.config.filters.map((filter) => {
					if (!Validation.isNonEmptyArray(filter.rules)) {
						setBlockError(
							t('fb.flowErrors.noCondition', {
								block: t('fb.blockTypes.read'),
							})
						);
					} else {
						filter.rules.map((condition) => {
							// check if used field in condition exists in action
							// and is marked as Filter field
							if (
								!Validation.fieldInConditionExists(
									condition,
									action.output.filter((f) => f.filter)
								)
							) {
								setBlockError(
									t(
										'fb.flowErrors.fieldInConditionNotExistInAction',
										{
											fieldInCondition:
												Field.getNameAsPath(
													condition.name
												),
											block: t('fb.blockTypes.read'),
										}
									)
								);
							}

							// check if a filter condition has an operator
							if (!Validation.conditionHasOperator(condition)) {
								setBlockError(
									t('fb.flowErrors.noOperatorChosen', {
										fieldName: Field.getNameAsPath(
											condition.name
										),
										block: t('fb.blockTypes.read'),
									})
								);
							} else {
								// check if a filter condition has a right operator
								if (
									!Validation.conditionHasCorrectOperator(
										condition,
										operators
									)
								) {
									setBlockError(
										t('fb.flowErrors.wrongOperatorChosen', {
											fieldName: Field.getNameAsPath(
												condition.name
											),
											block: t('fb.blockTypes.read'),
										})
									);
								}

								// check if operator has correct amount of values
								if (
									!Validation.conditionHasCorrectAmountOfValues(
										condition,
										operators
									)
								) {
									setBlockError(
										t(
											'fb.flowErrors.wrongValueAmountForOperator',
											{
												fieldName: Field.getNameAsPath(
													condition.name
												),
												operator: condition.operator,
												block: t('fb.blockTypes.read'),
											}
										)
									);
								}

								//check if dynamic value exist in flow var
								if (condition.rule_type === FILTER_TYPE_DYNAMIC) {
									const filterType = Field.getVarType(
										condition.type
									);
									const isDateOrTime = [
										'DATE',
										'DATETIME',
									].includes(filterType);
									if (isDateOrTime) {
										//check value exist
										condition.value.forEach((value) => {
											const mainValue =
												value.split(' ')[0];
											// checking if the first part of the value has [ and ] so yes it's then a flow var and we can check if it exist in the flow, example [Contact.BirthDate] -5 HOUR
											if (
												mainValue.includes('[') &&
												mainValue.includes(']')
											) {
												let fieldValue =
													mainValue.replace('[', '');
												fieldValue = fieldValue.replace(
													']',
													''
												);
												// check if value field in condition exist in the flow vars
												if (
													!Validation.valueFieldInConditionExists(
														fieldValue,
														blockInputs
													)
												) {
													setBlockError(
														t(
															'fb.flowErrors.fieldValueInConditionNotExist',
															{
																fieldInCondition:
																	Field.getNameAsPath(
																		fieldValue
																	),
																block: t(
																	'fb.blockTypes.read'
																),
															}
														)
													);
												} else {
													const fieldValueInFlowVar =
														blockInputs.find(
															(input) =>
																input.name ===
																fieldValue
														);
													// check if condition input type match the value input type
													if (
														!Validation.mappedFieldTypesMatch(
															fieldValueInFlowVar,
															condition
														)
													) {
														setBlockError(
															t(
																'fb.flowErrors.typeNotMatchInCondition',
																{
																	fieldName:
																		Field.getNameAsPath(
																			condition.name
																		),
																	fieldValue:
																		Field.getNameAsPath(
																			fieldValue
																		),
																	block: t(
																		'fb.blockTypes.read'
																	),
																}
															)
														);
													}
												}
											}
										});
									} else {
										condition.value.forEach((value) => {
											// check if value field in condition exist in the flow vars
											if (
												!Validation.valueFieldInConditionExists(
													value,
													blockInputs
												)
											) {
												setBlockError(
													t(
														'fb.flowErrors.fieldValueInConditionNotExist',
														{
															fieldInCondition:
																Field.getNameAsPath(
																	value
																),
															block: t(
																'fb.blockTypes.read'
															),
														}
													)
												);
											} else {
												const fieldValueInFlowVar =
													blockInputs.find(
														(input) =>
															input.name === value
													);
												// check if condition input type match the value input type
												if (
													!Validation.mappedFieldTypesMatch(
														fieldValueInFlowVar,
														condition
													)
												) {
													setBlockError(
														t(
															'fb.flowErrors.typeNotMatchInCondition',
															{
																fieldName:
																	Field.getNameAsPath(
																		condition.name
																	),
																fieldValue:
																	Field.getNameAsPath(
																		value
																	),
																block: t(
																	'fb.blockTypes.read'
																),
															}
														)
													);
												}
											}
										});
									}
								} else {
									if (condition.operator === 'between') {
										// check if operator between value 1 is gretter than value 2
										if (
											!Validation.conditionValue2IsGreaterThanValue1(
												condition
											)
										) {
											setBlockError(
												t(
													'fb.flowErrors.wrongValueOrderForOperatorBetween',
													{
														fieldName:
															Field.getNameAsPath(
																condition.name
															),
														operator:
															condition.operator,
														block: t(
															'fb.blockTypes.read'
														),
													}
												)
											);
										}
									}
								}
							}
						});
					}
				});
			}

			if (block.config.sorting.length > 0) {
				block.config.sorting.map((sortObj) => {
					// check if soorting is ASC or DESC
					if (!Validation.isAscOrDesc(sortObj)) {
						setBlockError(
							t('fb.flowErrors.noSortDirection', {
								fieldName: Field.getNameAsPath(sortObj.field),
								block: t('fb.blockTypes.read'),
							})
						);
					}
				});
			}

			// validate output, with aliases
			if (block.config.output.length > 0) {
				const fieldAliases = Field.setFullAliases(block.config.output);

				fieldAliases.forEach((fieldAlias) => {
					//check if field exists in action output
					if (!Validation.fieldExistsInList(fieldAlias.name, action.output)) {
						setBlockError(
							t(
								'fb.flowErrors.fieldDoesNotExistInAction',
								{
									fieldName: Field.getNameAsPath(
										fieldAlias.name
									),
									block: t('fb.blockTypes.read'),
								}
							)
						);
					// check if alias is not empty
					} else if (!fieldAlias.alias) {
						setBlockError(
							t(
								'fb.flowErrors.aliasNotEntered',
								{
									fieldName: Field.getNameAsPath(
										fieldAlias.name
									),
									block: t('fb.blockTypes.read'),
								}
							)
						);
					} else {
						// count the occurences of the alias
						const count = fieldAliases.reduce((prev, curr) => {
							if (curr.alias.toLowerCase() === fieldAlias.alias.toLowerCase()) return prev + 1;
							else return prev;
						}, 0);

						// throw error if alias occurs more than 1 time
						if (count > 1) {
							setBlockError(
								t(
									'fb.flowErrors.aliasNotUnique',
									{
										alias: fieldAlias.alias,
										block: t('fb.blockTypes.read'),
									}
								)
							);
						} else {
							// check if given name (alias) already exists in flow variables
							const fieldExists = blockInputs.some((inputField) => {
								return inputField.name.toLowerCase() === fieldAlias.alias.toLowerCase();
							});

							if (fieldExists) {
								setBlockError(
									t(
										'fb.flowErrors.duplicatedNameInFlowVars',
										{
											fieldName: Field.getNameAsPath(fieldAlias.alias),
											block: t('fb.blockTypes.read'),
										}
									)
								);
							}
						}
					}
				});
			} else if (typeof action.output === 'object' && action.output?.length > 0) {
				// throw error if no fields are chosen for output, while chosen action does
				// output fields and not Full output
				setBlockError(
					t(
						'fb.flowErrors.noOutputFieldsChosen',
						{
							block: t('fb.blockTypes.read'),
						}
					)
				);
			}
		}
	}
	return output;
};
