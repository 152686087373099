<template>
	<FieldConfigMain
		:fields="fields"
		:fieldKey="fieldKey"
		v-model:valueModel="field"
		@update:valueModel="fieldUpdated"
		v-model:nameValid="nameValid"
		:editMode="editMode"
		:parents="fieldParents"
		:context="context"
	/>

	<template v-if="showConfigOptions">
		<div class="has-padding-top-1 has-padding-bottom-1">
			<VTitle :size="4" :text="$t('fieldDetailsEditor.fieldSettings')" />

			<ConfigOptions
				:options="configOptions"
				:disabled="!editMode"
				v-model:valueModel="configModel"
				@update:valueModel="configChanged"
			/>
		</div>
	</template>

	<template v-if="showFieldDescription">
		<div class="has-padding-top-1 has-padding-bottom-1">
			<VTitle :size="4" :text="$t('fieldDetailsEditor.fieldDescription')" />
			
			<VTextarea
				:disabled="!editMode"
				v-model="field.description"
				@change="fieldUpdated"
				:is-narrow="true"
			/>
		</div>
	</template>

	<template v-if="showMetaDocumentChoice">
		<div class="has-margin-top-4">
			<VTitle :size="4" :text="$t('fieldDetailsEditor.metaDocumentTitle')" />
			
			<VField
				:label="$t('fieldDetailsEditor.metaDocument')"
				:isFloating="true"
				class="has-vue-select"
				:class="{ 'is-active': field.document }"
			>
				<VSelectWithSearch
					v-model:modelValue="field.document"
					:options="metaDocumentOptions"
					@update:modelValue="() => fieldUpdated()"
					:disabled="!editMode"
					:placeholder="field.document || ''"
					:searchPlaceholder="$t('vueSelect.placeholder')"
				/>
			</VField>
		</div>
	</template>

	<template v-if="showElementsTypeChoice">
		<div class="has-margin-top-4">
			<VTitle :size="4" :text="$t('fieldDetailsEditor.elementsTypeTitle')" />

			<VField
				:label="$t('fieldDetailsEditor.elementsType')"
				:isFloating="true"
				:class="{ 'is-active': field.arrayElementType }"
			>
				<VSelect
					:options="elementTypeOptions"
					v-model="field.arrayElementType"
					@change="fieldUpdated"
					:disabled="!editMode"
				/>
			</VField>
		</div>
	</template>

	<template v-if="showMakeRequiredChoice && !isMethodOutput">
		<div class="drawer-config-item">
			<VOptionBoolean
				v-model:modelValue="field.validation.required"
				:isButton="true"
				:isGrouped="true"
				:trueLabel="$t('general.yes')"
				:falseLabel="$t('general.no')"
				@update:modelValue="fieldUpdated"
				:isDisabled="!(editMode && requiredAllowed)"
			/>
			<VTitle :size="4" :text="$t('fieldDetailsEditor.isFieldRequired')" />
		</div>
	</template>

	<template v-if="showDefaultValueChoice && !isMethodOutput">
		<div class="drawer-config-item">
			<VOptionBoolean
				v-model:modelValue="useDefault"
				:isButton="true"
				:isGrouped="true"
				:trueLabel="$t('general.yes')"
				:falseLabel="$t('general.no')"
				@update:modelValue="fieldUpdated"
				:isDisabled="!editMode"
			/>
			<VTitle :size="4" :text="$t('fieldDetailsEditor.addDefaultValue')" />
		</div>

		<VField
			v-if="useDefault"
			:label="$t('fieldDetailsEditor.defaultValueType')"
			:isFloating="true"
			:class="[{ 'is-active': field.default.type }, 'has-margin-left-8']"
		>
			<VSelect
				:options="defaultValueTypeOptions"
				v-model="field.default.type"
				@change="fieldUpdated"
				:disabled="!editMode"
			/>
		</VField>

		<Component
			v-if="defaultValueComponent"
			:is="defaultValueComponent"
			v-model:valueModel="field.default.value"
			@update:valueModel="fieldUpdated"
			v-model:validModel="defaultValueValid"
			:isRequired="true"
			:isDisabled="!editMode"
			classes="has-margin-left-8"
		/>

		<template v-if="useDefault && showDefaultConfigOptions">
			<VTitle :size="4" :text="$t('fieldDetailsEditor.whenToUseDefaultValue')" class="has-margin-left-8 has-margin-bottom-05" />

			<ConfigOptions 
				:options="defaultConfigOptions"
				:disabled="!editMode"
				v-model:valueModel="defaultConfigModel"
				@update:valueModel="defaultConfigChanged"
				class="has-margin-left-8"
			/>
		</template>
	</template>

	<template v-if="isMethodOutput ? field.type.toLowerCase() === 'string' : showValidationChoice">
		<div class="drawer-config-item">
			<VOptionBoolean
				v-model:modelValue="useValidation"
				:isButton="true"
				:isGrouped="true"
				:trueLabel="$t('general.yes')"
				:falseLabel="$t('general.no')"
				@update:modelValue="fieldUpdated"
				:isDisabled="!editMode"
			/>
			<VTitle
				:size="4"
				:text="$t('fieldDetailsEditor.useValidationQuestion')"
			/>
		</div>
		<template v-if="useValidation">
			<template v-if="toolValidationAvailable && !isMethodOutput">
				<VOption
					v-model:modelValue="useFunctionList"
					type="checkbox"
					:label="$t('fieldDetailsEditor.useTool')"
					@update:modelValue="fieldUpdated"
					:disabled="!editMode"
					wrapperClasses="has-margin-left-8"
				/>

				<div
					class="content align-with-checkbox-label has-margin-left-8"
					v-if="useFunctionList"
				>
					<VTag
						v-if="selectedFunctionListName"
						:text="selectedFunctionListName"
						:isActive="true"
						@deleted="toolDeleted"
						@click="openFunctionListFieldPickerDrawer"
						:isDisabled="!editMode"
					/>
					<VButton
						v-else
						:text="$t('fieldDetailsEditor.selectTool')"
						variant="is-secondary"
						@clicked.prevent.stop="openFunctionListDrawer"
						:disabled="!editMode"
					/>
				</div>
			</template>

			<template v-if="referenceListValidationAvailable && !isMethodOutput">
				<VOption
					v-model:modelValue="useReferenceList"
					type="checkbox"
					:label="$t('fieldDetailsEditor.useReferenceList')"
					@update:modelValue="fieldUpdated"
					:disabled="!editMode || useRegex"
					wrapperClasses="has-margin-left-8"
				/>

				<div
					class="content align-with-checkbox-label has-margin-left-8"
					v-if="useReferenceList"
				>
					<VField
						:label="$t('fieldDetailsEditor.referenceList')"
						:isFloating="true"
						:class="{ 'is-active': field.validation.regex }"
					>
						<VSelect
							:options="referenceListOptions"
							v-model="field.validation.regex"
							@change="fieldUpdated"
							:disabled="!editMode"
						/>
					</VField>
				</div>
			</template>

			<template v-if="foreignReferenceListValidationAvailable && !isMethodOutput">
				<VOption
					v-model:modelValue="useForeignReferenceList"
					type="checkbox"
					:label="$t('fieldDetailsEditor.useForeignReferenceList')"
					@update:modelValue="fieldUpdated"
					:disabled="!editMode"
					wrapperClasses="has-margin-left-8"
				/>

				<div
					class="content align-with-checkbox-label has-margin-left-8"
					v-if="useForeignReferenceList"
				>
					<VField
						:label="$t('fieldDetailsEditor.foreignReferenceList')"
						:isFloating="true"
						:class="{ 'is-active': field.validation.regex }"
					>
						<VSelect
							:options="foreignReferenceListOptions"
							v-model="field.validation.regex"
							@change="fieldUpdated"
							:disabled="!editMode"
						/>
					</VField>
				</div>
			</template>

			<template v-if="isMethodOutput ? field.type.toLowerCase() === 'string' : maxLengthValidationAvailable">
				<VOption
					v-model:modelValue="useMaxLength"
					type="checkbox"
					:label="$t('fieldDetailsEditor.useMaxLength')"
					@update:modelValue="fieldUpdated"
					:disabled="!editMode"
					wrapperClasses="has-margin-left-8"
				/>

				<div
					class="content align-with-checkbox-label has-margin-left-8"
					v-if="useMaxLength"
				>
					<VInputInteger
						v-model:valueModel="field.validation.max"
						@update:valueModel="fieldUpdated"
						v-model:validModel="maxLengthValid"
						:isRequired="true"
						:min="1"
						:label="$t('validation.maxlength')"
						:isDisabled="!editMode"
					/>
				</div>
			</template>

			<template v-if="regexValidationAvailable && !isMethodOutput">
				<VOption
					v-model:modelValue="useRegex"
					type="checkbox"
					:label="$t('fieldDetailsEditor.useRegex')"
					@update:modelValue="fieldUpdated"
					:disabled="!editMode || useReferenceList"
					wrapperClasses="has-margin-left-8"
				/>
				<div
					v-if="useRegex"
					class="content align-with-checkbox-label has-margin-left-8"
				>
					<VField
						:isFloating="true"
						:label="$t('validation.regex')"
						:class="{
							'is-active': field.validation.regex || false,
						}"
						:isError="!regexValid"
					>
						<VInput
							v-model="field.validation.regex"
							:placeholder="$t('validation.regex')"
							type="text"
							@input="fieldUpdated"
							:disabled="!editMode"
						/>
					</VField>
				</div>
			</template>
		</template>
	</template>
</template>

<script>
import { reactive, toRefs } from '@vue/reactivity';
import { useI18n } from 'vue-i18n';
import {
	getStoreMutation,
} from '@assets/scripts/store/config';
import {
	functionListAvailableForType,
	setFunctionListAsRef
} from '@assets/scripts/components/function-lists';
import {
	staticValue,
} from '@assets/scripts/api/config';
import Helpers from '@assets/scripts/helpers';
import Field from '@assets/scripts/components/field';
import { useApiAsync } from '@assets/scripts/composables/useApi';

import { GET_LIST_BY_NAME, GET_FUNCTION_LIST_BY_ID } from '@assets/scripts/api/config';
import {
	GET_METADATA_DOCUMENTS,
	GET_REFERENCE_LISTS,
	GET_FOREIGN_REFERENCE_LIST,
} from '@modules/MetaDocumentBuilder/endpoints';

export default {
	name: 'FieldDetailsEditor',
	props: {
		editMode: {
			type: Boolean,
			default: false,
		},
		fields: {
			type: Array,
			default: () => [],
		},
		valueModel: {
			type: Object,
			required: true,
			default: () => {},
		},
		validModel: {
			type: Boolean,
			default: false,
		},
		fieldKey: {
			type: [Number, Boolean],
			default: false,
		},
		fieldMeta: {
			type: Object,
			default: () => {},
		},
		configOptions: {
			type: [Boolean, Array],
			default: false,
		},
		defaultConfigOptions: {
			type: [Array],
			default: () => [],
		},
		connGuid: {
			type: [String, Boolean],
			default: false,
		},
		fieldParents: {
			type: [Array, Boolean],
			default: () => [],
		},
		context: {
			type: String,
			required: false,
			default: '',
		},
		requiredAllowed: {
			type: Boolean,
			required: false,
			default: true
		},
		isMethodOutput: {
			type: Boolean,
			required: false,
			default: false
		},
		showFieldDescription: {
			type: Boolean,
			required: false,
			default: false
		},
	},
	data: function () {
		return {
			nameValid: true,
			defaultValueValid: false,
			maxLengthValid: false,
		};
	},
	computed: {
		isValid: function () {
			return this.nameValid;
		},
		showConfigOptions: function () {
			return typeof this.configOptions === 'object' && this.nameValid;
		},
		showDefaultConfigOptions: function () {
			return this.defaultConfigOptions.length > 0 && this.nameValid;
		},
		showMakeRequiredChoice: function () {
			return this.nameValid && this.field.type.length > 0;
		},
		showMetaDocumentChoice: function () {
			return (
				this.nameValid &&
				['DOCUMENTTYPE', 'DOCUMENTARRAY'].includes(
					Field.getVarType(this.field.type)
				)
			);
		},
		showElementsTypeChoice: function () {
			return (
				this.nameValid &&
				['BASICTYPEARRAY'].includes(Field.getVarType(this.field.type))
			);
		},
		showDefaultValueChoice: function () {
			return (
				this.showMakeRequiredChoice &&
				this.defaultValueTypeOptions.length > 0 &&
				// no default value options in DocBuilder for Key or ID fields
				!(
					this.context === 'document' &&
					(this.valueModel.is_key || this.valueModel.is_id)
				)
			);
		},
		defaultValueTypeOptions: function () {
			// check if field type is chosen and default value
			// type options have been loaded
			if (
				this.field.type.length < 1 ||
				typeof this.defaultValueTypes !== 'object' ||
				this.defaultValueTypes.length < 1
			)
				return [];

			// filter default value types for options applicable for
			// chosen field type
			const options = this.defaultValueTypes.filter((row) => {
				return row.type
					.map((v) => v.toLowerCase())
					.includes(this.field.type.toLowerCase());
			});

			// format options to use in VSelect component
			if (options.length > 0) {
				return Helpers.mapObjectArrayForSelect(options, {
					key: 'key',
					text: 'value',
				});
			}

			return [];
		},
		defaultValueComponent: function () {
			if (
				!this.useDefault ||
				!this.showDefaultValueChoice ||
				this.field.default.type !== staticValue
			)
				return false;

			return this.field.type.toLowerCase() === 'password'
				? 'StringDefaultValue'
				: this.field.type + 'DefaultValue';
		},
		maxLengthValidationAvailable: function () {
			// max length validation is only available
			// for String and password value
			return this.field.type.toLowerCase() === 'string' || this.field.type.toLowerCase() === 'password';
		},
		regexValidationAvailable: function () {
			// validation with regex is only available
			// for String and password value
			return typeof this.field.type === 'string' && (this.field.type.toLowerCase() === 'string' || this.field.type.toLowerCase() === 'password');
		},
		regexValid: function () {
			return this.field.validation.regex !== '';
		},
		toolValidationAvailable: function () {
			// validation using a tool (Function List) is
			// available for all types except Object, Array, BasicTypeArray & KeyValue
			if (
				['ARRAY', 'OBJECT', 'KEYVALUE', 'BASICTYPEARRAY'].includes(
					Field.getVarType(this.field.type)
				)
			) {
				return false;
			}

			// additionally, in the MetaDoc builder, Function Lists are not available
			// for String, GUID & Password
			if (
				this.context === 'meta' &&
				['STRING', 'GUID', 'PASSWORD'].includes(Field.getVarType(this.field.type))
			) {
				return false;
			}

			// do not allow tool validation ever in Document Builder
			if (this.context === 'document') return false;

			return true;
		},
		functionListFieldPickerAvailable: function () {
			return this.context === 'flow|start';
		},
		referenceListValidationAvailable: function () {
			// validation using a referenceList is
			// available for STRING and PASSWORD field type in metadata document
			if (
				['STRING', 'PASSWORD'].includes(Field.getVarType(this.field.type)) &&
				this.context === 'meta'
			) {
				return true;
			}
			return false;
		},
		foreignReferenceListValidationAvailable: function () {
			// validation using a foreignReferenceList is
			// available for GUID field type in metadata document
			if (
				['GUID'].includes(Field.getVarType(this.field.type)) &&
				this.context === 'meta'
			) {
				return true;
			}
			return false;
		},
		validationAvailable: function () {
			// more validation options can be added here in future
			return (
				this.maxLengthValidationAvailable ||
				this.regexValidationAvailable ||
				this.toolValidationAvailable ||
				this.foreignReferenceListValidationAvailable ||
				this.referenceListValidationAvailable
			);
		},
		showValidationChoice: function () {
			return (
				this.showMakeRequiredChoice &&
				this.validationAvailable &&
				!this.showMetaDocumentChoice
			);
		},
	},
	setup: async function (props) {
		const { t } = useI18n();

		const state = reactive({
			field: Helpers.cloneObject(props.valueModel),
			selectedFunctionListName: '',
			useDefault: false,
			useValidation: false,
			useMaxLength: false,
			useRegex: false,
			useFunctionList: false,
			useReferenceList: false,
			useForeignReferenceList: false,
			defaultValueTypes: [],
			configModel: {},
			defaultConfigModel: {},
			metadataDocuments: [],
			metaDocumentOptions: [],
			elementTypeOptions: [],
			referenceListOptions: [],
			foreignReferenceListOptions: [],
		});

		const setTool = async (
			guid = false,
			fieldName = false,
			aliases = []
		) => {
			let list = false;
			let listName = '';

			if (guid) {
				// load selected Function List
				list = await useApiAsync(GET_FUNCTION_LIST_BY_ID, {
					keys: {
						guid,
					},
				});

				if (list) listName = list.name;
			}

			// set or unset ref to list in field
			setFunctionListAsRef(list, state.field, fieldName, aliases);

			// set name for display purposes
			if (listName && fieldName) {
				state.selectedFunctionListName = `${listName} | ${fieldName}`;
			} else {
				state.selectedFunctionListName = '';
			}
		};

		// preselect option for default value yes/no
		state.useDefault = state.field.default.type ? true : false;

		// preselect choice for use of max length
		if (typeof state.field.validation.max === 'number') {
			state.useMaxLength = true;
			state.useValidation = true;
		}

		// preselect choice for use of regular expression or referenceList or foreignReferenceList
		if (
			typeof state.field.validation.regex === 'string' &&
			state.field.validation.regex !== '' && !state.field.validation.type
		) {
			state.useRegex = true;
			state.useValidation = true;
		} else if (
			typeof state.field.validation.regex === 'string' &&
			state.field.validation.regex !== '' && state.field.validation.type === "referencelist"
		) {
			state.useReferenceList = true;
			state.useValidation = true;
		} else if (
			typeof state.field.validation.regex === 'string' &&
			state.field.validation.regex !== '' && state.field.validation.type === "foreignreference"
		) {
			state.useForeignReferenceList = true;
			state.useValidation = true;
		}

		// preselect choice for use of function list
		if (
			typeof state.field.validation.element.ref.guid === 'string' &&
			state.field.validation.element.ref.guid
		) {
			state.useFunctionList = true;
			state.useValidation = true;

			const {
				guid,
				map: mappingField,
				fields,
			} = state.field.validation.element.ref;

			// get info about function list
			setTool(guid, mappingField, fields);
		}

		// Get all default value options
		const defaultValueTypes = await useApiAsync(GET_LIST_BY_NAME, {
			parameters: {
				name: 'DefaultValueTypes'
			}
		});

		if (defaultValueTypes) state.defaultValueTypes = defaultValueTypes.items;

		// translate default value options
		if (state.defaultValueTypes.length > 0) {
			// loop over options
			state.defaultValueTypes.forEach((row) => {
				// set value to translated version, based on key
				row.value = t('defaultType.' + row.key);
			});
		}

		if (typeof props.configOptions === 'object') {
			props.configOptions.forEach((option) => {
				if (typeof state.field[option.value] !== 'undefined') {
					state.configModel[option.value] = state.field[option.value];
				}
			});
		}

		if (typeof props.defaultConfigOptions === 'object') {
			props.defaultConfigOptions.forEach((option) => {
				if (typeof state.field.default[option.value] !== 'undefined') {
					state.defaultConfigModel[option.value] = state.field.default[option.value];
				}
			});
		}

		// check if meta documents should be loaded
		const getMetaDocsIfNeeded = async () => {
			// checking if field type match the type of metadocuments fields that needs metadocuments
			// and metadocuments still empty then get the meta documents from the api
			if (
				['DOCUMENTTYPE', 'DOCUMENTARRAY'].includes(
					Field.getVarType(state.field.type)
				) &&
				state.metadataDocuments.length === 0
			) {
				// get all metadata documents
				const metadataDocuments = await useApiAsync(GET_METADATA_DOCUMENTS);
				// filter on published documents
				state.metadataDocuments = metadataDocuments.filter((doc) => doc.is_published);

				// set metadata documents options for select element
				state.metaDocumentOptions = state.metadataDocuments.map(
					(document) => {
						return { value: document.name, text: document.name };
					}
				);
				state.metaDocumentOptions.sort((a, b) =>
					a.text.localeCompare(b.text)
				);
			}
		};

		await getMetaDocsIfNeeded();

		// get basic types for select element
		const getElementsTypeOptions = () => {
			// getting besic types when basicTypeArray field type is chosen and state.elementTypeOptions is empty
			if (
				['BASICTYPEARRAY'].includes(
					Field.getVarType(state.field.type)
				) &&
				state.elementTypeOptions.length === 0
			) {
				// using 'flow' as context becouse flow context has the same basic types
				state.elementTypeOptions =
					Field.getVarTypeOptionsByContext('flow');
			}
		};

		const getReferenceListOptions = async () => {
			// getting ReferenceListOptions when String or password field type is chosen and state.referenceListOptions is empty
			if (
				['STRING', 'PASSWORD'].includes(
					Field.getVarType(state.field.type)
				) &&
				state.referenceListOptions.length === 0
				&& props.context === 'meta'
			) {
				const referenceLists = await useApiAsync(GET_REFERENCE_LISTS);

				if (referenceLists) {
					referenceLists.forEach((list) => {
						state.referenceListOptions.push({
							value: list,
							text: list,
						});
					});
				}

				// add 'None' as first option
				state.referenceListOptions.unshift({
					value: t('general.none'),
					text: t('general.none'),
				});
			}
		};

		const getForeignReferenceListOptions = async () => {
			// gettingForeignReferenceList when Guid field type is chosen and state.foreignReferenceListOptions is empty
			if (
				['GUID'].includes(
					Field.getVarType(state.field.type)
				) &&
				state.foreignReferenceListOptions.length === 0
				&& props.context === 'meta'
			) {
				const foreignReferenceList = await useApiAsync(GET_FOREIGN_REFERENCE_LIST);

				if (foreignReferenceList) {
					state.foreignReferenceListOptions = Helpers.mapObjectArrayForSelect(foreignReferenceList.items, {
						key: 'value',
						text: 'key',
					});
				}

				// add 'None' as first option
				state.foreignReferenceListOptions.unshift({
					value: t('general.none'),
					text: t('general.none'),
				});
			}
		};

		getElementsTypeOptions();
		await getReferenceListOptions();
		await getForeignReferenceListOptions();

		return {
			...toRefs(state),
			setTool,
			getMetaDocsIfNeeded,
			getElementsTypeOptions,
			getReferenceListOptions,
			getForeignReferenceListOptions,
		};
	},
	methods: {
		fieldUpdated: async function () {
			await this.setField();
			this.$emit('update:valueModel', this.field);
		},
		configChanged: function () {
			Object.assign(this.field, this.configModel);
			this.fieldUpdated();
		},
		defaultConfigChanged: function () {
			Object.assign(this.field.default, this.defaultConfigModel);
			this.fieldUpdated();
		},
		openFunctionListDrawer: function () {
			// open drawer with function lists
			this.$store.commit(getStoreMutation('OPEN_DRAWER'), {
				type: 'functionLists',
				data: {
					field: this.field,
					conn_guid: this.connGuid,
					useFieldPicker: this.functionListFieldPickerAvailable,
					callback: (guid, field_name, aliases = []) => {
						this.setTool(guid, field_name, aliases);
					},
				},
			});
		},
		openFunctionListFieldPickerDrawer: function () {
			if (!this.functionListFieldPickerAvailable) return;

			const { guid, map, fields } = this.field.validation.element.ref;

			// open drawer with function list fields
			this.$store.commit(getStoreMutation('OPEN_DRAWER'), {
				type: 'functionListFieldPicker',
				data: {
					callback: (...args) => {
						this.setTool(...args);

						// mark field as updated
						this.fieldUpdated();
					},
					name: this.field.name,
					fields,
					guid,
					map,
					editMode: this.editMode,
				},
			});
		},
		maxLengthChanged: function (ev) {
			this.field.validation.max = Helpers.forceInt(ev.target.value);
			this.fieldUpdated();
		},
		setField: async function () {
			if (this.useRegex) {
				this.field.validation.type = 'regularexpression';
			} else if (this.useReferenceList) {
				this.field.validation.type = 'referencelist';
			} else if (this.useForeignReferenceList) {
				this.field.validation.type = 'foreignreference';
			} else {
				this.field.validation.type = null;
			}
			// determine if default value should be used
			// for field
			const useDefault =
				this.useDefault && this.defaultValueTypeOptions.length > 0;

			// unset default type if not applicable
			if (!useDefault) {
				Helpers.obj.setProp('default|type', this.field, '', true);
			}

			// unset default value if not applicable
			if (!(useDefault && this.field.default.type === staticValue)) {
				Helpers.obj.setProp('default|value', this.field, null, true);
			}

			// unset function list validation if it is no
			// longer available after user changed the Field Type

			// get guid of currently selected Function List
			const functionGuid = Helpers.obj.getProp(
				'validation|element|ref|guid',
				this.field,
				false
			);

			if (functionGuid) {
				// check if Function List is available for current var type
				const isAvailable = await functionListAvailableForType(
					functionGuid,
					this.field.type
				);
				// unset selected Function list if not available
				if (!isAvailable) await this.setTool();
			}

			// unset max length if not applicable
			if (
				!(
					this.useValidation &&
					this.maxLengthValidationAvailable &&
					this.useMaxLength &&
					this.maxLengthValid
				)
			) {
				Helpers.obj.setProp('validation|max', this.field, null, true);
			}

			// unset selected use ReferenceList checkbox
			// unset selected use ForeignReferenceList checkbox
			if (!this.useValidation) {
				this.useReferenceList = false;
				this.useForeignReferenceList = false;
			}

			// unset regular expression if not applicable
			if (
				!(
					this.useValidation &&
					this.useRegex &&
					this.regexValid
				) &&
				!this.useReferenceList &&
				!this.useForeignReferenceList
			) {
				Helpers.obj.setProp('validation|regex', this.field, null, true);
				Helpers.obj.setProp('validation|type', this.field, null, true);
			}

			// unset selected use tool checkbox
			if (!this.useValidation) {
				this.useFunctionList = false;
			}

			// unset selected selected Function list
			if (!this.useFunctionList) {
				this.setTool();
			}

			await this.getMetaDocsIfNeeded();
			this.getElementsTypeOptions();
			await this.getReferenceListOptions();
			await this.getForeignReferenceListOptions();
		},
		toolDeleted: function () {
			this.setTool();
			this.fieldUpdated();
		},
	},
	watch: {
		isValid() {
			this.$emit('update:validModel', this.isValid);
		},
		foreignReferenceListValidationAvailable(newVal) {
			if (!newVal) {
				this.useForeignReferenceList = false;
				this.field.validation.regex = '';
			}
		},
		referenceListValidationAvailable(newVal) {
			if (!newVal) {
				this.useReferenceList = false;
				this.field.validation.regex = '';
			}
		},
		regexValidationAvailable(newVal) {
			if (!newVal) {
				this.useRegex = false;
				this.field.validation.regex = '';
			}
		},
		showDefaultValueChoice(newVal) {
			if (!newVal) {
				// unset default value options when default value
				// is no longer available for the field
				this.field.default.type = false;
				this.field.default.value = null;
				this.field.default.insert = false;
				this.field.default.update = false;
			}
		},
	},
};
</script>
