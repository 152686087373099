<template>
	<div
		class="field field-parent"
		:class="{
			'is-expanded': isExpanded,
			'is-floating': isFloating,
			'is-active': isActive,
			'is-error': isError,
			'is-grouped': isGrouped,
			'has-addons': hasAddons && !isGrouped,
		}"
	>
		<label v-if="!isFloating && label" :class="labelType">
			{{ label }}
		</label>

		<div
			class="control control-input"
			:class="[
				iconPosition,
				{ 'is-expanded': isExpanded },
				{ 'has-icons-right': isError },
				{ 'has-btn-right' : btnOnRight },
			]"
		>
			<!--
			@slot This slot can be used to add input elements inside it
			-->
			<slot></slot>
			<div v-if="isError" class="input-error-icon">
				<VIcon name="cross" class="is-right" />
			</div>
			<VIcon
				v-if="icon"
				:name="icon"
				:classes="iconClasses"
				:class="[iconOnRight ? 'is-right' : 'is-left']"
			/>
			<label
				v-if="isFloating && label && slot.props"
				:for="slot.props.name || slot.props.id"
				class="label-floating"
			>
				{{ label }}
			</label>
		</div>
		<div v-if="hasAddons" class="control control-addons">
			<!--
			@slot This slot adds addons (like buttons) to the slot, so it is inline with the input
			-->
			<slot name="addons"></slot>
		</div>

		<p v-if="help" class="help" :class="[helpClasses]">
			{{ help }}
		</p>
	</div>
</template>

<script>
/**
 * Check if icon exist and if the class should be `left`, `right`, or empty.
 */
const getIconPosition = (icon, iconOnRight) => {
	// Need to add the extra if, because if no icon is present both classes give unwanted padding
	if (!icon) {
		return '';
	}

	if (iconOnRight === undefined || iconOnRight === false) {
		return 'has-icons-left';
	} else {
		return 'has-icons-right';
	}
};

/**
 * Get label size based on the component types given in the slot
 * corresponding to the componentCheck array.
 */
const getSmallLabelBasedOnComponentType = (slot) => {
	// Array of components which should return the 'is-small'
	const componentCheck = ['VOption'];
	const parentType = slot.type.name;
	// Only get the first child, because VField can only have one label, no matter how many children pass through it.
	const childrenType = slot.children?.shift().type.name;

	const slotTypeExist = [parentType, childrenType].some((type) =>
		componentCheck.includes(type)
	);

	if (slotTypeExist) {
		return 'is-small';
	}

	return null;
};

export default {
	name: 'VField',
	props: {
		/**
		 * Bulma has different styling for addons and grouped buttons.
		 * If this prop is true, it will add the 'is-grouped' to the child field.
		 * This class make the 'control' and the 'option-wrapper' classes be on the same line.
		 */
		isGrouped: {
			type: Boolean,
			default: false,
		},
		/**
		 * If true the label will snap to the top of the input on:
		 * - the focus state of the input,
		 * - when the input has a value
		 */
		isFloating: {
			type: Boolean,
			default: false,
		},
		/**
		 * Small text below the input element
		 */
		help: {
			type: String,
			default: null,
		},
		/**
		 * Classes for help element
		 */
		helpClasses: {
			type: String,
			default: null,
		},
		/**
		 * Gives an active class to the root `.field` element
		 */
		isActive: {
			type: Boolean,
			default: false,
		},
		/**
		 * If true the input will take up the remaining space of the `field` element.
		 * This is mostly used with addons: https://bulma.io/documentation/form/general/#form-addons
		 */
		isExpanded: {
			type: Boolean,
			default: false,
		},
		/**
		 * Gives an error class to the root `.field` element
		 */
		isError: {
			type: Boolean,
			default: false,
		},
		/**
		 * Text to display the label above the input
		 */
		label: {
			type: String,
		},
		/**
		 * Optional name of icon to use in the input
		 */
		icon: {
			type: String,
		},
		/**
		 * Optional prop for placing the icon on the right. Standard it gets placed on the left
		 */
		iconOnRight: {
			type: Boolean,
			default: false,
		},
		/**
		 * Optional prop for adding class has-btn-right.
		 */
		btnOnRight: {
			type: Boolean,
			default: false,
		},
		/**
		 * Additional classes to add to the icon component
		 */
		iconClasses: {
			type: String,
		},
	},
	setup(props, context) {
		const hasAddons = context.slots.addons ? true : false;
		const slot = context.slots.default()[0];
		const iconPosition = getIconPosition(props.icon, props.iconOnRight);
		const labelType = getSmallLabelBasedOnComponentType(slot);

		return {
			hasAddons,
			iconPosition,
			slot,
			labelType,
		};
	},
};
</script>
