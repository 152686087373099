/**
 * File offers functionality to handle Nominows 'Function Lists'
 * These are simple tables that return 1 or more values gotten from
 * a look up based on the input. For instance get the full country
 * name (The Netherlands) based on the given iso code (nl)
 */
import Helpers from '@assets/scripts/helpers';
import Field from '@assets/scripts/components/field';
import {
	functionListRefFieldsMeta,
	GET_FUNCTION_LIST_BY_ID,
} from '@assets/scripts/api/config';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import i18n from '@assets/i18n';

// translate function of vue-i18n
const { t } = i18n.global;

const getFunctionList = async (guid) => {
	// load Function List
	return await useApiAsync(GET_FUNCTION_LIST_BY_ID, {
		keys: {
			guid: guid,
		},
	});
};

/**
 * Returns the variables returned by a Function List
 * that is possibly referenced in the given config
 *
 * @param {Object} input
 *  Variable from block config (normalized) that
 *  might contain a reference to a Function List
 *
 * @returns {Array}
 *  Array of found variables
 */
export const getVariablesFromFunctionList = async (input) => {
	// get the GUID of the used function list, the name of the
	// field that is mapped on, and the list of name/alias pairs
	// of fields from the function list to use
	const { guid, map, fields } = Helpers.obj.getProp(
		'validation|element|ref',
		input,
		false
	);

	// return false if no guid found
	if (!guid) return false;

	// prepare default result
	const result = [];

	// load Function List
	const list = Helpers.cloneObject(await getFunctionList(guid));

	if (list) {
		/**
		 * Variables returned by a Function List should be added to the
		 * flow as siblings of the configured field that uses the Function List
		 */

		// loop over fields of function list
		Helpers.obj.getProp('fields', list, []).forEach((field) => {
			// get configured name/alias pair for field
			const alias = fields.find(
				(f) => f.name.toLowerCase() === field.name.toLowerCase()
			);

			// if field is the mapping field of the Function List, and its
			// name matches the name of the configured field that uses the
			// Function List, do not add the field to the result
			// this to prevent having 2 Flow Variables named GenderCode for instance
			if (
				!alias ||
				(field.name === map &&
					field.name.toLowerCase() ===
						Field.getChildName(input.name).toLowerCase())
			)
				return;

			// add field, using alias name, to output
			result.push(
				Field.makeSibling(input, {
					...field,
					name: alias.alias,
				})
			);
		});
	}

	return result;
};

/**
 * Get info about Function Lists formatted for
 * use in FunctionListsDrawer component table
 *
 * @param {Array} lists
 *  Array of Function Lists definitions
 *
 * @param {Array} connections
 *  Array of Connections
 *
 * @param {Object} field
 *  Field that user wants to use as input for
 *  Function List. Used to disable all table rows that
 *  require different input type
 *
 * @returns {Array}
 *  Array of objects per table row
 */
export const formatForFunctionListsTable = (
	lists,
	connections = [],
	field
) => {
	const result = [];

	// loop over function lists
	lists.forEach((list) => {
		// get all mapping fields for list
		const mappingFields = getMapFieldsForFunctionList(list);
		if (mappingFields.length > 0) {
			// loop over mapping fields
			mappingFields.forEach((mappingField) => {
				const { type, name, validation } = mappingField;

				result.push({
					guid: list.guid,
					list_name: list.name,
					field_name: name || false,
					field_type: type ? Field.translateVarType(type) : false,
					field_validation:
						typeof validation.max === 'number'
							? t('validation.maxlengthWithValue', {
									max: validation.max,
							  })
							: false,
					conn_guid: list.conn_guid || false,
					connection: Helpers.getPropValueByProp(
						connections,
						'conn_guid',
						'name',
						list.conn_guid || false
					),
					disabled: !Field.fieldCanBeMappedToType(field, mappingField.type),
				});
			});
		}
	});

	return result;
};

/**
 * Checks if the Function List with a given GUID is
 * available for the given var type
 *
 * @param {String} guid
 *  GUID of Function List
 *
 * @param {String} fieldType
 *  Var type to check
 *
 * @returns {Boolean}
 */
export const functionListAvailableForType = async (guid, fieldType) => {
	// load Function List
	const list = await getFunctionList(guid);

	// check if Function list exists
	if (!list) return false;

	// Check if any of the mapping fields of the Function list
	// match the given type
	return getMapFieldsForFunctionList(list).some(
		(field) => field.type === fieldType
	);
};

/**
 * Returns the mapping fields from the Function List
 *
 * @param {Object} list
 *  Instance of Function List
 *
 * @returns {Array}
 *  Found mapping fields or empty array
 */
export const getMapFieldsForFunctionList = (list) => {
	// mapping can be done on fields that are markes as mapping and
	// on fields that are marked as key
	return list.fields.filter((field) => field.is_map || field.is_key);
};

/**
 * Adds a given list to validation of given field
 * as reference
 *
 * @param {Object} list
 *  Instance of Function List
 *
 * @param {Object} field
 *  Field to set list as reference in
 *
 * @param {String} mappingField
 *  Name of field of reference list to map to
 *
 * @param {Array} aliases
 *  Optional list of objects of name/alias pairs
 *
 * @returns {void}
 */
export const setFunctionListAsRef = (
	list = false,
	field,
	mappingField,
	aliases = []
) => {
	// create empty object
	const functionListRefFields = Helpers.obj.create(
		functionListRefFieldsMeta,
		{}
	);

	// set values if list is given
	if (list) {
		functionListRefFields.guid = list.guid || '';
		functionListRefFields.conn_guid = list.conn_guid || '';

		functionListRefFields.map = mappingField;
		functionListRefFields.fields = aliases;
	}

	// add reference to given field
	Helpers.obj.setProp(
		'validation|element|ref',
		field,
		functionListRefFields,
		true
	);
};
