import i18n from '@assets/i18n';
const TheComposedDocument = () => import('./materials/TheComposedDocument.vue');

// translate function of vue-i18n
const { t } = i18n.global;

export default [
    {
        path: '/composeddocument',
        component: TheComposedDocument,
        name: 'composedDocument',
        meta: {
            title: t('cd.pageTitle'),
            permission: 'Read',
            showInSideNav: true,
            showInHome: true,
            parent: 'builder',
            icon: 'gear',
            module: 'ComposedDocument',
            order: 15,
        },
    },
]