<template>
	<DrawerItem
		:id="id"
		:drawerIndex="drawerIndex"
		:title="drawerName"
		:closeOnBackgroundClick="true"
		:onClose="closeDrawer"
	>
		<VField
			:label="$t('ad.actionDetails.selectAction')"
			:isFloating="true"
			class="has-margin-right-1"
			:isActive="!!action.guid"
		>
			<VSelectWithSearch
				v-model:modelValue="action.guid"
				:options="actionOptions"
				:placeholder="action.name || ''"
				:disabled="!canBeEdited"
				:searchPlaceholder="$t('vueSelect.placeholder')"
			/>
		</VField>

		<template v-if="!!action.guid">
			<div class="has-padding-top-1">
				<VTitle :size="4" :text="$t('ad.actionDetails.actionSettings')" />
				<ConfigOptions
					:options="configOptions"
					:disabled="!canBeEdited"
					v-model:valueModel="configModel"
				/>
			</div>

			<template v-if="!isRootAction">
				<div class="has-padding-top-1">
					<VTitle :size="4" :text="$t('ad.actionDetails.actionAlias')" />
					<VInputString
						v-model:valueModel="action.relation.child_name"
						:isFloating="false"
						:disabled="!canBeEdited"
					/>
				</div>

				<div class="has-padding-top-5">
					<VTitle :size="4" :text="$t('ad.actionDetails.actionRelation')" />
					<div class="is-flex">
						<div class="has-margin-right-1">
							<VInputSelect
								label="Parent field"
								v-model:valueModel="action.relation.parent_field"
								:disabled="!canBeEdited"
								:options="relationParentOptions"
							/>
						</div>

						<VInputSelect
							label="Child field"
							v-model:valueModel="action.relation.child_field"
							:disabled="!canBeEdited"
							:options="relationChildOptions"
						/>
					</div>
				</div>
			</template>

			<div v-if="!isRead" class="has-padding-top-5">
				<div class="content-header has-padding has-bottom-divider has-margin-bottom-075">
					<div class="field is-grouped is-flex-grow-1 is-align-items-center">
						<VTitle
							:size="4"
							:text="$t('ad.actionDetails.requiredFields')"
							class="has-margin-bottom-0"
						/>
						<VSearch v-model="requiredFieldsSearch" />
					</div>
				</div>

				<SortableDataTable
					:hoverable="false"
					:stickyHeader="false"
					default-sort="field_name"
					default-sort-direction="ASC"
					tableClasses="table-without-padding"
					:columns="requiredFieldsTableColumns"
					:data="requiredFieldsTableRowsFiltered"
					:emptyText="$t('ad.actionDetails.requiredFieldsPickerTable.noResults')"
					:checkable="true"
					:isRowCheckable="() => canBeEdited"
					:headerCheckable="canBeEdited && requiredFieldsTableRows.length > 0"
					v-model:checkedRows="checkedRows"
				>
					<template
						v-for="(row) in requiredFieldsTableRowsFiltered"
						:key="row.key"
						#[`field_name-${row.key}`]
					>
						<div @click.stop="rowClicked(row)">
							{{ row.field_name }}
						</div>
					</template>
				</SortableDataTable>
			</div>
		</template>

		<template v-if="canBeEdited" #footer>
			<SaveButton
				:isDisabled="!modified"
				class="button-modal-footer"
				:text="$t('ad.actionDetails.saveButton')"
				icon="chevron-right"
				:iconOnRight="true"
				:callbackFn="saveAction"
			/>
		</template>
	</DrawerItem>
</template>

<script>
export default {
	name: 'ActionDocumentActionDetailsDrawer',
};
</script>

<script setup>
import { useStore } from 'vuex';
import { ref, unref, watch, computed, onBeforeMount, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';
import Helpers from '@assets/scripts/helpers';
import {
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import {
	createNewActionDocumentAction,
} from '@modules/ActionDocument/components/action-document';
import {
	GET_METHODS,
	GET_METHOD,
	GET_DOCUMENTS,
	GET_DOCUMENT,
	GET_REFERENCING_DOCUMENTS,
} from '@modules/ActionDocument/endpoints';

const {
	drawerIndex,
	id,
	config: {
		parent = false,
		int_guid = false,
		level = 0,
		callback = () => {},
	},
} = defineProps({
	/**
	* Index of this drawer
	*/
	drawerIndex: {
		type: Number,
		required: true,
		default: 0,
	},
	/**
	* Unique key of this drawer
	*/
	id: {
		type: String,
		required: true,
	},
	config: {
		type: Object,
		default: {
			callback: () => {},
		},
	},
});

const store = useStore();
const { t } = useI18n();

const requiredFieldsSearch = ref('');

// define empty action object
const action = ref({});
// define action that is currently active/chosen by user
const activeAction = ref({});

// create empty arrays for available documents and methods
const availableDocs = ref([]);
const availableMethods = ref([]);

const availableActions = computed(() => {
	const output = [];

	// add all available documents to output
	unref(availableDocs).forEach((doc) => {
		output.push({
			type: 'Document',
			name: doc.name,
			guid: doc.guid,
		});
	});

	// add all available methods to output
	unref(availableMethods).forEach((method) => {
		output.push({
			type: 'Method',
			name: method.name,
			guid: method.guid,
		});
	});

	return output;
});

// prepare available actions for select component
const actionOptions = computed(() => {
	const output = [];

	// add all available actions to output
	unref(availableActions).forEach((action) => {
		output.push({
			value: action.guid,
			text: action.name,
		});
	});

	return output;
});

// compute available options for parent field in relation object
const relationParentOptions = computed(() => {
	let output = [];

	// add currently selected parent field in action to options
	if (unref(action).relation && !!unref(action).relation.parent_field) {
		output.push({
			value: unref(action).relation.parent_field,
			text: unref(action).relation.parent_field,
		});
	}

	// only add fields from parent if we are in edit mode
	if (canBeEdited && !!parent) {
		// action & parent are both of type 'Document'
		if (unref(bothAreDocuments)) {
			// only add configured field from action reference object
			const reference = getReferenceObject(unref(activeAction), parentAction.guid);

			if (!!reference.key) {
				output.push({
					value: reference.key,
					text: reference.key,
				});
			}
		} else if (parentType === 'Document') {
			// add all key fields from parent document
			if (unref(activeParent).fields) {
				unref(activeParent).fields.forEach((field) => {
					if (field.is_key) {
						output.push({
							value: field.name,
							text: field.name,
						});
					}
				});
			}
		} else {
			// add all fields from parent method output
			if (unref(activeParent).output) {
				unref(activeParent).output.forEach((field) => {
					output.push({
						value: field.name,
						text: field.name,
					});
				});
			}
		}

		if (output.length > 1) {
			// make unique
			output = output.filter((value, index, self) => self.findIndex((t) => t.value === value.value) === index);
			// order alphabetically
			output.sort((a, b) => a.text.localeCompare(b.text));
		}
	}

	return output;
});

// compute available options for child field in relation object
const relationChildOptions = computed(() => {
	let output = [];

	// add currently selected parent field in action to options
	if (unref(action).relation && !!unref(action).relation.child_field) {
		output.push({
			value: unref(action).relation.child_field,
			text: unref(action).relation.child_field,
		});
	}

	// only add fields from action if we are in edit mode
	// and full active action object exists
	if (canBeEdited && unref(activeAction).guid) {
		// action & parent are both of type 'Document'
		if (unref(bothAreDocuments)) {
			// only add configured field from action reference object
			const reference = getReferenceObject(unref(activeAction), parentAction.guid);

			if (!!reference.child) {
				output.push({
					value: reference.child,
					text: reference.child,
				});
			}
		} else if (unref(action)?.type === 'Document') {
			// add all fields from action document
			if (unref(activeAction).fields) {
				unref(activeAction).fields.forEach((field) => {
					output.push({
						value: field.name,
						text: field.name,
					});
				});
			}
		} else {
			// add all fields from action method input
			if (unref(activeAction).input) {
				unref(activeAction).input.forEach((field) => {
					output.push({
						value: field.name,
						text: field.name,
					});
				});
			}
		}

		if (output.length > 1) {
			// make unique
			output = output.filter((value, index, self) => self.findIndex((t) => t.value === value.value) === index);
			// order alphabetically
			output.sort((a, b) => a.text.localeCompare(b.text));
		}
	}

	return output;
});

// get currently active action document
const document = store.getters[getStoreGetter('CURRENT_ACTION_DOCUMENT', 'AD')];
// set boolean to indicate whether action document is of type 'Read'
const isRead = document.is_read ?? false;
// is action the root action of the action document?
const isRootAction = !parent;
// get parent action
const parentAction = isRootAction ? false : document.actions[parent];
// get type of parent action
const parentType = parentAction ? parentAction.type : false;
// define empty action for the current parent
const activeParent = ref({});
// check if both parent and child are of type Document
const bothAreDocuments = computed(() => {
	return (
		parentType === 'Document' &&
		unref(action)?.type === 'Document'
	);
});
// get current connection
const currentConnection = store.getters[getStoreGetter('CURRENT_CONNECTION', 'AD')];
// Boolean to indicate whether current action document can be edited
const canBeEdited = store.getters[getStoreGetter('CAN_BE_EDITED', 'AD')];
// set boolean to indicate whether current action is modified by user
const modified = ref(false);

const drawerName = computed(() => {
	return (canBeEdited ? t('ad.actionDetails.configureAction') : t('ad.actionDetails.actionDetails'));
});

const requiredFieldsTableColumns = computed(() => {
	const columns = [
		{
			label: t('ad.actionDetails.requiredFieldsPickerTable.columns.field_name'),
			field: 'field_name',
			sortable: true,
			searchable: true,
			default: t('general.dash'),
			width: canBeEdited ? '75%' : 'auto',
		},
	];

	if (canBeEdited) {
		columns.push(
			{
				label: t('ad.actionDetails.requiredFieldsPickerTable.columns.field_type'),
				field: 'field_type',
				sortable: true,
				searchable: true,
				default: t('general.dash'),
				width: '25%',
			},
		);
	}

	return columns;
});

const requiredFieldsTableRows = computed(() => {
	const rows = [];

	// required fields are not applicable for 'read' action documents
	if (isRead) return rows;

	if (canBeEdited) {
		// check if action is of type 'Document' and has fields
		if (unref(action)?.type === 'Document' && unref(activeAction).fields) {
			// add all fields to table rows
			unref(activeAction).fields.forEach((field, key) => {
				rows.push({
					key,
					field_name: field.name,
					field_type: field.type,
				});
			});
		}
		// check if action is of type 'Method' and has input
		else if (unref(action)?.type === 'Method' && unref(activeAction).input) {
			// add all input fields to table rows
			unref(activeAction).input.forEach((field, key) => {
				rows.push({
					key,
					field_name: field.name,
					field_type: field.type,
				});
			});
		}

	} else {
		// add only currently configured required fields to rows
		unref(action)?.required.forEach((field, key) => {
			rows.push({
				key,
				field_name: field,
			});
		});
	}

	return rows;
});

// check rows used as v-model for setting required fields
const checkedRows = computed({
	get: () => {
		return unref(requiredFieldsTableRows).filter((row) => unref(action).required.includes(row.field_name));
	},
	set: (value) => {
		action.value.required = value.map((row) => row.field_name);
	},
});

const rowClicked = (row) => {
	if (!canBeEdited) return;

	const index = unref(checkedRows).findIndex((el) => el.field_name === row.field_name);

	// N.B.: make SHALLOW copy here
	// two-way binding for checked rows only works that
	// way with Buefy Table
	const newCheckedRows = Helpers.cloneObject(unref(checkedRows), false);

	// check row
	if (index < 0) newCheckedRows.push(row);
	// uncheck row
	else newCheckedRows.splice(index, 1);

	// set checkedRows prop
	checkedRows.value = newCheckedRows;
};

const requiredFieldsTableRowsFiltered = computed(() => {
	// loop over all documents
	const newData = unref(requiredFieldsTableRows);

	// return if no rows match
	if (unref(requiredFieldsSearch).length === 0 || newData.length === 0) return newData;

	// filter on search string
	return Helpers.filterByString(
		newData,
		unref(requiredFieldsSearch),
		Helpers.getSearchableColumns(unref(requiredFieldsTableColumns)),
	);
});

const watchers = [];
const configModel = ref({});

const configOptions = [
	{
		value: 'is_mandatory',
		label: t('ad.actionDetails.settings.is_mandatory'),
		info: t('ad.actionDetails.settings.is_mandatory_info'),
	},
	{
		value: 'is_updatable',
		label: t('ad.actionDetails.settings.is_updatable'),
		info: t('ad.actionDetails.settings.is_updatable_info'),
	},
];

onBeforeMount(async () => {
	if (int_guid && unref(document).actions[int_guid]) {
		// get viewed action from action document, cloned as to not modify original
		action.value = Helpers.cloneObject(unref(document).actions[int_guid]);
	} else {
		// create empty action document action
		action.value = createNewActionDocumentAction(parent, level);
	}

	//prepare config model
	configOptions.forEach((option) => {
		if (typeof action.value[option.value] !== 'undefined') {
			configModel.value[option.value] = action.value[option.value];
		}
	});

	watchers.push(watch(configModel, (value) => {
		// update action with new values of the config
		Object.assign(action.value, value);
	}));

	// watch any change to the action object
	watchers.push(watch(action, () => {
		// mark as modified if action object changes
		modified.value = true;
	}, {
		deep: true,
	}));
	
	// watch specifically a change to the action guid
	watchers.push(watch(() => unref(action).guid, () => {
		actionGuidUpdated();
	}));

	if (canBeEdited) {
		// load full object of active action
		activeAction.value = await getFullAction(unref(action).guid, unref(action).type);

		// load full object of parent action
		if (parentAction) activeParent.value = await getFullAction(parentAction.guid, parentType);

		if (parentType === 'Document' && unref(document).actions[parent]?.guid) {
			const parentGuid = unref(document).actions[parent].guid;
			// load all documents of type action for current connection, that reference the parent document
			availableDocs.value = await useApiAsync(GET_REFERENCING_DOCUMENTS, {
				keys: {
					connection: currentConnection,
					guid: parentGuid,
				},
			});
		} else {
			// load all documents of type action for current connection
			availableDocs.value = await useApiAsync(GET_DOCUMENTS, {
				keys: {
					connection: currentConnection,
				},
			});
		}

		// load all available methods for current connection
		availableMethods.value = await useApiAsync(GET_METHODS, {
			keys: {
				connection: currentConnection,
			},
			parameters: {
				read: isRead,
			}
		});
	}
});

onUnmounted(() => {
	// stop al watchers on unmount
	watchers.forEach((unwatch) => unwatch());
});

// called when action is updated by user
const actionGuidUpdated = async () => {
	let newAction = false;

	// get new GUID
	const newGuid = unref(action).guid;

	// get new action object from available actions
	if (!!newGuid) newAction = unref(availableActions).find((action) => action.guid === newGuid);

	activeAction.value = newAction ? await getFullAction(newGuid, newAction.type) : {};

	// prepare new values for action object
	const newActionValues = {
		name: unref(activeAction).name || '',
		type: newAction ? newAction.type : '',
		required: [],
	};

	// ad relation object if action is not root action of the action document
	if (!isRootAction) {
		newActionValues.relation = getRelationObject(unref(activeAction), newActionValues.type);
	}

	// update action object with new values
	action.value = {
		...unref(action),
		...newActionValues,
	};
};

const getReferenceObject = (document, guid) => {
	// check if given document has references
	if (
		Array.isArray(document.references) &&
		document.references.length > 0
	) {
		// find reference to parent document with given guid
		const reference = document.references.find((el) => el.guid === guid);
		
		if (reference) {
			return reference;
		}
	}

	return {
		child: '',
		key: '',
	};
};

// get relation object for action between given action and parent action
const getRelationObject = (action, childType = '') => {
	const relation = {
		child_name: action.name ?? '',
		child_field: '',
		parent_field: '',
	};

	// relation can only be automatically set if both parent and child are of type 'Document',
	// and the child has a reference to the parent
	if (
		childType === 'Document' &&
		parentType === 'Document' &&
		Array.isArray(action.references) &&
		action.references.length > 0
	) {
		// find reference to parent action
		const reference = getReferenceObject(action, parentAction.guid);

		relation.child_field = reference.child;
		relation.parent_field = reference.key;
	}

	return relation;
};

const getFullAction = async (guid = false, type = false) => {
	if (!guid || !type) return {};

	const endpoint = type === 'Document' ? GET_DOCUMENT : GET_METHOD;

	// load full action object for current connection
	const result = await useApiAsync(endpoint, {
		keys: {
			connection: currentConnection,
			guid,
		},
	});

	return result ?? {};
};

const saveAction = () => {
	if (!canBeEdited) return;

	callback(unref(action));

	// mark as no longer modified
	modified.value = false;

	closeDrawer();
};

const closeDrawer = () => {
	const closeDrawer = () => {
		store.commit(getStoreMutation('CLOSE_DRAWER'), id);
	};

	if (!unref(modified)) {
		// close immediately if action has not been modified
		closeDrawer();
	} else {
		// ask confirmation before closing if a change has been made
		// to the action config
		store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
			title: t(
				'ad.actionDetails.close.confirm.title'
			),
			body: t(
				'ad.actionDetails.close.confirm.body'
			),
			confirmButtonText: t(
				'ad.actionDetails.close.confirm.confirmButtonText'
			),
			confirmFn: () => {
				// close after confirmation
				closeDrawer();
			},
		});
	}
};
</script>
