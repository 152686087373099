import { IS_DEV } from '@assets/scripts/helpers';
import Helpers from '@assets/scripts/helpers';
import {
	blockIsOfType,
	getBlockGuid,
	getBlockConnections,
	getBlockOutput,
	getBlockConfig,
	getBlockConfigProp,
} from '@modules/FlowBuilder/components/block';
import { composeDisplayName } from '@modules/FlowBuilder/components/actions';
import Field from '@assets/scripts/components/field';
import { intersectFlowVariables } from '@modules/FlowBuilder/components/flow-variables';
import { log } from '@assets/scripts/components/notifications';

export const names = {
	BLOCKS: 'blocks',
	BLOCKS_BY_GUID: 'blocksByGuid',
	BLOCK_BY_GUID: 'blockByGuid',
	BLOCKS_BY_TYPE: 'blocksByType',
	BLOCK_INPUT: 'blockInput',
	BLOCK_CONFIG: 'blockConfig',
	BLOCK_OUTPUT_COUNT: 'blockOutputCount',
	BLOCK_APPEND_COUNT: 'blockAppendCount',
	BLOCK_RULE_COUNT: 'blockRuleCount',
	BLOCK_RESULT_COUNT: 'blockResultCount',
	BLOCK_METHOD: 'blockMethod',
	RESULT_GUID: 'resultGuid',
	START_GUID: 'startGuid',
	FLOW_BLOCK_START: 'xFlowBlockStart',
	BLOCK_ACTIVE: 'blockActive',
	CURRENT_BLOCK: 'currentBlock',
	START_BLOCK_FIELDS: 'startBlockFields',
	RESULT_BLOCK_FIELDS: 'resultBlockFields',
	ADD_BLOCK_FIELDS: 'addBlockFields',
	PARENT_FIELDS_FROM_BLOCK: 'parentFieldsFromBlock',
	PARENT_FIELDS_FROM_CURRENT_BLOCK: 'parentFieldsFromCurrentBlock',
	MODIFIED: 'blockModified',
	NEW_BLOCK_OPTIONS: 'newBlockOptions',
};

export default {
	/**
	 * Get blocks
	 *
	 * @param {Array} blocks
	 *  Ref to state.blocks
	 *
	 * @returns {Array}
	 *  List of blocks
	 */
	[names.BLOCKS]: ({ blocks }) => {
		return blocks;
	},
	/**
	 * Get blocks keyed by GUID
	 *
	 * @param {Array} blocks
	 *  Ref to state.blocks
	 *
	 * @returns {Object}
	 *  Blocks keyed by GUID
	 */
	[names.BLOCKS_BY_GUID]: ({ blocks }) => {
		const result = {};
		try {
			blocks.forEach((block) => {
				result[getBlockGuid(block)] = block;
			});
		} catch (err) {
			log(err.message, 'danger', err);
		}
		return result;
	},
	/**
	 * Get a block from current flow by GUID
	 *
	 * @param {Object} state
	 *  Ref to current module state
	 *
	 * @param {Object} getters
	 *  Ref to module getters
	 *
	 * @param {String} guid
	 * 	GUID of block to get
	 *
	 * @returns {Object/Boolean}
	 *  Requested block or false if not found
	 */
	[names.BLOCK_BY_GUID]: (state, getters) => (guid) => {
		let result = false;
		try {
			result = getters[names.BLOCKS_BY_GUID][guid];
		} catch (err) {
			log(err.message, 'danger', err);
		}
		return result;
	},
	/**
	 * Get all blocks from current state of
	 * a given type
	 *
	 * @param {Object} state
	 *  Ref to current module state
	 *
	 * @param {String} type
	 *  Block type to return
	 *
	 * @returns {Array}
	 *  Array of blocks of requested type
	 */
	[names.BLOCKS_BY_TYPE]: (state) => (type) => {
		return state.blocks.filter((block) => blockIsOfType(block, type));
	},
	/**
	 * Get the current input of a block with a given GUID
	 *
	 * @param {Object} state
	 *  Ref to current module state
	 *
	 * @param {Object} getters
	 *  Ref to module getters
	 *
	 * @param {String} guid
	 * 	GUID of block to get input for
	 *
	 * @returns {Array}
	 *  Input for block
	 */
	[names.BLOCK_INPUT]: (state, getters) => (guid) => {
		const input = [];

		// get block object
		const block = getters[names.BLOCK_BY_GUID](guid);

		if (block) {
			// loop over parents of block
			getBlockConnections(block, 'IN').forEach((parentGuid) => {
				// get parent block object
				const parent = getters[names.BLOCK_BY_GUID](parentGuid);
				// add output of parent to input of requested block
				if (parent) {
					input.push(getBlockOutput(parent));
				}
			});
		}

		return intersectFlowVariables(input);
	},
	/**
	 * Get the config of a block with a given GUID
	 *
	 * @param {Object} state
	 *  Ref to current module state
	 *
	 * @param {Object} getters
	 *  Ref to module getters
	 *
	 * @param {String} guid
	 * 	GUID of block to get config for
	 *
	 * @returns {Object}
	 *  config for block
	 */
	[names.BLOCK_CONFIG]: (state, getters) => (guid) => {
		let result = {};

		// get block object
		const block = getters[names.BLOCK_BY_GUID](guid);

		if (block) {
			result = Helpers.obj.getProp('config', block, {});
		}

		return result;
	},
	/**
	 * Get the count of current variables outputted
	 * by a block with a given GUID
	 *
	 * @param {Object} state
	 *  Ref to current module state
	 *
	 * @param {Object} getters
	 *  Ref to module getters
	 *
	 * @param {String} guid
	 * 	GUID of block to get output count for
	 *
	 * @returns {Int}
	 *  Count of output variables
	 */
	[names.BLOCK_OUTPUT_COUNT]: (state, getters) => (guid) => {
		// get block object
		const block = getters[names.BLOCK_BY_GUID](guid);
		if (!block) return 0;
		return getBlockOutput(block).length;
	},
	/**
	 * Get the count of variables added
	 * within a block with a given GUID
	 *
	 * @param {Object} state
	 *  Ref to current module state
	 *
	 * @param {Object} getters
	 *  Ref to module getters
	 *
	 * @param {String} guid
	 * 	GUID of block to get count for
	 *
	 * @returns {Int}
	 *  Count of output variables
	 */
	[names.BLOCK_APPEND_COUNT]: (state, getters) => (guid) => {
		// get block object
		const block = getters[names.BLOCK_BY_GUID](guid);
		if (!block) return 0;
		return Helpers.obj.getProp('config|add', block, []).length;
	},
	/**
	 * Get the count of conditionals configured
	 * inside a block with a given GUID
	 *
	 * @param {Object} state
	 *  Ref to current module state
	 *
	 * @param {Object} getters
	 *  Ref to module getters
	 *
	 * @param {String} guid
	 * 	GUID of block to get output count for
	 *
	 * @returns {Int}
	 *  Count of configured conditionals
	 */
	[names.BLOCK_RULE_COUNT]: (state, getters) => (guid) => {
		// get block object
		const block = getters[names.BLOCK_BY_GUID](guid);
		if (!block) return 0;

		let count = 0;
		Helpers.obj.getProp('config|rules', block).forEach((row) => {
			count += row.rules.length;
		});
		return count;
	},
	/**
	 * Get the count of configured output result
	 * by a block with a given GUID
	 *
	 * @param {Object} state
	 *  Ref to current module state
	 *
	 * @param {Object} getters
	 *  Ref to module getters
	 *
	 * @param {String} guid
	 * 	GUID of block to get result count for
	 *
	 * @returns {Int}
	 *  Count of result variables
	 */
	[names.BLOCK_RESULT_COUNT]: (state, getters) => (guid) => {
		// get block object
		const block = getters[names.BLOCK_BY_GUID](guid);
		if (!block) return 0;
		return getBlockConfigProp(block, 'output', []).length;
	},
	/**
	 * Get info about READ/WRITE method used
	 * by a block with a given GUID
	 *
	 * @param {Object} state
	 *  Ref to current module state
	 *
	 * @param {Object} getters
	 *  Ref to module getters
	 *
	 * @param {String} guid
	 * 	GUID of block to get method info for
	 *
	 * @returns {String}
	 *  Descriptive string or false
	 */
	[names.BLOCK_METHOD]: (state, getters) => (guid) => {
		// get block object
		const block = getters[names.BLOCK_BY_GUID](guid);
		if (!block) return false;

		// return descriptive string like 'Contact - Read'
		return composeDisplayName(getBlockConfig(block));
	},
	/**
	 * Get the GUID of the Start block
	 *
	 * @param {String/Boolean} startId
	 *  Ref to state.startId
	 *
	 * @returns {String/Boolean}
	 *  GUID of start block or false if not found
	 */
	[names.START_GUID]: ({ startId }) => {
		return startId;
	},
	/**
	 * Get the GUID of the Result block
	 *
	 * @param {String/Boolean} resultId
	 *  Ref to state.resultId
	 *
	 * @returns {String/Boolean}
	 *  GUID of result block or false if not found
	 */
	[names.RESULT_GUID]: ({ resultId }) => {
		return resultId;
	},
	/**
	 * Get the current value of xFlowBlockStart
	 *
	 * @param {Integer} xFlowBlockStart
	 *  Ref to state.xFlowBlockStart
	 *
	 * @returns {Integer}
	 *  Current value of xFlowBlockStart
	 */
	[names.FLOW_BLOCK_START]: ({ xFlowBlockStart }) => {
		return xFlowBlockStart;
	},
	[names.BLOCK_ACTIVE]: ({ currentBlock }) => {
		return Object.keys(currentBlock).length > 0;
	},
	[names.CURRENT_BLOCK]: ({ currentBlock }) => {
		return currentBlock;
	},
	/**
	 * Get array of configured fields of Start Block
	 * N.B.: only returns filled array if current block
	 * is actually a Start Block
	 *
	 * @param {Object} currentBlock
	 *  Block that is currently viewed/edited by user
	 *
	 * @returns {Array}
	 */
	[names.START_BLOCK_FIELDS]: ({ currentBlock }) => {
		return Helpers.obj.getProp('config|input', currentBlock, []);
	},
	/**
	 * Get array of configured output of Result Block
	 * N.B.: only returns filled array if current block
	 * is actually a Result Block
	 *
	 * @param {Object} currentBlock
	 *  Block that is currently viewed/edited by user
	 *
	 * @returns {Array}
	 */
	[names.RESULT_BLOCK_FIELDS]: ({ currentBlock }) => {
		return Helpers.obj.getProp('config|output', currentBlock, []);
	},
	/**
	 * Get array of configured fields of Add Block
	 * N.B.: only returns filled array if current block
	 * is actually an Add Block
	 *
	 * @param {Object} currentBlock
	 *  Block that is currently viewed/edited by user
	 *
	 * @returns {Array}
	 */
	[names.ADD_BLOCK_FIELDS]: ({ currentBlock }) => {
		return Helpers.obj.getProp('config|add', currentBlock, []);
	},
	/**
	 * Get array of fields from given block that are parent fields,
	 * i.e. all array and object fields
	 *
	 * @param {Object} block
	 *  Block to get parent fields from
	 *
	 * @param {Object} exclude
	 *  Optional field to exclude form returned result
	 *
	 * @returns {Array}
	 *  Array of parent fields
	 */
	[names.PARENT_FIELDS_FROM_BLOCK]:
		(state, getters) =>
		(block, exclude = false) => {
			let fields = [];

			// for Start block, get parent fields from configured fields
			// for all other blocks, get parent fields from calculated input
			if (blockIsOfType(block, 'START')) {
				fields = Helpers.obj.getProp('config|input', block, []);
			} else {
				// get all input for block, return is grouped by connected
				// parent block
				fields = getters[names.BLOCK_INPUT](getBlockGuid(block));

				// for Add block, also get parent fields that are created
				// in the block itself
				if (blockIsOfType(block, 'ADD')) {
					fields = fields.concat(
						Helpers.obj.getProp('config|add', block, [])
					);
				}
			}

			if (fields.length > 0) {
				// filter for parent fields
				return fields.filter((field) => {
					if (!Field.isParent(field)) return false;
					// true when no field has been given to exclude, or name
					// of the field to exclude differs from this field
					return !exclude?.name || field.name !== exclude.name;
				});
			}

			return fields;
		},
	/**
	 * Get array of fields from current block that are parent fields,
	 * i.e. all array and object fields
	 *
	 * @param {Object} exclude
	 *  Optional field to exclude form returned result
	 *
	 * @returns {Array}
	 *  Array of parent fields
	 */
	[names.PARENT_FIELDS_FROM_CURRENT_BLOCK]:
		({ currentBlock }, getters) =>
		(exclude = false) => {
			return getters[names.PARENT_FIELDS_FROM_BLOCK](
				currentBlock,
				exclude
			);
		},
	[names.MODIFIED]: ({ modified }) => {
		return modified;
	},
	[names.NEW_BLOCK_OPTIONS]: ({ newBlockOptions }) => {
		return newBlockOptions;
	},
	debug: (state) => {
		// output whole state for debugging since Vue Devtools plugin
		// not (always) shows the updated state of the store, but it
		// does show the correct value of all getters
		return IS_DEV ? state : {};
	},
};
