<template>
	<a
		v-if="item.meta.isExternal"
		class="menu-anchor external"
		:href="item.href"
		@click.prevent.stop="() => externalLinkClicked(item.href)"
	>
		<VMenuItemContent :icon="item.meta.icon" :name="item.meta.title" />
	</a>
	<RouterLink
		v-else
		:to="item.path"
		class="menu-anchor"
		active-class="is-active"
	>
		<VMenuItemContent :icon="item.meta.icon" :name="item.meta.title" />
	</RouterLink>
</template>

<script>
import { getStoreMutation } from '@assets/scripts/store/config';
import {
	dataLossPossible,
	reset,
} from '@assets/scripts/composables/usePreventDataLoss';

export default {
	name: 'SideNavigationLink',
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
	data: function () {
		return {
			dataLossPossible,
			reset,
		};
	},
	methods: {
		externalLinkClicked: function (href) {
			const go = () => {
				window.location = href;
			};

			if (!this.dataLossPossible) {
				go();
				return;
			}

			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t('sideNavigation.leave.confirm.title'),
				body: this.$t('sideNavigation.leave.confirm.body'),
				confirmButtonText: this.$t(
					'sideNavigation.leave.confirm.confirmButtonText'
				),
				cancelButtonText: this.$t(
					'sideNavigation.leave.confirm.cancelButtonText'
				),
				confirmFn: () => {
					this.reset();
					go();
				},
			});
		},
	},
};
</script>
