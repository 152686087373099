<template>
	<div class="vue-select-wrapper" ref="select" :class="{ 'is-moved': isMoved }">
		<vue-select
			v-model="value"
			:options="options"
			:close-on-select="true"
			:clear-on-select="true"
			:searchable="true"
			label-by="text"
			value-by="value"
			:disabled="disabled"
			:class="{ 'is-disabled': disabled }"
			:placeholder="placeholder"
			:search-placeholder="searchPlaceholder"
			openDirection="bottom"
			@open="activateTooltip"
			@focus="activateTooltip"
			@blur="deactivateTooltip"
			@close="deactivateTooltip"
		/>
	</div>
</template>

<script>
import { computed, ref } from '@vue/reactivity';

// vue-select https://vuejsexamples.com/a-complete-selecting-solution-for-vue-js-3-x/
import VueSelect from 'vue-next-select';

export default {
	name: 'VSelectWithSearch',
	components: {
		'vue-select': VueSelect
	},
	data: function () {
		return {
			isActive: false,
			isMoved: false,
			appLayer: false,
			wrapper: false,
			placeholderEl: false
		};
	},
	props: {
		options: {
			type: Array,
			required: true
		},
		disabled: {
			type: Boolean,
			default: false
		},
		placeholder: {
			type: String,
			default: ''
		},
		searchPlaceholder: {
			type: String,
			default: ''
		},
		modelValue: {
			modelValue: [String, Number, Boolean, Function, Object, Array],
		}
	},
	setup(props, { emit }) {
		const select = ref(null);

		const value = computed({
			get() {
				return props.modelValue;
			},
			set(_value) {
				emit('update:modelValue', _value);
			},
		});

		return {
			select,
			value,
		};
	},
	mounted: function () {
		// find closest modal or fullscreen layer to place select element in
		this.appLayer = this.select.closest('.modal, .app-layer');

		// get parent element of select
		this.wrapper = this.select.parentNode;

		// create placeholder element to put in 'old' DOM position of
		// select element when it is moved
		this.placeholderEl = document.createElement('div');
	},
	unmounted: function () {
		this.deactivateTooltip();
	},
	methods: {
		activateTooltip() {
			if (this.isActive || !this.appLayer) return;

			// get position of select element
			const pos = this.select.getBoundingClientRect();

			// give placeholder element correct dimensions
			this.placeholderEl.style.width = `${pos.width}px`;
			this.placeholderEl.style.height = `${pos.height}px`;

			// remove select element from position in DOM
			const content = this.wrapper.removeChild(this.select);

			// put placeholder in place in DOM
			this.wrapper.appendChild(this.placeholderEl);

			// add select element to correct layer
			this.appLayer.appendChild(content);

			// set some positioning styles
			this.select.style.top = `${pos.top}px`;
			this.select.style.left = `${pos.left}px`;
			this.select.style.width = `${pos.width}px`;
			this.select.style.height = `${pos.height}px`;

			// mark select element as active and moved
			this.isMoved = true;
			this.isActive = true;
		},
		deactivateTooltip() {
			if (!this.isActive || !this.appLayer) return;

			// remove select element from higher layer
			const content = this.appLayer.removeChild(this.select);

			// place select element back at original position in DOM
			this.wrapper.appendChild(content);

			// remove placeholder from DOM
			this.wrapper.removeChild(this.placeholderEl);

			// unset positioning styles
			this.select.style.top = '';
			this.select.style.left = '';
			this.select.style.width = '';
			this.select.style.height = '';

			// mark select element as inactive and unmoved
			this.isMoved = false;
			this.isActive = false;
		},
	},
	emits: ['update:modelValue'],
};
</script>
