/**
 * Translations for English
 * See https://vue-i18n.intlify.dev/guide/ on how to use
 */
 export default {
	pageTitle: 'Dashboard',
	permissions: {
		label: 'Dashboard',
		info: 'Permissions connected to the Dashboard module',
		use: {
			label: 'Use Dashboard',
			info: 'Permission to use the Dashboard module',
		},
	},
};
