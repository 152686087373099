<template>
	<DrawerItem
		:id="id"
		:drawerIndex="drawerIndex"
		:title="drawerName"
		:hasJsonButton="false"
		:hasFullwidthBody="false"
		:closeOnBackgroundClick="true"
		:onClose="closeDrawer"
	>
		<Suspense>
			<FieldDetailsEditor
				:editMode="fieldCanBeEdited"
				:fields="fields"
				v-model:valueModel="field"
				@update:valueModel="markModified"
				v-model:validModel="isValid"
				:fieldKey="fieldKey"
				:fieldMeta="documentFieldMeta"
				:connGuid="document.conn_guid"
				:fieldParents="false"
				:configOptions="configOptions"
				:defaultConfigOptions="defaultConfigOptions"
				:requiredAllowed="fieldCanBeRequired"
				:showFieldDescription="true"
				context="document"
			/>
		</Suspense>

		<template v-if="fieldCanBeEdited" #footer>
			<SaveButton
				:isDisabled="!canBeSaved"
				class="button-modal-footer"
				:text="$t('db.fieldDetails.saveButton')"
				icon="chevron-right"
				:iconOnRight="true"
				:callbackFn="saveField"
			/>
		</template>
	</DrawerItem>
</template>

<script>
import { useStore } from 'vuex';
import { ref, unref, computed } from '@vue/reactivity';
import { useI18n } from 'vue-i18n';
import {
	getStoreAction,
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import Field from '@assets/scripts/components/field';
import { documentFieldMeta } from '@modules/DocumentBuilder/endpoints';
import Helpers from '@assets/scripts/helpers';
import {
	fieldCanBeEdited,
	getDocType,
} from '@modules/DocumentBuilder/components/connection-document';

export default {
	name: 'DocumentFieldDetailsDrawer',
	props: {
		/**
		 * Index of this drawer
		 */
		drawerIndex: {
			type: Number,
			required: true,
			default: 0,
		},
		config: {
			type: Object,
			required: false,
		},
		/**
		 * Unique key of this drawer
		 */
		id: {
			type: String,
			required: true,
		},
	},
	data: function () {
		return {
			documentFieldMeta,
		};
	},
	computed: {
		drawerName: function () {
			return this.field.name || '...';
		},
	},
	setup: function(props) {
		const store = useStore();
		const { t } = useI18n();

		const modified = ref(false);
		const isValid = ref(true)

		const markModified = () => {
			modified.value = true;
		};

		const canBeSaved = computed(() => {
			return unref(modified) && unref(isValid);
		});

		// Gets currently active Document
		const document = store.getters[getStoreGetter('CURRENT_DOCUMENT', 'DB')];

		// field can not be made required if document can only be extended
		// but not edited, which is the case after it is published
		const fieldCanBeRequired = store.getters[getStoreGetter('CAN_BE_EDITED', 'DB')];

		// get the type of the document
		const docType = getDocType(document.type);

		// Get fields of document that is currently viewed/edited
		const fields = document.fields || [];

		// determine key of field in list
		const fieldKey = props.config && props.config.key ? props.config.key - 1 : false;

		// get field, or create a new field
		const field = ref(fields && typeof fields[fieldKey] !== 'undefined'
				? Helpers.cloneObject(fields[fieldKey])
				: Field.createNew({
					is_new: true,
					order: props.config?.order ?? -1,
				}, documentFieldMeta, true));

		// prepare additional options for field editor
		const configOptions = [];
		const defaultConfigOptions = [];

		// shared config options
		['is_id', 'is_key', 'is_updatable'].forEach((value) => {
			configOptions.push({
				value,
				label: t('fieldDetailsEditor.fieldOptions.' + value),
				info: t('fieldDetailsEditor.fieldOptions.' + value + '_info'),
			});
		});

		// add shared default value options
		['insert', 'update'].forEach((value) => {
			defaultConfigOptions.push({
				value,
				label: t('fieldDetailsEditor.fieldDefaultOptions.' + value),
				info: t('fieldDetailsEditor.fieldDefaultOptions.' + value + '_info'),
			});
		});
		
		if (docType === 'LIST') {
			// add additional field options for list document
			['is_map'].forEach((value) => {
				configOptions.push({
					value,
					label: t('fieldDetailsEditor.fieldOptions.' + value),
					info: t('fieldDetailsEditor.fieldOptions.' + value + '_info'),
				});
			});
		}

		const saveField = () => {
			if (!unref(canBeSaved)) return;

			store.dispatch(getStoreAction('SAVE_FIELD', 'DB'), {
				key: fieldKey, // key of current field in field list or false for new field
				drawerId: props.id, // id of drawer to close after save
				field: unref(field),
			});
		};

		return {
			modified,
			isValid,
			markModified,
			canBeSaved,
			document,
			fieldCanBeRequired,
			docType,
			configOptions,
			defaultConfigOptions,
			fields,
			fieldKey,
			field,
			fieldCanBeEdited: fieldCanBeEdited(unref(field), document),
			saveField,
		};
	},
	methods: {
		closeDrawer: function () {
			const closeDrawer = () => {
				this.$store.commit(getStoreMutation('CLOSE_DRAWER'), this.id);
			};

			if (!this.modified) {
				// close immediately if config has not been modified
				closeDrawer();
			} else {
				// ask confirmation before closing if a change has been made
				// to the field config
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t(
						'db.fieldDetails.close.confirm.title'
					),
					body: this.$t(
						'db.fieldDetails.close.confirm.body'
					),
					confirmButtonText: this.$t(
						'db.fieldDetails.close.confirm.confirmButtonText'
					),
					confirmFn: () => {
						// close after confirmation
						closeDrawer();
					},
				});
			}
		},
	},
};
</script>
