<template>
	<DrawerItem
		:id="id"
		:drawerIndex="drawerIndex"
		:title="$t('ad.documentDetails.previewTitle')"
		:hasJsonButton="false"
		:hasFullwidthBody="false"
		:closeOnBackgroundClick="true"
		:onClose="closeDrawer"
	>
		<pre><code>{{json}}</code></pre>
	</DrawerItem>
</template>

<script>
export default {
	name: 'ActionDocumentJsonPreviewDrawer',
};
</script>

<script setup>
import { useStore } from 'vuex';
import { computed, unref } from 'vue';
import Helpers from '@assets/scripts/helpers';
import { constructActionDocument } from '@modules/ActionDocument/components/action-document';
import { actionDocumentMeta } from '@modules/ActionDocument/endpoints';
import {
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';

const store = useStore();

const { drawerIndex, id } = defineProps({
	/**
	* Index of this drawer
	*/
	drawerIndex: {
		type: Number,
		required: true,
		default: 0,
	},
	/**
	* Unique key of this drawer
	*/
	id: {
		type: String,
		required: true,
	},
});

const closeDrawer = () => {
	store.commit(getStoreMutation('CLOSE_DRAWER'), id);
};

// get currently active action document
const document = computed(() => store.getters[getStoreGetter('CURRENT_ACTION_DOCUMENT', 'AD')]);

// construct document to be BE json and use constructActionDocument function to construct full document
const constructedDocument = computed(() => Helpers.obj.construct(constructActionDocument(unref(document)), actionDocumentMeta));

// stringfy the document to be used in VTextaria component
const json = computed(() => JSON.stringify(unref(constructedDocument), null, 4));
</script>
