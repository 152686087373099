<template>
	<DrawerItem
		:id="id"
		:drawerIndex="drawerIndex"
		:title="$t('ra.apiUserDetails.title')"
		:hasJsonButton="false"
		:hasFullwidthBody="false"
		:closeOnBackgroundClick="true"
		:onClose="closeDrawer"
	>
		<div class="has-margin-bottom-5">
			<div
				class="has-bottom-divider has-padding-bottom-2 has-margin-bottom-2"
			>
				<VTitle
					:size="2"
					:text="$t('ra.apiUserDetails.apiUserInformation')"
				/>
			</div>
			<VField
				:isFloating="true"
				:label="$t('ra.apiUserDetails.apiToken')"
				:class="{ 'is-active': apiUser.api_token }"
				class="is-wide has-buttons"
			>
				<VInput
					:placeholder="$t('ra.apiUserDetails.apiToken')"
					:disabled="true"
					type="text"
					ref="clone"
					v-model:modelValue="apiUser.api_token"
				/>
				<VButton
					:is-tool="true"
					:disabled="!editMode"
					:icon-on-right="true"
					:text="$t('ra.apiUserDetails.copy')"
					:title="$t('ra.apiUserDetails.copy')"
					@clicked="copyApiToken(apiUser.api_token)"
				/>
				<VButton
					:is-tool="true"
					:disabled="!editMode"
					:title="$t('ra.apiUserDetails.renew')"
					:icon-on-right="true"
					icon="refresh"
					@clicked="refreshApiToken"
				/>
			</VField>
			<VField
				:isFloating="true"
				:label="$t('ra.apiUserDetails.username')"
				:class="{ 'is-active': apiUser.username }"
				:isError="!nameValid && modified"
			>
				<VInput
					:placeholder="$t('ra.apiUserDetails.username')"
					:disabled="!editMode"
					type="text"
					@input="markModified"
					v-model:modelValue="apiUser.username"
				/>
			</VField>

			<VNotification
				:text="$t('ra.apiUserDetails.nameError')"
				:isFullwidth="false"
				:hasClose="false"
				type="danger"
				v-if="!nameValid && modified"
			/>
		</div>
		<div class="has-margin-bottom-5">
			<div
				class="has-bottom-divider has-padding-bottom-2 has-margin-bottom-2"
			>
				<VTitle :size="2" :text="$t('ra.apiUserDetails.apiUserStatus')" />
			</div>
			<VField class="is-fullwidth">
				<VOption
					type="checkbox"
					:label="$t('ra.apiUserDetails.isActive')"
					wrapperClasses="has-margin-0 has-margin-right-05"
					:disabled="!editMode"
					@change="markModified"
					v-model:modelValue="apiUser.is_active"
				/>
			</VField>
			<div class="field field-parent is-floating is-active">
				<label class="label-floating">
					{{ $t('ra.apiUserDetails.last_time_edited') }}
				</label>
				<p>
					{{
						getDateForDisplay(apiUser.modified) ||
						$t('general.notApplicable')
					}}
				</p>
			</div>
		</div>
		<template #footer>
			<SaveButton
				:isDisabled="!modified || !nameValid"
				class="button-modal-footer"
				:text="$t('ra.apiUserDetails.saveButton')"
				icon="chevron-right"
				:iconOnRight="true"
				:callbackFn="saveApiUser"
			/>
		</template>
	</DrawerItem>
</template>

<script>
import { mapGetters } from 'vuex';
import {
	getStoreAction,
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import { log } from '@assets/scripts/components/notifications';
import Helpers from '@assets/scripts/helpers';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { GET_API_USERS } from '@modules/RestApiBuilder/endpoints';
import usePermission from '@assets/scripts/composables/usePermission';

export default {
	name: 'ApiUserDetailsDrawer',
	props: {
		/**
		 * Index of this drawer
		 */
		drawerIndex: {
			type: Number,
			required: true,
			default: 0,
		},
		/**
		 * Unique key of this drawer
		 */
		id: {
			type: String,
			required: true,
		},
	},
	data: function () {
		return {
			editMode: usePermission('Upsert', 'RestApiBuilder'),
			userUsernames: [],
		};
	},
	mounted: async function () {
		const apiUsers = await useApiAsync(GET_API_USERS);

		if (apiUsers) {
			// get list of existing API user names, excluding
			// current API user
			apiUsers.forEach((user) => {
				if (user.guid !== this.apiUser.guid) {
					this.userUsernames.push(user.username.toLowerCase());
				}
			});
		}
	},
	computed: {
		...mapGetters({
			/**
			 * Get indicator whether current User has been modified
			 */
			modified: getStoreGetter('API_USER_MODIFIED', 'RA'),
			/**
			 * Get User that is currently being edited
			 */
			apiUser: getStoreGetter('CURRENT_API_USER', 'RA'),
		}),
		nameValid: function () {
			return (
				this.apiUser.username &&
				this.userUsernames.indexOf(this.apiUser.username.toLowerCase()) < 0
			);
		},
	},
	methods: {
		getDateForDisplay: function (date) {
			return Helpers.date.localeStringWithMinutes(date);
		},
		markModified: function () {
			if (this.modified) return;

			this.$store.commit(getStoreMutation('MARK_API_USER_MODIFIED', 'RA'));
		},
		saveApiUser: function () {
			this.$store.dispatch(getStoreAction('SAVE_CURRENT_API_USER', 'RA'), {
				drawerId: this.id, // id of drawer to close after save
			});
		},
		closeDrawer: function () {
			const closeDrawer = () => {
				// unset current API user
				this.$store.commit(getStoreMutation('UNSET_API_USER', 'RA'));

				// close drawer
				this.$store.commit(getStoreMutation('CLOSE_DRAWER'), this.id);
			};

			if (!this.modified) {
				// close immediately if config has not been modified
				closeDrawer();
			} else {
				// ask confirmation before closing if a change has been made
				// to the field config
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t('ra.apiUserDetails.close.confirm.title'),
					body: this.$t('ra.apiUserDetails.close.confirm.body'),
					confirmButtonText: this.$t(
						'ra.apiUserDetails.close.confirm.confirmButtonText'
					),
					confirmFn: () => {
						// close after confirmation
						closeDrawer();
					},
				});
			}
		},
		copyApiToken: async function (s) {
			await navigator.clipboard.writeText(s);
			log(this.$t('ra.apiUserDetails.copySuccess'), 'success');
		},
		refreshApiToken: function () {
			this.apiUser.api_token = Helpers.newGuid();
			this.markModified();
		},
	},
};
</script>
