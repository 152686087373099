import ActionSortingFieldsDrawer from './ActionSortingFieldsDrawer.vue';
import AddFieldDetailsDrawer from './AddFieldDetailsDrawer.vue';
import CheckConditionDrawer from './CheckConditionDrawer.vue';
import ChooseNewBlockDrawer from './ChooseNewBlockDrawer.vue';
import FlowDetailsDrawer from './FlowDetailsDrawer.vue';
import FunctionsDrawer from './FunctionsDrawer.vue';
import PredefinedFieldsDrawer from './PredefinedFieldsDrawer.vue';
import ReadFilterDrawer from './ReadFilterDrawer.vue';
import ResultFieldsDrawer from './ResultFieldsDrawer.vue';
import StartFieldDetailsDrawer from './StartFieldDetailsDrawer.vue';

export default {
	ActionSortingFieldsDrawer,
	AddFieldDetailsDrawer,
	CheckConditionDrawer,
	ChooseNewBlockDrawer,
	FlowDetailsDrawer,
	FunctionsDrawer,
	PredefinedFieldsDrawer,
	ReadFilterDrawer,
	ResultFieldsDrawer,
	StartFieldDetailsDrawer,
};
