import Validation from '@assets/scripts/components/validationChecks';

import Helpers from '@assets/scripts/helpers';
import Field from '@assets/scripts/components/field';
import i18n from '@assets/i18n';
import {
	operators,
	FILTER_TYPE_DYNAMIC,
	filterValueIsNullish,
} from '@modules/FlowBuilder/components/filtering';

import { store } from '@assets/scripts/components/store-proxy';
import { getStoreGetter } from '@assets/scripts/store/config';

// translate function of vue-i18n
const { t } = i18n.global;

/**
 * Validate check block and returns erros array
 * for a given block
 *
 * @param {Object} block
 *  Block to get errors
 *
 * @returns {Array}
 *	array of errors
 */
export const validateCheckBlock = async (block) => {
	let output = [];

	const setBlockError = (description) => {
		output.push(Helpers.createErrorObject(description, block.guid));
	};
	if (!Validation.isNonEmptyArray(block.config.rules)) {
		setBlockError(
			t('fb.flowErrors.noConditionGroup', {
				block: t('fb.blockTypes.check'),
			})
		);
	} else {
		block.config.rules.map((conditionsGroup) => {
			if (!Validation.isNonEmptyArray(conditionsGroup.rules)) {
				setBlockError(
					t('fb.flowErrors.noCondition', {
						block: t('fb.blockTypes.check'),
					})
				);
			} else {
				// get all block inputs
				const blockInputs = store.getters[
					getStoreGetter('BLOCK_INPUT', 'BLOCKS')
				](block.guid);

				conditionsGroup.rules.map((condition) => {
					// check if used field in condition exist in the flow vars
					if (
						!Validation.fieldInConditionExists(condition, blockInputs)
					) {
						setBlockError(
							t('fb.flowErrors.fieldInConditionNotExist', {
								fieldInCondition: Field.getNameAsPath(
									condition.name
								),
								block: t('fb.blockTypes.check'),
							})
						);
					}

					// check if a filter condition has an operator
					if (!Validation.conditionHasOperator(condition)) {
						setBlockError(
							t('fb.flowErrors.noOperatorChosen', {
								fieldName: Field.getNameAsPath(condition.name),
								block: t('fb.blockTypes.check'),
							})
						);
					} else {
						// check if a filter condition has a right operator
						if (
							!Validation.conditionHasCorrectOperator(
								condition,
								operators
							)
						) {
							setBlockError(
								t('fb.flowErrors.wrongOperatorChosen', {
									fieldName: Field.getNameAsPath(
										condition.name
									),
									block: t('fb.blockTypes.check'),
								})
							);
						}

						// check if operator has correct amount of values
						if (
							!Validation.conditionHasCorrectAmountOfValues(
								condition,
								operators
							)
						) {
							setBlockError(
								t('fb.flowErrors.wrongValueAmountForOperator', {
									fieldName: Field.getNameAsPath(
										condition.name
									),
									operator: condition.operator,
									block: t('fb.blockTypes.check'),
								})
							);
						}

						// check if condition is dynamic
						if (condition?.rule_type === FILTER_TYPE_DYNAMIC) {

							// loop over values
							condition?.values.forEach((val) => {
								// get separate variables for dynamic values
								const {
									field,
									offset,
									unit
								} = val;

								// check if offset and unit are either both set or both empty
								if (filterValueIsNullish(offset) !== filterValueIsNullish(unit)) {
									setBlockError(
										t('fb.flowErrors.offsetAndUnitShouldBothBeSetorUnset', {
											fieldName: Field.getNameAsPath(
												condition.name
											),
											block: t('fb.blockTypes.check'),
										})
									);
								}

								// check if 'field' var contains a flow variable and not a function
								// functions start with '$', like '$today'
								if (!field.startsWith('$')) {

									// check if used field exists in flow variables
									if (!Validation.fieldExistsInList(field, blockInputs)) {
										setBlockError(
											t('fb.flowErrors.usedCompareFieldDoesNotExist', {
												flowField: Field.getNameAsPath(
													field
												),
												fieldName: Field.getNameAsPath(
													condition.name
												),
												block: t('fb.blockTypes.check'),
											})
										);
									// check if the 2 fields can be compared with each other
									} else if (!Field.fieldCanBeComparedToType(
												Field.getByName(condition.name, blockInputs),
												Field.getByName(field, blockInputs)?.type
									)) {
										setBlockError(
											t('fb.flowErrors.usedCompareFieldWrongType', {
												flowField: Field.getNameAsPath(
													field
												),
												fieldName: Field.getNameAsPath(
													condition.name
												),
												block: t('fb.blockTypes.check'),
											})
										);
									}
								}
							});
						}
					}
				});
			}
		});
	}
	return output;
};
