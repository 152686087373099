import { IS_DEV } from '@assets/scripts/helpers';
import { isEmpty } from 'lodash';
import { constructFullMetaDocument } from '@modules/MetaDocumentBuilder/components/metadata-document';
import usePermission from '@assets/scripts/composables/usePermission';

export const names = {
	MODIFIED: 'modified',
	CAN_BE_EDITED: 'canBeEdited',
	CAN_BE_SAVED: 'canBeSaved',
	CAN_BE_PUBLISHED: 'canBePublished',
	METADATA_ACTIVE: 'metadataActive',
	CURRENT_META_DOCUMENT: 'currentMetaDocument',
	SHOW_CREATE_NEW: 'showCreateNew',
	FULL_META_DOCUMENT: 'fullMetaDocument',
};

export default {
	[names.MODIFIED]: ({ modified }) => {
		return modified;
	},
	[names.CAN_BE_EDITED]: ({ canBeModified }) => {
		// can be edited if it has never been published yet
		return canBeModified;
	},
	[names.CAN_BE_SAVED]: ({ currentMetaDocument, modified }) => {
		// can be saved only if it has never
		// been published yet, and it has been modified
		return modified && !currentMetaDocument.is_published;
	},
	[names.CAN_BE_PUBLISHED]: ({ canBeModified, modified }) => {
		// if a meta document has been modified, it can be published,
		// IF current user has correct permissions
		return (
			( canBeModified || modified ) &&
			usePermission('Upsert', 'MetaDocumentBuilder')
		);
	},
	[names.METADATA_ACTIVE]: ({ currentMetaDocument }) => {
		return Object.keys(currentMetaDocument).length > 0;
	},
	[names.CURRENT_META_DOCUMENT]: ({ currentMetaDocument }) => {
		return currentMetaDocument;
	},
	[names.SHOW_CREATE_NEW]: ({ showCreateNew }) => {
		return showCreateNew;
	},
	[names.FULL_META_DOCUMENT]: ({ currentMetaDocument }) => {
		return !isEmpty(currentMetaDocument) && IS_DEV ? constructFullMetaDocument(currentMetaDocument) : {};
	},
	debug: (state) => {
		// output whole state for debugging since Vue Devtools plugin
		// not (always) shows the updated state of the store, but it
		// does show the correct value of all getters
		return IS_DEV ? state : {};
	},
};
