import i18n from '@assets/i18n';
const TheInsightTagsPage = () => import('@modules/InsightTags/TheInsightTagsPage.vue');

// translate function of vue-i18n
const { t } = i18n.global;

export default [
	{
		path: '/insightTags',
		component: TheInsightTagsPage,
		name: 'insightTags',
		meta: {
			title: t('insightTags.pageTitle'),
			permission: 'Read',
			showInHome: true,
			showInSideNav: true,
			parent: 'clientModules',
			icon: 'gear',
			module: 'Insight Tags',
			order: 100,
		},
	},
];