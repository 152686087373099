/**
 * Translations for English
 * See https://vue-i18n.intlify.dev/guide/ on how to use
 */
 export default {
	pageTitle: 'Function Blocks',
	permissions: {
		label: 'Function Blocks',
		info: 'Permissions connected to the Function Blocks module',
		use: {
			label: 'Use Function Blocks',
			info: 'Permission to use the Function Blocks module',
		},
	},
};
