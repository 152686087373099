<template>
	<div class="base-layer app-layer is-active">
		<aside class="sidebar" v-if="isLoggedIn">
			<TheSideNavigation />
		</aside>
		<main class="main-content">
			<RouterView />
		</main>
	</div>
	<div class="drawer-layer app-layer">
		<Drawers v-if="isLoggedIn" />
	</div>
	<div
		class="popup-layer app-layer"
		:class="{ 'is-active': confirmationActive }"
	>
		<!-- Popups (small modals, confirmations etc) will be placed here -->
		<TheConfirmation v-if="confirmationActive" />
	</div>
	<div
		class="inactivity-warning-layer app-layer has-background"
		:class="{ 'is-active': showInactivityWarning }"
	>
		<TheInactivityWarning v-if="showInactivityWarning" :secondsLeft="secondsUntilAutoLogout" />
	</div>
	<div class="notification-layer app-layer">
		<TheNotificationBar />
	</div>
	<div
		class="loader-layer app-layer has-background"
		:class="{ 'is-active': isLoading }"
	>
		<VLoader :active="isLoading" />
	</div>
</template>

<script>
import { IS_DEV } from '@assets/scripts/helpers';
import { useStore } from 'vuex';
import { watch, unref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useIdle, useTimestamp } from '@vueuse/core';
import {
	getStoreAction,
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import { fetchAvailableModules } from '@assets/scripts/composables/useModules';
import useJWT from '@assets/scripts/composables/useJWT';
import { clearCache } from '@assets/scripts/composables/useApiCache';
import {
	dataLossPossible,
	reset as resetDataLoss,
} from '@assets/scripts/composables/usePreventDataLoss';

import TheSideNavigation from '@materials/TheSideNavigation.vue';
import TheNotificationBar from '@materials/TheNotificationBar.vue';
import TheConfirmation from '@materials/TheConfirmation.vue';
import TheInactivityWarning from '@materials/TheInactivityWarning.vue';
import Drawers from '@materials/components/drawer/Drawers.vue';
import ability, { userHasAccess } from '@assets/scripts/auth';
import { log } from '@assets/scripts/components/notifications';

export default {
	name: 'App',
	components: {
		TheSideNavigation,
		TheNotificationBar,
		TheConfirmation,
		TheInactivityWarning,
		Drawers,
	},
	setup: function () {
		const route = useRoute();
		const router = useRouter();
		const store = useStore();
		const { t } = useI18n();

		const { init: initJWT, isLoggedIn, logout } = useJWT();

		// initialize store
		store.dispatch(getStoreAction('INIT'));
		
		// boolean to indicate if a loader should be displayed
		const isLoading = computed(() => store.getters[getStoreGetter('IS_LOADING')]);
		// boolean to indicate whether the confirmation popup should be displayed
		const confirmationActive = computed(() => store.getters[getStoreGetter('CONFIRMATION_ACTIVE')]);

		// initialize JWT
		initJWT();

		// watch for changes to the logged in status
		watch(isLoggedIn, (newValue) => {
			// clear api cache when user logged in status changes
			clearCache();

			if (!newValue) {
				// if visitor is not logged in the whole store state
				// should be reset, since the persisted state in the
				// store might contain data from a previous session

				// reset store to empty out module state
				store.dispatch(getStoreAction('RESET'));

				// redirect to login page
				router.push({ name: 'login' });
			}
		}, {
			immediate: true,
		});

		// max idle time in seconds before auto logout
		const maxIdleTime = 60 * 20;

		// time in seconds before auto logout to show warning about auto logout
		const idleWarningTime = 60;

		// see https://vueuse.org/core/useIdle/
		const { idle, lastActive } = useIdle(maxIdleTime * 1000);

		const now = useTimestamp({ interval: 1000 });

		const idledFor = computed(() =>
			Math.floor((unref(now) - unref(lastActive)) / 1000));

		const secondsUntilAutoLogout = computed(() =>
			maxIdleTime - unref(idledFor));

		const showInactivityWarning = computed(() =>
			(unref(secondsUntilAutoLogout) <= idleWarningTime) && unref(isLoggedIn));

		watch(idle, (nowIdle) => {
			if (nowIdle && unref(isLoggedIn)) {
				// auto logout
				logout();

				// show message
				log(t('logout.autoLoggedOutAfterInactivity'), 'warning');
			}
		});
		
		// default redirect url
		const redirectUrl = { name: 'home' };

		// subscribe to updates to abilities
		// https://casl.js.org/v6/en/guide/intro#update-rules
		ability.on('updated', () => {
			// set new access per route
			// checking if user has access when logging out and in redirect to home if no access
			if (!userHasAccess(route)) {
				router.push(redirectUrl);
			}
		});
		
		// fetch available modules
		fetchAvailableModules();

		let routesInitialized = false;

		router.beforeEach((to) => {
			const closeAllDrawers = () => {
				// close all drawers
				store.dispatch(
					getStoreAction('CLOSE_ALL_DRAWERS'),
					{},
					{ root: true }
				);
			};
			// check if user is logged in
			if (
				!unref(isLoggedIn) &&
				![
					'login',
					'resetPassword',
					'requestPassword'
				].includes(to.name)
			) {
				// redirect to login page
				return { name: 'login' };
			}

			if (
				to.name === 'resetPassword' &&
				Object.keys(to.query).length === 0
			) {
				// redirect to request password page if
				// on reset password page without token
				return { name: 'requestPassword' };
			}

			if (
				unref(isLoggedIn) &&
				[
					'login',
					'resetPassword',
					'requestPassword'
				].includes(to.name)
			) {
				// redirect to home if logged in and trying to access
				// any of the login pages
				return redirectUrl;
			}

			if (!userHasAccess(to)) {
				// redirect to home if no access
				return redirectUrl;
			}

			// prevent triggering navigation guard on
			// initial page load
			if (!routesInitialized) {
				routesInitialized = true;
				return true;
			}

			if (!unref(dataLossPossible)) {
				// close all drawers before routing
				closeAllDrawers();
				return true;
			}

			return new Promise((resolve, reject) => {
				store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: t('sideNavigation.leave.confirm.title'),
					body: t('sideNavigation.leave.confirm.body'),
					confirmButtonText: t(
						'sideNavigation.leave.confirm.confirmButtonText'
					),
					cancelButtonText: t(
						'sideNavigation.leave.confirm.cancelButtonText'
					),
					confirmFn: () => {
						// close all drawers before routing
						closeAllDrawers();
						resetDataLoss();
						resolve(true);
					},
					cancelFn: () => {
						reject();
					},
				});
			});
		});

		window.addEventListener('beforeunload', (e) => {
			// prevent confirm prompt for each auto-reload after
			// code change on dev env
			if (unref(dataLossPossible) && !IS_DEV) {
				e.preventDefault();
				// Chrome requires returnValue to be set
				e.returnValue = '';
			}
		});

		return {
			isLoggedIn,
			isLoading,
			confirmationActive,
			secondsUntilAutoLogout,
			showInactivityWarning,
		};
	},
};
</script>
