import { getDefaultState } from './state';
import { getStoreGetter, getStoreMutation } from '@assets/scripts/store/config';
import {
	userCanModifyActionDoc,
	flattenActionDocument,
} from '@modules/ActionDocument/components/action-document';

export const names = {
	TOGGLE_CREATE_NEW: 'toggleCreateNewDocument',
	SET_CURRENT_ACTION_DOCUMENT: 'setCurrentActionDocument',
	UPDATE_CURRENT_ACTION_DOCUMENT: 'updateCurrentActionDocument',
	UNSET_ACTION_DOCUMENT: 'unsetActionDocument',
	SET_CURRENT_CONNECTION: 'setCurrentConnection',
	MARK_MODIFIED: 'markModified',
	SET_PUBLISHED_STATE: 'setPublishedState',
	RESET: 'reset',
};

export default {
	[names.TOGGLE_CREATE_NEW](state, status = true) {
		if (state.showCreateNew !== status) state.showCreateNew = status;
	},
	/**
	 * Sets the current action document to newly retrieved document
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {Object} document
	 *  Retrieved action document from Nominow REST API
	 *
	 * @returns {void}
	 */
	[names.SET_CURRENT_ACTION_DOCUMENT](state, actionDocument) {
		// save action document to state
		state.currentActionDocument = flattenActionDocument(actionDocument);

		// a newly loaded action document is not modified yet
		state.modified = false;

		// determine if action document can be modified
		state.canBeModified = userCanModifyActionDoc(actionDocument);
	},
	/**
	 * Updates the current action document to given (already flattened) document
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {Object} actionDocument
	 *  Flattened action document edited by user through UI
	 *
	 * @returns {void}
	 */
	[names.UPDATE_CURRENT_ACTION_DOCUMENT](state, actionDocument) {
		// save action document to state
		state.currentActionDocument = actionDocument;

		// mark action document as modified
		state.modified = true;
	},
	/**
	 * Unsets the current action document
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @returns {void}
	 */
	[names.UNSET_ACTION_DOCUMENT](state) {
		// unset current action document in state
		state.currentActionDocument = {};

		// unset modified marker
		state.modified = false;
	},
	/**
	 * Sets the current connection to given connection
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {Object} connection
	 *  Retrieved connection from Nominow REST API
	 *
	 * @returns {void}
	 */
	[names.SET_CURRENT_CONNECTION](state, connection) {
		// save connection guid to state
		state.currentConnection = connection.conn_guid;
	},
	/**
	 * Marks the current document as modified
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @returns {void}
	 */
	[names.MARK_MODIFIED](state) {
		state.modified = true;

		// every change to the action document means that the action document should be validated again
		this.commit(
			getStoreMutation('SET_IS_VALID'), false, { root: true }
		);
	},
	/**
	 * Updates the published state of the current action document
	 *
	 * @param {Object} currentActionDocument
	 *	Currently active action document
	 *
	 * @param {Boolean} status
	 *  Boolean indicating whether current action document is
	 *  published
	 */
	[names.SET_PUBLISHED_STATE]({ currentActionDocument }, status = false) {
		currentActionDocument.is_published = status;
	},
	/**
	 * Resets module state to default
	 *
	 * @param {Object} state
	 *  Ref to current module state
	 *
	 * @returns {void}
	 */
	[names.RESET](state) {
		Object.assign(state, getDefaultState());
	},
};
