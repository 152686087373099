<template>
	<DrawerItem
		:id="id"
		:drawerIndex="drawerIndex"
		:title="drawerName || null"
		:hasJsonButton="false"
		:hasFullwidthBody="true"
		:closeOnBackgroundClick="true"
		:onClose="closeDrawer"
	>
		<div class="spaced-content">
			<FieldConfigMain
				:fields="fields"
				:existingFields="blockInput"
				:fieldKey="fieldKey"
				v-model:valueModel="field"
				@update:valueModel="markModified"
				v-model:nameValid="nameValid"
				:editMode="editMode"
				:parents="parents"
				context="flow|add"
			/>

			<template v-if="showAppendTypeChoice">
				<VTitle
					:size="2"
					:text="$t('fb.addFieldDetails.chooseFieldValue')"
				/>

				<VField class="is-fullwidth">
					<VOption
						v-for="(item, key) in appendTypeOptions"
						:key="key"
						:value="item.id"
						:label="item.label"
						:disabled="item.disabled || null"
						v-model="field.add_type"
						@update:modelValue="appendTypeChanged"
						type="radio"
					/>
				</VField>
			</template>

			<div
				v-if="showStaticValueOptions"
				class="content align-with-checkbox-label"
			>
				<Component
					:is="staticValueComponent"
					v-model:valueModel="field.value"
					@update:valueModel="markModified"
					:isDisabled="!editMode"
				/>
			</div>

			<div
				v-if="showSystemValueOptions"
				class="content align-with-checkbox-label"
			>
				<VField
					:label="$t('fb.addFieldDetails.chooseSystemValue')"
					:isFloating="true"
					:class="{ 'is-active': field.value }"
				>
					<VSelect
						:options="defaultValueTypeOptions"
						v-model="field.value"
						@change="markModified"
						:disabled="!editMode"
					/>
				</VField>
			</div>
			<!-- NOTE: this is temp out for version 1.0 we will have functions in 1.1 -->
			<!-- <div
				v-if="showFunctionOption"
				class="content align-with-checkbox-label"
			>
				<VTag
					v-if="currentFunction.guid"
					:text="currentFunction.name"
					:isActive="true"
					@deleted="setFunction"
					:isDisabled="!editMode"
				/>
				<VButton
					v-else
					:text="$t('fb.addFieldDetails.selectFunction')"
					variant="is-secondary"
					@clicked.prevent.stop="openFunctionDrawer"
					:disabled="!editMode"
				/>
				<div v-if="functionError" class="has-margin-top-1">
					<VNotification
						:text="$t('fb.functions.loadingError')"
						:hasClose="false"
						type="danger"
					/>
				</div>
			</div> -->
		</div>

		<!-- NOTE: this is temp out for version 1.0 we will have functions in 1.1 -->
		<!-- <div
			v-if="showFunctionOption && currentFunction.input"
			class="has-margin-bottom-075 has-bottom-divider"
		>
			<div class="spaced-content">
				<VTitle
					:size="3"
					:text="$t('fb.functions.mapping.configureMapping')"
					class="has-padding-bottom-05 has-margin-top-2"
				/>
			</div>
		</div>

		<SortableDataTable
			v-if="showFunctionOption && currentFunction.input"
			:hoverable="false"
			:scrollable="false"
			:stickyHeader="false"
			:narrowed="false"
			defaultSort="to"
			:data="mappingTableRows"
			:columns="mappingColumns"
		>
			<template
				v-for="(row, key) in mappingTableRows"
				:key="key"
				#[`from-${key}`]
			>
				<VField
					:label="$t('fb.functions.mapping.chooseField')"
					:isFloating="true"
					:class="{
						'is-active': field.mapping[key]['from'],
					}"
				>
					<VSelect
						:options="fieldOptions(row.type, blockInput)"
						v-model="field.mapping[key]['from']"
						@update:modelValue="mappingUpdated"
						:disabled="!editMode"
					/>
				</VField>
			</template>
		</SortableDataTable> -->
		<template v-if="editMode" #footer>
			<SaveButton
				:isDisabled="!modified || !isValid"
				class="button-modal-footer"
				:text="$t('fb.addFieldDetails.saveButton')"
				icon="chevron-right"
				:iconOnRight="true"
				:callbackFn="saveField"
			/>
		</template>
	</DrawerItem>
</template>

<script>
import { mapGetters } from 'vuex';
import {
	getStoreAction,
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import Field from '@assets/scripts/components/field';
import { functionAvailableForType } from '@modules/FlowBuilder/components/functions';
import { staticValue } from '@assets/scripts/api/config';
import Helpers from '@assets/scripts/helpers';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { addBlockAppendFieldMeta } from '@modules/FlowBuilder/endpoints';
import { GET_LIST_BY_NAME } from '@assets/scripts/api/config';

export default {
	name: 'AddFieldDetailsDrawer',
	props: {
		/**
		 * Index of this drawer
		 */
		drawerIndex: {
			type: Number,
			required: true,
			default: 0,
		},
		config: {
			required: true,
		},
		/**
		 * Unique key of this drawer
		 */
		id: {
			type: String,
			required: true,
		},
	},
	data: function () {
		return {
			field: Field.createNew({}, addBlockAppendFieldMeta, true),
			parents: [],
			fieldKey: this.config || 0,
			modified: false,
			nameValid: true,
			defaultValueTypes: [],
			typesForStaticOption: [],
			typesForSystemOption: [],
			currentFunction: {},
			mappingCache: {},
			fieldOptions: Field.createFieldOptions,
			// definition of table columns
			mappingColumns: [
				{
					label: this.$t('fb.functions.mapping.columns.systemField'),
					field: 'to',
					sortable: true,
					searchable: false,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('fb.functions.mapping.columns.flowField'),
					field: 'from',
					sortable: false,
					searchable: false,
					default: this.$t('general.dash'),
					component: 'VSelect',
				},
				{
					label: this.$t('fb.functions.mapping.columns.required'),
					field: 'required',
					sortable: false,
					searchable: false,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('fb.functions.mapping.columns.type'),
					field: 'field_type',
					sortable: true,
					searchable: false,
					default: this.$t('general.dash'),
				},
			],
		};
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate if flow is in Edit mode
			 */
			editMode: getStoreGetter('EDIT_MODE', 'FLOW'),
			/**
			 * Get add block fields of block that is currently viewed/edited
			 */
			fields: getStoreGetter('ADD_BLOCK_FIELDS', 'BLOCKS'),
			/**
			 * Get block that is currently viewed/edited
			 */
			block: getStoreGetter('CURRENT_BLOCK', 'BLOCKS'),
		}),
		blockInput: function () {
			if (!this.block) return [];

			// get calculated input for block
			return this.$store.getters[
				getStoreGetter('BLOCK_INPUT', 'BLOCKS')
			](this.block.guid);
		},
		isValid: function () {
			return this.nameValid;
		},
		drawerName: function () {
			return Field.getNameAsPath(this.field.name);
		},
		showAppendTypeChoice: function () {
			return this.nameValid && this.field.type.length > 0;
		},
		showStaticValueOptions: function () {
			return (
				this.nameValid &&
				this.field.add_type === 'Static' &&
				this.staticOptionAvailable
			);
		},
		showSystemValueOptions: function () {
			return (
				this.nameValid &&
				this.field.add_type === 'System' &&
				this.systemOptionAvailable
			);
		},
		showFunctionOption: function () {
			return this.nameValid && this.field.add_type === 'Function';
		},
		staticOptionAvailable: function () {
			return this.typesForStaticOption.includes(
				this.field.type
			);
		},
		systemOptionAvailable: function () {
			return this.typesForSystemOption.includes(
				this.field.type
			);
		},
		appendTypeOptions: function () {
			return [
				{
					id: 'Static',
					label: this.$t('fb.addFieldDetails.valueOptions.static'),
					disabled: !this.staticOptionAvailable || !this.editMode,
				},
				{
					id: 'System',
					label: this.$t('fb.addFieldDetails.valueOptions.system'),
					disabled: !this.systemOptionAvailable || !this.editMode,
				},
				// NOTE: this is temp out for version 1.0 we will have functions in 1.1 
				// {
				// 	id: 'Function',
				// 	label: this.$t('fb.addFieldDetails.valueOptions.function'),
				// 	disabled: !this.editMode,
				// },
			];
		},
		defaultValueTypeOptions: function () {
			// check if field type is chosen and default value
			// type options have been loaded
			if (
				this.field.type.length < 1 ||
				typeof this.defaultValueTypes !== 'object' ||
				this.defaultValueTypes.length < 1
			)
				return [];

			// filter default value types for options applicable for
			// chosen field type, and exclude static value
			const options = this.defaultValueTypes.filter((row) => {
				return (
					row.type
						.map((v) => v.toLowerCase())
						.includes(this.field.type.toLowerCase()) &&
					row.key !== staticValue
				);
			});

			// format options to use in VSelect component
			if (options.length > 0) {
				return Helpers.mapObjectArrayForSelect(options, {
					key: 'key',
					text: 'value',
				});
			}

			return [];
		},
		staticValueComponent: function () {
			if (
				this.field.type.length <= 0 ||
				this.field.add_type !== 'Static' ||
				!this.staticOptionAvailable
			)
				return false;

			return this.field.type + 'DefaultValue';
		},
		mappingTableRows: function () {
			const rows = [];

			if (!this.currentFunction.guid) return rows;

			// loop over input fields for Function
			this.currentFunction.input.forEach((row, index) => {
				rows.push({
					to: Field.getNameAsPath(row.name),
					required: row.validation.required
						? this.$t('general.yes')
						: this.$t('general.no'),
					type: row.type,
					field_type: Field.translateVarType(row.type),
					key: index,
				});
			});

			return rows;
		},
	},
	mounted: async function () {
		// get field from fields list
		this.field =
			this.fields && typeof this.fields[this.fieldKey] !== 'undefined'
				? Helpers.cloneObject(this.fields[this.fieldKey])
				: this.field;

		/**
		 * Get all parent fields for current block, excluding current field
		 */
		// TODO: restore when needed. Temporarily return false
		// until Array/Object are supported again in the FlowBuilder
		this.parents = false;
		// this.parents = this.$store.getters[
		// 	getStoreGetter('PARENT_FIELDS_FROM_CURRENT_BLOCK', 'BLOCKS')
		// ](this.field);

		// add currently configured mapping for field into cache
		if (this.field.function_guid && this.field.mapping)
			this.setMappingCacheForGuid(this.field.function_guid);

		// get info about function
		this.setFunction(this.field.function_guid || false, true);

		// get all default value options
		const defaultValueTypes = await useApiAsync(GET_LIST_BY_NAME, {
			parameters: {
				name: 'DefaultValueTypes'
			}
		});

		if (defaultValueTypes) this.defaultValueTypes = defaultValueTypes.items;

		// translate default value options
		if (this.defaultValueTypes.length > 0) {
			// loop over options
			this.defaultValueTypes.forEach((row) => {
				// set value to translated version, based on key
				row.value = this.$t('defaultType.' + row.key);

				// determine all variable types that can be configured
				// with a static value
				if (row.key === staticValue) {
					this.typesForStaticOption = row.type;
				} else {
					this.typesForSystemOption =
						this.typesForSystemOption.concat(row.type);
				}
			});
		}
	},
	methods: {
		markModified: function () {
			this.modified = true;
		},
		appendTypeChanged: function () {
			this.field.value = '';
			this.functionError = false;
			this.markModified();
		},
		openFunctionDrawer: function () {
			// open drawer with functions
			this.$store.commit(getStoreMutation('OPEN_DRAWER'), {
				type: 'functions',
				data: {
					type: this.field.type,
					callback: (guid) => {
						this.setFunction(guid);
					},
				},
			});
		},
		setFunction: async function (guid = false, initial = false) {
			this.currentFunction = {};
			this.functionError = false;
			let mapping = [];

			if (guid) {
				// load selected Function
				const item = await this.$store.dispatch(
					getStoreAction('FUNCTION_BY_ID', 'FB'),
					guid
				);

				if (item) {
					this.currentFunction = item;

					item.input.forEach((row) => {
						mapping.push(this.getFromMappingCache(row.name));
					});
				} else {
					this.functionError = true;
				}
			}

			// set function guid in field
			Helpers.obj.setProp(
				'function_guid',
				this.field,
				this.currentFunction.guid || null,
				true
			);

			// set function connection guid in field
			Helpers.obj.setProp(
				'conn_guid',
				this.field,
				this.currentFunction.conn_guid || null,
				true
			);

			// set function name in field
			Helpers.obj.setProp(
				'function_name',
				this.field,
				this.currentFunction.name || '',
				true
			);

			// set function mapping in field
			Helpers.obj.setProp('mapping', this.field, mapping, true);

			if (!initial) this.markModified();
		},
		saveField: async function () {
			// unset value and append type if not applicable
			if (
				(this.field.add_type === 'Static' &&
					!this.staticOptionAvailable) ||
				(this.field.add_type === 'System' &&
					!this.systemOptionAvailable)
			) {
				this.field.add_type = null;
				this.field.value = null;
			}

			// unset function if it is no  longer available
			// after user changed the Field Type

			// get guid of currently selected Function
			const functionGuid = this.field.function_guid || false;

			if (functionGuid) {
				// check if Function List is available for current var type
				const isAvailable = await functionAvailableForType(
					functionGuid,
					this.field.type
				);
				// unset selected Function list if not available
				if (!isAvailable) await this.setFunction();
			}

			this.$store.dispatch(getStoreAction('UPDATE_FIELD', 'BLOCKS'), {
				key: this.fieldKey, // key of current field in field list
				drawerId: this.id, // id of drawer to close after save
				field: this.field,
				type: 'add',
			});
		},
		closeDrawer: function () {
			const closeDrawer = () => {
				this.$store.commit(getStoreMutation('CLOSE_DRAWER'), this.id);
			};

			if (!this.modified) {
				// close immediately if config has not been modified
				closeDrawer();
			} else {
				// ask confirmation before closing if a change has been made
				// to the field config
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t(
						'fb.blockDetails.configDetails.confirmClose.title'
					),
					body: this.$t(
						'fb.blockDetails.configDetails.confirmClose.body'
					),
					confirmButtonText: this.$t(
						'fb.blockDetails.configDetails.confirmClose.confirmButtonText'
					),
					confirmFn: () => {
						// close after confirmation
						closeDrawer();
					},
				});
			}
		},
		setMappingCache: function () {
			if (!this.currentFunction.guid) return;

			this.setMappingCacheForGuid(this.currentFunction.guid);
		},
		setMappingCacheForGuid: function (guid) {
			if (!guid) return;

			// update cache, used when switching between Actions
			this.mappingCache[guid] = Helpers.cloneObject(this.field.mapping);
		},
		mappingUpdated: function () {
			this.setMappingCache();

			this.markModified();
		},
		getFromMappingCache: function (name) {
			const result = {
				to: name,
				from: '',
			};

			if (
				!this.currentFunction.guid ||
				!this.mappingCache[this.currentFunction.guid]
			)
				return result;

			if (this.mappingCache[this.currentFunction.guid]) {
				const cached = this.mappingCache[
					this.currentFunction.guid
				].filter((row) => row.to === name);

				if (cached.length > 0) {
					result.from = cached[0].from || '';
				}
			}

			return result;
		},
	},
};
</script>
