import i18n from '@assets/i18n';
const TheUserManagementPage = () => import('./materials/TheUserManagementPage.vue');

// translate function of vue-i18n
const { t } = i18n.global;

export default [
	{
		path: '/usermanagement',
		component: TheUserManagementPage,
		name: 'userManagement',
		meta: {
			title: t('user.pageTitle'),
			permission: 'Read',
			showInSideNav: true,
			showInHome: true,
			icon: 'customers',
			parent: 'management',
			module: 'UserManagement',
			order: 35,
		},
	},
];