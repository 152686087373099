import Helpers from '@assets/scripts/helpers';
import { restFlowMeta } from '@modules/FlowBuilder/endpoints';
import { store } from '@assets/scripts/components/store-proxy';
import { constructBlocks } from '@modules/FlowBuilder/components/block';
import { getStoreGetter } from '@assets/scripts/store/config';
import { isEmpty } from 'lodash';
import i18n from '@assets/i18n';
import usePermission from '@assets/scripts/composables/usePermission';

// translate function of vue-i18n
const { t } = i18n.global;

// possible statuses for flow versions
const statuses = {
	ARCHIVED: 'archived',
	PUBLISHED: 'published',
	DRAFT: 'draft',
	INACTIVE: 'inactive',
};

/**
 * Returns a newly created flow
 *
 * @param {String} method
 *  Method the Flow will use, either 'POST' or 'GET'
 *
 * @param {String} api
 *  GUID of REST API the Flow will be part of
 *
 * @param {String} api_name
 *  Name of REST API the Flow will be part of
 *
 * @param {String} name
 *  Name of the Flow
 *
 * @param {Boolean} is_script
 *  Indicator whether the Flow uses the Script Service Workflow
 *
 * @returns {Object}
 *  New Flow
 */
export const createNewFlow = ({ method, api, api_name, name, is_script = false }) => {
	// get current date
	const dateIso = new Date().toISOString();

	// create and return new flow
	return Helpers.obj.create(restFlowMeta, {
		guid: Helpers.newGuid(),
		version: Helpers.newGuid(),
		created: dateIso,
		modified: dateIso,
		name,
		method,
		api,
		api_name,
		is_script,
		is_new: true,
		is_active: false,
	});
};

export const trimFlowName = (flowName) => {
	// only allow lower and uppercase letters and numbers and underscroe and slash
	const regex2 = new RegExp('(^[/])|([^a-z0-9_/])|(\\/\\/)', 'gi');
	return flowName.replace(regex2, '');
};

/**
 * Returns a duplicate of a given flow, with the
 * correct properties updated/reset
 *
 * @param {Object} flow
 *  Flow to duplicate (normalized)
 *
 * @returns {Object}
 *  Duplicated flow
 */
export const duplicateFlow = (flow) => {
	const dateIso = new Date().toISOString();
	// clone given flow, and reset needed properties
	return Helpers.obj.create(restFlowMeta, {
		...Helpers.cloneObject(flow),
		created: dateIso,
		modified: dateIso,
		published: '',
		last_published: '',
		archived: '',
		is_published: false,
		version: Helpers.newGuid(),
	});
};

/**
 * Adds a Block to a given Flow
 *
 * N.B.: Only use this function to add Blocks
 *       to a Flow that is not set as the Current
 *       Flow in the Store. Adding and removing
 *       blocks from the current flow uses different
 *       procedures.
 *
 * @param {Object} flow
 *  Flow Object to add block to (normalized)
 *
 * @param {Object} block
 *  Block to add to flow (normalized)
 *
 * @returns {Boolean}
 *  True on success, false otherwise
 */
export const addBlockToFlow = (flow, block) => {
	// get blocks of Flow
	const blocks = Helpers.obj.getProp('flow|blocks', flow, false);

	// throw error if blocks not found
	if (blocks === false) {
		throw new Error('Blocks not found on Flow');
	}

	// add new block to blocks array
	blocks.push(block);

	return true;
};

/**
 * Function to set the name of a given flow
 *
 * @param {Object} flow
 *  Flow to set name of (normalized)
 *
 * @param {String} name
 *  Name to set
 *
 * @returns {Boolean}
 *  Succes indicator
 */
export const setFlowName = (flow, name) => {
	return Helpers.obj.setProp('name', flow, name, true);
};

/**
 * Get indicator whether given flow is
 * marked as active
 *
 * @param {Object} flow
 *  Flow to get status for (normalized)
 *
 * @returns {Boolean}
 */
export const getFlowActive = (flow) => {
	return Helpers.trueish(flow.is_published) && Helpers.trueish(flow.is_active);
};

/**
 * Checks if a given flow has a draft version
 *
 * @param {Object} flow
 *  Flow to check (normalized)
 *
 * @returns {Boolean}
 */
export const flowHasDraft = (flow) => {
	// loop over all versions
	return flow.versions.some((version) => {
		// get full flow version
		const fullVersion = getVersionAsFlow(flow, version);

		// determine status
		return getFlowStatus(fullVersion) === statuses.DRAFT;
	});
};

/**
 * Gets the status of a given flow
 * See https://thevalley.atlassian.net/wiki/spaces/NMN/pages/275120129/Flow+endpoint+status for
 * which status takes precedence in which case
 *
 * @param {Object} flow
 *  Flow to get status of (normalized)
 *
 * @returns {String}
 *  Status of given flow
 */
const getFlowStatus = (flow) => {
	// determine status
	let status = statuses.DRAFT;

	// check if flow is archived
	if (flow.archived) {
		status = statuses.ARCHIVED;
	} else {
		// check if flow is published
		if (Helpers.trueish(flow.is_published)) {
			status = statuses.PUBLISHED;

			// check if flow is inactive (only possible
			// for flow itself, not for versions)
			if (typeof flow.is_active === 'boolean' && !flow.is_active) {
				status = statuses.INACTIVE;
			}
		}
	}

	return status;
};

/**
 * Gets the status of a given flow as human readable text,
 * i.e.: Active, Published, Archived etc.
 * Meant for display purposes, not for checking conditions
 *
 * @param {Object} flow
 *  Flow to get status of (normalized)
 *
 * @returns {String}
 *  Translated human readable status
 */
const getFlowStatusName = (flow) => {
	let output;

	// determine status
	switch (getFlowStatus(flow)) {
		case statuses.DRAFT:
			output = t('general.draft');
			break;
		case statuses.INACTIVE:
			output = t('general.inactive');
			break;
		case statuses.ARCHIVED:
			output = t('general.archived');
			break;
		case statuses.PUBLISHED:
			output = t('general.published');
			break;
	}

	return output;
};

/**
 * Checks if given Flow is published
 *
 * @param {Object} flow
 *  Flow to check
 *
 * @returns {Boolean}
 */
const isPublishedFlow = (flow) => { // TODO: check
	return Helpers.trueish(flow.is_published);
};

/**
 * Checks if given Flow is archived
 *
 * @param {Object} flow
 *  Flow to check
 *
 * @returns {Boolean}
 */
const isArchivedFlow = (flow) => {
	return flow.archived || false;
};

/**
 * Checks whether a given Flow is a 'Special' flow
 * Which means that it either uses Script Service or
 * uses Put or Delete method
 *
 * @param {Object} flow
 *  Flow to check
 *
 * @returns {Boolean}
 */
const isSpecialFlow = (flow) => {
	return flow.is_script || ['put', 'delete'].includes(flow.method.toLowerCase());
};

/**
 * Sets a given GUID as Start GUID for a
 * given Flow
 *
 * @param {Object} flow
 *  Flow to set Start GUID for (normalized)
 *
 * @param {String} guid
 *  GUID to set as Start GUID
 *
 * @returns {Boolean}
 */
export const setStartGuid = (flow, guid) => {
	return Helpers.obj.setProp('flow|start_block', flow, guid, true);
};

/**
 * Sets a given GUID as Result GUID for a
 * given Flow
 *
 * @param {Object} flow
 *  Flow to set Result GUID for (normalized)
 *
 * @param {String} guid
 *  GUID to set as Result GUID
 *
 * @returns {Boolean}
 */
export const setResultGuid = (flow, guid) => {
	return Helpers.obj.setProp('flow|result_block', flow, guid, true);
};

/**
 * Get info about flow to use in Table in FlowList component
 *
 * @param {Object} flow
 *  Flow to return formatted (normalized)
 *
 * @returns {Object}
 * 	Obejct with info about given flow
 */
export const formatForTable = (flow) => {
	// get last modified time
	const lastModified = flow.modified;

	// get flow method
	const flowMethod = flow.method;

	// set flow method to display
	let method = t('general.dash');
	if (flowMethod === 'POST') method = t('general.methods.post');
	else if (flowMethod === 'GET') method = t('general.methods.get');
	else if (flowMethod === 'PUT') method = t('general.methods.put');
	else if (flowMethod === 'DELETE') method = t('general.methods.delete');

	// determine status
	const status = getFlowStatusName(flow);

	return {
		flow_name: flow.name || t('general.dash'),
		method,
		// time as ISO string, used for sorting
		time: lastModified,
		// localized time for display
		last_time_edited: lastModified
			? Helpers.date.localeStringWithMinutes(lastModified)
			: t('general.dash'),
		status,
		guid: flow.guid,
		rest_api: flow.api,
		rest_api_name: flow.api_name,
	};
};

/**
 * Get info about flow versions formatted for
 * use in FlowHistory component table
 *
 * @param {Object} flow
 *  Flow to format for output in table (normalized)
 *
 * @returns {Array}
 *  Array of objects per table row
 */
export const formatForVersionTable = (flow) => {
	const result = [];

	flow.versions.forEach((row) => {
		const version = getVersionAsFlow(flow, row);

		// get publish time
		const publishTime = version.published;

		// get last modified time
		const lastModified = version.modified;

		// determine status
		const status = getFlowStatusName(version);

		result.push({
			// time as ISO string, used for sorting
			publish_time: publishTime,
			// localized time for display
			publish_date: publishTime
				? Helpers.date.localeStringWithMinutes(publishTime)
				: t('general.dash'),
			// time as ISO string, used for sorting
			edited_time: lastModified,
			// localized time for display
			last_time_edited: lastModified
				? Helpers.date.localeStringWithMinutes(lastModified)
				: t('general.dash'),
			// status as translated string
			status,
			// GUID of flow
			guid: version.guid,
			// version GUID
			version_guid: version.version,
			preview: usePermission('Read', 'FlowBuilder'),
			duplicate: userCanDuplicateFlow(version),
			delete: userCanDeleteFlow(version),
		});
	});

	return result;
};

/**
 * When getting a Flow from the API without specifying a
 * Version GUID, the main version is received, with a property
 * 'Versions' that contains all versions, inc. the 'main' one.
 * These flow version objects itself do not contain all fields. Info
 * about the method, the name and the API are missing for instance.
 * But this information IS available in the main flow object and is
 * identical for all versions. So this function can be used to get a
 * pseudo flow object with combined info from the main version, with
 * specific info from the given version.
 * This is useful since many helper functions here need the complete
 * flow object, for instance to determine the flow type.
 *
 * @param {Object} flow
 *  Main flow object
 *
 * @param {Object} version
 *  Version object
 *
 * @returns {Object}
 *  Cloned version of given main Flow, with properties
 *  from given Version
 */
const getVersionAsFlow = (flow, version) => {
	const result = Object.assign(Helpers.cloneObject(flow), version);
	delete result.is_active; // versions do not have an active indicator
	return result;
};

/**
 * Checks if a given flow can be duplicated by the current user
 *
 * @param {Object} flow
 *  Flow to check
 *
 * @returns {Boolean}
 */
const userCanDuplicateFlow = (flow) => {
	const isSpecial = isSpecialFlow(flow);

	return (
		( isSpecial && usePermission('Upsert special flow', 'FlowBuilder') ) ||
		( !isSpecial && usePermission('Upsert', 'FlowBuilder') )
	);
}

/**
 * Check if a given flow can be modified by the current
 * user. It can ONLY be modified if ALL of these
 * conditions apply:
 *  1) Flow is not currently published
 *  2) Flow is not archived
 *  3) Current user has correct permissions
 *
 * @param {Object} flow
 *  Flow to check
 *
 * @returns {Boolean}
 */
export const userCanModifyFlow = (flow) => {
	const isSpecial = isSpecialFlow(flow);
	const isPublished = isPublishedFlow(flow);
	const isArchived = isArchivedFlow(flow);

	if (isSpecial) {
		if (
			!isPublished &&
			!isArchived &&
			usePermission('Upsert special flow', 'FlowBuilder')
		) {
			return true;
		}
	}
	else if (
		!isPublished &&
		!isArchived &&
		usePermission('Upsert', 'FlowBuilder')
	) {
		return true;
	}

	return false;
};

/**
 * Check if current user can delete a given flow
 *
 * @param {Object} flow
 *  Flow to check
 *
 * @returns {Boolean}
 */
const userCanDeleteFlow = (flow) => {
	const isSpecial = isSpecialFlow(flow);

	return (
		getFlowStatus(flow) === statuses.DRAFT && 
		(
			( isSpecial && usePermission('Delete special flow', 'FlowBuilder') ) ||
			(
				!isSpecial &&
				usePermission('Delete', 'FlowBuilder')
			)
		)
	);
};

/**
 * Constructs a Flow Object from the currently active flow
 * as can be used in communication with the Nominow API
 *
 * @returns {Object}
 *  Fully constructed Flow Object
 */
export const constructFullFlow = () => {
	// get current flow from store
	const currentFlow = store.getters[getStoreGetter('CURRENT_FLOW', 'FLOW')];

	// return empty object if no flow is currently active
	if (!currentFlow || isEmpty(currentFlow)) return {};

	// get current blocks from store
	// N.B.: Blocks NEED to be retrieved from Block store module
	// here, because added/deleted blocks are not in the Flow store
	// module state
	const blocks = store.getters[getStoreGetter('BLOCKS', 'BLOCKS')];

	// get fully constructed blocks
	const fullBlocks = constructBlocks(blocks);

	// get fully constructed flow, excluding the blocks
	const flow = Helpers.obj.construct(currentFlow, restFlowMeta, 'flow');

	// add constructed blocks to constructed flow
	Helpers.obj.setPropUsingMeta(
		restFlowMeta,
		'flow|blocks',
		flow,
		fullBlocks,
		true
	);

	// return complete flow
	return flow;
};
