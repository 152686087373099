import i18n from '@assets/i18n';
const TheQueuePage = () => import('@modules/Queue/TheQueuePage.vue');

// translate function of vue-i18n
const { t } = i18n.global;

export default [
	{
		path: '/queue',
		component: TheQueuePage,
		name: 'queue',
		meta: {
			title: t('queue.pageTitle'),
			permission: 'Read',
			showInHome: true,
			showInSideNav: true,
			parent: 'clientModules',
			icon: 'gear',
			module: 'Queue',
			order: 100,
		},
	},
];