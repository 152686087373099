import i18n from '@assets/i18n';
const TheMetadataBuilder = () => import('@modules/MetaDocumentBuilder/materials/TheMetadataBuilder.vue');

// translate function of vue-i18n
const { t } = i18n.global;

export default [
	{
		path: '/metadatabuilder',
		component: TheMetadataBuilder,
		name: 'metadataBuilder',
		meta: {
			title: t('mb.pageTitle'),
			permission: 'Read',
			showInSideNav: true,
			showInHome: true,
			icon: 'target',
			parent: 'admin',
			module: 'MetaDocumentBuilder',
			order: 45,
		},
	},
];