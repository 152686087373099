import { methodMeta } from '@modules/MethodBuilder/endpoints';
import { connectionDocumentMeta } from '@modules/DocumentBuilder/endpoints';

const apiBase = 'UIServices/ActionDocument';

const docFields = {
	name: {
		name: 'ActionName',
		type: 'string',
		default: '',
	},
	guid: {
		name: 'ActionGuid',
		type: 'string',
		default: '',
	},
	type: {
		name: 'ActionType',
		type: 'string',
		default: '',
	},
	is_mandatory: {
		name: 'IsMandatory',
		type: 'boolean',
		default: false,
	},
	is_updatable: {
		name: 'IsUpdate',
		type: 'boolean',
		default: false,
	},
	required: {
		name: "RequiredFields",
		type: 'array',
		default: [],
		omitIfEmpty: true,
	}
};

export const actionDocumentMeta = {
	name: {
		name: 'ActionDocumentName',
		type: 'string',
		default: false,
	},
	description: {
		name: 'Description',
		type: 'string',
		default: '',
		omitIfEmpty: true,
	},
	is_published: {
		name: 'IsActive',
		type: 'boolean',
		default: false,
	},
	is_read: {
		name: 'IsReadAction',
		type: 'boolean',
		default: false,
	},
	guid: {
		name: 'ActionDocumentGuid',
		type: 'string',
		default: '',
	},
	conn_guid: {
		name: 'FlowConnectionGuid',
		type: 'string',
		default: '',
	},
	created: {
		name: 'CreatedDateTime',
		default: false,
		omitIfEmpty: true,
	},
	modified: {
		name: 'LastModifiedDateTime',
		default: false,
		omitIfEmpty: true,
	},
	action: {
		name: 'Action',
		type: 'object',
		default: {},
		omitIfEmpty: true,
		children: {
			...docFields,
			childs: {
				name: 'Childs',
				type: 'array',
				recursive: true,
				omitIfEmpty: true,
				default: [],
				children: {
					...docFields,
					relation: {
						name: 'ActionRelation',
						type: 'object',
						default: {
							childName: '',
							childField: '',
							parentField: '',
						},
						children: {
							child_name: {
								name: 'ChildName',
								type: 'string',
								default: '',
							},
							child_field: {
								name: 'ChildField',
								type: 'string',
								default: '',
							},
							parent_field: {
								name: 'ParentField',
								type: 'string',
								default: '',
							},
						}
					},
				},
			},
		},
	},
	rules: {
		name: 'MatchRules',
		type: 'array',
		omitIfEmpty: true,
		default: [],
		children: {
			guid: {
				name: 'ActionGuid',
				type: 'string',
				default: '',
			},
			name: {
				name: 'ActionName',
				type: 'string',
				default: '',
			},
			stop_if_match: {
				name: 'StopIfMatch',
				type: 'boolean',
				default: false,
			},
			stop_if_no_match: {
				name: 'StopIfNoMatch',
				type: 'boolean',
				default: false,
			},
			order: {
				name: 'SortOrder',
				type: 'number',
				default: 0,
			},
			output: {
				name: 'OutputField',
				type: 'array',
				default: [],
			},
			input: {
				name: 'InputField',
				type: 'array',
				default: [],
			},
		},
	},
};

// Action Documents
export const GET_ACTION_DOCUMENTS = {
    path: 'GetActionDocuments/{connection}',
    realm: apiBase,
    meta: actionDocumentMeta,
}

// Single Action Document
export const GET_ACTION_DOCUMENT = {
    path: 'GetActionDocument/{connection}/{guid}',
    realm: apiBase,
    meta: actionDocumentMeta,
}

// Documents of type action
export const GET_DOCUMENTS = {
    path: 'GetDocumentsOfTypeAction/{connection}',
    realm: apiBase,
    meta: connectionDocumentMeta,
	cacheKey: 'ActionDocument|GET_DOCUMENTS|{connection}',
}

// Referenced documents of type action
export const GET_REFERENCING_DOCUMENTS = {
    path: 'GetReferencingDocumentsOfTypeAction/{connection}/{guid}',
    realm: apiBase,
    meta: connectionDocumentMeta,
}

// Document of type action
export const GET_DOCUMENT = {
    path: 'GetConnectionDocument/{connection}/{guid}',
    realm: apiBase,
    meta: connectionDocumentMeta,
}

// Get Methods for specific Connection
export const GET_METHODS = {
	path: 'GetConnectionMethods/{connection}',
	realm: apiBase,
	parameters: {
        read: 'IsReadAction',
    },
	meta: methodMeta,
	cacheKey: 'ActionDocument|GET_METHODS|{connection}|{read}',
};

// Get Method for specific Connection
export const GET_METHOD = {
	path: 'GetConnectionMethod/{connection}/{guid}',
	realm: apiBase,
	meta: methodMeta,
};

// Save or publish Action Document
export const POST_ACTION_DOCUMENT = {
    path: 'PostActionDocument/{connection}/{guid}',
    method: 'post',
    realm: apiBase,
    meta: actionDocumentMeta,
}

// Delete Action Document
export const DELETE_ACTION_DOCUMENT = {
    path: 'DeleteActionDocument/{connection}/{guid}',
    method: 'delete',
    realm: apiBase,
}