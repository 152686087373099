<template>
	<DrawerItem
		:id="id"
		:drawerIndex="drawerIndex"
		:title="drawerName"
		:closeOnBackgroundClick="true"
		:onClose="closeDrawer"
	>
		<VField
			:isFloating="true"
			class="has-margin-right-1"
			:isActive="!!rule.guid"
		>
			<VInputSelect
				:label="$t('ad.matchRuleDetails.document')"
				v-model:valueModel="rule.guid"
				:disabled="!canBeEdited"
				:options="documentOptions"
			/>
		</VField>

		<template v-if="!!rule.guid">
			<div class="has-padding-top-1">
				<VTitle :size="4" :text="$t('ad.matchRuleDetails.matchRuleSettings')" />
				<ConfigOptions
					:options="configOptions"
					:disabled="!canBeEdited"
					v-model:valueModel="configModel"
				/>
			</div>

			<div class="has-padding-top-3">
				<div class="content-header has-padding has-bottom-divider has-margin-bottom-075">
					<div class="field is-grouped is-flex-grow-1 is-align-items-center">
						<VTitle
							:size="4"
							:text="$t('ad.matchRuleDetails.inputFields')"
							class="has-margin-bottom-0"
						/>
						<VSearch v-model="inputFieldsSearch" />
					</div>
				</div>

				<SortableDataTable
					:hoverable="false"
					:stickyHeader="false"
					default-sort="field_name"
					default-sort-direction="ASC"
					tableClasses="table-without-padding"
					:columns="fieldsTablesColumns"
					:data="inputFieldsTableRowsFiltered"
					:emptyText="$t('ad.matchRuleDetails.fieldsPickerTable.noResults')"
					:checkable="true"
					:isRowCheckable="() => canBeEdited"
					:headerCheckable="canBeEdited && inputFieldsTableRows.length > 0"
					v-model:checkedRows="inputCheckedRows"
				>
					<template
						v-for="(row) in inputFieldsTableRowsFiltered"
						:key="row.key"
						#[`field_name-${row.key}`]
					>
						<div @click.stop="inputFieldsRowClicked(row)">
							{{ row.field_name }}
						</div>
					</template>
				</SortableDataTable>
			</div>

			<div class="has-padding-top-3">
				<div class="content-header has-padding has-bottom-divider has-margin-bottom-075">
					<div class="field is-grouped is-flex-grow-1 is-align-items-center">
						<VTitle
							:size="4"
							:text="$t('ad.matchRuleDetails.outputFields')"
							class="has-margin-bottom-0"
						/>
						<VSearch v-model="outputFieldsSearch" />
					</div>
				</div>

				<SortableDataTable
					:hoverable="false"
					:stickyHeader="false"
					default-sort="field_name"
					default-sort-direction="ASC"
					tableClasses="table-without-padding"
					:columns="fieldsTablesColumns"
					:data="outputFieldsTableRowsFiltered"
					:emptyText="$t('ad.matchRuleDetails.fieldsPickerTable.noResults')"
					:checkable="true"
					:isRowCheckable="() => canBeEdited"
					:headerCheckable="canBeEdited && outputFieldsTableRows.length > 0"
					v-model:checkedRows="outputCheckedRows"
				>
					<template
						v-for="(row) in outputFieldsTableRowsFiltered"
						:key="row.key"
						#[`field_name-${row.key}`]
					>
						<div @click.stop="outputFieldsRowClicked(row)">
							{{ row.field_name }}
						</div>
					</template>
				</SortableDataTable>
			</div>
		</template>

		<template v-if="canBeEdited" #footer>
			<SaveButton
				:isDisabled="!modified"
				class="button-modal-footer"
				:text="$t('ad.matchRuleDetails.saveButton')"
				icon="chevron-right"
				:iconOnRight="true"
				:callbackFn="saveMatchRule"
			/>
		</template>
	</DrawerItem>
</template>

<script>
export default {
	name: 'ActionDocumentMatchRuleDetailsDrawer',
};
</script>

<script setup>
import { useStore } from 'vuex';
import { ref, unref, watch, computed, onBeforeMount, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';
import Helpers from '@assets/scripts/helpers';
import {
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import {
	createNewMatchRule,
} from '@modules/ActionDocument/components/action-document';
import {
	GET_DOCUMENT,
} from '@modules/ActionDocument/endpoints';

const {
	drawerIndex,
	id,
	config: {
		key = false,
		callback = () => {},
	},
} = defineProps({
	/**
	* Index of this drawer
	*/
	drawerIndex: {
		type: Number,
		required: true,
		default: 0,
	},
	/**
	* Unique key of this drawer
	*/
	id: {
		type: String,
		required: true,
	},
	config: {
		type: Object,
		default: {
			callback: () => {},
		},
	},
});

const store = useStore();
const { t } = useI18n();

const inputFieldsSearch = ref('');
const outputFieldsSearch = ref('');

// define empty match rule object
const rule = ref({});
// define document that is currently active/chosen by user
const activeDocument = ref({});

// get currently active action document
const document = store.getters[getStoreGetter('CURRENT_ACTION_DOCUMENT', 'AD')];
// get current connection
const currentConnection = store.getters[getStoreGetter('CURRENT_CONNECTION', 'AD')];
// Boolean to indicate whether current action document can be edited
const canBeEdited = store.getters[getStoreGetter('CAN_BE_EDITED', 'AD')];
// set boolean to indicate whether current action is modified by user
const modified = ref(false);

const drawerName = t('ad.documentDetails.matchRule');

const fieldsTablesColumns = computed(() => {
	const columns = [
		{
			label: t('ad.matchRuleDetails.fieldsPickerTable.columns.field_name'),
			field: 'field_name',
			sortable: true,
			searchable: true,
			default: t('general.dash'),
			width: canBeEdited ? '75%' : 'auto',
		},
	];

	if (canBeEdited) {
		columns.push(
			{
				label: t('ad.matchRuleDetails.fieldsPickerTable.columns.field_type'),
				field: 'field_type',
				sortable: true,
				searchable: true,
				default: t('general.dash'),
				width: '25%',
			},
		);
	}

	return columns;
});

const inputFieldsTableRows = computed(() => {
	const rows = [];

	if (canBeEdited) {
		// check if active document has fields
		if (unref(activeDocument).fields) {
			// add all fields to table rows
			unref(activeDocument).fields.forEach((field, key) => {
				rows.push({
					key,
					field_name: field.name,
					field_type: field.type,
				});
			});
		}
	} else {
		// add only currently configured required fields to rows
		unref(rule)?.input.forEach((field, key) => {
			rows.push({
				key,
				field_name: field,
			});
		});
	}

	return rows;
});

const inputFieldsTableRowsFiltered = computed(() => {
	const newData = unref(inputFieldsTableRows);

	// return if no rows match
	if (unref(inputFieldsSearch).length === 0 || newData.length === 0) return newData;

	// filter on search string
	return Helpers.filterByString(
		newData,
		unref(inputFieldsSearch),
		Helpers.getSearchableColumns(unref(fieldsTablesColumns)),
	);
});

// check rows used as v-model for setting input fields
const inputCheckedRows = computed({
	get: () => {
		return unref(inputFieldsTableRows).filter((row) => unref(rule).input.includes(row.field_name));
	},
	set: (value) => {
		rule.value.input = value.map((row) => row.field_name);
	},
});

const inputFieldsRowClicked = (row) => {
	if (!canBeEdited) return;

	const index = unref(inputCheckedRows).findIndex((el) => el.field_name === row.field_name);

	// N.B.: make SHALLOW copy here
	// two-way binding for checked rows only works that
	// way with Buefy Table
	const newCheckedRows = Helpers.cloneObject(unref(inputCheckedRows), false);

	// check row
	if (index < 0) newCheckedRows.push(row);
	// uncheck row
	else newCheckedRows.splice(index, 1);

	// set checkedRows prop
	inputCheckedRows.value = newCheckedRows;
};

const outputFieldsTableRows = computed(() => {
	const rows = [];

	if (canBeEdited) {
		// check if active document has fields
		if (unref(activeDocument).fields) {
			// add all fields to table rows
			unref(activeDocument).fields.forEach((field, key) => {
				rows.push({
					key,
					field_name: field.name,
					field_type: field.type,
				});
			});
		}
	} else {
		// add only currently configured required fields to rows
		unref(rule)?.output.forEach((field, key) => {
			rows.push({
				key,
				field_name: field,
			});
		});
	}

	return rows;
});

const outputFieldsTableRowsFiltered = computed(() => {
	const newData = unref(outputFieldsTableRows);

	// return if no rows match
	if (unref(outputFieldsSearch).length === 0 || newData.length === 0) return newData;

	// filter on search string
	return Helpers.filterByString(
		newData,
		unref(outputFieldsSearch),
		Helpers.getSearchableColumns(unref(fieldsTablesColumns)),
	);
});

// check rows used as v-model for setting output fields
const outputCheckedRows = computed({
	get: () => {
		return unref(outputFieldsTableRows).filter((row) => unref(rule).output.includes(row.field_name));
	},
	set: (value) => {
		rule.value.output = value.map((row) => row.field_name);
	},
});

const outputFieldsRowClicked = (row) => {
	if (!canBeEdited) return;

	const index = unref(outputCheckedRows).findIndex((el) => el.field_name === row.field_name);

	// N.B.: make SHALLOW copy here
	// two-way binding for checked rows only works that
	// way with Buefy Table
	const newCheckedRows = Helpers.cloneObject(unref(outputCheckedRows), false);

	// check row
	if (index < 0) newCheckedRows.push(row);
	// uncheck row
	else newCheckedRows.splice(index, 1);

	// set checkedRows prop
	outputCheckedRows.value = newCheckedRows;
};

const watchers = [];
const configModel = ref({});

const configOptions = [
	{
		value: 'stop_if_match',
		label: t('ad.matchRuleDetails.settings.stop_if_match'),
		info: t('ad.matchRuleDetails.settings.stop_if_match_info'),
	},
	{
		value: 'stop_if_no_match',
		label: t('ad.matchRuleDetails.settings.stop_if_no_match'),
		info: t('ad.matchRuleDetails.settings.stop_if_no_match_info'),
	},
];

// compute available options for document
const documentOptions = computed(() => {
	let output = [];

	// add currently selected document to options
	if (!!unref(rule).guid && !!unref(rule).name) {
		output.push({
			value: unref(rule).guid,
			text: unref(rule).name,
		});
	}

	// add all available documents to options
	Object.values(document.actions).forEach((action) => {
		if (action.type === 'Document') {
			output.push({
				value: action.guid,
				text: action.name,
			});
		}
	});

	if (output.length > 1) {
		// make unique
		output = output.filter((value, index, self) => self.findIndex((t) => t.value === value.value) === index);
		// order alphabetically
		output.sort((a, b) => a.text.localeCompare(b.text));
	}

	return output;
});

onBeforeMount(async () => {
	if (key !== false && unref(document).rules[key]) {
		// get viewed match rule from action document, cloned as to not modify original
		rule.value = Helpers.cloneObject(unref(document).rules[key]);
	} else {
		// create empty match rule object
		rule.value = createNewMatchRule(document);
	}

	//prepare config model
	configOptions.forEach((option) => {
		if (typeof rule.value[option.value] !== 'undefined') {
			configModel.value[option.value] = rule.value[option.value];
		}
	});

	watchers.push(watch(configModel, (value) => {
		// update match rule with new values of the config
		Object.assign(rule.value, value);
	}));

	// watch any change to the match rule object
	watchers.push(watch(rule, () => {
		// mark as modified if match rule object changes
		modified.value = true;
	}, {
		deep: true,
	}));
	
	// watch specifically a change to the rule guid
	watchers.push(watch(() => unref(rule).guid, () => {
		ruleGuidUpdated();
	}));

	if (canBeEdited) {
		// load full object of active document
		activeDocument.value = await getFullDocument(unref(rule).guid);
	}
});

onUnmounted(() => {
	// stop al watchers on unmount
	watchers.forEach((unwatch) => unwatch());
});

// called when rule is updated by user
const ruleGuidUpdated = async () => {
	// get new document object
	activeDocument.value = await getFullDocument(unref(rule).guid);

	// prepare new values for rule object
	const newRuleValues = {
		name: unref(activeDocument).name || '',
		input: [],
		output: [],
	};

	// update action object with new values
	rule.value = {
		...unref(rule),
		...newRuleValues,
	};
};

const getFullDocument = async (guid = false) => {
	if (!guid) return {};

	// load full action object for current connection
	const result = await useApiAsync(GET_DOCUMENT, {
		keys: {
			connection: currentConnection,
			guid,
		},
	});

	return result ?? {};
};

const saveMatchRule = () => {
	if (!canBeEdited) return;

	callback(unref(rule));

	// mark as no longer modified
	modified.value = false;

	closeDrawer();
};

const closeDrawer = () => {
	const closeDrawer = () => {
		store.commit(getStoreMutation('CLOSE_DRAWER'), id);
	};

	if (!unref(modified)) {
		// close immediately if action has not been modified
		closeDrawer();
	} else {
		// ask confirmation before closing if a change has been made
		// to the action config
		store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
			title: t(
				'ad.matchRuleDetails.close.confirm.title'
			),
			body: t(
				'ad.matchRuleDetails.close.confirm.body'
			),
			confirmButtonText: t(
				'ad.matchRuleDetails.close.confirm.confirmButtonText'
			),
			confirmFn: () => {
				// close after confirmation
				closeDrawer();
			},
		});
	}
};
</script>
