import ConnectionBuilder from './ConnectionBuilder/auth';
import DocumentBuilder from './DocumentBuilder/auth';
import DocumentEditor from './DocumentEditor/auth';
import FlowBuilder from './FlowBuilder/auth';
import MethodBuilder from './MethodBuilder/auth';
import RestApiBuilder from './RestApiBuilder/auth';
import UserManagement from './UserManagement/auth';
import ComposedDocument from './ComposedDocument/auth';
import ActionDocument from './ActionDocument/auth';
import SegmentBuilder from './SegmentBuilder/auth';
import Reporting from './Reporting/auth';
import Dashboard from './Dashboard/auth';
import Customers from './Customers/auth';
import Queue from './Queue/auth';
import Dispatcher from './Dispatcher/auth';
import Datacleansing from './Datacleansing/auth';
import FunctionBlocks from './FunctionBlocks/auth';
import GenerativeAI from './GenerativeAI/auth';

// NOTE: this is the order in which the modules are displayed when
// setting permissions for a role
export default {
    FlowBuilder,
    DocumentBuilder,
    ComposedDocument,
    ActionDocument,
    MethodBuilder,
    ConnectionBuilder,
    RestApiBuilder,
    UserManagement,
    DocumentEditor,
    Reporting,
    SegmentBuilder,
    Dashboard,
    Customers,
    Queue,
    Dispatcher,
    Datacleansing,
    FunctionBlocks,
    GenerativeAI,
};
