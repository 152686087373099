import i18n from '@assets/i18n';
const TheDashboardPage = () => import('@modules/Dashboard/TheDashboardPage.vue');

// translate function of vue-i18n
const { t } = i18n.global;

export default [
	{
		path: '/dashboard',
		component: TheDashboardPage,
		name: 'dashboard',
		meta: {
			title: t('dashboard.pageTitle'),
			permission: 'Read',
			showInHome: true,
			showInSideNav: true,
			parent: 'clientModules',
			icon: 'gear',
			module: 'Dashboard',
			order: 100,
		},
	},
];