import { IS_DEV } from '@assets/scripts/helpers';
import { isEmpty } from 'lodash';
import { constructFullDocument } from '@modules/DocumentBuilder/components/connection-document';
import usePermission from '@assets/scripts/composables/usePermission';

export const names = {
	MODIFIED: 'modified',
	CAN_BE_EDITED: 'canBeEdited',
	CAN_EDIT_DOC_NAME: 'canEditDocName',
	CAN_BE_EXTENDED: 'canBeExtended',
	CAN_BE_SAVED: 'canBeSaved',
	CAN_BE_PUBLISHED: 'canBePublished',
	DOCUMENT_ACTIVE: 'documentActive',
	CURRENT_DOCUMENT: 'currentDocument',
	CURRENT_CONNECTION: 'currentConnection',
	SHOW_CREATE_NEW: 'showCreateNew',
	FULL_DOCUMENT: 'fullDocument',
};

export default {
	[names.MODIFIED]: ({ modified }) => {
		return modified;
	},
	[names.CAN_BE_EDITED]: ({ canBeModified }) => {
		// can be edited if it has never been published yet or user is is admin
		return canBeModified;
	},
	[names.CAN_EDIT_DOC_NAME]: ({ canModifyDocName }) => {
		// can be edited if it has never been published yet
		return canModifyDocName;
	},
	[names.CAN_BE_EXTENDED]: ({ canBeExtended }) => {
		return canBeExtended;
	},
	[names.CAN_BE_SAVED]: ({ canBeModified, modified }) => {
		// can be saved only if it has never
		// been published yet, and it has been modified
		return canBeModified && modified;
	},
	[names.CAN_BE_PUBLISHED]: ({ canBeModified, canBeExtended, modified }) => {
		// if a document has not been published yet, or it has been
		// modified, it can be published, IF current user has correct permissions
		return (
			(canBeModified || (canBeExtended && modified)) &&
			usePermission('Upsert', 'DocumentBuilder')
		);
	},
	[names.DOCUMENT_ACTIVE]: ({ currentDocument }) => {
		return Object.keys(currentDocument).length > 0;
	},
	[names.CURRENT_DOCUMENT]: ({ currentDocument }) => {
		return currentDocument;
	},
	[names.CURRENT_CONNECTION]: ({ currentConnection }) => {
		return currentConnection;
	},
	[names.SHOW_CREATE_NEW]: ({ showCreateNew }) => {
		return showCreateNew;
	},
	[names.FULL_DOCUMENT]: ({ currentDocument }) => {
		return !isEmpty(currentDocument) && IS_DEV
			? constructFullDocument(currentDocument)
			: {};
	},
	debug: (state) => {
		// output whole state for debugging since Vue Devtools plugin
		// not (always) shows the updated state of the store, but it
		// does show the correct value of all getters
		return IS_DEV ? state : {};
	},
};
